import React, { useContext, useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { firestore, storage } from './firebase';
import { getFirestore, doc, getDoc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { AppContext } from './AppContext';
import SPNListPageMediaItems from './SPNListPageMediaItems';
import { getAuth } from "firebase/auth";
import addToSPNLISTbutton from './assets/AddToSPNLISTbutton.png';
import { useLocation, useNavigate } from 'react-router-dom';

const SPNListPage = ({ navigation }) => {
  const { currentUser, purchasedMedia, playlistsFresh, setPlaylistsFresh } = useContext(AppContext);
  const [selectedMedia, setSelectedMedia] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const { playlistId } = location.state;

  const extractSongsFromAlbums = (albums) => {
    let songs = [];
    albums.forEach(album => {
      if (album.songs && Array.isArray(album.songs)) {
        songs = [...songs, ...album.songs.map(song => ({ ...song, albumId: album.contentId }))];
      }
    });
    return songs;
  };

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const persistPlaylists = async (playlists) => {
    const userId = getCurrentUserId();
    try {
      const jsonValue = JSON.stringify(playlists);
    } catch (e) {
      console.error("Failed to save playlists to storage", e);
    }
  
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, {
        playlists: playlists,
      });
    } catch (error) {
      console.error("Error updating user's playlists:", error);
    }
  };

  const addSongToPlaylist = (playlistId, songIdsToAdd) => {
    const songsToAdd = Array.isArray(songIdsToAdd) ? songIdsToAdd : [songIdsToAdd];
    
    const updatedPlaylists = playlistsFresh.map(playlist => {
      if (playlist.id === playlistId) {
        let updatedSongs = playlist.songs ? [...playlist.songs] : [];
  
        let nextTrackNumber = updatedSongs.reduce((max, song) => Math.max(max, song.trackNumber), 0) + 1;
  
        songsToAdd.forEach(songId => {
          if (!updatedSongs.find(song => song?.contentId === songId)) {
            updatedSongs.push({
              contentId: songId,
              trackNumber: nextTrackNumber++
            });
          }
        });
  
        return { ...playlist, songs: updatedSongs };
      }
      return playlist;
    });
  
    setPlaylistsFresh(updatedPlaylists);
    persistPlaylists(updatedPlaylists);
  };

  const singlesFromPurchased = purchasedMedia.filter(item => !item.isAlbum && !item.isVideo);
  const songsFromPurchasedAlbums = extractSongsFromAlbums(purchasedMedia.filter(item => item.isAlbum && !(/^\d+$/).test(item.contentId)));

  const allMedia = [
    ...singlesFromPurchased,
    ...songsFromPurchasedAlbums,
    ...(currentUser?.singles || []),
    ...extractSongsFromAlbums(currentUser?.albums || []),
  ];

  const handleSelectMedia = (mediaId) => {
    if (selectedMedia.includes(mediaId)) {
      setSelectedMedia(selectedMedia.filter(id => id !== mediaId));
    } else {
      setSelectedMedia([...selectedMedia, mediaId]);
    }
  };

  const handleSubmit = () => {
    
    
    selectedMedia.forEach(songId => {
        addSongToPlaylist(playlistId, selectedMedia);
    });

    navigate(`/library/spnlistprofile`, { state: { playlistId } });
  };

  const fetchArtworkUrl = async (artworkUrl) => {
    try {
      let url;
      if (artworkUrl.startsWith('https://')) {
        url = artworkUrl;
      } else if (artworkUrl.startsWith('gs://')) {
        const imagePath = artworkUrl.split('gs://')[1].split('/').slice(1).join('/');
        url = await getDownloadURL(ref(storage, imagePath));
      }
      return url;
    } catch (error) {
      console.error('Failed to load image', error);
      return null;
    }
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.container}>
        <div style={styles.scrollContainer}>
          <div style={styles.contentContainer}>
            <div style={styles.listHeader}>
              <button style={styles.createIconTouchable} onClick={handleSubmit}>
                <img src={addToSPNLISTbutton} style={styles.createIcon} alt="Add to SPNList" />
              </button>
            </div>
            {allMedia.map((item) => (
              <SPNListPageMediaItems
                key={item.contentId}
                item={item}
                onPress={() => handleSelectMedia(item.contentId)}
                isSelected={selectedMedia.includes(item.contentId)}
                fetchArtworkUrl={fetchArtworkUrl}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    zIndex: 10,
    height: '82%',
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  fullScreenImageOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  createIcon: {
    height: '50px',
    width: '150px',
    alignSelf: 'center',
    resizeMode: 'contain',
    zIndex: 100, 
  },
  createIconTouchable: {
    marginTop: '10px',
    display: 'flex',
    margin: '0 auto',
    justifyContent: 'center',
    height: '5.2%',
    width: '22vw',
    alignSelf: 'center',
    resizeMode: 'contain',
    zIndex: 100, 
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    width: '100vw',
    height: '82vh',
    zIndex: 2,
    overflowY: 'auto',
  },
  contentContainer: {
    paddingBottom: '18vh',
    paddingHorizontal: '4.2vw',
  },
  fullScreen: {
    position: 'relative',
    height: '100vh',
  },
  buttonTouchableArea: {
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    zIndex: 1,
  },
  listHeader: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1vh',
  },
};

export default SPNListPage;
