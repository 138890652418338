import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, deleteUser, signInWithEmailAndPassword } from 'firebase/auth';
import { deleteDoc, getDocs, query, collection, where, updateDoc, arrayRemove, doc, getFirestore, writeBatch } from 'firebase/firestore';
import { AppContext } from './AppContext';

const DeactivateAccount = () => {
  const auth = getAuth();
  const db = getFirestore();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');

  const { currentlyDeletingProfile, setCurrentlyDeletingProfile } = useContext(AppContext);

  const reauthenticate = async (currentPassword) => {
    try {
      const user = auth.currentUser;
      await signInWithEmailAndPassword(auth, user.email, currentPassword);
      return true;
    } catch (error) {
      console.error("Re-authentication failed:", error);
      alert("Authentication failed. Please check your current password and try again.");
      return false;
    }
  };

  async function deleteUserData(userId) {
    try {
      // Delete user document from 'users' collection
      await deleteDoc(doc(db, 'users', userId));

      // List all known user subcollections
      const userSubcollections = ['notifications', 'likes', 'posts', 'profileData', 'purchaseHistory', 'salesHistory', 'transactionHistory', 'transactions'];
      await Promise.all(userSubcollections.map(async subcollection => {
        const subRef = collection(db, 'users', userId, subcollection);
        const subSnapshot = await getDocs(subRef);
        return Promise.all(subSnapshot.docs.map(doc => deleteDoc(doc.ref)));
      }));

      // Define other collections to check for userId
      const collectionsToDelete = ['Songs', 'Albums', 'Videos'];
      for (const colName of collectionsToDelete) {
        const colRef = collection(db, colName);
        const q = query(colRef, where('userId', '==', userId));
        const querySnapshot = await getDocs(q);
        await Promise.all(querySnapshot.docs.map(document => deleteDoc(doc(db, colName, document.id))));
      }

      // Handle posts collection more robustly
      const postsRef = collection(db, 'posts');
      const postsQuery = query(postsRef, where('userId', '==', userId));
      const postsSnapshot = await getDocs(postsQuery);
      const batch = writeBatch(db);
      postsSnapshot.docs.forEach((postDoc) => {
        batch.delete(doc(db, 'posts', postDoc.id)); // Delete the post document
      });
      await batch.commit();

      const likesRef = collection(db, 'likes');
      const likesQuery = query(likesRef, where('userId', '==', userId));
      const likesSnapshot = await getDocs(likesQuery);
      await Promise.all(likesSnapshot.docs.map(likeDoc => deleteDoc(doc(db, 'likes', likeDoc.id))));

      // Remove userId from followers and following of other users
      const usersRef = collection(db, 'users');
      const followersQuery = query(usersRef, where('followers', 'array-contains', userId));
      const followingQuery = query(usersRef, where('following', 'array-contains', userId));

      const [followersSnapshot, followingSnapshot] = await Promise.all([
        getDocs(followersQuery),
        getDocs(followingQuery)
      ]);

      const userUpdateBatch = writeBatch(db);
      followersSnapshot.docs.concat(followingSnapshot.docs).forEach(userDoc => {
        userUpdateBatch.update(userDoc.ref, {
          followers: arrayRemove(userId),
          following: arrayRemove(userId)
        });
      });
      await userUpdateBatch.commit();

    } catch (error) {
      console.error("Error during user data deletion process: ", error);
    }
  }

  const handleDeactivateAccount = async () => {
    if (await reauthenticate(currentPassword)) {
      try {
        setCurrentlyDeletingProfile(true);
        const user = auth.currentUser;
        await deleteUserData(user.uid);
        await deleteUser(user); // Then delete the user from Firebase Authentication
        alert("Success! Account deactivated successfully!");
        setCurrentlyDeletingProfile(false);
        navigate('/'); // Navigate to a different page after account deletion
      } catch (error) {
        console.error("Error deactivating account:", error);
        alert("Deactivation Failed. Failed to delete account.");
        setCurrentlyDeletingProfile(false);
      }
    }
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <p style={styles.infoText}>
          Enter your password to permanently delete your account and content
        </p>
        <input
          style={styles.input}
          onChange={(e) => setCurrentPassword(e.target.value)}
          value={currentPassword}
          placeholder="Current password"
          type="password"
        />
        <button style={styles.button} onClick={handleDeactivateAccount}>
          <span style={styles.buttonText}>Delete Account</span>
        </button>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollContainer: {
    width: '100%',
    maxWidth: '800px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20vh',
    paddingBottom: '10vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  input: {
    width: '90%',
    padding: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    alignSelf: 'center',
    color: 'white',
    backgroundColor: 'transparent',
  },
  button: {
    borderRadius: 5,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    width: '90%',
    marginBottom: 10,
    backgroundColor: 'transparent',
    border: 'none',
  },
  buttonText: {
    fontFamily: 'brhendrix',
    color: '#62fbfa',
    fontSize: '18px',
  },
  infoText: {
    width: '90%',
    alignSelf: 'center',
    marginBottom: 10,
    color: 'white',
    textAlign: 'center',
  },
};

export default DeactivateAccount;
