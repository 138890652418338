import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { AppContext } from './AppContext';
import { 
  doc, 
  getDoc, 
  collection, 
  query, 
  where, 
  getDocs, 
  limit 
} from 'firebase/firestore';
import { firestore } from './firebase';
import { ProfileComponentFollowing } from './ProfileComponentFollowing';
import debounce from 'lodash/debounce';

const CACHE_DURATION = 30000;

const ProfilesDisplayShareDATpost = ({ onShareDAT }) => {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { showMediaPopup, followers, currentUser, setViewingProfile, userId2, setActivePopupMedia, activePopupMedia, setShowShareDATPopup, setShowSharePostPopup, setSharePostActive, selectedUserIdShare, setSelectedProfile, setSelectedUserIdShare } = useContext(AppContext);

  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const followersSet = useMemo(() => new Set(followers), [followers]);

  const isLoadingRef = useRef(false);

  useEffect(() => {
    if (isSelecting) {
      setIsSelecting(false);
    }
  }, [activePopupMedia, isSelecting]);

  useEffect(() => {
    loadInitialProfiles();
  }, [followers]);

  async function loadInitialProfiles() {
    if (Array.isArray(followers)) {
      setIsLoading(true);
      let loadedProfiles = [];
      for (const userId of followers.slice(0, 9)) {
        if (userId !== userId2) {
          const userData = await getCachedUserDocMinimal(userId);
          if (userData) {
            loadedProfiles.push(userData);
          }
        }
      }
      setProfiles(loadedProfiles);
      setFilteredProfiles(loadedProfiles);
      setIsLoading(false);
    } else {
      console.warn("Followers data is not valid.");
    }
  }

  const getCachedUserDocMinimal = async (userId) => {
    let userDataCached = [];

    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const freshUserData = userDocSnap.data();
      userDataCached = {
        ...freshUserData,
        timestamp: new Date().getTime(),
      };

      return {
        userId: userId,
        voiceDropMuted: userDataCached.voiceDropMuted,
        accountType: userDataCached.accountType,
        name: userDataCached.name,
        username: userDataCached.username,
        profileImageUrl: userDataCached.profileImageUrl,
      };
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const loadMoreProfiles = async () => {
    if (isLoadingRef.current) return;
    if (!isSearching && profiles.length < followers.length) {
      if (profiles.length >= followers.length) return;

      isLoadingRef.current = true;
      setIsLoading(true);
      let newProfiles = [];
      let index = profiles.length;
      let loadedCount = 0;

      while (loadedCount < 9 && index < followers.length) {
        const userId = followers[index];
        index++;

        if (userId !== userId2 && !profiles.some((p) => p.userId === userId)) {
          const userData = await getCachedUserDocMinimal(userId);
          if (userData) {
            newProfiles.push(userData);
            loadedCount++;
          }
        }
      }

      setProfiles((prevProfiles) => {
        const existingIds = new Set(prevProfiles.map((p) => p.userId));
        const filteredNewProfiles = newProfiles.filter((p) => !existingIds.has(p.userId));
        return [...prevProfiles, ...filteredNewProfiles];
      });
      setFilteredProfiles((prevFilteredProfiles) => {
        const existingIds = new Set(prevFilteredProfiles.map((p) => p.userId));
        const filteredNewProfiles = newProfiles.filter((p) => !existingIds.has(p.userId));
        return [...prevFilteredProfiles, ...filteredNewProfiles];
      });
      setIsLoading(false);
      isLoadingRef.current = false;
    }
  };

  const handleProfileSelect = async (item) => {
    setSelectedProfile(item);
    const selectedUserId = item.userId;
    setSelectedUserIdShare(selectedUserId);
    setShowSharePostPopup(true);
    setSharePostActive(false);
  };

  const renderProfile = (item) => {
    if (!item) return null;

    return <ProfileComponentFollowing userProfile={item} onPress={() => handleProfileSelect(item)} />;
  };

  const fetchProfilesFromFirestore = async (searchTextInput) => {
    if (!searchTextInput.trim()) {
      setFilteredProfiles(profiles);
      setIsSearching(false);
      return;
    }

    setIsLoading(true);
    setIsSearching(true);
    const searchTextLower = searchTextInput.toLowerCase().trim();
    const usernameSearchText = searchTextLower.replace(/\s+/g, '');

    try {
      // Define your search queries
      const userQuery = query(
        collection(firestore, 'users'),
        where('username_lower', '>=', searchTextLower),
        where('username_lower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );

      const userArtistNameQuery = query(
        collection(firestore, 'users'),
        where('ArtistNameLower', '>=', searchTextLower),
        where('ArtistNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );

      const exactUsernameQuery = query(
        collection(firestore, 'users'),
        where('username_lower', '==', usernameSearchText),
        limit(7)
      );

      // Execute all queries concurrently
      const results = await Promise.all([
        getDocs(userQuery),
        getDocs(userArtistNameQuery),
        getDocs(exactUsernameQuery)
      ]);

      // Combine all fetched documents
      const combinedItems = results.flatMap((snap) =>
        snap.docs.map((doc) => ({ ...doc.data(), userId: doc.id }))
      );

      // Remove duplicates
      const uniqueItems = Object.values(
        combinedItems.reduce((acc, item) => {
          const key = `${item.userId}`;
          if (!acc[key]) acc[key] = item;
          return acc;
        }, {})
      );

      // Sort the unique items based on your criteria
      uniqueItems.sort((a, b) => {
        const aNameNoSpaces = a.name.replace(/\s+/g, '').toLowerCase();
        const bNameNoSpaces = b.name.replace(/\s+/g, '').toLowerCase();
        const aUsername = a.username.toLowerCase();
        const bUsername = b.username.toLowerCase();

        if (aUsername === aNameNoSpaces && bUsername !== bNameNoSpaces) return -1;
        if (aUsername !== aNameNoSpaces && bUsername === bNameNoSpaces) return 1;

        if (a.accountType === 'Artist' && b.accountType !== 'Artist') return -1;
        if (a.accountType !== 'Artist' && b.accountType === 'Artist') return 1;

        return 0;
      });

      // Filter the unique items to include only those in followers
      const filteredResults = uniqueItems.filter(item => followersSet.has(item.userId));

      setFilteredProfiles(filteredResults);
    } catch (error) {
      console.error('Error fetching users:', error);

    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (text) => {
    setSearchText(text);
    debounceFetchProfiles(text);
  };

  // Debounce the search to reduce the number of Firestore reads
  const debounceFetchProfiles = useMemo(() => debounce((text) => {
    fetchProfilesFromFirestore(text);
  }, 600), [followersSet, profiles]);

  return (
    <div style={styles.container}>
      {/* Added Header Text */}
      <h2 style={styles.headerText}>Share this post in a message</h2>

      <input
        style={styles.searchInput}
        placeholder="Search..."
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <div style={styles.profileList}>
        {filteredProfiles.map((profile, index) => (
          <ProfileComponentFollowing key={profile.userId} userProfile={profile} onPress={() => handleProfileSelect(profile)} />
        ))}
        {isLoading && <div style={styles.activityIndicator}>Loading...</div>}
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: '100%',
    position: 'absolute',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '20px', // Added padding to accommodate the header
  },
  headerText: { // New style for the header text
    textAlign: 'center',
    color: 'white',
    fontSize: '24px',
    marginBottom: '10px',
    fontFamily: 'brhendrix', // Assuming consistent font family
  },
  searchInput: {
    alignSelf: 'center',
    height: '4vh',
    width: '400px',
    display: 'flex',
    justifyContent: 'center',
    borderColor: 'rgba(189, 252, 251, 0.3)',
    fontSize: '16px',
    borderWidth: '1px',
    border: '1px solid #6b92c2',
    margin: '10px auto', // Combined margin properties
    backgroundColor: 'transparent',
    borderRadius: '5px',
    paddingLeft: '10px',
    color: 'white',
    fontFamily: 'brhendrix',
  },
  activityIndicator: {
    marginTop: '5vh',
    textAlign: 'center',
    color: 'white',
  },
  profileList: {
    display: 'flex',
    overflowX: 'scroll',
    paddingRight: '10vw',
    justifyContent: 'center', // Center profiles horizontally
  },
};

export { ProfilesDisplayShareDATpost };
