import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { firestore } from './firebase';

const SPNSKNscreenSKN = ({ item, onPress }) => {
  const { currentUser, screenSKNs } = useContext(AppContext);
  const [songArtworkUrl, setSongArtworkUrl] = useState(null);

  useEffect(() => {
    if (!item.isLocal && item.buttonImage) {
      const fetchArtworkUrl = async () => {
        try {
          let url;
          if (item.buttonImage.startsWith('https://')) {
            url = item.buttonImage;
          } else if (item.buttonImage.startsWith('gs://')) {
            const imagePath = item.buttonImage.split('gs://')[1].split('/').slice(1).join('/');
            url = await getDownloadURL(ref(getStorage(), imagePath));
          }
          setSongArtworkUrl(url);
        } catch (error) {
          console.error('Failed to load image', error);
        }
      };
      fetchArtworkUrl();
    }
  }, [item.buttonImage, item.isLocal]);

  if (!item) {
    return <div style={styles.songArtworkPlaceholder} />;
  }



  return (
    <div style={styles.itemContainer} >
      <div style={styles.horizontalTouchable}>
        {(item.isLocal || songArtworkUrl) ? (
          <img src={item.isLocal ? item.buttonImage : songArtworkUrl} style={styles.songArtwork} alt="Artwork" />
        ) : (
          <div style={styles.songArtworkPlaceholder}>Loading...</div>
        )}
      </div>
      <p style={styles.artistName}>{item.artistName}</p>
      <p style={styles.songName}>
        {item.isAlbum ? (item.albumName || 'Unknown Album') : (item.screenSKNName || 'Unknown Song')}
      </p>
    </div>
  );
};

const imageSize = '100px';

const styles = {
  itemContainer: {
    width: '130px',
    alignItems: 'flex-start',
    marginRight: '10px',
    paddingTop: '10px',
  },
  horizontalTouchable: {
    width: imageSize,
    height: imageSize,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  songArtwork: {
    width: '100%',
    height: '100%',
    borderRadius: '100px',
  },
  songArtworkPlaceholder: {
    width: imageSize,
    height: imageSize,
    borderRadius: '8px',
    backgroundColor: 'grey',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  artistName: {
    marginTop: '15px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
  songName: {
    marginTop: '-15px',
    color: '#b8b8b8',
    fontSize: '14px',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
};

export default SPNSKNscreenSKN;
