import React, { useState, useEffect, useContext } from 'react';
import { ProfileComponentMessages } from './ProfileComponentMessages';
import { AppContext } from './AppContext';
import unreadIndicatorImage from './assets/unread.png';

const ProfilesDisplayMessages = ({ onProfilePress }) => {
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { showMediaPopup, currentUser, activePopupMedia, profiles, isLoading } = useContext(AppContext);
  const [isSelecting, setIsSelecting] = useState(false);

  const handleProfileSelect = async (profileData) => {
    onProfilePress(profileData);
    try {
      setIsSelecting(true);
    } catch (error) {
      console.error('Error in handleProfileSelect:', error);
    }
  };

  useEffect(() => {
    if (isSelecting && activePopupMedia) {
      onProfilePress(); // Call the function after activePopupMedia has been updated
      setIsSelecting(false); // Reset isSelecting to false after calling onProfilePress
    }
  }, [activePopupMedia, isSelecting, onProfilePress]); // Depend on activePopupMedia and isSelecting

  useEffect(() => {
    
  }, [profiles]);

  useEffect(() => {
    if (searchText.trim() === '') {
      setFilteredProfiles(profiles);
    } else {
      const lowercasedSearchText = searchText.toLowerCase().trim();
      const filtered = profiles.filter(profile => {
        const usernameMatch = profile.username.toLowerCase().includes(lowercasedSearchText);
        const nameMatch = profile.name && profile.name.toLowerCase().includes(lowercasedSearchText);

        
        

        return usernameMatch || nameMatch;
      });
      setFilteredProfiles(filtered);
    }
  }, [profiles, searchText]);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  return (
    <div style={styles.container}>
      <input
        style={styles.searchInput}
        placeholder="Search..."
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
      />
      <div style={styles.dynamicContentContainer}>
        {isLoading ? (
          <div style={styles.activityIndicator}>Loading...</div>
        ) : (
          <div style={styles.profilesList}>
            {filteredProfiles.map(item => (
              <div key={item.userId} style={styles.profileItem}>
                <ProfileComponentMessages userProfile={item} onPress={() => handleProfileSelect(item)} />
                {item.unread && (
                  <img
                    src={unreadIndicatorImage}
                    alt="Unread"
                    style={styles.unreadIndicator}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div style={styles.extraSpace}></div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    
  },
  extraSpace: {
    height: '300px',
      },
  searchInput: {
    width: '90%',
    height: '40px',
    borderColor: 'rgba(189, 252, 251, 0.3)',
    borderWidth: '1px',
    margin: '10px',
    borderRadius: '5px',
    paddingLeft: '10px',
    color: 'white',
    fontSize: '16px',
    backgroundColor: '#0e061e',
    outline: 'none',
  },
  dynamicContentContainer: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    position: 'relative',
    overflowY: 'scroll',
    overflowX: 'hidden',
    
  },
  activityIndicator: {
    marginTop: '20px',
    color: '#62fbfa',
    fontSize: '18px',
  },
  profilesList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  profileItem: {
    position: 'relative',
    width: '90%',
    cursor: 'pointer',
  },
  unreadIndicator: {
    position: 'absolute',
    width: '10px',
    height: '10px',
    right: '60px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
};

export { ProfilesDisplayMessages };
