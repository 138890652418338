import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './firebase';

const SPNListProfileMediaItems = ({ item, onPress, isSelected, onDelete }) => {
  const [songArtworkUrl, setSongArtworkUrl] = useState(null);

  useEffect(() => {
    const fetchArtworkUrl = async (artworkUrl) => {
      try {
        let url;
        if (artworkUrl.startsWith('https://')) {
          url = artworkUrl;
        } else if (artworkUrl.startsWith('gs://')) {
          const imagePath = artworkUrl.split('gs://')[1].split('/').slice(1).join('/');
          url = await getDownloadURL(ref(storage, imagePath));
        }
        setSongArtworkUrl(url);
      } catch (error) {
        console.error('Failed to load image', error);
      }
    };

    if (item?.ArtworkURL || item?.artworkURL) {
      fetchArtworkUrl(item.ArtworkURL || item.artworkURL);
    }
  }, [item]);

  const titleToDisplay = item.isAlbum ? item.albumName : item.SongName;

  return (
    <div style={{ ...styles.mediaItem, ...(isSelected ? styles.selected : {}) }} onClick={onPress}>
      <div style={styles.mediaRow}>
        {songArtworkUrl ? (
          <img src={songArtworkUrl} alt="Artwork" style={styles.songArtwork} />
        ) : (
          <div style={styles.songArtworkPlaceholder}></div>
        )}
        <div style={styles.textContainer}>
          <span style={styles.artistName}>{item.ArtistName}</span>
          <span style={styles.songName}>{titleToDisplay}</span>
        </div>
        <button style={styles.deleteButton} onClick={() => onDelete(item.contentId)}>
          <span style={styles.deleteButtonText}>Delete</span>
        </button>
      </div>
      <div style={styles.borderContainer}>
        <div style={styles.centeredBorder}></div>
      </div>
    </div>
  );
};

const styles = {
  mediaItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '4px',
    alignItems: 'flex-start',
    position: 'relative',
    marginLeft: '23px',
  },
  mediaRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '70%',
  },
  textContainer: {
    marginLeft: '5px',
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  songArtwork: {
    width: '50px',
    height: '50px',
    borderRadius: '8px',
  },
  videoArtwork: {
    width: '200px',
    height: '112.5px', // Maintains 16:9 aspect ratio
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  songArtworkPlaceholder: {
    width: '50px',
    height: '50px',
    borderRadius: '8px',
    backgroundColor: '#f0f0f0',
  },
  artistName: {
    marginBottom: '4px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
  songName: {
    color: '#b8b8b8',
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    whiteSpace: 'normal', // Allow text to wrap to the next line
    overflow: 'hidden',   // Ensure text does not overflow outside the container
    display: '-webkit-box', 
    WebkitLineClamp: 2,   // Limit text to 2 lines
    WebkitBoxOrient: 'vertical'
  },
  selected: {
    backgroundColor: 'rgba(189, 252, 251, 0.15)',
  },
  borderContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: '0px',
  },
  centeredBorder: {
    marginTop: '4px',
    marginLeft: '23px',
    width: '83%',
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(189, 252, 251, 0.3)',
  },
  deleteButton: {
    backgroundColor: '#852061',
    justifyContent: 'center',
    alignItems: 'center',
    width: '80px',
    height: '30px',
    display: 'flex',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    right: '0',
    marginRight: '25px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 10,
  },
  deleteButtonText: {
    color: 'white',
    fontWeight: 'bold',
  },
};

export default SPNListProfileMediaItems;
