
import React, { createContext, useState, useEffect, useRef, useCallback } from 'react';
import { firestore, storage, database } from './firebase';
import { collection, getDocs, query, setDoc, orderBy, limit, onSnapshot, getDoc, doc, updateDoc, getFirestore, increment, where, deleteDoc, runTransaction } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, onValue, off } from 'firebase/database';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const setUser = (userData) => {
    setCurrentUser(userData);
  };

  const [dataFeaturedMusic, setDataFeaturedMusic] = useState([]);
  const [featuredSongs, setFeaturedSongs] = useState([]);
  const [newReleases, setNewReleases] = useState([]);

  const [groupedByGenres, setGroupedByGenres] = useState({});
  const [lastGenreDocs, setLastGenreDocs] = useState({});
  const [dataFeaturedVideos, setDataFeaturedVideos] = useState([]);
  const [newVideoReleases, setNewVideoReleases] = useState([]); 

  const auth = getAuth();

  const [stateCountsSKN, setStateCountsSKN] = useState({});
  const [recentData, setRecentData] = useState({});
  const stateListeners = useRef({});
  const [showNewMessageScreen, setShowNewMessageScreen] = useState(false);

  const [selectedProfile, setSelectedProfile] = useState(null);

  const [selectedUserIdShare, setSelectedUserIdShare] = useState(null);

  const [showSharePostPopup, setShowSharePostPopup] = useState(false);

  const [showShareDATPopup, setShowShareDATPopup] = useState(false);

  const [showShareDATPopupProfile, setShowShareDATPopupProfile] = useState(false);

  const [showCreditSharePopup, setShowCreditSharePopup] = useState(false);

  const [showCreditSharePopupProfile, setShowCreditSharePopupProfile] = useState(false);

  const [showSuccessAlertShareCredits, setShowSuccessAlertShareCredits] = useState(false);

  const [currentlySharingCredits, setCurrentlySharingCredits] = useState(false);



  const [postToShare, setPostToShare] = useState(null);

  const [userId2, setUserId2] = useState();
  const db = getFirestore();

  const [notifications, setNotifications] = useState([]);

  const consolidateNotifications = (notifications) => {
    const groupedNotifications = {};
  
    notifications.forEach(notification => {
      let key;
      if (notification.postId && notification.commentId) {
        key = `comment-${notification.postId}-${notification.commentId}`;
      } else if (notification.postId && notification.replyId) {
        key = `reply-${notification.postId}-${notification.replyId}`;
      } else {
        key = `unique-${notification.id}`; // Unique key for non-groupable notifications
      }
  
      if (!groupedNotifications[key]) {
        groupedNotifications[key] = [notification];
      } else {
        groupedNotifications[key].push(notification);
      }
    });
  
    return Object.values(groupedNotifications).map(group => {
      if (group.length > 1) {
        // Create a new type for consolidated notifications
        return {
          ...group.find(n => n.type === 'reply') || group[0], // Prefer 'reply' as base notification
          type: 'consolidatedReplyTag', // New type for consolidated notifications
          originalNotifications: group // Store original notifications for reference
        };
      }
      return group[0];
    });
  };

  useEffect(() => {
    if (!userId2) return; // Make sure userId is not undefined
  
    const notificationsRef = collection(db, 'users', userId2, 'notifications');
    const notificationsQuery = query(notificationsRef, orderBy('timestamp', 'desc'), limit(50));
  
    const unsubscribe = onSnapshot(notificationsQuery, async (snapshot) => {
      const fetchedNotifications = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
        const notification = docSnapshot.data();
        notification.createdAt = notification.timestamp.toDate(); // Normalize timestamp for all notifications
  
        if (notification.type === 'like' || notification.type === 'comment' || notification.type === 'reply') {
          // For notifications that are related to posts, fetch the post data
          const postRef = doc(db, 'posts', notification.postId);
          const postSnapshot = await getDoc(postRef);
  
          if (!postSnapshot.exists()) {
            return {
              id: docSnapshot.id,
              postText: 'Post not found',
              ...notification,
              isReposted: false
            };
          }
  
          const post = postSnapshot.data();
          return {
            id: docSnapshot.id,
            postText: post.text,
            ...notification,
            isReposted: !!post.originalPost
          };
        } else {
          // For notifications like new followers that do not relate to a specific post
          return {
            id: docSnapshot.id,
            ...notification
          };
        }
      }));
  
      // Consolidate notifications here
      const consolidatedNotifications = consolidateNotifications(fetchedNotifications);
      setNotifications(consolidatedNotifications);
    });
  
    return () => unsubscribe();
  }, [userId2]);

  const [isViewingScreenSKN, setIsViewingScreenSKN] = useState(false);

  const [currentlyBuying, setCurrentlyBuying] = useState(false);
  const [showSuccessAlertSKN, setShowSuccessAlertSKN] = useState(false);

  const [likes, setLikes] = useState({});
  const [reposts, setReposts] = useState({});

  const [activeCategory, setActiveCategory] = useState('default');

  const [viewingDefaultAnalytics, setViewingDefaultAnalytics] = useState(false);
  const [viewingDefaultAnalyticsLocal, setViewingDefaultAnalyticsLocal] = useState(true);

  const [viewingTotalSpnsAnalytics, setViewingTotalSpnsAnalytics] = useState(false);
  const [viewingAlbumAnalytics, setViewingAlbumAnalytics] = useState(false);
  const [viewingSingleAnalytics, setViewingSingleAnalytics] = useState(false);
  const [viewingVideosAnalytics, setViewingVideosAnalytics] = useState(false);
  const [viewingVerchAnalytics, setViewingVerchAnalytics] = useState(false);

  const [profileImage, setProfileImage] = useState('');
  const [credits, setCredits] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [posts, setPosts] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [purchasedMedia, setPurchasedMedia] = useState([]);

  const [voiceDropMuted, setVoiceDropMuted] = useState(true);
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [numberOfFollowers, setNumberOfFollowers] = useState(0);
const [numberOfFollowing, setNumberOfFollowing] = useState(0);
const [bio, setBio] = useState('');
const [blurb, setBlurb] = useState('');

const [currentAnalyticsImage, setCurrentAnalyticsImage] = useState(require('./assets/AnalyticsBoxes.png'));

const [screenSKNs, setScreenSKNs] = useState([]);

  const [playlistsFresh, setPlaylistsFresh] = useState([]);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState('');

  const [sharePostActive, setSharePostActive] = useState(false);

  const [shareDATactive, setShareDATactive] = useState(false);

  const [currentlyReleasing, setCurrentlyReleasing] = useState(false);

  const [totalSpns, setTotalSpns] = useState(0);
  const [musicSpns, setMusicSpns] = useState(0);
  const [videoSpns, setVideoSpns] = useState(0);
  const [singlesSales, setSinglesSales] = useState(0);
  const [albumsSales, setAlbumsSales] = useState(0);
  const [videosSales, setVideosSales] = useState(0);

  const [currentEarnings, setCurrentEarnings] = useState(0);
const [totalEarnings, setTotalEarnings] = useState(0);
const [verchSales, setVerchSales] = useState(0);

  const creditsUnsubscribeRef = useRef(null);
  const statsUnsubscribeRef = useRef(null);

  const currentEarningsUnsubscribeRef = useRef(null);
const totalEarningsUnsubscribeRef = useRef(null);
const verchUnsubscribeRef = useRef(null);

  const [creditsIOS, setCreditsIOS] = useState(0);


  const earnedCreditsUnsubscribeRef = useRef(null);


  const [miniPlayer, setMiniPlayer] = useState(null);
  const [miniPlayerVideo, setMiniPlayerVideo] = useState(null);
  const [audio, setAudio] = useState(null);

  const audioRef = useRef(null);
  const videoRef = useRef(null);
  const playbackIntervalRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaylist, setIsPlaylist] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoURL, setVideoURL] = useState(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [currentPlaylist, setCurrentPlaylist] = useState([]);
const [currentIndex, setCurrentIndex] = useState(0);
const [isShuffle, setIsShuffle] = useState(false);



const [isUnder400, setIsUnder400] = useState(window.innerWidth < 400 && window.innerWidth > 390);

const [isUnder390, setIsUnder390] = useState(window.innerWidth < 390);

const [is480, setIs480] = useState(window.innerWidth === 480);

const [isUnder480, setIsUnder480] = useState(window.innerWidth < 480);

const [isBetween400And479, setIsBetween400And479] = useState(window.innerWidth >= 400 && window.innerWidth < 479);

useEffect(() => {
  const handleResize = () => {
    setIsUnder400(window.innerWidth < 400);
    setIsUnder390(window.innerWidth < 390);
    setIsUnder480(window.innerWidth < 480);
    setIs480(window.innerWidth === 480);
    setIsBetween400And479(window.innerWidth >= 400 && window.innerWidth < 479);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);









  useEffect(() => {
    return () => {
      if (audioRef.current) {
        
        audioRef.current.pause();
        audioRef.current.removeEventListener('abort', handleAbort);
        audioRef.current.removeEventListener('error', handleError);
        audioRef.current.removeEventListener('play', handlePlay);
        audioRef.current.removeEventListener('pause', handlePause);
        audioRef.current.removeEventListener('loadeddata', handleLoadedData);
        audioRef.current.removeEventListener('canplaythrough', handleCanPlayThrough);
      }
      clearInterval(playbackIntervalRef.current);
    };
  }, []);

  const handleAbort = (event) => {
    console.error('Audio load aborted:', event);
    setIsPlaylist(false);
  };

  const handleError = (event) => {
    console.error('Audio load error:', event);
  };

  const handlePlay = () => {
    if (audioRef.current) {
      setIsPlaying(true);
      
      console.log('Audio properties:', {
        paused: audioRef.current.paused,
        ended: audioRef.current.ended,
        currentTime: audioRef.current.currentTime,
        duration: audioRef.current.duration,
        readyState: audioRef.current.readyState,
        volume: audioRef.current.volume,
        muted: audioRef.current.muted
      });
      playbackIntervalRef.current = setInterval(() => {
        
      }, 1000);
    }
  };

  const handlePause = () => {
    setIsPlaying(false);
    
    clearInterval(playbackIntervalRef.current);
  };

  const togglePlayPause = useCallback(() => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((error) => {
          if (error.name === 'NotAllowedError' || error.name === 'NotSupportedError') {
            console.error('Browser prevented audio playback:', error);
          } else {
            console.error('Error playing audio:', error);
          }
        });
      }
    }
  }, [isPlaying]);

  const playVideo = (url) => {
    if (videoRef.current) {
      if (videoRef.current.src !== url) {
        videoRef.current.src = url;
      }
      videoRef.current.play();
      setIsVideoPlaying(true);
    }
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsVideoPlaying(false);
    }
  };

  const toggleVideoPlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsVideoPlaying(true);
      } else {
        videoRef.current.pause();
        setIsVideoPlaying(false);
      }
    }
  };

  const handleLoadedData = () => {
    if (audioRef.current) {
      
      console.log('Audio properties after loadeddata:', {
        paused: audioRef.current.paused,
        ended: audioRef.current.ended,
        currentTime: audioRef.current.currentTime,
        duration: audioRef.current.duration,
        readyState: audioRef.current.readyState,
        volume: audioRef.current.volume,
        muted: audioRef.current.muted
      });
    }
  };

  const handleCanPlayThrough = () => {
    if (audioRef.current) {
      
      console.log('Audio properties after canplaythrough:', {
        paused: audioRef.current.paused,
        ended: audioRef.current.ended,
        currentTime: audioRef.current.currentTime,
        duration: audioRef.current.duration,
        readyState: audioRef.current.readyState,
        volume: audioRef.current.volume,
        muted: audioRef.current.muted
      });
      audioRef.current.play().catch(error => {
        console.error('Error playing audio in canplaythrough:', error);
      });
    }
  };

  const playSong = async (songURL) => {
    
  
    if (audioRef.current) {
      
      audioRef.current.pause();
      audioRef.current.removeEventListener('abort', handleAbort);
      audioRef.current.removeEventListener('error', handleError);
      audioRef.current.removeEventListener('play', handlePlay);
      audioRef.current.removeEventListener('pause', handlePause);
      audioRef.current.removeEventListener('loadeddata', handleLoadedData);
      audioRef.current.removeEventListener('canplaythrough', handleCanPlayThrough);
      clearInterval(playbackIntervalRef.current);
    }
  
    
    try {
      const response = await fetch(songURL, { method: 'HEAD' });
  
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
  
      
      
      
      
  
      // Ensure that the content type is something playable
      if (!response.headers.get('Content-Type').startsWith('audio/')) {
        throw new Error('Invalid Content-Type for audio');
      }
    } catch (error) {
      console.error('Network error fetching audio URL:', error);
      return;
    }
  
    
    const newAudio = new Audio(songURL);
    
  
    audioRef.current = newAudio;
  
    newAudio.volume = 1.0;
    newAudio.muted = false;
  
    
    newAudio.addEventListener('abort', handleAbort);
    newAudio.addEventListener('error', handleError);
    newAudio.addEventListener('play', handlePlay);
    newAudio.addEventListener('pause', handlePause);
    newAudio.addEventListener('loadeddata', handleLoadedData);
    newAudio.addEventListener('canplaythrough', handleCanPlayThrough);
  
    
    newAudio.load(); // Explicitly load the audio
  
    
    newAudio.play()
      .then(() => {
        
      })
      .catch((error) => {
        console.error('Audio play promise rejected:', error);
        if (error.name === 'NotAllowedError' || error.name === 'NotSupportedError') {
          console.error('Browser prevented audio playback:', error);
        } else {
          console.error('Error playing audio:', error);
        }
      });
  };
  
  



  const handleCloseClick = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setMiniPlayer(null);
      setIsPlaying(false);
      setIsPlaylist(false);
    }
    if (videoRef.current) {
      videoRef.current.pause();
      setMiniPlayerVideo(null);
      setIsVideoPlaying(false);
      setIsPlaylist(false);
    }
  };


  const playNextSong = useCallback(() => {
    if (currentPlaylist.length > 0) {
      let nextIndex = currentIndex + 1;
      if (nextIndex >= currentPlaylist.length) {
        nextIndex = 0;
      }
      setCurrentIndex(nextIndex);
      playSong(currentPlaylist[nextIndex].SongURL);
      setMiniPlayer(currentPlaylist[nextIndex]);
    }
  }, [currentIndex, currentPlaylist, playSong]);
  
  const playPreviousSong = useCallback(() => {
    if (currentPlaylist.length > 0) {
      let prevIndex = currentIndex - 1;
      if (prevIndex < 0) {
        prevIndex = currentPlaylist.length - 1;
      }
      setCurrentIndex(prevIndex);
      playSong(currentPlaylist[prevIndex].SongURL);
      setMiniPlayer(currentPlaylist[prevIndex]);
    }
  }, [currentIndex, currentPlaylist, playSong]);
  
  const playPlaylistSequentially = useCallback((playlist) => {
    
    setIsPlaylist(true);
    setCurrentPlaylist(playlist);
    setCurrentIndex(0);
    setIsShuffle(false);
    if (playlist.length > 0) {
      playSong(playlist[0].SongURL);
      setMiniPlayer(playlist[0]);
    }
  }, [playSong]);
  
  const playPlaylistShuffled = useCallback((playlist) => {
    const shuffledPlaylist = [...playlist].sort(() => Math.random() - 0.5);
    setIsPlaylist(true);
    setCurrentPlaylist(shuffledPlaylist);
    setCurrentIndex(0);
    setIsShuffle(true);
    if (shuffledPlaylist.length > 0) {
      playSong(shuffledPlaylist[0].songURL);
      setMiniPlayer(shuffledPlaylist[0]);
    }
  }, [playSong]);
  
  
  useEffect(() => {
    const handleEnded = () => {
      playNextSong();
    };
  
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', handleEnded);
    }
  
    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener('ended', handleEnded);
      }
    };
  }, [playNextSong]);


  useEffect(() => {
    if (miniPlayerVideo) {
      playVideo(miniPlayerVideo.VideoURL);
    }
  }, [miniPlayerVideo]);


  useEffect(() => {
    const auth = getAuth();
  
    // Subscribe to auth state changes
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        
  
        // References to the database
        const currentCreditsRef = ref(database, `users/${user.uid}/summary/currentCredits`);
        const earnedCreditsRef = ref(database, `users/${user.uid}/summary/earnedCredits`);
  
        // Combined listener for currentCredits and earnedCredits
        creditsUnsubscribeRef.current = onValue(currentCreditsRef, (currentCreditsSnapshot) => {
          if (currentCreditsSnapshot.exists()) {
            const currentCredits = currentCreditsSnapshot.val().totalCredits || 0;
  
            if (earnedCreditsUnsubscribeRef.current) {
              earnedCreditsUnsubscribeRef.current();
            }
  
            earnedCreditsUnsubscribeRef.current = onValue(earnedCreditsRef, (earnedCreditsSnapshot) => {
              if (earnedCreditsSnapshot.exists()) {
                const earnedCredits = earnedCreditsSnapshot.val().totalCredits || 0;
                const totalCredits = currentCredits + earnedCredits;
                setCreditsIOS(totalCredits >= 10000 ? `${Math.floor(totalCredits / 1000)}k` : Math.round(totalCredits));
              } else {
                setCreditsIOS(currentCredits >= 10000 ? `${Math.floor(currentCredits / 1000)}k` : Math.round(currentCredits));
              }
            }, (error) => {
              console.error('[EarnedCreditsListener] Error:', error);
            });
  
          } else {
            if (earnedCreditsUnsubscribeRef.current) {
              earnedCreditsUnsubscribeRef.current();
            }
  
            earnedCreditsUnsubscribeRef.current = onValue(earnedCreditsRef, (earnedCreditsSnapshot) => {
              if (earnedCreditsSnapshot.exists()) {
                const earnedCredits = earnedCreditsSnapshot.val().totalCredits || 0;
                setCreditsIOS(earnedCredits >= 10000 ? `${Math.floor(earnedCredits / 1000)}k` : Math.round(earnedCredits));
              } else {
                setCreditsIOS(0);
              }
            }, (error) => {
              console.error('[EarnedCreditsListener] Error:', error);
            });
          }
        }, (error) => {
          console.error('[CurrentCreditsListener] Error:', error);
        });
  
        // Subscribe to stats from Realtime Database
        const userStatsRef = ref(database, `users/${user.uid}/stats`);
        statsUnsubscribeRef.current = onValue(userStatsRef, (snapshot) => {
          if (snapshot.exists()) {
            const stats = snapshot.val();
            setTotalSpns(stats.totalSpns || 0);
            setMusicSpns(stats.musicSpns || 0);
            setVideoSpns(stats.videoSpns || 0);
            setSinglesSales(stats.sales?.singles || 0);
            setAlbumsSales(stats.sales?.albums || 0);
            setVideosSales(stats.sales?.videos || 0);
          }
        }, (error) => {
          console.error('[StatsListener] Error:', error);
        });
  
        // Subscribe to currentEarnings from Realtime Database
        const userCurrentEarningsRef = ref(database, `users/${user.uid}/currentEarnings`);
        currentEarningsUnsubscribeRef.current = onValue(userCurrentEarningsRef, (snapshot) => {
          if (snapshot.exists()) {
            setCurrentEarnings(snapshot.val());
          }
        }, (error) => {
          console.error('[CurrentEarningsListener] Error:', error);
        });
  
        // Subscribe to totalEarnings from Realtime Database
        const userTotalEarningsRef = ref(database, `users/${user.uid}/totalEarnings`);
        totalEarningsUnsubscribeRef.current = onValue(userTotalEarningsRef, (snapshot) => {
          if (snapshot.exists()) {
            setTotalEarnings(snapshot.val());
          }
        }, (error) => {
          console.error('[TotalEarningsListener] Error:', error);
        });
  
        // Subscribe to verch from Realtime Database
        const userVerchRef = ref(database, `users/${user.uid}/verch`);
        verchUnsubscribeRef.current = onValue(userVerchRef, (snapshot) => {
          if (snapshot.exists()) {
            setVerchSales(snapshot.val());
          }
        }, (error) => {
          console.error('[VerchListener] Error:', error);
        });
  
      } else {
        setIsLoggedIn(false);
        if (creditsUnsubscribeRef.current) {
          creditsUnsubscribeRef.current();
        }
        if (earnedCreditsUnsubscribeRef.current) {
          earnedCreditsUnsubscribeRef.current();
        }
        if (statsUnsubscribeRef.current) {
          statsUnsubscribeRef.current();
        }
        if (currentEarningsUnsubscribeRef.current) {
          currentEarningsUnsubscribeRef.current();
        }
        if (totalEarningsUnsubscribeRef.current) {
          totalEarningsUnsubscribeRef.current();
        }
        if (verchUnsubscribeRef.current) {
          verchUnsubscribeRef.current();
        }
      }
    });
  
    // Cleanup function to unsubscribe from both auth state changes and document changes when component unmounts
    return () => {
      unsubscribeAuth();
      if (creditsUnsubscribeRef.current) {
        creditsUnsubscribeRef.current();
      }
      if (earnedCreditsUnsubscribeRef.current) {
        earnedCreditsUnsubscribeRef.current();
      }
      if (statsUnsubscribeRef.current) {
        statsUnsubscribeRef.current();
      }
      if (currentEarningsUnsubscribeRef.current) {
        currentEarningsUnsubscribeRef.current();
      }
      if (totalEarningsUnsubscribeRef.current) {
        totalEarningsUnsubscribeRef.current();
      }
      if (verchUnsubscribeRef.current) {
        verchUnsubscribeRef.current();
      }
    };
  }, []);




  const cleanseId = (id) => {
    return id.replace(/\D/g, ''); // This regex removes all non-digit characters
};

const removeContent = async (contentId, type) => {
  const auth = getAuth();
  let collectionName, genres = [], tags = [];

  switch (type) {
      case 'albums':
      case 'videoAlbums':
          collectionName = 'Albums';
          break;
      case 'videos':
          collectionName = 'Videos';
          break;
      default:
          collectionName = 'Songs'; 
  }

  

  try {
      if (type === 'albums') {
          
          const albumDocRef = doc(db, collectionName, contentId);
          const albumDocSnap = await getDoc(albumDocRef);
          if (albumDocSnap.exists()) {
              const albumData = albumDocSnap.data();
              genres = albumData.genres || [];
              tags = albumData.tags || [];
              
          } else {
              
          }
      }

      
      await deleteDoc(doc(db, collectionName, contentId));

      
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      await runTransaction(db, async (transaction) => {
          const userDocSnap = await transaction.get(userDocRef);
          if (!userDocSnap.exists()) {
              throw new Error("User Document does not exist!");
          }
          const userData = userDocSnap.data();
          const updatedArray = userData[type].filter(item => item.contentId !== contentId);
          transaction.update(userDocRef, { [type]: updatedArray });

          // Update local state immediately after successful transaction
          setMediaData(currentMediaData => ({
              ...currentMediaData,
              [type]: currentMediaData[type].filter(item => item.contentId !== contentId)
          }));
          
      });

  } catch (error) {
      console.error(`Failed to remove ${type.slice(0, -1)}:`, error);
  }
};

const [myPosts, setMyPosts] = useState([]);

const [profiles, setProfiles] = useState([]);

const [hasUnread, setHasUnread] = useState(false);

const [currentView, setCurrentView] = useState('profiles');

const [profileViewing, setProfileViewing] = useState(null);



useEffect(() => {
  
  const unsubscribeAuth = auth.onAuthStateChanged(user => {
    if (user) {
      setIsLoggedIn(true);
      

      const statesAbbreviations = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

      const analyticsCategories = [
        { key: 'Album', viewing: viewingAlbumAnalytics },
        { key: 'Single', viewing: viewingSingleAnalytics },
        { key: 'Videos', viewing: viewingVideosAnalytics },
        { key: 'Verch', viewing: viewingVerchAnalytics },
        { key: 'TotalSpns', viewing: viewingTotalSpnsAnalytics, dbKey: 'RecentSpns' }
      ];

      analyticsCategories.forEach(category => {
        
        if (category.viewing) {
          // Setup listeners for state-specific counts
          statesAbbreviations.forEach(state => {
            const stateRef = ref(database, `users/${user.uid}/stats/State${category.key}Locations/${state}`);
            
            stateListeners.current[`${category.key}_${state}`] = onValue(stateRef, snapshot => {
              if (snapshot.exists()) {
                
                setStateCountsSKN(prev => ({
                  ...prev,
                  [`${category.key}_${state}`]: snapshot.val()
                }));
              } else {
                
                setStateCountsSKN(prev => {
                  const newStateCounts = { ...prev };
                  delete newStateCounts[`${category.key}_${state}`];
                  return newStateCounts;
                });
              }
            });
          });

          // Setup listeners for recent activities
          const dbKey = category.dbKey || `Recent${category.key}`;
          const recentRef = ref(database, `users/${user.uid}/stats/${dbKey}`);
          
          stateListeners.current[dbKey] = onValue(recentRef, snapshot => {
            if (snapshot.exists()) {
              
              setRecentData(prev => ({
                ...prev,
                [dbKey]: snapshot.val()
              }));
            } else {
              
              setRecentData(prev => {
                const newRecentData = { ...prev };
                delete newRecentData[dbKey];
                return newRecentData;
              });
            }
          });
        } else {
          // Cleanup listeners for both state-specific counts and recent activities
          statesAbbreviations.forEach(state => {
            const listenerKey = `${category.key}_${state}`;
            if (stateListeners.current[listenerKey]) {
              
              stateListeners.current[listenerKey]();
              delete stateListeners.current[listenerKey];
            }
          });
          const dbKey = category.dbKey || `Recent${category.key}`;
          if (stateListeners.current[dbKey]) {
            
            stateListeners.current[dbKey]();
            delete stateListeners.current[dbKey];
          }
        }
      });
    } else {
      setIsLoggedIn(false);
      
      // When the user logs out, unsubscribe from all listeners
      Object.values(stateListeners.current).forEach(unsubscribe => unsubscribe?.());

      // Reset the state to ensure no stale data remains
      setStateCountsSKN({});
      setRecentData({});
    }
  });

  // Cleanup function to unsubscribe from all listeners upon component unmount or auth state change
  return () => {
    
    unsubscribeAuth(); // Unsubscribe from auth state changes
    // Additionally, unsubscribe from all dynamically created listeners
    Object.values(stateListeners.current).forEach(unsubscribe => unsubscribe?.());
    // Reset the reference object for state listeners to prevent memory leaks
    stateListeners.current = {};
  };
}, [
  viewingAlbumAnalytics,
  viewingSingleAnalytics,
  viewingVideosAnalytics,
  viewingVerchAnalytics,
  viewingTotalSpnsAnalytics,
  auth, // Make sure to include `auth` if it's a dependency that could change
]);


useEffect(() => {
  let unsubscribeConversations = () => {};

  const ensureSummaryDocumentExists = async (userId) => {
    const summaryRef = doc(firestore, 'users', userId, 'conversationsSummary', 'summary');
    const summarySnap = await getDoc(summaryRef);

    if (!summarySnap.exists()) {
      await setDoc(summaryRef, { conversations: {} });
    }
  };

  const subscribeToConversations = (userId) => {
    const summaryRef = doc(firestore, 'users', userId, 'conversationsSummary', 'summary');
    unsubscribeConversations = onSnapshot(summaryRef, docSnapshot => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (data && data.conversations) {
          const profiles = Object.keys(data.conversations).map(conversationId => {
            const conversation = data.conversations[conversationId];
            const lastMessageTimestamp = conversation.latestMessageTimestamp ? conversation.latestMessageTimestamp.toDate() : null;

            return {
              id: conversationId,
              otherUserId: conversation.otherUser.userId,
              unread: conversation.unread,
              lastMessageTimestamp: lastMessageTimestamp,
              ...conversation.otherUser  // Include user data for display
            };
          });

          // Sort profiles by lastMessageTimestamp in descending order
          profiles.sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp);

          setProfiles(profiles);
        } else {
          
          setProfiles([]);
        }
      } else {
        
        setProfiles([]);
      }
    }, (error) => {
      console.error('Error fetching conversation summaries:', error);
    });
  };

  if (userId2) {
    ensureSummaryDocumentExists(userId2)
      .then(() => {
        subscribeToConversations(userId2);
      })
      .catch(error => {
        console.error('Error ensuring summary document exists:', error);
      });
  } else {
    
  }

  return () => {
    unsubscribeConversations();
  };
}, [userId2]);

useEffect(() => {
  const anyUnread = profiles.some(profile => profile.unread);
  setHasUnread(anyUnread);
}, [profiles]);


  const [mediaData, setMediaData] = useState({
      singles: [],
      albums: [],
      videos: [],
      videoAlbums: [],
      spncasts: [],
  });

const mandatoryFollowingIds = [
  "h0fEIStkM4XHTDErZM5i8XIo9rA3",
  "OHaY20b54nTGd5BBgbJOL2BHEbX2",
  "rdX289s3YBP31Hw5qn2eXVywtBq1",
  "Mkf4631LCEOYmHXWRzGirPdxBrt1"
];



useEffect(() => {
  const auth = getAuth();
  let currentUserID = null; // Local variable to hold the current user ID

  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user && currentUserID !== user.uid) {
      
      currentUserID = user.uid;
      setUserId2(user.uid);
    } else if (!user && currentUserID !== null) {
      
      currentUserID = null;
      setUserId2(null);
    }
  });

  return () => unsubscribe();
}, []);





const [purchasedMovies, setPurchasedMovies] = useState([]);


useEffect(() => {
  const auth = getAuth();
  const firestore = getFirestore();
  let unsubscribeDoc = null;
  let unsubscribePurchasedMovies = null;

  // Subscribe to auth state changes
  const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
    if (user) {

      const userDocRef = doc(firestore, 'users', user.uid);

      // Clean up the previous subscriptions if they exist
      if (unsubscribeDoc) {
        unsubscribeDoc();
      }

      unsubscribeDoc = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();

          // Set the whole currentUser with the merged data
          setUser(userData);
          setMediaData({
            singles: userData.singles || [], 
            albums: userData.albums || [],
            beats: userData.beats || [],
            videos: userData.accountType === 'SPNCaster' ? userData.spncasts : userData.videos || [],
            spncasts: userData.spncasts || [],
            videoAlbums: userData.videoAlbums || [],
          });

          const cleansedPlaylists = userData?.playlists?.map(playlist => ({
            ...playlist,
            id: cleanseId(playlist.id),
            contentId: cleanseId(playlist.contentId)
          })) || [];

          const updatedPurchasedMedia = [
            ...(userData.purchasedMedia || []),
            ...cleansedPlaylists
          ];

          // Set individual pieces of state
          setVoiceDropMuted(userData.voiceDropMuted || false);
          setFollowers(userData.followers || []);
          const updatedFollowing = new Set([...mandatoryFollowingIds, user.uid, ...(userData.following || [])].map(userId => {
            return userId;
          }));
          setFollowing(Array.from(updatedFollowing));

          const numberOfFollowers = (userData.followers || []).length;
          const numberOfFollowing = Array.from(updatedFollowing).length;

          // Set the calculated values in state
          setNumberOfFollowers(numberOfFollowers);
          setNumberOfFollowing(numberOfFollowing);

          setPlaylistsFresh(userData.playlists);
          setPurchasedMedia(updatedPurchasedMedia);
          setHasUnreadNotifications(userData.hasUnreadNotifications || false);
          setBio(userData?.bio ? userData?.bio : '');
          setBlurb(userData?.blurb ? userData?.blurb : '');
          setScreenSKNs([{
            buttonImage: require('./assets/images/screenSKN/screenSKNbuttonSPNDAT.png'),
            screenSKNName: "Default",
            artistName: "SPNDAT",
            isLocal: true,
          }, ...(userData.ScreenSKNpurchased || []), ...(userData.ScreenSKN || [])]);
          setProfileImageUrl(userData.profileImageUrl || '');

          // Subscribe to the PurchasedMovies subcollection
          const purchasedMoviesRef = collection(firestore, 'users', user.uid, 'PurchasedMovies');
          unsubscribePurchasedMovies = onSnapshot(purchasedMoviesRef, (snapshot) => {
            const purchasedMovies = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPurchasedMovies(purchasedMovies);
          }, (error) => {
            console.error("Error listening to PurchasedMovies collection:", error);
          });
        } else {
          console.log('No such document!');
        }
      }, (error) => {
        console.error("Error listening to user document:", error);
      });

      // Ensure we clean up these subscriptions when user logs out or on other auth changes
      return () => {
        if (unsubscribeDoc) {
          console.log("Unsubscribing from user document updates due to user log out or auth change...");
          unsubscribeDoc();
        }
        if (unsubscribePurchasedMovies) {
          console.log("Unsubscribing from PurchasedMovies collection updates...");
          unsubscribePurchasedMovies();
        }
      };
    } else {
      console.log("No user logged in, cancelling document subscriptions.");
      if (unsubscribeDoc) {
        unsubscribeDoc();  // Unsubscribe when user logs out
      }
      if (unsubscribePurchasedMovies) {
        unsubscribePurchasedMovies();  // Unsubscribe from PurchasedMovies
      }
    }
  });

  // Cleanup function to unsubscribe from both auth state changes and document changes when component unmounts
  return () => {
    console.log("Cleaning up all subscriptions...");
    unsubscribeAuth();
    if (unsubscribeDoc) {
      unsubscribeDoc();
    }
    if (unsubscribePurchasedMovies) {
      unsubscribePurchasedMovies();
    }
  };
}, []);

  const [imageUri, setImageUri] = useState(null);
  const [tempImageUri, setTempImageUri] = useState(null);

  return (
    <AppContext.Provider value={{ 
      dataFeaturedMusic, setDataFeaturedMusic,
      featuredSongs, setFeaturedSongs,
      newReleases, setNewReleases,
      newVideoReleases,
      setNewVideoReleases,
      dataFeaturedVideos,
      setDataFeaturedVideos,
      groupedByGenres,
      setGroupedByGenres,
      lastGenreDocs,
      setLastGenreDocs,
      tempImageUri, setTempImageUri, imageUri, setImageUri, profileViewing, setProfileViewing,
      currentlyReleasing, setCurrentlyReleasing,
      is480, isUnder390, isUnder480, showNewMessageScreen, setShowNewMessageScreen,
      voiceDropMuted, setVoiceDropMuted, isViewingScreenSKN, setIsViewingScreenSKN, isUnder400, isBetween400And479,
      currentlyBuying, setCurrentlyBuying, showSuccessAlertSKN, setShowSuccessAlertSKN,
      playNextSong,
      playPreviousSong,
      playPlaylistSequentially,
      playPlaylistShuffled,
      currentPlaylist,
      currentIndex,
      isShuffle,
      setIsShuffle,
      isPlaylist, setIsPlaylist,
      hasUnreadNotifications,
      screenSKNs, shareDATactive, setShareDATactive, showCreditSharePopup, setShowCreditSharePopup, currentlySharingCredits, setCurrentlySharingCredits, showSuccessAlertShareCredits, setShowSuccessAlertShareCredits, showCreditSharePopupProfile, setShowCreditSharePopupProfile, showShareDATPopupProfile, setShowShareDATPopupProfile, showShareDATPopup, setShowShareDATPopup, showSharePostPopup, setShowSharePostPopup, selectedUserIdShare, setSelectedUserIdShare, sharePostActive, setSharePostActive, postToShare, setPostToShare, stateCountsSKN, recentData, viewingTotalSpnsAnalytics, setViewingTotalSpnsAnalytics,
      viewingAlbumAnalytics, setViewingAlbumAnalytics,
      viewingSingleAnalytics, setViewingSingleAnalytics,
      viewingVideosAnalytics, setViewingVideosAnalytics,
      viewingVerchAnalytics, setViewingVerchAnalytics,
      viewingDefaultAnalytics, setViewingDefaultAnalytics,
      activeCategory, setActiveCategory, musicSpns, setMusicSpns, videoSpns, setVideoSpns,
      totalSpns, setTotalSpns, singlesSales, setSinglesSales, verchSales,
      albumsSales, setAlbumsSales, videosSales, setVideosSales, totalEarnings, currentEarnings,
      currentAnalyticsImage, setCurrentAnalyticsImage, viewingDefaultAnalyticsLocal, setViewingDefaultAnalyticsLocal, setBlurb, setBio, blurb, duration, setDuration, currentTime, setCurrentTime, toggleVideoPlayPause, isVideoPlaying, setIsVideoPlaying, miniPlayerVideo, setMiniPlayerVideo, handleCloseClick, videoRef, audioRef, setMiniPlayer, isPlaying, togglePlayPause, setPlaylistsFresh, playlistsFresh, miniPlayer, setMiniPlayer, playSong, selectedProfile, setSelectedProfile, currentView, setCurrentView, hasUnread, profiles, notifications, creditsIOS, userId2, profileImageUrl, myPosts, setMyPosts, bio, mediaData, followers, numberOfFollowers, numberOfFollowing, viewingTotalSpnsAnalytics, setViewingTotalSpnsAnalytics, viewingAlbumAnalytics, setViewingAlbumAnalytics, viewingSingleAnalytics, setViewingSingleAnalytics, viewingVideosAnalytics, setViewingVideosAnalytics, viewingVerchAnalytics, setViewingVerchAnalytics, 
      viewingDefaultAnalytics, setViewingDefaultAnalytics, viewingDefaultAnalyticsLocal, setViewingDefaultAnalyticsLocal, activeCategory, setActiveCategory, currentAnalyticsImage, setCurrentAnalyticsImage, reposts, setReposts, likes, setLikes, following, purchasedMedia, posts, currentUser, setPosts, setUser, profileImage, setProfileImage, credits, setCredits, isLoggedIn, setIsLoggedIn, purchasedMovies }}>
      {children}
    </AppContext.Provider>
  );
};
