import React, { useEffect, useRef } from 'react';

const PlayVoiceDropUser = ({ profile }) => { // Receive profile as a prop
  const hasPlayedRef = useRef(false);
  const lastVocalDropUrl = useRef(null); // Ref to track the last played URL
  const lastMutedState = useRef(profile?.voiceDropMuted); // Ref to track the last muted state

  useEffect(() => {
    // Check if the profile and the vocalDrop URL are defined
    if (profile && profile.vocalDrop) {
      // Determine if muted has just been switched from true to false
      const justUnmuted = lastMutedState.current === true && !profile.voiceDropMuted;
      
      // Check if it's appropriate to reset the play state
      if (profile.voiceDropMuted) {
        // Reset hasPlayedRef.current to false when muted to allow replay when unmuted
        hasPlayedRef.current = false;
      } else if (justUnmuted || (profile.vocalDrop !== lastVocalDropUrl.current && !hasPlayedRef.current)) {
        // Play the audio only if it's just unmuted or if the vocalDrop URL changed and it hasn't played yet
        const audio = new Audio(profile.vocalDrop);
        lastVocalDropUrl.current = profile.vocalDrop; // Update the last played URL

        audio.play()
          .then(() => {
            // Set the ref to true after the audio has been played
            hasPlayedRef.current = true;
          })
          .catch(error => {
            console.error('Error playing the audio:', error);
          });
      }

      // Update the last muted state after handling the current state
      lastMutedState.current = profile.voiceDropMuted;
    }
  }, [profile]); // Add profile as a dependency to handle changes

  return null; // This component does not render anything
};

export default PlayVoiceDropUser;
