import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const CASHDATEmail = () => {
  const auth = getAuth();
  const firestore = getFirestore();
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState('');
  const [cashdatEmail, setCashdatEmail] = useState('');

  const reauthenticate = async (currentPassword) => {
    try {
      const user = auth.currentUser;
      await signInWithEmailAndPassword(auth, user.email, currentPassword);
      return true;
    } catch (error) {
      console.error("Re-authentication failed:", error);
      alert("Authentication failed. Please check your current password and try again.");
      return false;
    }
  };

  const handleUpdateCashdatEmail = async () => {
    if (await reauthenticate(currentPassword)) {
      try {
        const user = auth.currentUser;
        const userRef = doc(firestore, "users", user.uid);
        await updateDoc(userRef, {
          CASHDATemail: cashdatEmail
        });
        alert("Success! CASHDAT email updated successfully!");
      } catch (error) {
        console.error("Error updating CASHDAT email:", error);
        alert("Update Failed. Failed to update CASHDAT email.");
      }
    }
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <input
          style={styles.input}
          onChange={(e) => setCurrentPassword(e.target.value)}
          value={currentPassword}
          placeholder="Current password"
          type="password"
        />
        <input
          style={styles.input}
          onChange={(e) => setCashdatEmail(e.target.value)}
          value={cashdatEmail}
          placeholder="PayPal Email"
          type="email"
        />
        <button style={styles.button} onClick={handleUpdateCashdatEmail}>
          <span style={styles.buttonText}>Update CASHDAT Email</span>
        </button>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollContainer: {
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20vh',
    paddingBottom: '10vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  input: {
    width: '90%',
    padding: '10px',
    marginBottom: '10px',
    borderWidth: '1px',
    fontSize: '16px',
    borderColor: '#ccc',
    borderRadius: '5px',
    color: 'white',
    backgroundColor: 'transparent', // Ensure background is transparent to show background image
  },
  button: {
    borderRadius: '5px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginBottom: '10px',
    backgroundColor: 'transparent', // Add a background color for the button
    border: 'none', // Remove border for the button
  },
  buttonText: {
    color: '#62fbfa',
    fontSize: '18px',
  },
};

export default CASHDATEmail;
