import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import defaultProfilePic from './assets/defaultProfilePic.png';

const ProfilesDisplaySharePost = ({ onPress, userProfile }) => {
  const { currentUser } = useContext(AppContext);
  const [contentWidth, setContentWidth] = useState(0);
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(userProfile?.profileImageUrl ? userProfile.profileImageUrl : null);
  const [isLoading, setIsLoading] = useState(true);
  const db = getFirestore();
  const profileImageWidth = 100; // Set a fixed width for simplicity
  const padding = 10; // Total padding (left + right)

  const onTextLayout = (event) => {
    const textWidth = event.target.offsetWidth;
    const totalWidth = profileImageWidth + textWidth + padding;
    setContentWidth(totalWidth);
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      if (userProfile?.userId) {
        
        setIsLoading(true);
        try {
          const userDocRef = doc(db, 'users', userProfile.userId);
          const docSnap = await getDoc(userDocRef);
          if (docSnap.exists()) {
            
            setProfileImageUrl(docSnap.data().profileImageUrl);
          } else {
            
          }
        } catch (error) {
          console.error("Error getting document:", error);
        }
        setIsLoading(false);
      } else {
        
        setIsLoading(false);
      }
    };

    
    if (userProfile?.userId) {
      fetchProfileImage();
    } else {
      
      setIsLoading(false);
    }
  }, [userProfile]);

  if (isLoadingProfilePicture) {
    return (
      <div style={styles.loaderContainer}>
        {/* Loader UI */}
      </div>
    );
  }

  // Determine which profile image to use
  const profileImageSource = userProfile?.profileImageUrl?.startsWith("file") 
    ? profileImageUrl || defaultProfilePic 
    : userProfile.profileImageUrl || profileImageUrl || defaultProfilePic;

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(userProfile?.username);

  const formatUsername = username => {
    if (!username) return '@';
    if (username[0] !== '@') return '@' + username;
    return username;
  };

  return (
    <div onClick={onPress} style={{ ...styles.container, width: contentWidth || 'auto' }}>
      <div style={styles.profileViewStyle}>
        <div style={styles.imageStack}>
          <img src={profileImageSource} alt="Profile" style={styles.circularImage} />
        </div>
        <div onLayout={onTextLayout} style={styles.textContainer}>
          <span style={styles.artistName}>{userProfile?.name}</span>
          <div style={styles.textWithIconContainer}>
            <span style={styles.username}>{formatUsername(userProfile?.username)}</span>
            {userProfile?.accountType !== 'Fan' && !isArtistIcon2Visible && (
              <img src={ArtistIcon1} alt="Artist Icon 1" style={styles.profilePicIcon} />
            )}
            {userProfile?.accountType !== 'Fan' && isArtistIcon2Visible && (
              <img src={ArtistIcon2} alt="Artist Icon 2" style={styles.profilePicIcon} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 80, // Example fixed height
    marginBottom: 20,
    zIndex: 10,
    
  },
  profileViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    transform: 'scale(0.67)',
    cursor: 'pointer',
    position: 'relative',
    right: 30,
  },
  imageStack: {
    width: 100, // Example fixed width
    height: 100, // Example fixed height
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  circularImage: {
    width: 80, // Example fixed width
    height: 80, // Example fixed height
    borderRadius: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    objectFit: 'cover',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 10,
  },
  artistName: {
    color: 'white',
    fontSize: 20, // Example font size
    fontWeight: 'regular',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
  textWithIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loaderContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {},
  username: {
    color: '#62fbfa',
    fontSize: 18, // Example font size
    opacity: 0.9,
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    marginRight: 2,
  },
  profilePicIcon: {
    width: 20, // Example width
    height: 20, // Example height
    marginLeft: 5,
    opacity: 1,
    objectFit: 'contain',
  },
};

export default ProfilesDisplaySharePost;
