import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './firebase';

const MYDATscreenSKN = ({ item, onPress }) => {
  const [songArtworkUrl, setSongArtworkUrl] = useState(null);

  useEffect(() => {
    const fetchArtworkUrl = async (artworkUrl) => {
      try {
        let url;
        if (artworkUrl.startsWith('https://')) {
          url = artworkUrl;
        } else if (artworkUrl.startsWith('gs://')) {
          const imagePath = artworkUrl.split('gs://')[1].split('/').slice(1).join('/');
          url = await getDownloadURL(ref(storage, imagePath));
        }
        setSongArtworkUrl(url);
      } catch (error) {
        console.error('Failed to load image', error);
      }
    };

    const artworkUrl = item?.buttonImage;
    if (artworkUrl) {
      fetchArtworkUrl(artworkUrl);
    }
  }, [item]);

  if (!item) {
    return <div style={styles.songArtworkPlaceholder} />;
  }

  return (
    <div style={styles.itemContainer}>
      <div onClick={() => onPress(item)} style={styles.horizontalTouchable}>
        {songArtworkUrl ? (
          <img src={songArtworkUrl} alt="Artwork" style={styles.songArtwork} />
        ) : (
          <div style={styles.songArtworkPlaceholder} />
        )}
      </div>
      <div style={styles.artistName}>{item.artistName}</div>
      <div style={styles.songName}>
        {item.isAlbum ? (item.albumName || 'Unknown Album') : (item.screenSKNName || 'Unknown Song')}
      </div>
    </div>
  );
};

const imageSize = '73px';

const styles = {
  itemContainer: {
    width: '140px',
    alignItems: 'flex-start',
    marginRight: '10px',
    zIndex: 10,
  },
  horizontalTouchable: {
    width: imageSize,
    height: imageSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  songArtwork: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  songArtworkPlaceholder: {
    width: imageSize,
    height: imageSize,
    borderRadius: '50%',
    backgroundColor: '#cccccc',
  },
  artistName: {
    marginTop: '10px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    maxWidth: '140px', // Limit the width to match container size
    whiteSpace: 'normal', // Allow wrapping
    overflow: 'hidden', // Hide overflow
    wordBreak: 'break-word', // Break long words
  },
  songName: {
    marginTop: '2px',
    color: '#b8b8b8',
    fontSize: '16px',
    textAlign: 'left',
    maxWidth: '140px', // Limit the width to match container size
    whiteSpace: 'normal', // Allow wrapping
    overflow: 'hidden', // Hide overflow
    wordBreak: 'break-word', // Break long words
  },
};

export default MYDATscreenSKN;
