import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';

const ArtistsScreen = () => {
  const navigate = useNavigate();
  const { currentUser, purchasedMedia } = useContext(AppContext);

  const artists = useMemo(() => {
    if (!currentUser) {
      
      return [];
    }

    
    

    if (!currentUser.name && !currentUser.artistName) {
      
      return [];
    }

    const allArtists = purchasedMedia
      .filter(item => !/^\d+$/.test(item.contentId))
      .map(item => item.ArtistName)
      .filter(artistName => {
        const isExcluded = (artistName !== currentUser.name) && (artistName !== currentUser.artistName);
        
        return isExcluded;
      });

    const uniqueSortedArtists = [...new Set(allArtists)].sort();
    
    return uniqueSortedArtists;
  }, [purchasedMedia, currentUser.name, currentUser.artistName]);

  const handleSelectArtist = (artist) => {
    navigate('/library/artists/artistsmedialist', { state: { artist } });
  };

  return (
    <div style={styles.fullScreen}>
      <img src={require('./assets/BlankBG.png')} style={styles.fullScreenImage} alt="Background" />
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <h2 style={styles.headerText}>Artists</h2>
        </div>
        <ul style={styles.scrollContainer}>
          {artists.map((artist, index) => (
            <li key={`artist-${index}`} style={styles.listItem}>
              <button onClick={() => handleSelectArtist(artist)} style={styles.button}>
                <div style={styles.row}>
                  <img src={require('./assets/dot.png')} alt="Dot" style={{ width: '2vh', height: '2vh', marginRight: '1vw' }} />
                  <span style={styles.text}>{artist}</span>
                </div>
              </button>
              <div style={styles.borderContainer}>
                <div style={styles.centeredBorder}></div>
              </div>
            </li>
          ))}
        </ul>
        <div style={styles.extraSpace}></div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 10,
    height: `calc(100vh - 18vh)`,
  },
  extraSpace: {
    height: '300px',
      },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center', // Center the header horizontally
    position: 'relative',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '1vh',
    margin: '1vh 0',
    cursor: 'pointer',
    zIndex: 10,
    textAlign: 'left',
    width: '100%',
  },
  text: {
    color: 'white',
    fontSize: '2.4vh',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  scrollContainer: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    position: 'relative', // Changed from 'absolute' to 'relative'
    width: '100%',
    height: '130%',
    zIndex: 2,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  borderContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: '0',
  },
  centeredBorder: {
    marginLeft: '18%',
    width: '110%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(189, 252, 251, 0.3)',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1vh 0', // Add padding to the header container
    width: '100%',
    backgroundColor: 'transparent', // Ensure the background is transparent
    zIndex: 10,
  },
  headerText: {
    color: 'white',
    fontSize: '2.5vh',
    fontWeight: 'bold',
    textAlign: 'center', // Center the text horizontally
    fontFamily: 'brhendrix',
  },
  listItem: {
    marginBottom: '1vh',
  },
  image: {
    width: '2vh',
    height: '2vh',
    marginRight: '10px',
    resizeMode: 'contain',
  },
};

export default ArtistsScreen;
