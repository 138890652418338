import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import { collection, query, where, getDocs, doc, getDoc, limit } from 'firebase/firestore';
import { firestore } from './firebase';
import { debounce } from 'lodash';
import { ProfileComponentFollowing } from './ProfileComponentFollowing';
import { useNavigate } from 'react-router-dom';

const CACHE_DURATION = 5000;

const ProfilesDisplaySearchdat = ({ onProfilePress, onMoveMP }) => {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [viewMode, setViewMode] = useState('horizontal');
  const {
    userId2,
    showMediaPopup, setTrackToView, setExpandedShare, setIsExpanded,
    setIsLoadingArtwork, setIsLoadingProfilePicture, setIsLoadingExpand,
    setIsLoadingNewPopup, setIsExpandedShare, setIsLoadingHideEA,
    setInitiallySelectedItemId, setCurrentlyLoadingProfile, setActiveTab,
    setHideExpandedAlbum, currentUser, setViewingProfile,
    setActivePopupMedia, setCenteredRelease, setArtworkUri,
    setInitialExpandedAlbumAndSong, activePopupMedia
  } = useContext(AppContext);

  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);

  const handleProfileSelect = (profile) => {
      const clonedProfile = JSON.parse(JSON.stringify(profile));
      const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
      navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
    };

  useEffect(() => {
    if (isSelecting && activePopupMedia) {
      onProfilePress();
      setIsSelecting(false);
    }
  }, [activePopupMedia]);

  const sortReleasesByTimestamp = (releases) => {
    return [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
  };

  const preparePopupData = (userData) => {
    const albums = sortReleasesByTimestamp(userData.albums || []);
    const singles = sortReleasesByTimestamp(userData.singles || []);
    const videos = sortReleasesByTimestamp(userData.videos || []);

    return {
      artistName: userData.artistName || userData.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: userData.username,
      userId: userData.userId,
      accountType: userData.accountType,
      otherReleases: { albums, singles, videos },
      vocalDrop: userData.vocalDrop,
      voiceDropMuted: userData?.voiceDropMuted,
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
      timestamp: new Date().getTime(),
      ScreenSKN: userData.ScreenSKN,
    };
  };

  const getCachedUserDoc = async (userId) => {
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      return { ...userDocSnap.data(), userId: userId };
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const fetchItems = async (loadMore = false) => {
    if (searchText.trim() === '') {
      console.log("Clearing items due to empty search.");
      setItems([]);
      setIsLoading(false);
      return;
    }
  
    console.log(`Searching for: ${searchText}`);
    setIsLoading(true);
    const searchTextLower = searchText.toLowerCase();
    const usernameSearchText = searchTextLower.replace(/\s+/g, ''); // Remove spaces for username search
  
    const searchTextWithAt = '@' + searchText;
    const searchTextWithAtLower = '@' + searchTextLower;
  
    try {
      const userQuery = query(
        collection(firestore, 'users'),
        where('username_lower', '>=', searchTextLower),
        where('username_lower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      const userArtistNameQuery = query(
        collection(firestore, 'users'),
        where('ArtistNameLower', '>=', searchTextLower),
        where('ArtistNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      const exactUsernameQuery = query(
        collection(firestore, 'users'),
        where('username_lower', '==', usernameSearchText),
        limit(7)
      );
  
      const albumNameQuery = query(
        collection(firestore, 'Albums'),
        where('albumNameLower', '>=', searchTextLower),
        where('albumNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      const albumArtistNameQuery = query(
        collection(firestore, 'Albums'),
        where('ArtistNameLower', '>=', searchTextLower),
        where('ArtistNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      const albumUsernameQuery = query(
        collection(firestore, 'Albums'),
        where('username', '>=', searchTextWithAt),
        where('username', '<=', searchTextWithAt + '\uf8ff'),
        limit(7)
      );
  
      const albumUsernameLowerQuery = query(
        collection(firestore, 'Albums'),
        where('username', '>=', searchTextWithAtLower),
        where('username', '<=', searchTextWithAtLower + '\uf8ff'),
        limit(7)
      );
  
      const songNameQuery = query(
        collection(firestore, 'Songs'),
        where('songNameLower', '>=', searchTextLower),
        where('songNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      const songArtistNameQuery = query(
        collection(firestore, 'Songs'),
        where('ArtistNameLower', '>=', searchTextLower),
        where('ArtistNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      const songUsernameQuery = query(
        collection(firestore, 'Songs'),
        where('username', '>=', searchTextWithAt),
        where('username', '<=', searchTextWithAt + '\uf8ff'),
        limit(7)
      );
  
      const songUsernameLowerQuery = query(
        collection(firestore, 'Songs'),
        where('username', '>=', searchTextWithAtLower),
        where('username', '<=', searchTextWithAtLower + '\uf8ff'),
        limit(7)
      );
  
      const videoNameQuery = query(
        collection(firestore, 'Videos'),
        where('videoNameLower', '>=', searchTextLower),
        where('videoNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      const videoArtistNameQuery = query(
        collection(firestore, 'Videos'),
        where('ArtistNameLower', '>=', searchTextLower),
        where('ArtistNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      const videoUsernameQuery = query(
        collection(firestore, 'Videos'),
        where('username', '>=', searchTextWithAt),
        where('username', '<=', searchTextWithAt + '\uf8ff'),
        limit(7)
      );
  
      const videoUsernameLowerQuery = query(
        collection(firestore, 'Videos'),
        where('username', '>=', searchTextWithAtLower),
        where('username', '<=', searchTextWithAtLower + '\uf8ff'),
        limit(7)
      );
  
      const results = await Promise.all([
        getDocs(userQuery),
        getDocs(userArtistNameQuery),
        getDocs(exactUsernameQuery),  // Add this to your existing queries
        getDocs(albumNameQuery),
        getDocs(albumArtistNameQuery),
        getDocs(albumUsernameQuery),
        getDocs(albumUsernameLowerQuery),
        getDocs(songNameQuery),
        getDocs(songArtistNameQuery),
        getDocs(songUsernameQuery),
        getDocs(songUsernameLowerQuery),
        getDocs(videoNameQuery),
        getDocs(videoArtistNameQuery),
        getDocs(videoUsernameQuery),
        getDocs(videoUsernameLowerQuery)
      ]);
  
      let combinedItems = results.flatMap((snap, index) => snap.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        type: ['user', 'user', 'user', 'album', 'album', 'album', 'album', 'song', 'song', 'song', 'song', 'video', 'video', 'video', 'video'][index]
      })));
  
      // Deduplicate items based on 'id' and 'type'
      const uniqueItems = Object.values(combinedItems.reduce((acc, item) => {
        // Using a combination of id and type as a key to avoid duplicates
        const key = `${item.id}_${item.type}`;
        if (!acc[key]) {
          acc[key] = item;
        }
        return acc;
      }, {}));
  
      // Separate the users with matching username and name
      const prioritizedItems = [];
      const otherUsers = [];
      const musicItems = [];
      let foundMatchingUser = false;
  
      uniqueItems.forEach((item) => {
        if (item.type === 'user') {
          const nameNoSpaces = item.name.replace(/\s+/g, '').toLowerCase();
          const username = item.username.toLowerCase();
  
          if (username === nameNoSpaces) {
            foundMatchingUser = true;
            prioritizedItems.push(item);
            // Collect music items related to this user
            const relatedMusicItems = uniqueItems.filter(i => i.username === item.username && i.type !== 'user');
            prioritizedItems.push(...relatedMusicItems);
          } else {
            otherUsers.push(item);
          }
        } else {
          musicItems.push(item);
        }
      });
  
      // Separate fan accounts and move them to the end
      const fanAccounts = otherUsers.filter(user => user.accountType === 'Fan');
      const nonFanAccounts = otherUsers.filter(user => user.accountType !== 'Fan');
  
      // Determine finalItems structure based on whether a matching user was found
      const finalItems = foundMatchingUser
        ? [...prioritizedItems, ...musicItems, ...nonFanAccounts, ...fanAccounts]
        : [...prioritizedItems, ...nonFanAccounts, ...musicItems, ...fanAccounts];
  
      setItems(finalItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const debouncedFetch = debounce(() => fetchItems(), 500);

    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, [searchText]);

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const renderItem = ({ item }) => {
    switch (item.type) {
      case 'user':
        return <ProfileComponentFollowing userProfile={item} onPress={() => handleProfileSelect(item)} />;
      case 'song':
      case 'album':
      case 'video':
        return <MediaItem item={item} onPress={handlePressBeginsSongs} type={item.type} />;
      default:
        return <div />;
    }
  };

  const handlePressBeginsSongs = async (song) => {
    const clonedSong = JSON.parse(JSON.stringify(song));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  return (
    <div style={styles.container}>
      <div style={styles.title}>Search</div>
      <input
        style={styles.searchInput}
        placeholder="Search..."
        value={searchText}
        onChange={(e) => handleSearch(e.target.value)}
        placeholderTextColor="white"
      />
      <div style={styles.dynamicContentContainer}>
        {isLoading ? (
          <div style={styles.loaderContainer}>Loading...</div>
        ) : (
          <div style={styles.listContainer}>
            {items.map((item) => renderItem({ item }))}
          </div>
        )}
      </div>
    </div>
  );
};

const MediaItem = ({ item, onPress, type }) => {
  const getDisplayName = () => {
    switch (type) {
      case 'album':
        return item.albumName || 'Unknown Album';
      case 'song':
        return item.SongName || 'Unknown Song';
      case 'video':
        return item.VideoName || 'Unknown Video';
      default:
        return 'Unknown';
    }
  };

  return (
    <div onClick={() => onPress(item)} style={styles.itemContainer}>
      <img
        src={item.ArtworkURL}
        alt="Artwork"
        style={styles.songArtwork}
      />
      <div style={styles.textContainer}>
        <p style={styles.artistName}>{item.ArtistName || 'Unknown Artist'}</p>
        <p style={styles.songName}>{getDisplayName()}</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    flex: 1,

    alignSelf: 'center',
  },
  searchInput: {
    alignSelf: 'center',
    height: '30px',
    width: '93%',
    borderColor: 'rgba(189, 252, 251, 0.3)',
    borderWidth: 1,
    margin: 10,
    borderRadius: 5,
    paddingLeft: 10,
    color: 'white',
    backgroundColor: 'transparent',
    fontFamily: 'brhendrix',
    fontSize: '20px',
  },
  title: {
    alignSelf: 'center',
    fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    marginTop: 20,
    color: 'white',
    fontFamily: 'brhendrix',
  },
  dynamicContentContainer: {
    left: '1%',

    width: '100%',
    flex: 1,
    alignSelf: 'center',
    position: 'absolute',
    height: '58%',
  },
  listContainer: {
    paddingHorizontal: 10,
    overflowY: 'scroll',
    height: '100%', // You can adjust this height as needed
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    borderRadius: 8,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  songArtwork: {
    width: '100px',
    height: '100px',
    borderRadius: 8,
  },
  textContainer: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: '2%',
  },
  artistName: {
    color: 'white',
    fontSize: '16px',
    marginBottom: '-15px',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  songName: {
    color: '#b8b8b8',
    fontSize: '16px',
    fontFamily: 'brhendrix',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};


export { ProfilesDisplaySearchdat };
