

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import { AnalyticsComponent } from './AnalyticsComponent';
import { AnalyticsComponentRecent } from './AnalyticsComponentRecent';
import { IoArrowBack } from 'react-icons/io5';

import analyticsBoxes from './assets/AnalyticsBoxes.png';
import analyticsBoxesTotalSpns from './assets/AnalyticsBoxesTotalSpns.png';
import analyticsBoxesAlbumSales from './assets/AnalyticsBoxesAlbumSales.png';
import analyticsBoxesSingleSales from './assets/AnalyticsBoxesSingleSales.png';
import analyticsBoxesVideoSales from './assets/AnalyticsBoxesVideoSales.png';
import analyticsBoxesVerchSales from './assets/AnalyticsBoxesVerchSales.png';

const { width, height } = { width: window.innerWidth, height: window.innerHeight };
const bottomAreaHeight = height * 0.18;
const scrollAreaTopPadding = height * 0.16;

const AnalyticsPage = ({ onProfilePress }) => {
  const navigate = useNavigate();

  const {
    isUnder390, is480,
    viewingTotalSpnsAnalytics, setViewingTotalSpnsAnalytics, isUnder400, isBetween400And479,
    viewingAlbumAnalytics, setViewingAlbumAnalytics,
    viewingSingleAnalytics, setViewingSingleAnalytics,
    viewingVideosAnalytics, setViewingVideosAnalytics,
    viewingVerchAnalytics, setViewingVerchAnalytics,
    viewingDefaultAnalytics, setViewingDefaultAnalytics,
    activeCategory, setActiveCategory, musicSpns, setMusicSpns, videoSpns, setVideoSpns,
    totalSpns, setTotalSpns, singlesSales, setSinglesSales, verchSales,
    albumsSales, setAlbumsSales, videosSales, setVideosSales, totalearnings, currentEarnings,
    currentAnalyticsImage, setCurrentAnalyticsImage, viewingDefaultAnalyticsLocal, setViewingDefaultAnalyticsLocal
  } = useContext(AppContext);

  const toggleTotalSpnsDetails = () => {
    setCurrentAnalyticsImage(analyticsBoxesTotalSpns);
    setActiveCategory('TotalSpns');
    setViewingDefaultAnalyticsLocal(false);
  };

  const toggleAlbumSalesDetails = () => {
    setCurrentAnalyticsImage(analyticsBoxesAlbumSales);
    setActiveCategory('AlbumSales');
    setViewingDefaultAnalyticsLocal(false);
  };

  const toggleSingleSalesDetails = () => {
    setCurrentAnalyticsImage(analyticsBoxesSingleSales);
    setActiveCategory('SingleSales');
    setViewingDefaultAnalyticsLocal(false);
  };

  const toggleVideoSalesDetails = () => {
    setCurrentAnalyticsImage(analyticsBoxesVideoSales);
    setActiveCategory('VideoSales');
    setViewingDefaultAnalyticsLocal(false);
  };

  const toggleVerchSalesDetails = () => {
    setCurrentAnalyticsImage(analyticsBoxesVerchSales);
    setActiveCategory('VerchSales');
    setViewingDefaultAnalyticsLocal(false);
  };

  const toggleDefaultAnalytics = () => {
    setCurrentAnalyticsImage(analyticsBoxes);
    setActiveCategory('default');
    setViewingDefaultAnalyticsLocal(true);
  };

  const imageToAnalyticsMap = {
    analyticsBoxes: {
      image: analyticsBoxes,
      action: () => {
        setViewingDefaultAnalytics(true);
        setViewingAlbumAnalytics(false);
        setViewingSingleAnalytics(false);
        setViewingVideosAnalytics(false);
        setViewingVerchAnalytics(false);
        setViewingTotalSpnsAnalytics(false);
      },
    },
    analyticsBoxesTotalSpns: {
      image: analyticsBoxesTotalSpns,
      action: () => {
        setViewingTotalSpnsAnalytics(true);
        setViewingDefaultAnalytics(false);
      },
    },
    analyticsBoxesAlbumSales: {
      image: analyticsBoxesAlbumSales,
      action: () => {
        setViewingAlbumAnalytics(true);
        setViewingDefaultAnalytics(false);
      },
    },
    analyticsBoxesSingleSales: {
      image: analyticsBoxesSingleSales,
      action: () => {
        setViewingSingleAnalytics(true);
        setViewingDefaultAnalytics(false);
      },
    },
    analyticsBoxesVideoSales: {
      image: analyticsBoxesVideoSales,
      action: () => {
        setViewingVideosAnalytics(true);
        setViewingDefaultAnalytics(false);
      },
    },
    analyticsBoxesVerchSales: {
      image: analyticsBoxesVerchSales,
      action: () => {
        setViewingVerchAnalytics(true);
        setViewingDefaultAnalytics(false);
      },
    },
  };

  useEffect(() => {
    const entry = Object.values(imageToAnalyticsMap).find(({ image }) => 
      currentAnalyticsImage === image
    );
    if (entry) {
      entry.action();
    }
  }, [currentAnalyticsImage]);

  useEffect(() => {
    toggleDefaultAnalytics();
  }, []);

  const analyticsContainer = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '440px',
    top: '45px',
    width: '100%',
    position: 'absolute',

    zIndex: 8000,
    backgroundColor: 'transparent',
    padding: '0 10px',
  };

  const styles = {
    fullScreen: {
      display: 'flex',
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      flexDirection: 'column',

    },
    scrollContainer: {
      position: 'absolute',
      justifyContent: 'center',
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      zIndex: 2,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
    fullScreenImage: {
      width: width,
      height: height,
      position: 'absolute',
      zIndex: 0,
    },
    fullScreenTouchable: {
      width: '100%',
      height: '950px',
      top: '50px',
      alignSelf: 'center',
      justifyContent: 'center',
      margin: '0 auto',
      position: 'absolute',
      alignItems: 'center',
    },
    buttonTouchableArea: {
      position: 'absolute',
      width: width,
      height: height,
      zIndex: 1,
    },
    AnalyticsPopup: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      width: '100%',
      height: '100%',
      resizeMode: 'cover',
      zIndex: 900,
    },
    backArrow: {
      position: 'absolute',
      top: isUnder400 ? '8px' : '20px',
      left: isUnder400 ? '10px' : isBetween400And479 ? '10px' : '35px',
      fontSize: '24px',
      color: 'white',
      cursor: 'pointer',
      zIndex: 10000,
    },
    totalSpnsSelect: {

      alignSelf: 'center',
      justifyContent: 'center',
      width: '400px',
      height: '85px',
      zIndex: 9000,
      display: 'flex',
      backgroundColor: 'transparent',
      opacity: 0.5,
      border: 'none',
    },
    albumSalesSelect: {

      alignSelf: 'center',
      justifyContent: 'center',
      width: '400px',
      height: '85px',
      zIndex: 9000,
      display: 'flex',
      backgroundColor: 'transparent',
      opacity: 0.5,
      border: 'none',
    },
    singleSalesSelect: {

      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      width: '400px',
      height: '85px',
      zIndex: 9000,


      backgroundColor: 'transparent',
      opacity: 0.5,
      border: 'none',
    },
    videoSalesSelect: {

      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '400px',
      height: '85px',
      zIndex: 9000,
      backgroundColor: 'transparent',
      opacity: 0.5,
      border: 'none',
    },
    verchSalesSelect: {

      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'center',
      width: '400px',
      height: '85px',
      zIndex: 9000,
      backgroundColor: 'transparent',
      opacity: 0.5,
      border: 'none',
    },
    containerAnalytics: {
      flex: 1,
      alignItems: 'center',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      top: '11%',
      left: '3%',
      zIndex: 12000,
      height: '36%',
      width: '43%',
      backgroundColor: 'transparent',
    },
    containerAnalyticsRecent: {
      flex: 1,
      display: 'flex',
      backgroundColor: 'transparent',
      position: 'absolute',
      width: '43%',
      right: '3%',
      top: '12.5%',
      zIndex: 12000,
      height: '36%',
      overflow: 'hidden',

    },
    fullScreenImageAnalytics: {
      position: 'absolute',
      width: '100%',
      display: 'flex',
      height: '50%',
      objectFit: 'contain',
      zIndex: 9,
      alignSelf: 'center',
backgroundColor: 'transparent',

      justifyContent: 'center',
      alignItems: 'center',
    },
    analyticsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      height: '52.5vh',  // Use vh to make it responsive to viewport height
      width: '100%',
      position: 'absolute',
      top: '10vh',  // Use vh to position relative to viewport height
      zIndex: 7000,
      backgroundColor: 'transparent',
      padding: '0 10px',  // Add padding to ensure consistent spacing
    },
    valueText: {
      fontFamily: 'brhendrix',
      fontSize: '20px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center',
      zIndex: 10,
    },
    albumSalesText: {
      fontFamily: 'brhendrix',
      fontSize: '20px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    singleSalesText: {
      fontFamily: 'brhendrix',
      fontSize: '20px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    videosSalesText: {
      fontFamily: 'brhendrix',
      fontSize: '20px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    verchSalesText: {
      fontFamily: 'brhendrix',
      fontSize: '20px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  totalSpnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',  // Evenly distributes the space between children
    width: '370px',  // Fixed width
    margin: '0 auto',  // Centers the container horizontally in the parent
    top: '0.7%',
    height: '10%',
    zIndex: 10000,
    position: 'absolute',
    left: '50%',  // Moves the left edge to the center of the parent
    transform: 'translateX(-50%)',  // Shifts the container back by half its own width to truly center it
    padding: '0 2%',  // Padding on each side within the container
  },
  totalSpnsContainer2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',  // Evenly distributes the space between children
    width: '305px',  // Fixed width
    margin: '0 auto',  // Centers the container horizontally in the parent
    top: '0.7%',
    height: '10%',
    zIndex: 10000,
    position: 'absolute',
    left: '50%',  // Moves the left edge to the center of the parent
    transform: 'translateX(-50%)',  // Shifts the container back by half its own width to truly center it
    padding: '0 2%',  // Padding on each side within the container
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    top: '1.8%',
    justifyContent: 'space-around',  // Evenly distributes the space between children
    width: '370px',  // Fixed width
    margin: '0 auto',  // Centers the container horizontally in the parent
    height: '46.5%',
    zIndex: 10000,
    position: 'absolute',
    left: '50%',  // Moves the left edge to the center of the parent
    transform: 'translateX(-50%)',  // Shifts the container back by half its own width to truly center it
  },
    valueTextDetailsTotalSpns: {
      width: '30%', // Explicitly setting the width
      fontFamily: 'brhendrix',
      fontSize: '16px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center', // Ensures text is centered within the div
      zIndex: 10000,
    },
    musicSpnsDetails: {
      width: '30%', // Explicitly setting the width
      fontFamily: 'brhendrix',
      fontSize: '16px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center', // Ensures text is centered within the div
      zIndex: 10000,
    },
    videoSpnsDetails: {
      width: '30%', // Explicitly setting the width
      fontFamily: 'brhendrix',
      fontSize: '16px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center', // Ensures text is centered within the div
      zIndex: 10000,
    },
    albumSalesTextDetails: {

      fontFamily: 'brhendrix',
      fontSize: '18px',
      color: '#62fbfa',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold',
      textAlign: 'center',
      borderRadius: 5,
      alignSelf: 'center',
      zIndex: 10000,
    },
    singleSalesTextDetails: {
      fontFamily: 'brhendrix',
      fontSize: '18px',
      color: '#62fbfa',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold',
      textAlign: 'center',
      borderRadius: 5,
      alignSelf: 'center',
      zIndex: 10000,
    },
    videosSalesTextDetails: {
      fontFamily: 'brhendrix',
      fontSize: '18px',
      color: '#62fbfa',
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 'bold',
      textAlign: 'center',
      borderRadius: 5,
      alignSelf: 'center',
      zIndex: 10000,
    },
    verchSalesTextDetails: {

      fontFamily: 'brhendrix',
      fontSize: '18px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center',
      zIndex: 10000,
      borderRadius: 5,
    },
    verchSalesTextDetailsScreenSKN: {
      fontFamily: 'brhendrix',
      fontSize: '18px',
      color: '#62fbfa',
      fontWeight: 'bold',
      textAlign: 'center',
      borderRadius: 5,
      alignSelf: 'center',
      zIndex: 10000,
    },
  };

  return (
    <div style={styles.fullScreen}>

      <div style={styles.scrollContainer}>
        <div style={styles.AnalyticsPopup}>
          
          {viewingDefaultAnalyticsLocal ? null : (
            <IoArrowBack
              style={styles.backArrow}
              onClick={toggleDefaultAnalytics}
            />
          )}
          {viewingDefaultAnalyticsLocal && (
            <>
                      <div style={styles.fullScreenTouchable}>
                      <img src={currentAnalyticsImage} alt="Analytics" style={styles.fullScreenImageAnalytics} />
                      <div style={styles.selectContainer}>
              <button style={styles.totalSpnsSelect} onClick={toggleTotalSpnsDetails} />
              <button style={styles.albumSalesSelect} onClick={toggleAlbumSalesDetails} />
              <button style={styles.singleSalesSelect} onClick={toggleSingleSalesDetails} />
              <button style={styles.videoSalesSelect} onClick={toggleVideoSalesDetails} />
              <button style={styles.verchSalesSelect} onClick={toggleVerchSalesDetails} />
              </div>
              <div style={analyticsContainer}>
            <div style={styles.valueText}>{totalSpns}</div>
            <div style={styles.albumSalesText}>{albumsSales}</div>
            <div style={styles.singleSalesText}>{singlesSales}</div>
            <div style={styles.videosSalesText}>{videosSales}</div>
            <div style={styles.verchSalesText}>{verchSales}</div>
          </div>
              </div>
            </>
          )}
          {!viewingDefaultAnalyticsLocal && (
            <>

            </>
          )}
        {viewingDefaultAnalyticsLocal && (
          <>
                    <div style={styles.fullScreenTouchable}>
                      
          </div>
          </>
        )}
          {viewingAlbumAnalytics && (
            <>
                                <div style={styles.fullScreenTouchable}>
            <img src={currentAnalyticsImage} alt="Analytics" style={styles.fullScreenImageAnalytics} />
            <div style={styles.totalSpnsContainer}>
              <div style={styles.albumSalesTextDetails}>{albumsSales}</div>
              </div>
              <div style={styles.containerAnalytics}>
                <AnalyticsComponent />
              </div>
              <div style={styles.containerAnalyticsRecent}>
                <AnalyticsComponentRecent onProfilePress={() => onProfilePress()} />
              </div>
              </div>
            </>
          )}
          {viewingSingleAnalytics && (
            <>
                                            <div style={styles.fullScreenTouchable}>
            <img src={currentAnalyticsImage} alt="Analytics" style={styles.fullScreenImageAnalytics} />
            <div style={styles.totalSpnsContainer}>
              <div style={styles.singleSalesTextDetails}>{singlesSales}</div>
              </div>
              <div style={styles.containerAnalytics}>
                <AnalyticsComponent />
              </div>
              <div style={styles.containerAnalyticsRecent}>
                <AnalyticsComponentRecent onProfilePress={() => onProfilePress()} />
              </div>
              </div>
            </>
          )}
          {viewingVideosAnalytics && (
            <>
                                                        <div style={styles.fullScreenTouchable}>
            <img src={currentAnalyticsImage} alt="Analytics" style={styles.fullScreenImageAnalytics} />
            <div style={styles.totalSpnsContainer}>
              <div style={styles.videosSalesTextDetails}>{videosSales}</div>
              </div>
              <div style={styles.containerAnalytics}>
                <AnalyticsComponent />
              </div>
              <div style={styles.containerAnalyticsRecent}>
                <AnalyticsComponentRecent onProfilePress={() => onProfilePress()} />
              </div>
              </div>
            </>
          )}
          {viewingVerchAnalytics && (
            <>
                                                                    <div style={styles.fullScreenTouchable}>
            <img src={currentAnalyticsImage} alt="Analytics" style={styles.fullScreenImageAnalytics} />
            <div style={styles.totalSpnsContainer2}>
              <div style={styles.verchSalesTextDetails}>{verchSales}</div>
              <div style={styles.verchSalesTextDetailsScreenSKN}>{verchSales}</div>
              </div>
              <div style={styles.containerAnalytics}>
                <AnalyticsComponent />
              </div>
              <div style={styles.containerAnalyticsRecent}>
                <AnalyticsComponentRecent onProfilePress={() => onProfilePress()} />
              </div>
              </div>
            </>
          )} 
        </div>
                  {viewingTotalSpnsAnalytics && (
            <>
                                                                                <div style={styles.fullScreenTouchable}>
                                                                                <div style={styles.containerAnalytics}>
                <AnalyticsComponent />
              </div>
              <div style={styles.containerAnalyticsRecent}>
                <AnalyticsComponentRecent onProfilePress={() => onProfilePress()} />
              </div>
            <img src={currentAnalyticsImage} alt="Analytics" style={styles.fullScreenImageAnalytics} />

            <div style={styles.totalSpnsContainer}>
              <div style={styles.valueTextDetailsTotalSpns}>{totalSpns}</div>
              <div style={styles.musicSpnsDetails}>{musicSpns}</div>
              <div style={styles.videoSpnsDetails}>{videoSpns}</div>
             </div>
             </div>
            </>
          )}
      </div>
    </div>
  );
};

const imageSize = width * 0.43;



export default AnalyticsPage;

