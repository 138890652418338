import React, { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from './AppContext'; // Adjust the path as necessary to match your project structure
import { getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import Modal from 'react-modal';

const VoiceDropPage = () => {
  const { setUser, currentUser } = useContext(AppContext);

  const [fileName, setFileName] = useState('');
  const [fileUri, setFileUri] = useState('');
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [isValidFile, setIsValidFile] = useState(false); // To manage upload button state
  const fileInputRef = useRef(null); // To reset the file input

  useEffect(() => {
    return audioPlayer
      ? () => {
          audioPlayer.pause();
        }
      : undefined;
  }, [audioPlayer]);

  const pickSong = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUri = URL.createObjectURL(file);

      const audio = new Audio(fileUri);

      // Load the audio to get its duration
      audio.onloadedmetadata = () => {
        const duration = audio.duration;

        // Check if the duration is 5 seconds or less
        if (duration <= 5) {
          setFileName(file.name);
          setFileUri(fileUri);
          setAudioPlayer(audio);
          setIsValidFile(true);
        } else {
          alert("Please select an audio file that is 5 seconds or less.");
          // Reset the file input
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
          // Clean up any existing audio player
          if (audioPlayer) {
            audioPlayer.pause();
            setAudioPlayer(null);
          }
          setFileName('');
          setFileUri('');
          setIsValidFile(false);
        }
      };

      // Handle error in loading metadata
      audio.onerror = () => {
        alert("Unable to load audio file. Please try a different file.");
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setFileName('');
        setFileUri('');
        setIsValidFile(false);
      };
    }
  };

  const previewSong = () => {
    if (audioPlayer) {
      audioPlayer.play();
    }
  };

  const handleUpload = async () => {
    try {
      if (!fileUri || !fileName) {
        alert('No file selected for upload.');
        return;
      }
      const downloadURL = await uploadSongFile(fileUri, fileName);
      await saveFileToFirestore(downloadURL, fileName);
      alert('Voice drop has been uploaded and saved.');
      setFileName('');
      setFileUri('');
      setIsValidFile(false);
      if (audioPlayer) {
        audioPlayer.pause();
        setAudioPlayer(null);
      }
      // Reset the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    } catch (error) {
      alert('There was a problem uploading your voice drop.');
      console.error("Upload error:", error);
    }
  };

  const uploadSongFile = async (uri, name) => {
    const storage = getStorage();
    const storageRef = ref(storage, `voicedrops/${name}`);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();
      const uploadTask = uploadBytesResumable(storageRef, blob);

      const downloadURL = await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          () => {},
          (error) => {
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              resolve(url);
            });
          }
        );
      });

      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const saveFileToFirestore = async (downloadURL, name) => {
    const firestore = getFirestore();
    const currentUser = getAuth().currentUser;
    if (!currentUser) {
      alert('No authenticated user found.');
      return;
    }
    const userDocRef = doc(firestore, "users", currentUser.uid);
    try {
      await updateDoc(userDocRef, {
        vocalDrop: downloadURL
      });
    } catch (error) {
      alert('Failed to save the file to Firestore.');
      console.error("Error saving file to Firestore:", error);
    }
  };

  const CustomButton = ({ onClick, title, disabled }) => (
    <button onClick={onClick} style={{ ...customButtonStyle, opacity: disabled ? 0.5 : 1 }} disabled={disabled}>
      {title}
    </button>
  );

  return (
    <div style={fullScreenStyle}>
      <div style={scrollContainerStyle}>
        <div style={scrollViewInnerContainerStyle}>
          <h1 style={titleStyle}>Add Voice Drop</h1>
          <input
            type="file"
            accept="audio/*"
            onChange={pickSong}
            style={fileInputStyle}
            ref={fileInputRef}
          />
          {fileName && (
            <>
              <p style={subtitleStyle}>Selected File: {fileName}</p>
              <CustomButton title="Preview" onClick={previewSong} disabled={!isValidFile} />
              <CustomButton title="Upload Voice Drop" onClick={handleUpload} disabled={!isValidFile} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const fullScreenStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100vh',
  position: 'relative',
  zIndex: 10,
};

const buttonTouchableAreaStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
};

const fullScreenImageStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 0,
};

const scrollContainerStyle = {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: 'calc(100vh - 18vh)', // Adjust the height calculation as needed
  zIndex: 2,
  overflowY: 'auto',
};

const scrollViewInnerContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',
  paddingTop: '11.9vh', // Adjust the padding calculation as needed
  zIndex: 5,
};

const titleStyle = {
  fontSize: '3.5vw',
  fontWeight: 'bold',
  marginBottom: 20,
  color: 'white',
  fontFamily: 'brhendrix', // Ensure you have this font available, or adjust as necessary
};

const subtitleStyle = {
  fontSize: '2.4vw',
  fontWeight: 'regular',
  color: 'white',
  marginBottom: 8,
  marginTop: 15,
};

const customButtonStyle = {
  padding: 10,
  borderRadius: 5,
  backgroundColor: 'rgba(0,0,0,0.5)',
  marginBottom: 16,
  width: '80%',
  color: 'white',
  fontSize: '2.4vw',
  fontFamily: 'brhendrix',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
  cursor: 'pointer',
  border: 'none',
};

const fullScreenImageOverlayStyle = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: 10,
};

const fileInputStyle = {
  marginBottom: 16,
  color: 'white',
  backgroundColor: 'rgba(0,0,0,0.5)',
  padding: 10,
  borderRadius: 5,
  cursor: 'pointer',
};

export default VoiceDropPage;
