import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { getFirestore, doc, updateDoc, getDocs, query, collection, where } from 'firebase/firestore';
import { AppContext } from './AppContext';

const PersonalInfo = () => {
  const { currentUser, setUser } = useContext(AppContext);

  const auth = getAuth();
  const firestore = getFirestore();

  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState('');
  const [realName, setRealName] = useState('');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [displayName, setDisplayName] = useState(currentUser?.name || '');
  const [username, setUsername] = useState(currentUser?.username || '');

  const reauthenticate = async (currentPassword) => {
    try {
      const user = auth.currentUser;
      await signInWithEmailAndPassword(auth, user.email, currentPassword);
      return true;
    } catch (error) {
      console.error("Re-authentication failed:", error);
      alert("Authentication failed. Please check your current password and try again.");
      return false;
    }
  };

  const handleUpdatePersonalInfo = async () => {
    if (await reauthenticate(currentPassword)) {
      try {
        const user = auth.currentUser;
        const userRef = doc(firestore, "users", user.uid);
        const updateData = {};

        if (realName.trim()) updateData.realName = realName;
        if (address.trim()) updateData.address = address;
        if (phoneNumber.trim()) updateData.phoneNumber = phoneNumber;

        if (currentUser.accountType === "Fan") {
          if (displayName.trim() && displayName !== currentUser.name) {
            updateData.name = displayName;
            updateData.ArtistNameLower = displayName.toLowerCase();
            updateData.ArtistName = displayName; // Update ArtistName field
          }
          if (username.trim() && username !== currentUser.username) {
            const usernameSnapshot = await getDocs(query(collection(firestore, "users"), where("username_lower", "==", username.toLowerCase())));
            if (!usernameSnapshot.empty) {
              alert("Username is taken. Please choose a different username.");
              return;
            }
            updateData.username = username;
            updateData.username_lower = username.toLowerCase();
          }
        }

        if (Object.keys(updateData).length > 0) {
          await updateDoc(userRef, updateData);
          alert("Personal information updated successfully!");
        } else {
          alert("No information has been updated.");
        }
      } catch (error) {
        console.error("Error updating personal information:", error);
        alert("Update failed. Failed to update personal information.");
      }
    }
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <input
          type="password"
          style={styles.input}
          onChange={(e) => setCurrentPassword(e.target.value)}
          value={currentPassword}
          placeholder="Current password"
        />
        <label style={styles.title}>Real Name</label>
        <input
          type="text"
          style={styles.input}
          onChange={(e) => setRealName(e.target.value)}
          value={realName}
          placeholder="Real Name"
        />
        {currentUser.accountType === "Fan" && (
          <>
            <label style={styles.title}>Display Name</label>
            <input
              type="text"
              style={styles.input}
              onChange={(e) => setDisplayName(e.target.value)}
              value={displayName}
              placeholder="Display Name"
            />
            <label style={styles.title}>Username</label>
            <input
              type="text"
              style={styles.input}
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              placeholder="Username"
            />
          </>
        )}
        <label style={styles.title}>Address</label>
        <input
          type="text"
          style={styles.input}
          onChange={(e) => setAddress(e.target.value)}
          value={address}
          placeholder="Address"
        />
        <label style={styles.title}>Phone Number</label>
        <input
          type="text"
          style={styles.input}
          onChange={(e) => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          placeholder="Phone Number"
          pattern="[0-9]*"
        />
        <button style={styles.button} onClick={handleUpdatePersonalInfo}>
          <span style={styles.buttonText}>Update Personal Info</span>
        </button>
        {currentUser.accountType === 'Artist' && (
          <p style={styles.supportText}>
            Contact support@spndat.com to request a change to your Artist Name or @username
          </p>
        )}
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  supportText: {
    textAlign: 'center',
    color: 'white',
    fontSize: '1rem',
    marginBottom: '20px',
  },
  scrollContainer: {
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '5vh',
    paddingBottom: '10vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  input: {
    width: '90%',
    padding: '10px',
    height: '40px',
    marginBottom: '10px',
    borderWidth: '1px',
    fontSize: '16px',
    borderColor: '#ccc',
    borderRadius: '5px',
    color: 'white',
    backgroundColor: 'transparent', // Ensure background is transparent to show background image
  },
  button: {
    borderRadius: '5px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginBottom: '10px',
    backgroundColor: 'transparent', // Slightly transparent background for button
    border: 'none',
  },
  buttonText: {
    color: '#62fbfa',
    fontSize: '18px',
  },
  title: {
    color: 'white',
    fontSize: '1.2rem',
    marginBottom: '5px',
    alignSelf: 'flex-start',
    marginLeft: '5%',
  },
};

export default PersonalInfo;
