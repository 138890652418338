import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from './AppContext';
import { query, getDocs, where, collection, getFirestore, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ProfileComponentMovie } from './ProfileComponentMovie';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { useNavigate, useLocation } from 'react-router-dom';
import BUYDATmoviePopup from './assets/BUYDATmoviePopup.png';
import RentMoviePopup from './assets/RentMoviePopup.png';
import popupDarker from './assets/popupDarker.png';
import BUYDATmovie from './assets/BUYDATmovie.png';
import SPNDATtouchablebutton from './assets/SPNDATtouchablebutton.png';
import PurchasedPlayButtonPlay from './assets/purchasedPlayButtonPlay.png';

const genresList = {
  action: "Action",
  adventure: "Adventure",
  animation: "Animation",
  comedy: "Comedy",
  crime: "Crime",
  documentary: "Documentary",
  drama: "Drama",
  fantasy: "Fantasy",
  historical: "Historical",
  horror: "Horror",
  musical: "Musical",
  mystery: "Mystery",
  romance: "Romance",
  sci_fi: "Sci-Fi",
  thriller: "Thriller",
};

const MovieView = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { userId2, creditsIOS, purchasedMovies } = useContext(AppContext);
  const firestore = getFirestore();
  const [rentMoviePrompt, setRentMoviePrompt] = useState(false);
  const [buyMoviePrompt, setBuyMoviePrompt] = useState(false);
  const [isMoviePurchased, setIsMoviePurchased] = useState(false);
  const [isMovieRented, setIsMovieRented] = useState(false);


  const location = useLocation();
  const navigate = useNavigate();
  const { movie } = location.state;

  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const [hasPremiered, setHasPremiered] = useState(false);



  useEffect(() => {
    const checkPremiereDate = () => {
      const now = new Date();
      const estToday = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      estToday.setHours(0, 0, 0, 0);  // Set time to midnight EST/EDT
  
      const premiereDate = new Date(movie.premiereDate);
      const estPremiereDay = new Date(premiereDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));
      estPremiereDay.setHours(0, 0, 0, 0);  // Set time to midnight EST/EDT
  
      if (estPremiereDay.getTime() <= estToday.getTime()) {
        setHasPremiered(true);
      } else {
        setHasPremiered(false);
      }
    };
  
    checkPremiereDate();
  }, [movie]);



  useEffect(() => {
    const checkMovieStatus = async () => {
      try {
        const purchasedMovie = purchasedMovies.find(item => item.link === movie.link);
        if (purchasedMovie) {
          setIsMoviePurchased(true);
        }
  
        const rentedMoviesRef = collection(firestore, `users/${userId2}/RentedMovies`);
        const rentedQuery = query(rentedMoviesRef, where('link', '==', movie.link));
        const rentedSnapshot = await getDocs(rentedQuery);
  
        if (!rentedSnapshot.empty) {
          const rentedMovieDoc = rentedSnapshot.docs[0];
          const rentedTimestamp = rentedMovieDoc.data().RentedTimestamp.toDate();
          const timeSinceRented = new Date() - rentedTimestamp;
  
          if (timeSinceRented < 24 * 60 * 60 * 1000) { // 24 hours in milliseconds
            setIsMovieRented(true);
          }
        }
      } catch (error) {
        console.error('Error checking movie status:', error);
      }
    };
  
    checkMovieStatus();
  }, [purchasedMovies]);
  

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userDocRef = doc(firestore, 'users', movie.userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists() && !userProfile) {
          setUserProfile(userDoc.data());
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    if (movie.userId) {
      fetchUserProfile();
    }
  }, []);

  useEffect(() => {
    if (isVideoPlaying && videoRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        autoplay: true,
        preload: 'auto',
        controlBar: {
          children: [
            'playToggle',         // Play/Pause button
            'volumePanel',        // Volume control
            'currentTimeDisplay', // Current time of the video
            'timeDivider',        // Divider between current time and duration
            'durationDisplay',    // Total duration of the video
            'progressControl',    // Seek bar (this includes the seek bar)
            'fullscreenToggle',   // Fullscreen button
          ],
        },
      });
  
      playerRef.current.src({
        src: movie.link,
        type: 'video/mp4',
      });
  
      playerRef.current.on('fullscreenchange', () => {
        const isFullscreen = playerRef.current.isFullscreen();
        setIsFullscreen(isFullscreen);
      });
  
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
        }
      };
    }
  }, [isVideoPlaying]);


  const playTrailer = () => {
    setIsVideoPlaying(true);
  }


  const handleBUY = async () => {
    try {
      setBuyMoviePrompt(false);
      const firestore = getFirestore();
      const purchasedMoviesRef = collection(firestore, `users/${userId2}/PurchasedMovies`);
  
      // Query for the movie by the link field
      const q = query(purchasedMoviesRef, where("link", "==", movie.link));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        setIsVideoPlaying(true);
        return;
      }
  
      const functions = getFunctions();
      const purchaseMovie = httpsCallable(functions, 'purchaseMovie2');
      const readableTimestamp = new Date().toISOString();
  
      if (creditsIOS >= 200) {
        const result = await purchaseMovie({
          contentId: movie.contentId,
          userId: movie.userId,
          centeredRelease: movie,
          readableTimestamp,
          movie,
        });
  
        if (result.data.status === 'success') {
          setIsVideoPlaying(true);  // Set the state to play the video
        }
      } else {
        alert('Insufficient Credits', 'You do not have enough credits to purchase this movie.');
      }
    } catch (error) {
      console.error('Error purchasing movie:', error);
    }
  };
  

  const handleSPN = async () => {
    try {
      setRentMoviePrompt(false);
      const movieLink = movie.link;

      if (!userId2 || !movieLink) {
        console.error('Error: Missing userId2 or movieLink');
        setRentMoviePrompt(false);
        return;
      }

      if (isMoviePurchased || isMovieRented) {
        setIsVideoPlaying(true);
        return;
      }

      const rentedMoviesRef = collection(firestore, `users/${userId2}/RentedMovies`);
      const rentedMovieQuery = query(rentedMoviesRef, where('link', '==', movieLink));
      const querySnapshot = await getDocs(rentedMovieQuery);

      if (!querySnapshot.empty) {
        const rentedMovieDoc = querySnapshot.docs[0];
        const rentedTimestamp = rentedMovieDoc.data().RentedTimestamp.toDate();
        const timeSinceRented = new Date() - rentedTimestamp;

        if (timeSinceRented < 24 * 60 * 60 * 1000) {
          setIsVideoPlaying(true);
          return;
        }
      }

      const functions = getFunctions();
      const rentMovie = httpsCallable(functions, 'rentMovie2');
      const readableTimestamp = new Date().toISOString();

      if (creditsIOS >= 100) {
        const result = await rentMovie({
          contentId: movieLink,
          userId: movie.userId,
          centeredRelease: movie,
          readableTimestamp,
          movie,
        });

        if (result.data.status === 'success') {
          setIsVideoPlaying(true);
          setRentMoviePrompt(false);
        }
      } else {
        setRentMoviePrompt(false);
        alert('Insufficient Credits', 'You do not have enough credits to rent this movie.');
      }
    } catch (error) {
      setRentMoviePrompt(false);
      console.error('Error renting movie:', error);
    }
  };

  const handleNavigateProfile = () => {
    const clonedProfile = JSON.parse(JSON.stringify(userProfile));
    const targetRoute = userProfile.userId === userId2 ? '/mydat/profile' : `/userprofile/${userProfile.username}`;
    navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
  };

  const handleOpenRentMoviePrompt = () => {
    if (isMoviePurchased || isMovieRented) {
      handleSPN();
    } else {
      setRentMoviePrompt(true);
    }
  };

  const getGenreLabel = (value) => {
    return genresList[value] || value;
  };

  const renderCredits = (label, items) => (
    <div style={styles.creditsSection}>
      <h3 style={styles.sectionTitle}>{label}:</h3>
      {items.map((item, index) => (
        <p key={index} style={styles.creditsText}>
          {label === 'Genres' ? getGenreLabel(item) : item.tag || item}
        </p>
      ))}
    </div>
  );

  const stylesToApply = isFullscreen ? styles.fullscreenStyles : styles.nonFullscreenStyles;

  return (
    <>
      <div style={styles.fullScreen}>
        <div style={styles.scrollContainer}>
          {userProfile && (
            <div style={styles.profileContainer}>
              <ProfileComponentMovie
                profileImageUrl={userProfile.profileImageUrl}
                username={userProfile.username}
                name={userProfile.name}
                onClick={handleNavigateProfile}
                accountType={userProfile.accountType}
              />
            </div>
          )}

          <div style={styles.movieItemContainer}>
            {isVideoPlaying ? (
              <div data-vjs-player style={stylesToApply.videoContainer}>
                <video ref={videoRef} className="video-js vjs-default-skin vjs-big-play-centered" style={stylesToApply.videoPlayer}></video>
              </div>
            ) : (
              <img src={movie.VideoThumbnailURL} alt="Movie Thumbnail" style={styles.movieThumbnail} />
            )}
            <h2 style={styles.movieTitle}>{movie?.isTrailer ? movie.title : movie.MovieTitle}</h2>
          </div>
{hasPremiered && (
          <div style={isMoviePurchased ? styles.buttonsContainer2 : styles.buttonsContainer}>
<div style={styles.button} >
  <img src={isMoviePurchased ? PurchasedPlayButtonPlay : SPNDATtouchablebutton} alt="Rent Movie" style={styles.button} onClick={handleOpenRentMoviePrompt} />
  </div>
  {!isMoviePurchased && (
  <div style={styles.button2}>
  <img src={BUYDATmovie} alt="Buy Movie" style={styles.button2} onClick={() => setBuyMoviePrompt(true)} />
  </div>
)}
          </div>
)}

{movie?.isTrailer && (
  <div style={styles.buttonsContainer2}>
  <div style={styles.button} >
  <img src={SPNDATtouchablebutton} alt="Rent Movie" style={styles.button} onClick={playTrailer} />
  </div>  
  </div>
)}

{!movie?.isTrailer && (
          <div style={styles.creditsContainer}>
            {renderCredits('Genres', movie.genres || [])}
            {renderCredits('Directors', movie.directors || [])}
            {renderCredits('Producers', movie.producers || [])}
            {renderCredits('Cast', movie.cast || [])}
            {renderCredits('Production Company', movie.productionCompany || [])}
          </div>
)}
          <div style={styles.extraSpace}></div>
        </div>
        
      </div>
      
      {rentMoviePrompt && (
        <>
          <div style={styles.fullScreenContainer}>
            <img src={popupDarker} alt="Rent Movie" style={styles.fullScreenImage} />
          </div>

          <div style={styles.alertContainer}>
            <img src={RentMoviePopup} alt="Rent Movie" style={styles.imageAlert} />
            <button style={styles.rightButtonAlert} onClick={handleSPN}>Confirm Rent</button>
            <button style={styles.leftButtonAlert} onClick={() => setRentMoviePrompt(false)}>Cancel</button>
          </div>
        </>
      )}

      {buyMoviePrompt && (
        <>
          <div style={styles.fullScreenContainer}>
            <img src={popupDarker} alt="Buy Movie" style={styles.fullScreenImage} />
          </div>

          <div style={styles.alertContainer}>
            <img src={BUYDATmoviePopup} alt="Buy Movie" style={styles.imageAlert} />
            <button style={styles.rightButtonAlert} onClick={() => handleBUY()}></button>
            <button style={styles.leftButtonAlert} onClick={() => setBuyMoviePrompt(false)}></button>
          </div>
        </>
      )}
    </>
  );
};

const styles = {
  fullScreen: {
    backgroundColor: 'black',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '200px', // Adjust width as necessary
  },
  button: {
    width: '100px', // Adjust width as necessary
    height: '40px', // Adjust height as necessary
    cursor: 'pointer',
  },
  button2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px', // Adjust width as necessary
    height: '40px', // Adjust height as necessary
    cursor: 'pointer',
  },
  fullscreenStyles: {
    videoContainer: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
    videoPlayer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  nonFullscreenStyles: {
    videoContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: 'auto',
      position: 'relative',
    },
    videoPlayer: {
      width: '100%',
      maxWidth: '640px',
      height: 'auto',
      aspectRatio: '16/9',
    },
  },
  fullScreenContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 90000,
    backgroundSize: 'cover',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullScreenImage: {
    resize: 'cover',
    height: '100%',
    width: '100%',
    backgroundColor: 'transparent',
  },
  extraSpace: {
    height: '250px',
  },
  leftButtonAlert: {
    position: 'absolute',
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    left: '12%',
    bottom: '40%',
    width: '37%',
    height: '7%',
    zIndex: 90000,
  },
  rightButtonAlert: {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    position: 'absolute',
    right: '12%',
    bottom: '40%',
    width: '37%',
    height: '7%',
    zIndex: 90000,
  },
  alertContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 90000,
    backgroundSize: 'cover',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageAlert: {
    width: '80%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 90000,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    width: '60%',
    margin: '0 auto',
  },
  buttonsContainer2: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
    width: '60%',
    margin: '0 auto',
  },
  buttonImage: {
    width: 120,
    height: 40,
    backgroundColor: '#62fbfa',
    color: 'white',
    border: 'none',
    borderRadius: 5,
    cursor: 'pointer',
    textAlign: 'center',
  },
  profileContainer: {
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  scrollContainer: {
    position: 'absolute',
    width: '100%',
    height: 'calc(100vh - 18vh)',
    zIndex: 2,
    overflowY: 'auto',
    paddingTop: '2vh',
    paddingBottom: '10vh',
  },
  movieItemContainer: {
    marginBottom: 20,
    alignItems: 'center',
    textAlign: 'center',
  },
  movieThumbnail: {
    width: '90%',
    aspectRatio: '16/9',
    borderRadius: 8,
    marginBottom: 10,
  },
  movieTitle: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 5,
  },
  creditsContainer: {
    padding: '0 20px',
    borderRadius: 8,
    marginVertical: 10,
  },
  creditsSection: {
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#62fbfa',
    marginBottom: 5,
  },
  creditsText: {
    fontSize: 14,
    color: 'white',
    marginLeft: 10,
  },
};

export default MovieView;
