import React from 'react';


const SMPPage = () => {

  

  const DISPLAY_TEXT = `SPNDAT offers strategic marketing programs to its vendor accounts. Coming soon, a list of updated programs.`;


  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <div style={styles.scrollContent}>
          <p style={styles.fullScreenText}>{DISPLAY_TEXT}</p>
        </div>
      </div>
      <div style={styles.bottomArea} />
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'relative',
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 'calc(100vh - 18vh)', // Adjust for bottom area height
    zIndex: 2,
    overflowY: 'auto',
    paddingTop: '16vh', // Scroll area top padding
    paddingBottom: '10vh',
  },
  scrollContent: {
    padding: '0 3%',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  buttonTouchableArea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  bottomArea: {
    height: '18vh', // Bottom area height
    backgroundColor: 'transparent',
  },
  fullScreenText: {
    marginTop: '3vh',
    fontSize: '2.5vh',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    letterSpacing: '-0.4px',
  },
};

export default SMPPage;
