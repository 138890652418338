import React, { useContext, useRef } from 'react';
import { AppContext } from './AppContext';

const { width, height } = { width: window.innerWidth, height: window.innerHeight };

const MYDATProfilePicSelector = ({ profile }) => {
  const { currentUser, profileImageUrl } = useContext(AppContext);
  const fileInputRef = useRef(null);

  const profileImageSource = profile?.profileImageUrl || require('./assets/defaultProfilePicProfile.png');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);

    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
  
      style={{ ...styles.touchableOpacity }}
    >
      <div
        style={{
          ...styles.imageBackground,
          ...styles.touchableOpacity,
        }}
      >
        <div style={styles.imageContainer}>
          <img src={profileImageSource} alt="Profile" style={styles.image} />
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
    </div>
  );
};

const styles = {
  touchableOpacity: {
    width: '160px',
    height: '160px',
    zIndex: 200,
    cursor: 'pointer',
    alignSelf: 'center',
    alignItems: 'center',
  },
  imageBackground: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${require('./assets/ProfilePicBG.png')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  imageContainer: {
    width: '87%',
    height: '78%',
    marginRight: 0.5,
    marginBottom: 2,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

export default MYDATProfilePicSelector;
