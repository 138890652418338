import React from 'react';
import { useNavigate } from 'react-router-dom';

const categories = [
  { id: 'playlists', title: 'SPNLISTS' },
  { id: 'artists', title: 'Artists' },
  { id: 'albums', title: 'Albums' },
  { id: 'songs', title: 'Songs' },
  { id: 'videos', title: 'Videos' },
  { id: 'genres', title: 'Genres' },
  { id: 'beats', title: 'Beats' },
  { id: 'myreleases', title: 'MyReleases' },
];

const CategoriesScreen = () => {
  const navigate = useNavigate();

  const handlePress = (category) => {
    navigate(`/library/${category}`);
  };

  return (
    <div style={styles.fullScreen}>
      <img src={require('./assets/BlankBG.png')} style={styles.fullScreenImage} alt="Background" />
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <h2 style={styles.headerText}>LIBRARY</h2>
        </div>
        <ul style={styles.scrollContainer}>
          {categories.map((item) => (
            <li key={item.id} style={styles.listItem}>
              <button onClick={() => handlePress(item.id)} style={styles.button}>
                <div style={styles.row}>
                  <img src={require('./assets/dot.png')} style={{ width: '2vh', height: '2vh', marginRight: '1vw' }} alt="Dot" />
                  <span style={styles.text}>{item.title}</span>
                </div>
              </button>
              <div style={styles.borderContainer}>
                <div style={styles.centeredBorder}></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 10,
    height: `calc(100vh - 18vh)`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center', // Center the header horizontally
    position: 'relative',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '1vh',
    margin: '1vh 0',
    cursor: 'pointer',
    zIndex: 10,
    textAlign: 'left',
    width: '100%',
  },
  text: {
    color: 'white',
    fontSize: '2.4vh',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  scrollContainer: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    position: 'relative', // Changed from 'absolute' to 'relative'
    width: '100%',
    height: `130%`,
    zIndex: 2,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  borderContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: '0',
  },
  centeredBorder: {
    marginLeft: '18%',
    width: '110%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(189, 252, 251, 0.3)',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1vh 0', // Add padding to the header container
    width: '100%',
    backgroundColor: 'transparent', // Ensure the background is transparent
    zIndex: 10,
  },
  headerText: {
    color: 'white',
    fontSize: '2.5vh',
    fontWeight: 'bold',
    textAlign: 'center', // Center the text horizontally
    fontFamily: 'brhendrix',
  },
  listItem: {
    marginBottom: '1vh',
  },
};

export default CategoriesScreen;
