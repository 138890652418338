import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CategoriesScreen from './CategoriesScreen';
import LibrarySPNLists from './LibrarySPNLists';
import LibraryCategoryScreen from './LibraryCategoryScreen';
import GenresScreen from './GenresScreen';
import GenreMediaListScreen from './GenreMediaListScreen';
import ArtistsScreen from './ArtistsScreen';
import ArtistsMediaListScreen from './ArtistsMediaListScreen';
import SPNListProfile from './SPNListProfile';
import SPNListPage from './SPNListPage';

const Library = ({ onMoveMP }) => {
  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <Routes>
          <Route index element={<CategoriesScreen />} />
          <Route path="/categories" element={<CategoriesScreen />} />
          <Route path="/playlists" element={<LibrarySPNLists category="Playlists" />} />
          <Route path="/spnlistprofile" element={<SPNListProfile />} />
          <Route path="/spnlistpage" element={<SPNListPage />} />
          <Route path="/artists/*" element={<ArtistsStackNavigator onMoveMP={onMoveMP} />} />
          <Route path="/albums" element={<LibraryCategoryScreen category="Albums" onMoveMP={onMoveMP} />} />
          <Route path="/songs" element={<LibraryCategoryScreen category="Songs" onMoveMP={onMoveMP} />} />
          <Route path="/videos" element={<LibraryCategoryScreen category="Videos" onMoveMP={onMoveMP} />} />
          <Route path="/genres/*" element={<GenresStackNavigator onMoveMP={onMoveMP} />} />
          <Route path="/myreleases" element={<LibraryCategoryScreen category="MyReleases" onMoveMP={onMoveMP} />} />
          <Route path="/beats" element={<LibraryCategoryScreen category="Beats" onMoveMP={onMoveMP} />} />
        </Routes>
      </div>
    //  <img
    //    src={require('./assets/TopAreaFades.png')}
    //    style={styles.popupImage}
    //    alt=""
      />
    </div>
  );
};

const GenresStackNavigator = ({ onMoveMP }) => (
  <Routes>
    <Route path="/" element={<GenresScreen />} />
    <Route path="/genremedialist" element={<GenreMediaListScreen onMoveMP={onMoveMP} />} />
  </Routes>
);

const ArtistsStackNavigator = ({ onMoveMP }) => (
  <Routes>
    <Route path="/" element={<ArtistsScreen />} />
    <Route path="/artistsmedialist" element={<ArtistsMediaListScreen onMoveMP={onMoveMP} />} />
  </Routes>
);

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
overflowX: 'hidden',
    position: 'relative',
    height: '100%', // Ensure the container takes the full height
  },
  popupImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 5,
  },
};

export default Library;
