import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './firebase';

const MYDATsongs = ({ item, onPress }) => {
  const [songArtworkUrl, setSongArtworkUrl] = useState(null);

  useEffect(() => {
    const fetchArtworkUrl = async (artworkUrl) => {
      try {
        let url;
        if (artworkUrl.startsWith('https://')) {
          url = artworkUrl;
        } else if (artworkUrl.startsWith('gs://')) {
          const imagePath = artworkUrl.split('gs://')[1].split('/').slice(1).join('/');
          url = await getDownloadURL(ref(storage, imagePath));
        }
        setSongArtworkUrl(url);
      } catch (error) {
        console.error('Failed to load image', error);
      }
    };

    const artworkUrl = item?.ArtworkURL || item?.artworkURL;
    if (artworkUrl) {
      fetchArtworkUrl(artworkUrl);
    }
  }, [item]);

  if (!item) {
    return <div style={styles.songArtworkPlaceholder} />;
  }

  return (
    <div style={styles.itemContainer}>
      <div onClick={() => onPress(item)} style={styles.horizontalTouchable}>
        <img src={item.ArtworkURL} alt="Artwork" style={styles.songArtwork} />
      </div>
      <div style={styles.artistName}>{item.ArtistName}</div>
      <div style={styles.songName}>
        {item.isAlbum ? item.albumName || 'Unknown Album' : item.SongName || 'Unknown Song'}
      </div>
    </div>
  );
};

const imageSize = '125px';

const styles = {
  itemContainer: {
    width: `calc(${imageSize} + 1px)`,
    alignItems: 'flex-start',
    marginRight: '10px',
    zIndex: 1,
    cursor: 'pointer',
  },
  horizontalTouchable: {
    width: imageSize,
    height: imageSize,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  songArtwork: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    objectFit: 'contain',
  },
  songArtworkPlaceholder: {
    width: imageSize,
    height: imageSize,
    borderRadius: '8px',
    backgroundColor: '#cccccc',
  },
  artistName: {
    marginTop: '4px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'left',
    maxWidth: imageSize, // Limit the width to match the artwork size
    whiteSpace: 'normal', // Allow wrapping
    overflow: 'hidden', // Hide overflow
    wordBreak: 'break-word', // Break long words
  },
  songName: {
    marginTop: '2px',
    color: '#b8b8b8',
    fontSize: '16px',
    textAlign: 'left',
    maxWidth: imageSize, // Limit the width to match the artwork size
    whiteSpace: 'normal', // Allow wrapping
    overflow: 'hidden', // Hide overflow
    wordBreak: 'break-word', // Break long words
  },
};

export default MYDATsongs;
