import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { ref, get, onValue, off } from 'firebase/database';
import { firestore, storage, database } from './firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import { AppContext } from './AppContext';
import cashdatImage from './assets/CASHOUTbutton.png';


const EarningsPage = ({ onPlayVideo, isVideoPlaying, onMoveMP }) => {
  const { currentUser, totalSpns, singlesSales, albumsSales, videosSales, credits, userId2 } = useContext(AppContext);
  const [activeURL, setActiveURL] = useState(null);
  const [isMaxCashout, setIsMaxCashout] = useState(false);
  const [promptEmail, setPromptEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [payoutAmount, setPayoutAmount] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentEarnings, setCurrentEarnings] = useState('0');
  const [loadingEarnings, setLoadingEarnings] = useState(true);
  const [loadingCashOut, setLoadingCashOut] = useState(true);
  const [availableToCashOut, setAvailableToCashOut] = useState(0);

  const [clearedFiscalMonths, setClearedFiscalMonths] = useState({});
  const [clearedPlayStoreMonths, setClearedPlayStoreMonths] = useState({});

  const firestore = getFirestore();
  const auth = getAuth();
  const functions = getFunctions();

  const creditsFromSpns = totalSpns;
  const creditsFromSingles = singlesSales * 200;
  const creditsFromAlbums = albumsSales * 500;
  const creditsFromVideos = videosSales * 200;
  const totalCredits = creditsFromSpns + creditsFromSingles + creditsFromAlbums + creditsFromVideos;

  const currentEarnedCredits = Math.floor(currentEarnings / 0.10);
  let adjustedCurrentEarnings = credits < currentEarnedCredits ? Math.floor(credits * 0.10 * 100) / 100 : currentEarnings;
  const currentEarnedCreditsBasedOnAdjustedEarnings = Math.floor(adjustedCurrentEarnings / 0.10);

  useEffect(() => {
    if (!currentUser.CASHDATemail) {
      setPromptEmail(true);
    }
  }, [currentUser]);

  const reauthenticate = async (password) => {
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);
    try {
      await reauthenticateWithCredential(user, credential);
      return true;
    } catch (error) {
      alert("Reauthentication Failed: Please check your password and try again.");
      return false;
    }
  };

  useEffect(() => {
    const listeners = [];
  
    const fetchEarnings = async () => {
      setLoadingEarnings(true);
      setLoadingCashOut(true);
  
      // Function to round down to two decimal places
      const roundDown = (value, decimals) => {
        const factor = Math.pow(10, decimals);
        return Math.floor(value * factor) / factor;
      };
  
      try {
        // Fetch earnedCredits from Realtime Database
        const earnedCreditsRef = ref(database, `users/${userId2}/summary/earnedCredits`);
        const earnedCreditsSnapshot = await get(earnedCreditsRef);
        if (!earnedCreditsSnapshot.exists()) {
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        const earnedCreditsData = earnedCreditsSnapshot.val();
        if (!earnedCreditsData) {
          console.error('Earned credits data is undefined');
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        const totalCreditsByFiscalMonth = earnedCreditsData.totalCreditsByFiscalMonth || {};
        const totalCreditsByPlayStoreMonth = earnedCreditsData.totalCreditsByPlayStoreMonth || {};
        if (!totalCreditsByFiscalMonth && !totalCreditsByPlayStoreMonth) {
          console.error('totalCreditsByFiscalMonth and totalCreditsByPlayStoreMonth are undefined');
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        // Fetch ClearedCredits from Firestore for fiscal months
        const fiscalMonthDocRef = doc(firestore, 'ClearedCredits', 'FiscalMonths');
        const fiscalMonthDocSnapshot = await getDoc(fiscalMonthDocRef);
        if (!fiscalMonthDocSnapshot.exists()) {
          console.error('FiscalMonths document not found');
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        const fiscalMonthData = fiscalMonthDocSnapshot.data();
        if (!fiscalMonthData) {
          console.error('FiscalMonth data is undefined');
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        const clearedFiscalMonths = fiscalMonthData.totalCreditsByFiscalMonth || {};
        setClearedFiscalMonths(clearedFiscalMonths);
  
        if (!clearedFiscalMonths) {
          console.error('clearedFiscalMonths is undefined');
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        // Fetch ClearedCredits from Firestore for play store months
        const playStoreMonthDocRef = doc(firestore, 'ClearedCredits', 'PlayStoreMonths');
        const playStoreMonthDocSnapshot = await getDoc(playStoreMonthDocRef);
        if (!playStoreMonthDocSnapshot.exists()) {
          console.error('PlayStoreMonths document not found');
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        const playStoreMonthData = playStoreMonthDocSnapshot.data();
        if (!playStoreMonthData) {
          console.error('PlayStoreMonth data is undefined');
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        const clearedPlayStoreMonths = playStoreMonthData.totalCreditsByPlayStoreMonths || {};
        setClearedPlayStoreMonths(clearedPlayStoreMonths);
  
        if (!clearedPlayStoreMonths) {
          console.error('clearedPlayStoreMonths is undefined');
          setLoadingEarnings(false);
          setLoadingCashOut(false);
          return;
        }
  
        let totalEarnings = 0;
        let cashableEarnings = 0;
  
        // Function to recalculate cashable earnings
        const recalculateCashableEarnings = (updatedTotalCreditsByFiscalMonth, updatedTotalCreditsByPlayStoreMonth) => {
          let newCashableEarnings = 0;
          for (const [month, amount] of Object.entries(updatedTotalCreditsByFiscalMonth || {})) {
            if (clearedFiscalMonths[month]) {
              newCashableEarnings += roundDown(amount * 0.10, 2);
            }
          }
          for (const [month, amount] of Object.entries(updatedTotalCreditsByPlayStoreMonth || {})) {
            if (clearedPlayStoreMonths[month]) {
              newCashableEarnings += roundDown(amount * 0.10, 2);
            }
          }
          setAvailableToCashOut(newCashableEarnings);
        };
  
        // Initial calculation of earnings
        const calculateEarnings = (totalCreditsByMonth, clearedMonths) => {
          let earnings = 0;
          let cashable = 0;
  
          for (const [month, amount] of Object.entries(totalCreditsByMonth)) {
            if (amount === undefined) {
              console.error(`Amount for month ${month} is undefined`);
              continue;
            }
            earnings += roundDown(amount * 0.10, 2);
  
            if (clearedMonths[month]) {
              cashable += roundDown(amount * 0.10, 2);
            }
  
            if (amount > 0) {
              // Set up listener for this month
              const monthRef = ref(database, `users/${userId2}/summary/earnedCredits/${totalCreditsByMonth === totalCreditsByFiscalMonth ? 'totalCreditsByFiscalMonth' : 'totalCreditsByPlayStoreMonth'}/${month}`);
              const listener = onValue(monthRef, (snapshot) => {
                const newAmount = snapshot.val();
                if (newAmount !== null) {
                  const updatedTotalCreditsByMonth = {
                    ...totalCreditsByMonth,
                    [month]: newAmount
                  };
                  if (totalCreditsByMonth === totalCreditsByFiscalMonth) {
                    recalculateCashableEarnings(updatedTotalCreditsByMonth, totalCreditsByPlayStoreMonth);
                  } else {
                    recalculateCashableEarnings(totalCreditsByFiscalMonth, updatedTotalCreditsByMonth);
                  }
                }
              });
  
              // Store the listener ref for cleanup
              listeners.push({ ref: monthRef, listener });
            }
          }
  
          return { earnings, cashable };
        };
  
        const fiscalEarnings = calculateEarnings(totalCreditsByFiscalMonth, clearedFiscalMonths);
        const playStoreEarnings = calculateEarnings(totalCreditsByPlayStoreMonth, clearedPlayStoreMonths);
  
        totalEarnings = fiscalEarnings.earnings + playStoreEarnings.earnings;
        cashableEarnings = fiscalEarnings.cashable + playStoreEarnings.cashable;
  
        // Set up listener for totalCredits
        const totalCreditsRef = ref(database, `users/${userId2}/summary/earnedCredits/totalCredits`);
        const totalCreditsListener = onValue(totalCreditsRef, (snapshot) => {
          const newTotalCredits = snapshot.val();
          if (newTotalCredits !== null) {
            const updatedTotalEarnings = roundDown(newTotalCredits * 0.10, 2);
            setCurrentEarnings(updatedTotalEarnings);
          }
        });
  
        // Store the totalCredits listener for cleanup
        listeners.push({ ref: totalCreditsRef, listener: totalCreditsListener });
  

  
        setCurrentEarnings(totalEarnings);
        setAvailableToCashOut(cashableEarnings);
      } catch (error) {
        console.error('Error fetching earnings:', error);
      } finally {
        setLoadingEarnings(false);
        setLoadingCashOut(false);
      }
    };
  
    fetchEarnings();
  
    // Cleanup listeners when the component unmounts
    return () => {
      listeners.forEach(({ ref, listener }) => {
        off(ref, 'value', listener);
      });
    };
  }, [userId2]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleUpdateCashdatEmail = async () => {
    if (!isValidEmail(email)) {
      alert("Invalid Email: Please enter a valid email address.");
      return;
    }

    if (await reauthenticate(currentPassword)) {
      try {
        const user = auth.currentUser;
        const userRef = doc(firestore, "users", user.uid);
        await updateDoc(userRef, {
          CASHDATemail: email
        });
        alert("Success: CASHDAT email updated successfully!");
        setPromptEmail(false);
      } catch (error) {
        console.error("Error updating CASHDAT email:", error);
        alert("Update Failed: Failed to update CASHDAT email.");
      }
    }
  };

  const handleCashOut = async () => {
    const minimumPayoutAmount = 25;
    const payoutAmountNumber = parseFloat(payoutAmount);

    if (isNaN(payoutAmountNumber) || payoutAmountNumber < minimumPayoutAmount || payoutAmountNumber === 0) {
      alert(`Error: Minimum payout amount is $${minimumPayoutAmount}`);
      setPayoutAmount('');
      return;
    }

    const cashOutFunction = httpsCallable(functions, 'processPayPalPayoutIOS');
    try {
      const payoutAmountToUse = Math.min(payoutAmountNumber, availableToCashOut);
      const formattedPayoutAmount = Math.floor(payoutAmountToUse * 100) / 100;
      const result = await cashOutFunction({ payoutAmount: formattedPayoutAmount.toFixed(2), email: currentUser.CASHDATemail });

      if (result.data && result.data.message === 'Payout successful!') {
        alert('Success: Payout successful!');
      } else {
        throw new Error(result.data.message || 'Payout failed');
      }

      setPayoutAmount('');
    } catch (error) {
      setPayoutAmount('');
      alert(`Error: ${error.message}`);
    }
  };

  if (promptEmail) {
    return (
      <div style={styles.fullScreen}>
        <div style={styles.scrollContainer}>
          <div style={styles.emailPromptContainer}>
            <h2 style={styles.promptTitle}>Enter your PayPal email for a payout.</h2>
            <input
              type="email"
              placeholder="Payout Email..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.emailInput}
            />
            <input
              type="password"
              placeholder="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              style={styles.passwordInput}
            />
            <button style={styles.submitButton} onClick={handleUpdateCashdatEmail}>
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <div style={styles.EarningsPopup}>
          <h2 style={styles.titleText}>Current Earnings</h2>
          <h3 style={styles.valueTextCB}>${Number(currentEarnings).toFixed(2)}</h3>
          <h2 style={styles.titleText2}>Available To Cashout</h2>
          <h3 style={styles.earningsValueText}>${availableToCashOut?.toFixed(2)}</h3>
          <div style={styles.inputContainer}>
            <input
              type="number"
              placeholder="Enter payout amount"
              value={payoutAmount}
              onChange={(e) => {
                const normalizedText = e.target.value.replace(/[^0-9.]/g, '').replace(/^0+(?!\.|$)/, '');
                const inputValue = parseFloat(normalizedText) || 0;
                let maxEarnings = parseFloat(availableToCashOut) || 0;
                maxEarnings = parseFloat(maxEarnings.toFixed(2));
                if (inputValue > maxEarnings) {
                  setPayoutAmount(maxEarnings.toString());
                } else {
                  setPayoutAmount(normalizedText);
                }
                setIsMaxCashout(false);
              }}
              style={styles.inputField}
            />
          </div>
          <button style={styles.maxButton} onClick={() => {
            setPayoutAmount(availableToCashOut?.toFixed(2));
            setIsMaxCashout(true);
          }}>
            Max
          </button>
          <div style={styles.fullScreenImage2}>
          <img src={cashdatImage} alt="CASHDAT" onClick={handleCashOut} style={styles.fullScreenImage3} />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '20px',
    position: 'relative',
    justifyContent: 'center',

    
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  fullScreenImage2: {
    display: 'flex',
    width: '25%',
    height: '10%',
    position: 'relative',
    alignSelf: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    alignItems: 'center',
    zIndex: 0,
    objectFit: 'contain',  // Add this line
  },
  fullScreenImage3: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    zIndex: 0,
  },

  EarningsPopup: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '45%',
    zIndex: 20000,
    
  },
  titleText: {
    fontFamily: 'brhendrix',
    fontSize: '25px',
    color: '#62fbfa',
    marginBottom: '-7%',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '10px',
    borderRadius: '5px',
    zIndex: 10000,
    alignSelf: 'center',
  },
  valueTextCB: {
    fontFamily: 'brhendrix',
    fontSize: '25px',
    color: '#62fbfa',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '-1%',
    borderRadius: '5px',
    zIndex: 10000,
    alignSelf: 'center',
  },
  earningsValueText: {
    fontFamily: 'brhendrix',
    fontSize: '25px',
    color: '#62fbfa',
    
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '5px',
    alignSelf: 'center',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
  },
  inputField: {
    width: '90%',
    height: '5%',
    borderWidth: '1px',
    borderColor: 'grey',
    padding: '10px',
    zIndex: 20000,
    fontSize: '16px',
    color: 'white',
    fontFamily: 'brhendrix',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    border: '1px solid #6b92c2',
  },
  maxButton: {
    position: 'relative',
    alignSelf: 'center',
    justifyContent: 'center',
    width: '40%',
    height: '5%',
    marginBottom: '3%',
    zIndex: 9000,
    backgroundColor: 'transparent',
    color: '#62fbfa',
    fontWeight: 'bold',
    fontSize: '16px',
    border: 'none',
    borderRadius: '5px',
    display: 'flex',          // Add this line
    alignItems: 'center',     // Add this line
    justifyContent: 'center',
  },
  titleText2: {
    fontFamily: 'brhendrix',
    fontSize: '25px',
    color: '#62fbfa',
    marginBottom: '-5%',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '5px',
    zIndex: 10000,
    alignSelf: 'center',
  },
  emailPromptContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 10000,
    height: '30%',
    top: '10%',
  },
  promptTitle: {
    fontSize: '6%',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '3%',
    marginBottom: '20px',
    fontFamily: 'brhendrix',
  },
  emailInput: {
    width: '80%',
    height: '6%',
    borderWidth: '1px',
    borderColor: 'grey',
    padding: '10px',
    marginBottom: '20px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: '5px',
  },
  submitButton: {
    padding: '10px 20px',
    borderRadius: '5px',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: '#62fbfa',
    fontSize: '18px',
    fontWeight: 'bold',
    border: 'none',
  },
  passwordInput: {
    width: '80%',
    height: '6%',
    borderWidth: '1px',
    borderColor: 'grey',
    padding: '10px',
    marginBottom: '20px',
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.7)',
    borderRadius: '5px',
  },
};

export default EarningsPage;
