import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { ProfilesDisplayMessages } from './ProfilesDisplayMessages';
import ConversationComponent from './ConversationComponent';
import { AppContext } from './AppContext';
import { IoArrowBack } from 'react-icons/io5';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import { useNavigate } from 'react-router-dom';

const auth = getAuth();
const firestore = getFirestore();
const { innerWidth: width, innerHeight: height } = window;

const SPNSKN = ({ onMoveMP, onPostPress, onProfilePress }) => {
  const [text, setText] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [messages, setMessages] = useState([]);
  const fadeAnim = useRef(0); // Initial value for opacity: 0

  const navigate = useNavigate();

  const flatListRef = useRef();
  const inputRef = useRef();

  const { showNewMessageScreen, setShowNewMessageScreen, activeMedia, currentView, setCurrentView, profiles2, setProfiles2, selectedProfile, setSelectedProfile } = useContext(AppContext);

  const handleMove = () => {
    onMoveMP();
  };

  const onMyProfile = () => {
    navigate('/mydat/profile');
  };

  const handlePressPost = (post) => {
    onPostPress(post);
  };

  useEffect(() => {
    
  }, [selectedProfile]);

  const handleNavigateToConversation = useCallback((profileData) => {
    
    setSelectedProfile(profileData);
    setCurrentView('conversation');
  }, []);

  const handleBackToProfiles = useCallback(() => {
    setCurrentView('profiles');
    setSelectedProfile(null); // Reset the selected profile
    setShowNewMessageScreen(false);
  }, []);

  const handleSearch = (text) => setSearchText(text);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    const lowercasedSearchText = searchText.toLowerCase();
    setFilteredMessages(messages.filter(message =>
      message.text.toLowerCase().includes(lowercasedSearchText)
    ));
  }, [searchText, messages]);

  const profileImageWidth = width * 0.164; // Width of the profile picture
  const padding = width * 0.8;

  const profileImageSource = selectedProfile?.profileImageUrl;

  const messagingMediaPlayingStyle1 = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden', // Prevents horizontal scrolling
  };

  const messagingMediaPlayingStyle2 = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden', // Prevents horizontal scrolling
  };

  let mediaPlayingStyle = activeMedia?.contentId ? messagingMediaPlayingStyle2 : messagingMediaPlayingStyle1;

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(selectedProfile?.username);

  const formatUsername = username => {
    if (!username) return '@'; // Default to '@' if username is undefined or null
    if (username[0] !== '@') return '@' + username;
    return username;
  };

  return (
    <div style={styles.container}>
      {currentView === 'profiles' && !showNewMessageScreen && (
        <div style={styles.profileContainersCredits}>
          <ProfilesDisplayMessages profiles2={profiles2} onProfilePress={handleNavigateToConversation} />
        </div>
      )}

      {currentView === 'conversation' && (
        <>
          <div style={styles.searchContainer}>
            <button onClick={handleBackToProfiles} style={styles.backButton}>
              <IoArrowBack size={24} color="white" />
            </button>
            <div style={styles.profileViewStyle}>
              <div style={styles.imageStack}>
                {profileImageSource ? (
                  <img src={profileImageSource} alt="Profile" style={styles.circularImage} />
                ) : (
                  <img src={'./assets/defaultProfilePic.png'} alt="Default Profile" style={styles.circularImage} />
                )}
              </div>
              <div style={styles.textContainer}>
                <div style={styles.artistName}>{selectedProfile?.name}</div>
                <div style={styles.textWithIconContainer}>
                  <div style={styles.username}>{formatUsername(selectedProfile?.username)}</div>
                  {selectedProfile?.accountType !== 'Fan' && !isArtistIcon2Visible && (
                <img src={ArtistIcon1} alt="Artist Icon 1" style={styles.profilePicIcon} />
                  )}
                  {selectedProfile?.accountType !== 'Fan' && isArtistIcon2Visible && (
                <img src={ArtistIcon2} alt="Artist Icon 2" style={styles.profilePicIcon} />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div style={{ ...styles.mediaPlayingStyle, ...mediaPlayingStyle }}>
            <ConversationComponent
              onPostPress={handlePressPost}
              onMyProfile={onMyProfile}
              onProfilePress={onProfilePress}
              onBackPress={handleBackToProfiles}
              onMoveMP={onMoveMP}
              profile={selectedProfile}
              messages={messages}
            />
          </div>
        </>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'transparent',
    color: 'white',
    overflow: 'hidden', // Prevents scrolling
  },
  profileContainersCredits: {
    flex: 1,
  },
  mediaPlayingStyle: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden', // Prevents horizontal scrolling
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: 'transparent',
    borderBottom: '1px solid #333',
    width: '100%',
    boxSizing: 'border-box',
  },
  backButton: {
    backgroundColor: 'transparent',
    border: 'none',
    marginRight: '10px',
    cursor: 'pointer',
  },
  profileViewStyle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  imageStack: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
  },
  circularImage: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1, // Ensures text container takes up remaining space
  },
  artistName: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  textWithIconContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  username: {
    fontSize: '14px',
    color: '#62fbfa',
    marginRight: '5px',
  },
  profilePicIcon: {
    width: '16px',
    height: '16px',
  },
};

export default SPNSKN;
