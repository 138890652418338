import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from './AppContext';
import SPNListPageMediaItems from './SPNListPageMediaItems';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useCache } from './CacheContext';

const CACHE_DURATION = 5000;

const ArtistsMediaListScreen = ({ onMoveMP }) => {
  const {
    playMedia,
    isPopupVisible, setIsPopupVisible,
    showMediaPopup,
    activePopupMedia,
    closePopup,
    httpArtworkUrl, setHttpArtworkUrl,
    isLoadingArtwork, setIsLoadingArtwork,
    popupSongArtworkUrl, setPopupSongArtworkUrl,
    popupVideoArtworkUrl, setPopupVideoArtworkUrl,
    showPopup, setShowPopup,
    handlePlayAndClose,
    isExpanded, setIsExpanded,
    userId, setUserId,
    setInitialExpandedAlbumAndSong,
    isLoadingNewPopup, setIsLoadingNewPopup,
    isLoadingExpand, setIsLoadingExpand,
    isLoadingSameUser, setIsLoadingSameUser,
    isLoadingProfilePicture, setIsLoadingProfilePicture,
    lastViewedMedia, setLastViewedMedia,
    showSameMediaPopup,
    setArtworkUri, centeredRelease, setCenteredRelease,
    hideExpandedAlbum, setHideExpandedAlbum,
    activeTab, setActiveTab,
    setInitiallySelectedItemId,
    isLoadingHideEA, setIsLoadingHideEA, currentUser, setUser,
    trackToView, setTrackToView, purchasedMedia
  } = useContext(AppContext);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { artist } = state; // Destructure artist from state
  const [mediaItems, setMediaItems] = useState([]);



  const firestore = getFirestore();




  const sortReleasesByTimestamp = (releases) => {
    const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
    return sortedReleases;
  };

  const handlePressBegins = async (song) => {
    const clonedSong = JSON.parse(JSON.stringify(song));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  const preparePopupData = (song, userData) => {
    let profileImageUrl = null;
    const itemUserData = [];

    profileImageUrl = userData?.profileImageUrl || null;

    let userAlbums = sortReleasesByTimestamp(userData.albums || []);
    let userSingles = sortReleasesByTimestamp(userData.singles || []);
    let userVideos = sortReleasesByTimestamp(userData.videos || []);

    let selectedAlbum = userAlbums.find(album => album.contentId === song.contentId) || null;
    let selectedSingle = userSingles.find(single => single.contentId === song.contentId) || null;
    let selectedVideo = userVideos.find(video => video.contentId === song.contentId) || null;

    const artworkUri = selectedVideo?.VideoThumbnailURL || (selectedAlbum || selectedSingle)?.ArtworkURL || song.ArtworkURL;
    setArtworkUri(artworkUri);

    let dimensions = [];
    if (song.isVideo) {
      dimensions = song.dimensions || [];
    }

    if (selectedAlbum && selectedAlbum.songs.length > 0) {
      setInitialExpandedAlbumAndSong(selectedAlbum, selectedAlbum.songs[0]);
      setCenteredRelease(selectedAlbum);
    } else if (selectedSingle && selectedSingle.length > 0) {
      setCenteredRelease(selectedSingle);
    } else if (selectedVideo) {
      setCenteredRelease(selectedVideo);
    }

    const songName = song.isVideo ? song.VideoName : song.SongName;

    return {
      url: song.SongURL,
      dimensions: dimensions,
      isVideo: song.isVideo,
      artistName: song.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: song.username,
      songName: songName,
      artworkUrl: artworkUri,
      accountType: userData.accountType,
      contentId: song.contentId,
      localArtworkURL: song?.localArtworkURL,
      userId: song.userId,
      isAlbum: song.isAlbum,
      albumName: selectedAlbum?.albumName || '',
      producers: song.producers,
      songwriters: song.songwriters,
      recordLabels: song.recordLabels,
      otherReleases: { albums: userAlbums, singles: userSingles, videos: userVideos },
      albumSongs: selectedAlbum?.songs || [],
      single: selectedSingle,
      video: selectedVideo,
      timestamp: new Date().getTime(),
      vocalDrop: userData.vocalDrop,
      voiceDropMuted: userData?.voiceDropMuted,
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
      ScreenSKN: userData.ScreenSKN,
    };
  };





  const getCachedUserDoc = async (userId) => {
    let userDataCached = [];
    let userDataCachedNew = [];
    const now = new Date().getTime();

    if (!userDataCached || now - userDataCached.timestamp >= CACHE_DURATION) {
      
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        
        let freshUserData = userDocSnap.data();

        

        freshUserData = mergePurchasedMediaWithUserData(freshUserData, userId);

        userDataCachedNew = {
          ...freshUserData,
          profileImageUrl: freshUserData.profileImageUrl,
          timestamp: now
        };


      } else {
        console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);

        const userPurchasedMedia = purchasedMedia.filter(item => item.userId === userId);
        userDataCachedNew = {
          profileImageUrl: '',
          localProfileImageUrl: '',
          vocalDrop: '',
          bio: '',
          blurb: '',
          location: '',
          vocalDropMuted: false,
          ScreenSKN: [],
          accountType: 'Artist',
          albums: userPurchasedMedia.filter(item => item.isAlbum),
          singles: userPurchasedMedia.filter(item => !item.isAlbum && !item.isVideo),
          videos: userPurchasedMedia.filter(item => item.isVideo),
          timestamp: now
        };

        


      }
    }

    return userDataCachedNew;
  };

  const mergePurchasedMediaWithUserData = (userData, userId) => {
    const purchasedItems = purchasedMedia.filter(item => item.userId === userId);
    const mergeUniqueItems = (existingItems, newItems) => {
      newItems.forEach(item => {
        if (!existingItems.some(existingItem => existingItem.contentId === item.contentId)) {
          existingItems.push(item);
        }
      });
    };

    mergeUniqueItems(userData.albums || [], purchasedItems.filter(item => item.isAlbum));
    mergeUniqueItems(userData.singles || [], purchasedItems.filter(item => !item.isAlbum && !item.isVideo));
    mergeUniqueItems(userData.videos || [], purchasedItems.filter(item => item.isVideo));

    return userData; // Modified in-place
  };



  const fetchMediaByArtistName = async () => {
    const filteredMedia = purchasedMedia.filter(item =>
      item.ArtistName === artist && isNaN(+item.contentId) // Exclude numeric string contentIds
    );
    return filteredMedia;
  };

  useEffect(() => {
    const fetchMedia = async () => {
      const items = await fetchMediaByArtistName();
      setMediaItems(items);
    };

    fetchMedia();
  }, [artist, currentUser]);


  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };
  
  // Capitalized artist name
  const capitalizedArtist = capitalizeWords(artist);


  return (
    <div style={styles.fullScreen}>
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <h2 style={styles.headerText}>{capitalizedArtist}</h2>
        </div>
        {mediaItems.map(item => (
          <SPNListPageMediaItems key={item.contentId} item={item} onPress={() => handlePressBegins(item)} isSelected={false} onDelete={() => {}} />
        ))}
      </div>
      <div style={styles.extraSpace}></div>
    </div>
  );
};
  
  const styles = {
    container: {
      height: '82%', // calculated from height - bottomAreaHeight
      zIndex: 1,
      paddingTop: '16px', // calculated from scrollAreaTopPadding
      paddingBottom: '18px', // calculated from bottomAreaHeight
      paddingHorizontal: '10px', // calculated from width * 0.042
      overflowY: 'scroll',
    },
    extraSpace: {
      height: '300px',
        },
    scrollContainer: {
      position: 'absolute',
      top: 0,
      width: '100vw',
      height: '82vh', // calculated from height - bottomAreaHeight
      zIndex: 10,
      overflow: 'visible',
    },
    buttonTouchableArea: {
      position: 'absolute',
      width: '100vw',
      height: '100vh',
      zIndex: 1,
    },
    fullScreenImage: {
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      zIndex: 0,
    },
    fullScreenImageOverlay: {
      width: '100vw',
      height: '100vh',
      position: 'absolute',
      zIndex: 5,
    },
    fullScreen: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      overflow: 'hidden',
      position: 'relative',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginBottom: '2vh', // Space below the header
    },
    headerText: {
      color: 'white',
      fontSize: '2rem', // Adjusted font size
      fontWeight: 'bold',
      textAlign: 'center',
      fontFamily: 'brhendrix',
    },
  };

export default ArtistsMediaListScreen;
