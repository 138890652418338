import React, { useState } from 'react';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';

const ProfileComponentProducerSmall = ({ onClick, item, scale = 1 }) => {
  const [isLoadingProfilePicture, setIsLoadingProfilePicture] = useState(false);

  const username = item?.username;
  const name = item?.name;
  const accountType = item?.accountType;

  const profileImageSource = item?.profileImageUrl;

  const handleImageLoadStart = () => {
    setIsLoadingProfilePicture(true);
  };

  const handleImageLoadEnd = () => {
    setIsLoadingProfilePicture(false);
  };

  const formatUsername = (username) => {
    if (!username) return '@';
    if (username[0] !== '@') return '@' + username;
    return username;
  };

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(username);

  return (
    <div 
      onClick={() => onClick(item)} 
      style={{ ...styles.container, transform: `scale(${scale})` }}
    >
      <div style={styles.imageStack}>
        <img
          src={profileImageSource}
          alt="Profile"
          style={styles.circularImage}
          onLoadStart={handleImageLoadStart}
          onLoad={handleImageLoadEnd}
        />
      </div>
      <div style={styles.textContainer}>
        <p 
          style={styles.artistName} 
          title={name}
        >
          {name}
        </p>
        <div style={styles.textWithIconContainer}>
          <p 
            style={styles.username} 
            title={username}
          >
            {formatUsername(username)}
          </p>
          {accountType !== 'Fan' && !isArtistIcon2Visible && (
            <img src={ArtistIcon1} style={styles.profilePicIcon} alt="Artist Icon 1" />
          )}
          {accountType !== 'Fan' && isArtistIcon2Visible && (
            <img src={ArtistIcon2} style={styles.profilePicIcon} alt="Artist Icon 2" />
          )}
        </div>
      </div>
    </div>
  );
};

const imageSize = 70; // Fixed size for image container
const circularImageSize = 60; // Fixed size for profile image
const maxTextWidth = 150; // Fixed max width for text

const styles = {
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: maxTextWidth,
    cursor: 'pointer',
  },
  imageStack: {
    width: circularImageSize,
    height: circularImageSize,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularImage: {
    width: '100%', 
    height: '100%', 
    objectFit: 'cover',
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: maxTextWidth,
    marginTop: 4, // Reduced spacing between image and text
    textAlign: 'center',
  },
  artistName: {
    color: 'white',
    fontSize: 14, // Fixed font size
    fontWeight: '400',
    fontFamily: 'brhendrix',
    maxWidth: maxTextWidth,
    marginBottom: 2, // Reduced spacing between name and username
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  textWithIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: maxTextWidth,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  username: {
    color: '#62fbfa',
    fontSize: 12, // Fixed font size
    opacity: 0.9,
    fontWeight: '400',
    fontFamily: 'brhendrix',
    maxWidth: maxTextWidth - 20, 
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  profilePicIcon: {
    width: 18, // Fixed size for icon
    height: 18, // Fixed size for icon
    marginLeft: 4,
    objectFit: 'contain',
  },
};

export { ProfileComponentProducerSmall };
