import React, { useState, useMemo, useContext, useRef } from 'react';
import { updateDoc, doc, getFirestore } from 'firebase/firestore';
import { AppContext } from './AppContext'; // Adjust the path as needed

const SongItem = ({ item, onPress }) => {
  const { userId2 } = useContext(AppContext); // Access userId2 from AppContext
  const firestore = getFirestore();
  const artworkUrl = item?.ArtworkURL || item?.artworkURL;
  const [isEditing, setIsEditing] = useState(false);
  const dateInputRef = useRef(null); // Ref to the date input field

  // Initialize date and time in local format
  const localDate = item.releaseTimestamp ? new Date(item.releaseTimestamp.seconds * 1000) : new Date();
  const [date, setDate] = useState(localDate.toISOString().split('T')[0]);

  const mandatoryFollowingIds = [
    "h0fEIStkM4XHTDErZM5i8XIo9rA3",
    "OHaY20b54nTGd5BBgbJOL2BHEbX2",
    "rdX289s3YBP31Hw5qn2eXVywtBq1",
    "Mkf4631LCEOYmHXWRzGirPdxBrt1"
  ];

  const handleConfirm = async (e) => {
    e.stopPropagation(); // Prevent the event from propagating to the parent element

    // Preserve the original time and only update the date
    const updatedDate = new Date(date);
    updatedDate.setUTCHours(localDate.getUTCHours(), localDate.getUTCMinutes(), localDate.getUTCSeconds(), localDate.getUTCMilliseconds());

    const genre = item.genres && item.genres.length > 0 ? item.genres[0] : 'unknown';
    
    const songDocRef = doc(firestore, `MusicGenres/${genre}/${item.isAlbum ? 'Albums' : 'Singles'}`, item.id);
    const generalCollectionRef = doc(firestore, item.isAlbum ? 'Albums' : 'Songs', item.id);

    try {
      await updateDoc(songDocRef, {
        releaseTimestamp: updatedDate,
      });

      await updateDoc(generalCollectionRef, {
        releaseTimestamp: updatedDate,
      });

      // Update the local date state to reflect the new date
      setDate(updatedDate.toISOString().split('T')[0]);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating releaseTimestamp:", error);
    }
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    dateInputRef.current.blur(); // Close the calendar popup by blurring the input field
  };

  const formatArtistFeatures = (artistFeatures) => {
    return artistFeatures.map(feature => {
      const parts = feature.tag.split(' - ');
      return parts.length > 1 ? parts[1] : parts[0];
    }).join(', ');
  };
  
  const songDisplayName = useMemo(() => {
    return item.isAlbum
      ? item.albumName || 'Unknown Album'
      : `${item.SongName || 'Unknown Song'}${item.artistFeatures && item.artistFeatures.length > 0 ? ` ft. ${formatArtistFeatures(item.artistFeatures)}` : ''}`;
  }, [item]);
  

  if (!item) {
    return <div style={styles.songArtworkPlaceholder}>Loading...</div>;
  }

  return (
    <div style={styles.itemContainer} onClick={() => onPress(item)}>
      <div style={styles.horizontalTouchable}>
        {artworkUrl ? (
          <img src={artworkUrl} alt="Artwork" style={styles.songArtwork} />
        ) : (
          <div style={styles.songArtworkPlaceholder}>Loading...</div>
        )}
      </div>
      <div style={styles.artistName}>{item.ArtistName}</div>
      <div style={styles.songName}>{songDisplayName}</div>

      {mandatoryFollowingIds.includes(userId2) && (
        <div style={styles.timestampContainer}>
          {!isEditing ? (
            <>
              <div style={styles.timestampText}>
                Release Date: {localDate.toLocaleDateString()} {/* Display only the date */}
              </div>
              <button style={styles.editButton} onClick={(e) => { e.stopPropagation(); setIsEditing(true); }}>
                Edit
              </button>
            </>
          ) : (
            <div style={styles.editContainer}>
              <input 
                type="date" 
                value={date} 
                onChange={handleDateChange} 
                ref={dateInputRef} // Attach the ref to the input field
                style={styles.dateInput} 
                onClick={(e) => e.stopPropagation()}
              />
              <button style={styles.confirmButton} onClick={handleConfirm}>Confirm</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const styles = {
  itemContainer: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: '10px',
    cursor: 'pointer',
  },
  horizontalTouchable: {
    width: '150px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  songArtwork: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  },
  songArtworkPlaceholder: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#cccccc',
  },
  artistName: {
    marginTop: '10px',
    color: 'white',
    fontSize: '16px',
    fontFamily: 'brhendrix',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  songName: {
    marginTop: '2px',
    color: '#b8b8b8',
    fontFamily: 'brhendrix',
    fontSize: '14px',
    textAlign: 'left',
    whiteSpace: 'normal', // Allow wrapping
    wordWrap: 'break-word',
  },
  timestampContainer: {
    marginTop: '10px',
    color: '#b8b8b8',
    fontFamily: 'brhendrix',
    fontSize: '12px',
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
  },
  timestampText: {
    marginBottom: '5px',
  },
  editButton: {
    backgroundColor: '#62fbfa',
    border: 'none',
    padding: '5px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  dateInput: {
    fontSize: '14px',
    padding: '5px',
    borderRadius: '4px',
  },
  confirmButton: {
    backgroundColor: '#62fbfa',
    border: 'none',
    padding: '5px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};

export default React.memo(SongItem);
