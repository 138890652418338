import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './firebase';

const MYDATvideos = ({ item, onPress }) => {
  const [videoThumbnailUrl, setVideoThumbnailUrl] = useState(null);

  

  useEffect(() => {
    
    if (item?.VideoThumbnailURL) {
      if (item.VideoThumbnailURL.startsWith('https://')) {
        setVideoThumbnailUrl(item.VideoThumbnailURL);
      } else if (item.VideoThumbnailURL.startsWith('gs://')) {
        const imagePath = item.VideoThumbnailURL.split('gs://')[1]?.split('/').slice(1).join('/');
        const fetchThumbnailUrl = async () => {
          try {
            const url = await getDownloadURL(ref(storage, imagePath));
            setVideoThumbnailUrl(url);
          } catch (error) {
            console.error('Failed to load video thumbnail', error);
          }
        };
        fetchThumbnailUrl();
      }
    } else {
      console.error('VideoThumbnailURL is missing from item:', item);
    }
  }, [item]);

  if (!item) {
    return <div style={styles.videoThumbnailPlaceholder} />;
  }

  return (
    <div style={styles.videoItemContainer}>
      <div onClick={() => onPress(item)} style={styles.horizontalTouchable}>
        {videoThumbnailUrl ? (
          <img src={videoThumbnailUrl} alt="Thumbnail" style={styles.videoThumbnail} />
        ) : (
          <div style={styles.videoThumbnailPlaceholder} />
        )}
      </div>
      <div style={styles.artistName}>{item.ArtistName}</div>
      <div style={styles.songName}>{item.VideoName}</div>
    </div>
  );
};

const MYDATMusicVideoRow = ({ items, onPress }) => {
  return (
    <div style={styles.rowContainer}>
      {items?.map(item => (
        <MYDATvideos key={item.contentId} item={item} onPress={onPress} />
      ))}
    </div>
  );
};

const MYDATTwoRowScrollView = ({ musicVideos, onPress }) => {
  const halfwayIndex = Math.ceil(musicVideos?.length / 2);
  const firstRowItems = musicVideos?.slice(0, halfwayIndex);
  const secondRowItems = musicVideos?.slice(halfwayIndex);

  return (
    <div style={styles.horizontalScrollView}>
      <div style={{ flexDirection: 'column' }}>
        <MYDATMusicVideoRow items={firstRowItems} onPress={onPress} />
        <MYDATMusicVideoRow items={secondRowItems} onPress={onPress} />
      </div>
    </div>
  );
};

const imageSize = '250px';

const styles = {
  videoItemContainer: {
    alignItems: 'flex-start',
    marginRight: '3vw',
    zIndex: 1,
    cursor: 'pointer',
  },
  horizontalTouchable: {
    width: imageSize,
    height: `calc(${imageSize} * 9 / 16)`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  videoThumbnail: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    objectFit: 'contain',
  },
  videoThumbnailPlaceholder: {
    width: imageSize,
    height: `calc(${imageSize} * 9 / 16)`,
    borderRadius: '8px',
    backgroundColor: '#cccccc',
  },
  artistName: {
    marginTop: '4px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  songName: {
    marginTop: '2px',
    color: '#b8b8b8',
    fontSize: '16px',
    textAlign: 'left',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1vw',
  },
  horizontalScrollView: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'scroll',
  },
};

export default MYDATTwoRowScrollView;
