// SPNCastCategoryVideos.jsx
import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  collection,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  where,
  getFirestore,
  orderBy,
  startAfter,
  increment,
  query,
  limit,
} from 'firebase/firestore';
import { AppContext } from './AppContext';
import VideoItemSeeAll from './VideoItemSeeAll';

const SPNCastCategoryVideos = ({ onMoveMP }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { category } = location.state || {}; // Extract category from state
  const firestore = getFirestore();
  const [data, setData] = useState([]);
  const [lastDoc, setLastDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFetchingMore, setIsFetchingMore] = useState(false);


  const {
    groupedByGenres,
    setGroupedByGenres,
    newVideoReleases,
    setNewVideoReleases,
    dataFeaturedVideos,
    setDataFeaturedVideos,
    playMedia,
    isPopupVisible,
    setIsPopupVisible,
    showMediaPopup,
    activePopupMedia,
    closePopup,
    httpArtworkUrl,
    setHttpArtworkUrl,
    isLoadingArtwork,
    setIsLoadingArtwork,
    popupSongArtworkUrl,
    setPopupSongArtworkUrl,
    popupVideoArtworkUrl,
    setPopupVideoArtworkUrl,
    showPopup,
    setShowPopup,
    handlePlayAndClose,
    isExpanded,
    setIsExpanded,
    userId,
    setUserId,
    setInitialExpandedAlbumAndSong,
    isLoadingNewPopup,
    setIsLoadingNewPopup,
    isLoadingExpand,
    setIsLoadingExpand,
    isLoadingSameUser,
    setIsLoadingSameUser,
    isLoadingProfilePicture,
    setIsLoadingProfilePicture,
    lastViewedMedia,
    setLastViewedMedia,
    showSameMediaPopup,
    setArtworkUri,
    centeredRelease,
    setCenteredRelease,
    hideExpandedAlbum,
    setHideExpandedAlbum,
    activeTab,
    setActiveTab,
    setInitiallySelectedItemId,
    isLoadingHideEA,
    setIsLoadingHideEA,
    isExpandedShare,
    setIsExpandedShare,
    setTrackToView,
  } = useContext(AppContext);

  const CACHE_DURATION = 30000;

  const spnCastCategories = [
    { label: "Podcasts", value: "podcasts" },
    { label: "Behind-the-Scenes", value: "behind_the_scenes" },
    { label: "Interviews/Q&A", value: "interviews" },
    { label: "Music Gear", value: "musicgear" },
    { label: "Comedy/Sketch", value: "comedy_sketch" },
    { label: "Gaming", value: "gameplays" },
    { label: "General Entertainment", value: "general_entertainment" },
    { label: "Courses/Tutorials", value: "tutorials" },
    { label: "News", value: "news" },
    { label: "Vlogs", value: "vlogs" },
    { label: "Product Reviews", value: "product_reviews" },
    { label: "Unboxings", value: "unboxings" },
    { label: "Short Films", value: "short_films" },
    { label: "Sports Highlights", value: "sports_highlights" },
    { label: "Cooking/Food", value: "cooking_food" },
    { label: "Travel", value: "travel" },
    { label: "Fitness/Workout", value: "fitness_workout" },
    { label: "Animation", value: "animation" },
    { label: "DIY/Crafts", value: "diy_crafts" },
    { label: "Fashion & Beauty", value: "beauty_fashion" },
    { label: "Technology/Gadgets", value: "technology_gadgets" },
    { label: "ASMR", value: "asmr" },
    { label: "Motivational/Inspirational", value: "motivational_inspirational" },
    { label: "Pranks", value: "pranks" },
    { label: "Nature/Wildlife", value: "nature_wildlife" },
    { label: "Storytelling", value: "storytelling" },
    { label: "Pets/Animals", value: "pets_animals" },
  ];

  // Get the label for the passed category value
  const categoryLabel = useMemo(() => {
    const foundCategory = spnCastCategories.find((cat) => cat.value === category);
    return foundCategory ? foundCategory.label : 'Unknown Category';
  }, [category]);

  // Fetch initial videos from the selected SPNCast category
  const fetchCategoryData = async () => {
    setLoading(true);
    try {
      const fetchSize = 16;
      const videosQuery = query(
        collection(firestore, `spncasts/${category}/videos`),
        orderBy('releaseTimestamp', 'desc'),
        limit(fetchSize)
      );

      const videosSnapshot = await getDocs(videosQuery);
      const videosData = videosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setData(videosData);
      setLastDoc(videosSnapshot.docs[videosSnapshot.docs.length - 1]);


    } catch (error) {
      console.error('Error fetching category data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch more videos when scrolling to the end of the list
  const fetchMoreData = async () => {
    if (isFetchingMore || !lastDoc) {
      return;
    }

    setIsFetchingMore(true);
    try {
      const fetchSize = 16;
      const videosQuery = query(
        collection(firestore, `spncasts/${category}/videos`),
        orderBy('releaseTimestamp', 'desc'),
        startAfter(lastDoc),
        limit(fetchSize)
      );

      const videosSnapshot = await getDocs(videosQuery);
      const videosData = videosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setData(prevData => [...prevData, ...videosData]);
      setLastDoc(videosSnapshot.docs.length > 0 ? videosSnapshot.docs[videosSnapshot.docs.length - 1] : null);


    } catch (error) {
      console.error('Error fetching more data:', error);
    } finally {
      setIsFetchingMore(false);
    }
  };

  // Run fetchCategoryData when component mounts or category changes
  useEffect(() => {
    if (category) {
      fetchCategoryData();
    }
  }, [category]);

  const sortReleasesByTimestamp = (releases) => {
    const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
    return sortedReleases;
  };



  const handlePressBegins = (video) => {

    const clonedSong = JSON.parse(JSON.stringify(video));
    navigate('/view-song', { state: { song: clonedSong } });
  };



  const renderItem = useCallback(
    (item) => <VideoItemSeeAll item={item} onPress={handlePressBegins} />,
    []
  );

  return (
    <div style={styles.fullScreen}>

      <div style={styles.outerContainer}>
        <div style={styles.container}>
          {/* Header */}
          <h1 style={styles.title}>{categoryLabel}</h1>

          {/* Loading Indicator */}
          {loading ? (
            <div style={styles.loaderContainer}>
              <div className="loader"></div>
            </div>
          ) : data.length === 0 ? (
            <p style={styles.noDataText}>
              No videos available in this category. Be the first SPNCaster to release one, or refer a content creator!
            </p>
          ) : (
            <div style={styles.gridContainer}>
              {data.map((item) => (
                <VideoItemSeeAll key={item.id} item={item} onPress={handlePressBegins} />
              ))}
            </div>
          )}

          {/* Footer Spacer */}
          <div style={styles.bottomSpacer}></div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 0,
  },
  outerContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
  },
  container: {
    width: '100%',
    height: '100%',
    padding: '20px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: '#62fbfa',
    fontSize: '24px', // Adjust as needed
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '20px',
    fontFamily: 'Arial, sans-serif', // Replace with your desired font
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh',
  },
  loader: {
    border: '8px solid #f3f3f3',
    borderTop: '8px solid #62fbfa',
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    animation: 'spin 2s linear infinite',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '20px',
    width: '100%',
    padding: '10px',
  },
  noDataText: {
    fontSize: '16px',
    color: '#fff',
    textAlign: 'center',
    marginTop: '20px',
    maxWidth: '600px',
  },

  bottomSpacer: {
    height: '350px', // Equivalent to paddingBottom: '350px'
    backgroundColor: 'transparent',
  },
};

// Adding keyframes for loader animation
const styleSheet = document.styleSheets[0];
const keyframes =
  `@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }`;
styleSheet.insertRule(keyframes, styleSheet.cssRules.length);

export default SPNCastCategoryVideos;
