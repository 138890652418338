import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import { getFirestore, doc, getDoc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import SPNLISTflatlist from './SPNLISTflatlist';
import { firestore, storage } from './firebase';
import { getAuth } from "firebase/auth";
import Modal from 'react-modal';

const LibrarySPNLists = ({ onPlayVideo, isVideoPlaying, onMoveMP }) => {
  const navigate = useNavigate();
  const [isAddingSPNLIST, setIsAddingSPNLIST] = useState(false);
  const [newSPNListName, setNewSPNListName] = useState('');
  const fadeAnim = useRef(0);
  const [localPlaylists, setLocalPlaylists] = useState([]);
  const [purchasedMedia, setPurchasedMedia] = useState([]);
  const [purchasedSingles, setPurchasedSingles] = useState([]);
  const [purchasedAlbums, setPurchasedAlbums] = useState([]);
  const [purchasedVideos, setPurchasedVideos] = useState([]);

  const {
    currentUser, userId2,
    userId, playlistsFresh, setPlaylistsFresh
  } = useContext(AppContext);

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const persistPlaylists = async (playlists) => {
    const userId = getCurrentUserId();
    try {
      const jsonValue = JSON.stringify(playlists);
    } catch (e) {
      console.error("Failed to save playlists to storage", e);
    }
  
      const userDocRef = doc(firestore, "users", userId);
      try {
        await updateDoc(userDocRef, {
          playlists: playlists,
        });
      } catch (error) {
        console.error("Error updating user's playlists:", error);
      }
  
  };

  useEffect(() => {
    const singles = currentUser?.purchasedMedia.filter(item => !item.isAlbum && !item.isVideo);
    const albums = currentUser?.purchasedMedia.filter(item => item.isAlbum);
    const videos = currentUser?.purchasedMedia.filter(item => item.isVideo);

    setPurchasedMedia(currentUser?.purchasedMedia);
    setPurchasedSingles(singles);
    setPurchasedAlbums(albums);
    setPurchasedVideos(videos);
    setLocalPlaylists(playlistsFresh);
  }, [currentUser?.purchasedMedia, playlistsFresh]);


  const deletePlaylist = async (playlistId) => {

    const updatedPlaylists = playlistsFresh.filter(playlist => playlist.id !== playlistId);
    setPlaylistsFresh(updatedPlaylists);
    await persistPlaylists(updatedPlaylists);

    const userId = getCurrentUserId();
    if (!userId) {
        console.error("User not logged in");
        return;
    }
    const userDocRef = doc(firestore, "users", userId);

    try {
        const docSnap = await getDoc(userDocRef);
        if (docSnap.exists()) {
            const userData = docSnap.data();

            const updatedPurchasedMedia = userData.purchasedMedia ? userData.purchasedMedia.filter(media => media.id !== playlistId) : [];

            await updateDoc(userDocRef, {
                purchasedMedia: updatedPurchasedMedia,
            });
        } else {
            console.error("No such document!");
        }
    } catch (error) {
        console.error("Error updating document:", error);
    }
};


  const addPlaylist = (playlist) => {
    const newPlaylist = {
      ...playlist,
      videos: playlist.videos || [],
      albums: playlist.albums || [],
      creationDate: new Date().toISOString(),
      description: playlist.description || '',
      coverImage: playlist.coverImage,
      createdBy: playlist.createdBy,
    };
  
    // Initialize playlists to an empty array if it is undefined
    const safePlaylists = playlistsFresh || [];
  
    const updatedPlaylists = [...safePlaylists, newPlaylist];
    setPlaylistsFresh(updatedPlaylists);
    persistPlaylists(updatedPlaylists);
  };



  const handleCreateSPNListPress = () => {
    setIsAddingSPNLIST(true);
    fadeAnim.current = 1;
  };

  const handleCreateSPNList = async () => {
    if (!newSPNListName.trim()) {
      alert('Please enter a name for the SPNList.');
      return;
    }

    const uniqueId = `${Date.now()}${Math.floor(Math.random() * 1000000000)}`;
    const newSPNList = {
      id: uniqueId,
      contentId: uniqueId,
      name: newSPNListName,
      coverImage: 'defaultCoverImageURL',
      createdBy: currentUser.username,
      ArtistName: currentUser.name,
      albumName: newSPNListName,
      isAlbum: true,
      ArtworkURL: 'defaultCoverImageURL',
      songs: [],
      releaseTimestamp: new Date().getTime(),
    };

    const userDocRef = doc(getFirestore(), 'users', userId2);

    addPlaylist(newSPNList);

    try {
      setIsAddingSPNLIST(false);
      setNewSPNListName('');
      navigate(`/library/spnlistprofile`, { state: { playlistId: newSPNList.id } });
    } catch (error) {
      console.error('Error creating new SPNList:', error);
      alert('Failed to create new SPNList. Please try again.');
    }
  };

  const handleDismiss = () => {
    setIsAddingSPNLIST(false);
    fadeAnim.current = 0;
  };

  const handlePlaylistPress = (playlistId) => {
    navigate(`/library/spnlistprofile`, { state: { playlistId: playlistId } });
  };

  const styles = {
    fullScreen: {
      display: 'flex',
      flex: 1,
      position: 'relative',
    },
    container: {
      height: 'calc(100vh - 18vh)',
      zIndex: 1,
      paddingBottom: '18vh',
      width: '100%',
      zIndex: 10,
      overflowY: 'auto',
    },
    buttonTouchableArea: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
    fullScreenImage: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 0,
    },
    fadeInContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 100,
    },
    input: {
      borderWidth: 1,
      borderColor: '#FFF',
      backgroundColor: '#FFFFFF',
      width: '80%',
      padding: 10,
      margin: 20,
      borderRadius: 5,
      color: '#000',
      fontSize: 18,
    },
    confirmButton: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderRadius: 5,
      padding: 10,
      marginTop: 10,
      width: 150,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      fontSize: 18,
      cursor: 'pointer',
    },
    dismissButton: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderRadius: 5,
      padding: 10,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 10,
      width: 150,
      color: '#FFFFFF',
      fontSize: 18,
      cursor: 'pointer',
    },
    fullScreenImage2: {
      width: '100%',
      height: '80vh',
      position: 'absolute',
      top: 0,
      left: 0,
      objectFit: 'cover',
      zIndex: 100,
    },
    createIconTouchable: {
      top: '0.8vh',
      display: 'flex',
      justifyContent: 'center',
      height: '73px',
      width: '54px',
      alignSelf: 'center',
      objectFit: 'contain',
      margin: '0 auto',
      zIndex: 100,
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',
    },
    extraSpace: {
      height: '300px',
    },
    createIcon: {
      height: '62px',
      width: '200px',
      alignSelf: 'center',
      objectFit: 'contain',
      zIndex: 100,
    },
  };
  
  return (
    <div style={styles.fullScreen}>
      <Modal
        isOpen={isAddingSPNLIST}
        onRequestClose={handleDismiss}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 10000,
          },
          content: {
            height: '400px',
            width: '80%',
            maxWidth: '600px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: 'transparent',
            padding: '20px',
            border: 'none',
            borderRadius: '8px',
            margin: '0 auto', // Center horizontally
          },
        }}
      >
        {isAddingSPNLIST && (
          <div style={styles.fadeInContainer}>
            <input
              type="text"
              placeholder="Name your SPNLIST"
              value={newSPNListName}
              onChange={e => setNewSPNListName(e.target.value)}
              style={styles.input}
              autoFocus={true}
            />
            <button onClick={handleCreateSPNList} style={styles.confirmButton}>
              Confirm
            </button>
            <button onClick={handleDismiss} style={styles.dismissButton}>
              Cancel
            </button>
          </div>
        )}
      </Modal>
  
      <div style={styles.container}>
        <button style={styles.createIconTouchable} onClick={handleCreateSPNListPress}>
          <img src={require('./assets/newSPNLISTbutton.png')} style={styles.createIcon} alt="Create SPNList" />
        </button>
        {localPlaylists.map(item => (
          <SPNLISTflatlist
            key={item.id}
            item={item}
            onPress={() => handlePlaylistPress(item.id)}
            onDelete={deletePlaylist}
          />
        ))}
                <div style={styles.extraSpace}></div>
      </div>
  
      <div style={{ height: '18vh', backgroundColor: 'transparent' }} />
    </div>
  );
  
};

export default LibrarySPNLists;
