import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, updateEmail, updatePassword, reauthenticateWithCredential, EmailAuthProvider, verifyBeforeUpdateEmail } from 'firebase/auth';

const PasswordChange = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const reauthenticate = async (currentPassword) => {
    
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    try {
      await reauthenticateWithCredential(user, credential);
      
      return true;
    } catch (error) {
      console.error("Re-authentication failed:", error);
      alert("Authentication failed", "Please check your current password and try again.");
      return false;
    }
  };

  const handleUpdateEmail = async () => {
    
    const reauthenticated = await reauthenticate(currentPassword);
    if (reauthenticated) {
      const user = auth.currentUser;
      
      verifyBeforeUpdateEmail(user, newEmail)
        .then(() => {
          
          alert("Email Verification", "A verification link has been sent to the new email address. Please confirm it to complete the update.");
        })
        .catch((error) => {
          console.error("Error updating email:", error);
          if (error.code === 'auth/requires-recent-login') {
            console.error("Session Expired: Please re-authenticate and try again.");
          } else if (error.code === 'auth/email-already-in-use') {
            console.error("Update Failed: This email is already in use by another account.");
          } else if (error.code === 'auth/invalid-email') {
            console.error("Update Failed: The email address is not valid.");
          } else if (error.code === 'auth/operation-not-allowed') {
            console.error("Update Failed: Email update operation is not allowed. Please contact support.");
          } else {
            console.error("Update Failed: Failed to update email. Please ensure the new email is not already in use and try again.");
          }
        });
    } else {
      
    }
  };

  const handleUpdatePassword = async () => {
    
    const reauthenticated = await reauthenticate(currentPassword);
    if (reauthenticated) {
      const user = auth.currentUser;
      
      updatePassword(user, newPassword)
        .then(() => {
          
          alert("Password updated successfully");
        })
        .catch((error) => {
          console.error("Error updating password:", error);
        });
    } else {
      
    }
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <input
          style={styles.input}
          onChange={(e) => setCurrentPassword(e.target.value)}
          value={currentPassword}
          placeholder="Current password"
          type="password"
        />
        <input
          style={styles.input}
          onChange={(e) => setNewEmail(e.target.value)}
          value={newEmail}
          placeholder="New email"
          type="email"
        />
        <button style={styles.button} onClick={handleUpdateEmail}>
          <span style={styles.buttonText}>Update Email</span>
        </button>
        <input
          style={styles.input}
          onChange={(e) => setNewPassword(e.target.value)}
          value={newPassword}
          placeholder="New password"
          type="password"
        />
        <button style={styles.button} onClick={handleUpdatePassword}>
          <span style={styles.buttonText}>Update Password</span>
        </button>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  scrollContainer: {
    width: '100%',
    maxWidth: '500px',
    height: '130%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10vh',
    paddingBottom: '10vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
    zIndex: 10,
  },
  input: {
    width: '90%',
    padding: '10px',
    height: '40px',
    marginBottom: '10px',
    borderWidth: '1px',
    fontSize: '16px',
    borderColor: '#ccc',
    borderRadius: '5px',
    color: 'white',
    backgroundColor: 'transparent', // Ensure background is transparent to show background image
  },
  button: {
    borderRadius: '5px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginBottom: '10px',
    backgroundColor: 'transparent', // Slightly transparent background for button
    border: 'none',
  },
  buttonText: {
    fontFamily: 'brhendrix',
    color: '#62fbfa',
    fontSize: '18px',
  },
};

export default PasswordChange;
