import React, { useEffect, useState } from 'react';
import { collection, getDoc, setDoc, getDocs, getFirestore, query, where, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import BlankBG from './assets/BlankBG.png';
import TopAreaFades from './assets/TopAreaFades.png';

const Submissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [noSubmissions, setNoSubmissions] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadingSubmissionId, setUploadingSubmissionId] = useState(null);
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const submissionsRef = collection(firestore, 'MovieSubmissions');
        const q = query(submissionsRef, where('status', '==', 'ForReview'));
        const querySnapshot = await getDocs(q);
        const fetchedSubmissions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        if (fetchedSubmissions.length === 0) {
          setNoSubmissions(true);
        } else {
          setSubmissions(fetchedSubmissions);
        }
      } catch (error) {
        console.error("Error fetching submissions: ", error);
      }
    };

    fetchSubmissions();
  }, [firestore]);

  const formatPremiereDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const uploadVideoFile = async (file, fileName, retries = 5, delayTime = 3000) => {
    console.log(`Starting upload for file: ${fileName}`);
    const storage = getStorage();
    const storageRef = ref(storage, `videos/${fileName}`);
    
    const attemptUpload = async (retryCount) => {
      console.log(`Attempting upload for file: ${fileName}, Retry count: ${retries - retryCount + 1}`);
      try {
        console.log(`Blob size: ${file.size} bytes`);
  
        const uploadTask = uploadBytesResumable(storageRef, file);
        console.log(`Upload task created for file: ${fileName}`);
    
        return await new Promise((resolve, reject) => {
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log(`Upload progress for ${fileName}: ${progress.toFixed(2)}%`);
              setUploadProgress(progress); // Update the progress state
            },
            (error) => {
              console.error(`Upload error for file: ${fileName}`, error);
  
              // Check for additional details in the error payload
              if (error.serverResponse) {
                try {
                  const errorPayload = JSON.parse(error.serverResponse);
                  console.error(`Server response for file ${fileName}:`, errorPayload);
                } catch (e) {
                  console.error(`Error parsing server response for file ${fileName}:`, e);
                }
              }
  
              reject(error);
            },
            () => {
              console.log(`Upload successful for file: ${fileName}`);
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log(`Download URL for file ${fileName}: ${downloadURL}`);
                resolve(downloadURL);
              });
            }
          );
        });
      } catch (error) {
        console.error(`Error during upload for file: ${fileName}, Retry count: ${retries - retryCount + 1}`);
        if (retryCount > 0) {
          console.log(`Retrying upload for file: ${fileName} (${retries - retryCount + 1}/3) after delay of ${delayTime}ms...`);
          await delay(delayTime);
          return attemptUpload(retryCount - 1);
        } else {
          console.error(`Upload failed after 3 retries for file: ${fileName}`);
          alert("Low internet connection, please try again");
          throw error; // Re-throw to be caught by caller
        }
      }
    };
    
    return await attemptUpload(retries);
  };
  

const handleFileChange = (event) => {
  if (event.target.files[0]) {
    setSelectedFile(event.target.files[0]); // No need to convert to URI
  }
};

const handleUploadClick = async (submissionId) => {
  if (!selectedFile) return;

  setUploadingSubmissionId(submissionId);
  const downloadURL = await uploadVideoFile(selectedFile, selectedFile.name); // Pass the File object directly

  const submissionRef = doc(firestore, 'MovieSubmissions', submissionId);
  await updateDoc(submissionRef, {
    link: downloadURL,
  });

  // Update local state
  setSubmissions((prevSubmissions) =>
    prevSubmissions.map((submission) =>
      submission.id === submissionId ? { ...submission, link: downloadURL } : submission
    )
  );

  setUploadingSubmissionId(null);
  setSelectedFile(null);
  alert('Upload successful and link updated!');
};


const handleApproveClick = async (submissionId) => {
  const submissionRef = doc(firestore, 'MovieSubmissions', submissionId);

  // Fetch the submission document to get the user's ID
  const submissionDoc = await getDoc(submissionRef);
  
  if (submissionDoc.exists()) {
    const submissionData = submissionDoc.data();
    const userId = submissionData.userId; // Assuming userId is a field in the submission document

    // Update the status in the general MovieSubmissions collection
    await updateDoc(submissionRef, {
      status: 'ComingSoon',
    });

    // Update the status in the user's Movie Submissions subcollection
    const userSubmissionRef = doc(firestore, `users/${userId}/Movie Submissions`, submissionId);
    await updateDoc(userSubmissionRef, {
      status: 'ComingSoon',
    });

    // Copy the updated document data to the ComingSoonMovies collection
    const comingSoonMoviesRef = collection(firestore, 'ComingSoonMovies');
    await setDoc(doc(comingSoonMoviesRef, submissionId), submissionData);

    // Update local state
    setSubmissions((prevSubmissions) =>
      prevSubmissions.map((submission) =>
        submission.id === submissionId ? { ...submission, status: 'ComingSoon' } : submission
      )
    );

    alert('Movie approved, status updated to ComingSoon, and document copied to ComingSoonMovies!');
  } else {
    console.error('Submission document not found.');
  }
};




  const renderSubmission = (submission, index) => {
    const {
      id,
      VideoThumbnailURL,
      MovieTitle,
      premiereDate,
      status,
      link,
      genres,
      directors,
      producers,
      cast,
      productionCompany
    } = submission;

    const isFirebaseStorageLink = link && link.startsWith('https://firebasestorage.googleapis.com');

    return (
      <div key={index} style={styles.submissionContainer}>
        <img src={VideoThumbnailURL} alt={`${MovieTitle} thumbnail`} style={styles.thumbnail} />
        <h2 style={styles.movieTitle}>{MovieTitle}</h2>
        <p style={styles.status}>
          {status === 'pending' ? 'Status: Pending Payment (pay on spndat.com under My Submissions)' : `Status: ${status}`}
        </p>
        <p style={styles.sectionTitle}>Premiere Date:</p>
        <p style={styles.genre}>{formatPremiereDate(premiereDate)}</p>
        <p style={styles.sectionTitle}>Link:</p>
        <p style={styles.genre}>{link}</p>
        <p style={styles.sectionTitle}>Genres:</p>
        {genres.map((genre, idx) => (
          <p key={idx} style={styles.genre}>{genre}</p>
        ))}
        <p style={styles.sectionTitle}>Directors:</p>
        {directors.map((director, idx) => (
          <p key={idx} style={styles.personTag}>{director.tag}</p>
        ))}
        <p style={styles.sectionTitle}>Producers:</p>
        {producers.map((producer, idx) => (
          <p key={idx} style={styles.personTag}>{producer.tag}</p>
        ))}
        <p style={styles.sectionTitle}>Cast:</p>
        {cast.map((actor, idx) => (
          <p key={idx} style={styles.personTag}>{actor.tag}</p>
        ))}
        <p style={styles.sectionTitle}>Production Company:</p>
        {productionCompany.map((company, idx) => (
          <p key={idx} style={styles.personTag}>{company.tag}</p>
        ))}

        <input type="file" onChange={handleFileChange} />
        {selectedFile && uploadingSubmissionId !== id && (
          <button onClick={() => handleUploadClick(id)}>Upload Movie</button>
        )}
        {uploadingSubmissionId === id && (
          <p>Uploading... {uploadProgress.toFixed(2)}%</p>
        )}
        {isFirebaseStorageLink && (
          <button onClick={() => handleApproveClick(id)}>Approve this movie</button>
        )}
      </div>
    );
  };

  return (
    <div style={styles.fullScreen}>
      <img src={BlankBG} alt="Background" style={styles.fullScreenImage} />
      <div style={styles.scrollContainer}>
        {noSubmissions ? (
          <p style={styles.noSubmissionsText}>There are no movie submissions ready for review</p>
        ) : (
          submissions.map((submission, index) => renderSubmission(submission, index))
        )}
      </div>
      <div style={{ height: styles.bottomAreaHeight, backgroundColor: 'transparent' }} />
    </div>
  );
};

const styles = {
  fullScreen: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  popupImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 5,
    pointerEvents: 'none',
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: `calc(100vh - 18vh)`, 
    zIndex: 2,
    overflowY: 'auto',
    paddingTop: '2vh',
    paddingBottom: '10vh',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    objectFit: 'cover',
  },
  submissionContainer: {
    marginBottom: '20px',
    borderColor: '#ccc',
    borderWidth: '1px',
    padding: '10px',
    borderRadius: '8px',
    marginLeft: '10px',
    marginRight: '10px',
    color: 'white',
  },
  thumbnail: {
    width: '100%',
    aspectRatio: '16 / 9',
    borderRadius: '8px',
  },
  movieTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  premiereDate: {
    fontSize: '14px',
    marginTop: '5px',
  },
  status: {
    fontSize: '14px',
    color: '#d9534f',
    marginTop: '5px',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  genre: {
    fontSize: '14px',
  },
  personTag: {
    fontSize: '14px',
  },
  bottomAreaHeight: '18vh',
  noSubmissionsText: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'center',
    marginTop: '20px',
  },
};

export default Submissions;
