import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import SPNListPageMediaItems from './SPNListPageMediaItems'; // Assuming this is the layout you want for each item
import { getFirestore, runTransaction, addDoc, deleteDoc, setDoc, serverTimestamp, doc, getDoc, collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { firestore } from './firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { useCache } from './CacheContext';


const CACHE_KEY = 'userCache';
const CACHE_LIMIT = 300;
const CACHE_DURATION = 5000;

const bottomAreaHeight = window.innerHeight * 0.3;
const scrollAreaTopPadding = window.innerHeight * 0.17;

const LibraryCategoryScreen = ({ category, onMoveMP }) => {
  const {
    playMedia,
    isPopupVisible, setIsPopupVisible,
    showMediaPopup,
    activePopupMedia,
    closePopup,
    httpArtworkUrl, setHttpArtworkUrl,
    isLoadingArtwork, setIsLoadingArtwork,
    popupSongArtworkUrl, setPopupSongArtworkUrl,
    popupVideoArtworkUrl, setPopupVideoArtworkUrl,
    showPopup, setShowPopup,
    handlePlayAndClose,
    isExpanded, setIsExpanded,
    userId, setUserId,
    setInitialExpandedAlbumAndSong,
    isLoadingNewPopup, setIsLoadingNewPopup,
    isLoadingExpand, setIsLoadingExpand,
    isLoadingSameUser, setIsLoadingSameUser,
    isLoadingProfilePicture, setIsLoadingProfilePicture,
    lastViewedMedia, setLastViewedMedia,
    showSameMediaPopup,
    setArtworkUri, centeredRelease, setCenteredRelease,
    hideExpandedAlbum, setHideExpandedAlbum,
    activeTab, setActiveTab,
    setInitiallySelectedItemId,
    isLoadingHideEA, setIsLoadingHideEA, currentUser, setUser,
    trackToView, setTrackToView, purchasedMedia
  } = useContext(AppContext);

  const navigate = useNavigate();
  const [mediaItems, setMediaItems] = useState([]);
  const [activeURL, setActiveURL] = useState(null);


  const firestore = getFirestore();
  const auth = getAuth();

  const getCachedUserDoc = async (userId) => {
    let userDataCached = [];
    let userDataCachedNew = [];
    const now = new Date().getTime();
  
    if (!userDataCached || now - userDataCached.timestamp >= CACHE_DURATION) {
        
        const userDocRef = doc(firestore, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
            
            const freshUserData = userDocSnap.data();
  
            
            // Update userDataCached structure with fresh data and timestamp
            userDataCachedNew = {
                ...freshUserData, // overwrite with fresh data from Firestore
                profileImageUrl: freshUserData?.profileImageUrl,
                timestamp: now
            };
  
  
        } else {
            console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
        }
    } else {
        
        userDataCachedNew = userDataCached;
    }
  
    return userDataCachedNew;
  };

  const sortReleasesByTimestamp = (releases) => {
    return [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
  };

  const handlePressBegins = async (song) => {
    const clonedSong = JSON.parse(JSON.stringify(song));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  const preparePopupData = (song, userData) => {
    let profileImageUrl = userData?.profileImageUrl || null;
    let userAlbums = sortReleasesByTimestamp(userData.albums || []);
    let userSingles = sortReleasesByTimestamp(userData.singles || []);
    let userVideos = sortReleasesByTimestamp(userData.videos || []);
    let selectedAlbum = userAlbums.find(album => album.contentId === song.contentId) || null;
    let selectedSingle = userSingles.find(single => single.contentId === song.contentId) || null;
    let selectedVideo = userVideos.find(video => video.contentId === song.contentId) || null;
    const artworkUri = selectedVideo?.VideoThumbnailURL || (selectedAlbum || selectedSingle)?.ArtworkURL || song?.ArtworkURL;
    setArtworkUri(artworkUri);
    if (selectedAlbum && selectedAlbum.songs.length > 0) {
      setInitialExpandedAlbumAndSong(selectedAlbum, selectedAlbum.songs[0]);
      setCenteredRelease(selectedAlbum);
    } else if (selectedSingle && selectedSingle.length > 0) {
      setCenteredRelease(selectedSingle);
    } else if (selectedVideo) {
      setCenteredRelease(selectedVideo);
    }
    const songName = song.isVideo ? song.VideoName : song.SongName;
    return {
      url: song.SongURL,
      dimensions: song.dimensions || [],
      isVideo: song.isVideo,
      artistName: song.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: song.username,
      songName: songName,
      artworkUrl: artworkUri,
      contentId: song.contentId,
      localArtworkURL: song.localArtworkURL,
      userId: song.userId,
      isAlbum: song.isAlbum,
      accountType: userData.accountType,
      albumName: selectedAlbum?.albumName || '',
      producers: song.producers,
      songwriters: song.songwriters,
      recordLabels: song.recordLabels,
      otherReleases: { albums: userAlbums, singles: userSingles, videos: userVideos },
      albumSongs: selectedAlbum?.songs || [],
      single: selectedSingle,
      video: selectedVideo,
      timestamp: new Date().getTime(),
      vocalDrop: userData.vocalDrop,
      bio: userData.bio,
      blurb: userData.blurb,
      voiceDropMuted: userData?.voiceDropMuted,
      location: userData.location,
      ScreenSKN: userData.ScreenSKN,
    };
  };

  const getMediaForCategory = async (category) => {
    let filteredMedia = [];

    if (category === 'MyReleases') {
      const singles = currentUser.singles || [];
      const albums = currentUser.albums || [];
      const videos = currentUser.videos || [];
      const beats = currentUser?.beats || [];
      filteredMedia = [...singles, ...albums, ...videos, ...beats];
    } else {
      switch (category) {
        case 'Singles':
          filteredMedia = purchasedMedia.filter(item => !item.isAlbum && !item.isVideo);
          break;
        case 'Albums':
          filteredMedia = purchasedMedia.filter(item => item.isAlbum && !(/^\d+$/).test(item.contentId));
          break;
        case 'Songs':
          const albums = purchasedMedia.filter(item => item.isAlbum && !(/^\d+$/).test(item.contentId));
          const singles = purchasedMedia.filter(item => !item.isAlbum && !item.isVideo);
          const songsFromAlbums = extractSongsFromAlbums(albums);
          filteredMedia = [...singles, ...songsFromAlbums];
          break;
        case 'Videos':
          filteredMedia = purchasedMedia.filter(item => item.isVideo);
          break;
          case 'Beats':
            filteredMedia = purchasedMedia.filter(item => item?.isBeat);
            break;
        default:
          break;
      }
    }

    return filteredMedia;
  };

  const extractSongsFromAlbums = (albums) => {
    return albums.reduce((acc, album) => acc.concat(album.songs.map(song => ({ ...song, albumTitle: album.title }))), []);
  };

  useEffect(() => {
    const fetchMedia = async () => {
      const items = await getMediaForCategory(category);
      setMediaItems(items);
    };

    fetchMedia();
  }, [category, currentUser]);

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollableContainer}>
        <div style={styles.headerContainer}>
          <span style={styles.headerText}>{category}</span>
        </div>
        <div style={styles.scrollableList}>
          {mediaItems.map((item) => (
            <SPNListPageMediaItems
              key={item.contentId}
              item={item}
              onPress={() => handlePressBegins(item)}
              isSelected={false}
              onDelete={() => {}}
            />
          ))}
        </div>
      </div>
      <div style={styles.extraSpace}></div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center', // Center the header horizontally
  },
  extraSpace: {
    height: '200px',
      },
  
  scrollableContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the content horizontally
    backgroundColor: 'transparent',
    zIndex: 10,
    overflowY: 'auto', // Enable vertical scrolling
    width: '100%',
    paddingBottom: '20vh',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center', // Center the content horizontally
    alignItems: 'center',
    padding: '2vh',
    width: '100%',
  },
  headerText: {
    color: 'white',
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'brhendrix',
  },
  scrollableList: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
};


export default LibraryCategoryScreen;
