import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './firebase'; // Adjust the import path as per your project structure

const SPNListPageMediaItems = ({ item, onPress, isSelected, onDelete }) => {
  const [songArtworkUrl, setSongArtworkUrl] = useState(null);

  useEffect(() => {
    const fetchArtworkUrl = async (artworkUrl) => {
      try {
        let url;
        if (artworkUrl.startsWith('https://')) {
          url = artworkUrl;
        } else if (artworkUrl.startsWith('gs://')) {
          const imagePath = artworkUrl.split('gs://')[1].split('/').slice(1).join('/');
          url = await getDownloadURL(ref(storage, imagePath));
        }
        setSongArtworkUrl(url);
      } catch (error) {
        console.error('Failed to load image', error);
      }
    };

    if (item?.ArtworkURL || item?.artworkURL) {
      fetchArtworkUrl(item.ArtworkURL || item.artworkURL);
    }
  }, [item]);

  const titleToDisplay = item.isAlbum ? item.albumName : item.SongName;

  const renderRightActions = () => (
    <button onClick={() => onDelete(item.contentId)} style={styles.deleteButton}>
        <span style={styles.deleteButtonText}>Delete</span>
    </button>
  );

  return (
    <div style={{ ...styles.mediaItem, ...(isSelected ? styles.selected : {}) }} onClick={onPress}>
      <div style={styles.mediaRow}>
        {songArtworkUrl ? (
          <img src={songArtworkUrl} alt="Artwork" style={item.isVideo ? styles.videoArtwork : styles.songArtwork} />
        ) : (
          <div style={styles.songArtworkPlaceholder}></div>
        )}
        <div style={styles.textContainer}>
          <p style={styles.artistName}>{item.ArtistName}</p>
          <p style={styles.songName}>{titleToDisplay}</p>
        </div>
      </div>
      <div style={styles.borderContainer}>
        <div style={styles.centeredBorder}></div>
      </div>
    </div>
  );
};

const styles = {
  scrollableContainer: {
    height: '100vh', // Full viewport height
    overflowY: 'auto', // Enable vertical scrolling
    padding: '10px', // Optional padding
    paddingBottom: '20vh',
  },
  
  mediaItem: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '10px', // Fixed padding for consistency
    alignItems: 'center', // Center items horizontally
    cursor: 'pointer',
    width: '100%', // Ensure the item takes the full width
  },
    videoArtwork: {
    width: '160px', // Smaller width
    height: '90px', // Smaller height to maintain 16:9 aspect ratio
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  mediaRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center', // Center the row content horizontally
    width: '100%', // Ensure the row takes the full width
  },
  textContainer: {
    marginLeft: '20px', // Fixed margin for consistency
    width: '200px', // Fixed width for text wrapping
    textAlign: 'left',
    whiteSpace: 'normal', // Ensure text wraps to the next line
    wordWrap: 'break-word', // Ensure long words break to the next line
  },
  songArtwork: {
    width: '90px', // Fixed width for consistency
    height: '90px', // Fixed height for consistency
    borderRadius: '8px',
  },
  songArtworkPlaceholder: {
    width: '80px', // Fixed width for consistency
    height: '80px', // Fixed height for consistency
    borderRadius: '8px',
    backgroundColor: '#f0f0f0',
  },
  artistName: {
    marginBottom: '-8px', // Fixed margin for consistency
    color: 'white',
    fontSize: '16px', // Fixed font size for consistency
    fontWeight: 'bold',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
  songName: {
    color: '#b8b8b8',
    fontSize: '14px', // Fixed font size for consistency
    textAlign: 'left',
    fontWeight: 'normal',
    fontFamily: 'brhendrix',
  },
  selected: {
    backgroundColor: 'rgba(189, 252, 251, 0.15)',
  },
  borderContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: '10px', // Fixed margin for consistency
  },
  centeredBorder: {
    marginLeft: '0', // No margin left to center border with item
    width: '100%', // Full width for border to match item width
    borderBottomWidth: '1px',
    borderBottomColor: 'rgba(189, 252, 251, 0.3)',
  },
  deleteButton: {
    backgroundColor: '#852061',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100%', // Match the height of the list item
    bottom: '7px',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
  },
  deleteButtonText: {
    fontWeight: 'bold',
  },
};

export default SPNListPageMediaItems;
