import React, { useState, useRef, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { AppContext } from './AppContext';
import { getFirestore, doc, query, where, limit, getDocs, getDoc, setDoc, updateDoc, onSnapshot, serverTimestamp, addDoc, collection } from 'firebase/firestore';
import { firestore } from './firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ProfileComponentMyProfile } from './ProfileComponentMyProfile';

const PostScreen = ({ onNavigate, onPost, onLive }) => {
  const [text, setText] = useState('');
  const [uploading, setUploading] = useState(false);
  const [tempImageUri, setTempImageUri] = useState(null);
  const { showMediaPopup, currentUser, posts, setPosts, setJustPosted } = useContext(AppContext);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [mediaUri, setMediaUri] = useState(null);
  const [mediaType, setMediaType] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [comment, setComment] = useState("");
  const [searchResultsComments, setSearchResultsComments] = useState([]);
  const [isSearchingComments, setIsSearchingComments] = useState(false);
  const [tags, setTags] = useState([]);
  const commentInputRef = useRef(null);
  const [selection, setSelection] = useState({ start: 0, end: 0 });

  useEffect(() => {
    const searchUsers = async (queryText) => {
      const lastAtPos = queryText.lastIndexOf("@");
      if (lastAtPos === -1) {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
        return;
      }
  
      const searchQuery = queryText.substring(lastAtPos + 1);
      if (searchQuery.length > 0) {
        setIsSearchingComments(true);
        const searchValue = searchQuery.toLowerCase();
        const usersRef = collection(firestore, 'users');
        const q = query(
          usersRef, 
          where('username_lower', '>=', searchValue),
          where('username_lower', '<=', searchValue + '\uf8ff'),
          limit(3)
        );
  
        try {
          const querySnapshot = await getDocs(q);
          const users = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          setSearchResultsComments(users);
        } catch (error) {
          console.error("Error searching users:", error);
          setSearchResultsComments([]);
        }
      } else {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
      }
    };
  
    if (text.includes("@")) {
      const delayDebounce = setTimeout(() => {
        searchUsers(text);
      }, 300);
  
      return () => clearTimeout(delayDebounce);
    }
  }, [text]);

  const handleSelectUser = (user) => {
    const cursorPosition = selection.start;
    const lastAtPos = text.lastIndexOf("@", cursorPosition);

    const beforeAt = text.substring(0, lastAtPos);
    const afterAt = text.substring(cursorPosition);

    const newComment = `${beforeAt}@${user.username} ${afterAt}`;
    setText(newComment);

    const newTag = {
      username: user.username,
      userId: user.id,
      start: beforeAt.length + 1,
      end: beforeAt.length + 1 + user.username.length
    };

    const updatedTags = [...tags.filter(tag => tag.end <= lastAtPos), newTag].sort((a, b) => a.start - b.start);
    setTags(updatedTags);

    setIsSearchingComments(false);
    setSearchResultsComments([]);

    const newCursorPos = beforeAt.length + user.username.length + 2;
    setSelection({ start: newCursorPos, end: newCursorPos });
  };

  const handleTextChange = (text) => {
    if (currentUser.username === "spndatnews" || text.length <= 1000) {
      setText(text);
    } else {
      setText(text.substring(0, 1000));
    }
  
    if (text.lastIndexOf("@") > (tags.length > 0 ? Math.max(...tags.map(tag => tag.end)) : -1)) {
      setIsSearchingComments(true);
    } else {
      setIsSearchingComments(false);
    }
  
    const adjustedTags = tags.map(tag => {
      if (text.substring(tag.start, tag.end) === `@${tag.username}`) {
        return tag; 
      } else {
        const startPos = text.indexOf(`@${tag.username}`);
        if (startPos !== -1) {
          return { ...tag, start: startPos, end: startPos + tag.username.length + 1 };
        }
      }
      return null; 
    }).filter(tag => tag !== null);
  
    setTags(adjustedTags);
  
  };

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const handlePost = async () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      alert('Please log in to post');
      return;
    }

    if (!text && !mediaUri) {
      alert('Your post must contain text or media');
      return;
    }

    setUploading(true);
    setUploadProgress(0);

    try {
      let downloadURL = null;
      if (mediaUri) {
        downloadURL = await uploadMedia(mediaUri, (progress) => {
          setUploadProgress(progress);
        });
      }

      const postData = {
        text,
        mediaType,
        accountType: currentUser.accountType,
        imageUri: downloadURL || '',
        tags,
      };

      const newPostData = {
        ...postData,
        userId: user.uid,
        username: currentUser.username,
        name: currentUser.name,
        profileImageUrl: currentUser.profileImageUrl,
        accountType: currentUser.accountType,
        createdAt: new Date(),
        likeCount: 0,
        repostCount: 0,
        likes: {},
        comments: [],
        repostUserIds: [],
      };

      setPosts(prevPosts => [...prevPosts, newPostData]);

      setText('');
      setMediaUri(null);
      setMediaType(null);
      setTempImageUri(null);
      setUploading(false);

      const functions = getFunctions();
      const addPostTest = httpsCallable(functions, 'addPostTest');
      addPostTest(postData).then(response => {
        // Handle response if needed
      }).catch(error => {
        console.error('Error creating post:', error);
        alert('Error creating post. Please try again later.');
      });
    } catch (error) {
      console.error('Error during the post creation process:', error);
      alert('Error creating post. Please try again later.');
      setUploading(false);
    }
  };

  const uploadMedia = async (uri, onProgress) => {
    if (!uri) return null;
    const storage = getStorage();
    const userId = getCurrentUserId();
    const storageRef = ref(storage, `posts/${userId}/${Date.now()}`);
    
    const response = await fetch(uri);
    const blob = await response.blob();

    const metadata = {
      contentType: blob.type || 'image/png', // Use the correct MIME type
    };

    const uploadTask = uploadBytesResumable(storageRef, blob, metadata);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
          
          switch (snapshot.state) {
            case 'paused':
              // Handle pause state if needed
              break;
            case 'running':
              // Handle running state if needed
              break;
          }
        },
        (error) => {
          console.error('Upload failed:', error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          }).catch((error) => {
            console.error('Error getting download URL:', error);
            reject(error);
          });
        }
      );
    });
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const handleMediaSelection = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType.startsWith('image/') || fileType.startsWith('video/')) {
        setMediaUri(URL.createObjectURL(file));
        setMediaType(fileType.startsWith('video') ? 'video' : 'image');
      } else {
        alert('Please select an image or video file.');
      }
    }
  };

  const postContainerStyle = {
    padding: '10px',
    backgroundColor: 'rgba(0,0,0,0.5)',
    borderRadius: '8px',
    border: '1px solid rgba(23, 195, 250, 0.5)',
    margin: '0 10px',
    marginTop: '35px',
    maxHeight: 'auto', // Allow the container to adjust its height
    overflow: 'hidden', // Handle any potential overflow
  };

  return (
    <div style={styles.scrollContainer}>
      <div style={styles.container}>
        <div style={styles.commentSectionContainer}>
          {isSearchingComments && (
            <div style={styles.userSuggestionContainer}>
              {searchResultsComments.map((user) => (
                <div
                  key={user.id}
                  onClick={() => handleSelectUser(user)}
                  style={styles.userSuggestion}
                >
                  {user.username}
                </div>
              ))}
            </div>
          )}

          <div style={postContainerStyle}>
            <ProfileComponentMyProfile mediaUri={mediaUri} />

            <div style={styles.addCommentContainer}>
              <textarea
                ref={commentInputRef}
                placeholder="What's happening?"
                value={text}
                onChange={(e) => handleTextChange(e.target.value)}
                onSelect={(e) =>
                  setSelection({
                    start: e.target.selectionStart,
                    end: e.target.selectionEnd,
                  })
                }
                style={styles.textInput}
                maxLength={1000}
              />
              <div style={styles.characterCount}>{text.length}/1000</div>
            </div>

            {mediaUri && (
              <div onClick={toggleModal} style={styles.previewContainer}>
                {mediaType === 'video' ? (
                  <video
                    src={mediaUri}
                    style={styles.mediaPreview}
                    controls
                    alt="Video Preview"
                  />
                ) : (
                  <img
                    src={mediaUri}
                    style={styles.mediaPreview}
                    alt="Image Preview"
                  />
                )}
              </div>
            )}
          </div>
        </div>

        <div style={styles.buttonsRow}>
          <div style={styles.buttonContainer}>
            <label htmlFor="fileInput" style={styles.button}>
              ADD MEDIA
            </label>
            <input
              type="file"
              style={styles.fileInput}
              onChange={handleMediaSelection}
              id="fileInput"
              accept="image/*,video/*"
            />
            <div style={styles.limitText}>Video Limit: 60s</div>
          </div>

          <button
            style={styles.button}
            onClick={handlePost}
            disabled={uploading}
          >
            POST DAT
          </button>

        </div>




        {modalVisible && (
          <div style={styles.modal}>
            <div style={styles.fullScreenContainer} onClick={toggleModal}>
              {mediaType === 'video' ? (
                <video
                  src={mediaUri}
                  style={styles.fullScreenMedia}
                  controls
                  autoPlay
                  loop
                  alt="Full Screen Video Preview"
                />
              ) : (
                <img
                  src={mediaUri}
                  style={styles.fullScreenMedia}
                  alt="Full Screen Image Preview"
                />
              )}
            </div>
          </div>
        )}

        {uploading && (
          <div style={styles.progressBarContainer}>
            <div
              style={{ ...styles.progressBar, width: `${uploadProgress}%` }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const styles = {
  scrollContainer: {
    height: '100vh', // Full viewport height
    paddingBottom: '500px',
    overflowY: 'scroll', // Enable vertical scrolling
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align items at the top
    paddingTop: '70px',
    paddingBottom: '20px', // Optional: Add some padding at the bottom
    // Remove overflow from here
  },
  
  commentSectionContainer: {
    width: '100%',
    marginBottom: '2rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  userSuggestionContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    padding: '5px 10px',
    borderRadius: '5px',
    marginTop: '10px',
  },
  userSuggestion: {
    color: '#FFFFFF',
    padding: '10px',
    fontSize: '1.6rem',
    borderWidth: '1px',
    borderColor: '#CCCCCC',
    borderStyle: 'solid',
    borderRadius: '5px',
    margin: '2px 0',
    cursor: 'pointer',
  },
  addCommentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  textInput: {
    backgroundColor: 'transparent',
    padding: '20px 20px',
    width: '420px',
    fontSize: '15px',
    color: 'white',
    border: 'none',
    resize: 'none',
    marginBottom: '10px',
    textAlign: 'left',
    alignSelf: 'center',
    borderRadius: '20px',
    outline: 'none', // Removes default browser outline on focus
  },
  characterCount: {
    color: 'white',
    textAlign: 'right',
    padding: '10px',
    fontSize: '10px',
    width: '80%',
    alignSelf: 'center',
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center', // Center the buttons horizontally
    alignItems: 'center',     // Align items vertically at the center
    width: '100%',
    marginTop: '-20px', // Add margin instead of positioning
  },
  
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column', // Stack the button and text vertically
    alignItems: 'center',
    margin: '0 10px',
    marginTop: '24px',
  },
  fileInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
  },
  fileInput: {
    display: 'none',
  },
  customFileInputButton: {
    padding: '10px 20px',

    color: '#62fbfa',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    fontSize: '20px',
    marginBottom: '5px',
  },
  limitText: {
    color: 'white',
    fontSize: '13.5px',
    textAlign: 'center',
  },
  button: {
    padding: '10px 20px',
    color: '#62fbfa',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    fontSize: '20px',
    marginBottom: '5px',
  },
  previewContainer: {
    cursor: 'pointer',
    marginBottom: '10px',
    textAlign: 'center',
    width: '100%', // Ensure it takes full width of the container
  },
  mediaPreview: {
    maxWidth: '100%', // Ensure the media doesn't exceed the container's width
    maxHeight: '200px', // Set a reasonable max height
    width: 'auto',
    height: 'auto',
    marginBottom: '10px',
    borderRadius: '10px',
    objectFit: 'contain',
  },
  
  progressBarContainer: {
    width: '80%',
    backgroundColor: '#ccc',
    marginTop: '10px',
    height: '10px',
    alignSelf: 'center',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#6200EE',
  },
  fullScreenContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  fullScreenMedia: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
  modal: {
    display: 'block',
  },
};

export default PostScreen;
