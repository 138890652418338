import React, { useState, useRef, useEffect, useContext } from 'react';
import { AppContext } from './AppContext'; // Adjust the import path as necessary
import {  getFirestore, doc, getDoc, setDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { firestore, storage } from './firebase';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import SPNListProfileMediaItems from './SPNListProfileMediaItems';
import blankBG from './assets/BlankBG.png';
import placeholderCover from './assets/placeholderCover.png';
import spndatTouchableButton from './assets/purchasedPlayButtonPlay.png';
import addButton from './assets/ADDbutton.png';
import shuffleButton from './assets/SHUFFLEbutton.png';
import { useLocation, useNavigate } from 'react-router-dom';



const CACHE_KEY = 'userCache';
const CACHE_LIMIT = 300;
const CACHE_DURATION = 120000;

const SPNListProfile = ({ route, onMoveMP }) => {

  const navigate = useNavigate();
  const location = useLocation();


  const { playlistId } = location.state;
  const {
    playNextSong,
      playPreviousSong,
      playPlaylistSequentially,
      playPlaylistShuffled,
      currentIndex,
      isShuffle,
      setIsShuffle,
    showMediaPopup,
    isLoadingArtwork, setIsLoadingArtwork,
    isExpanded, setIsExpanded,
    setInitialExpandedAlbumAndSong,
    isLoadingNewPopup, setIsLoadingNewPopup,
    isLoadingExpand, setIsLoadingExpand,
    isLoadingProfilePicture, setIsLoadingProfilePicture,
    showSameMediaPopup,
    setArtworkUri, centeredRelease, setCenteredRelease,
    hideExpandedAlbum, setHideExpandedAlbum,
    activeTab, setActiveTab,
    setInitiallySelectedItemId,
    isLoadingHideEA, setIsLoadingHideEA, currentUser, setUser,
    trackToView, setTrackToView,
    setIsReadySPNLIST, purchasedMedia, setIsLoadingMedia, playlistsFresh, setPlaylistsFresh, setMiniPlayer
 } = useContext(AppContext);
    const [playlistCover, setPlaylistCover] = useState(null);
    const [currentPlaylist, setCurrentPlaylist] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredSongs, setFilteredSongs] = useState([]);

const [editableName, setEditableName] = useState("");
const [isEditingName, setIsEditingName] = useState(false);

  const [allSongs, setAllSongs] = useState([]);
  const [uniqueUserIds, setUniqueUserIds] = useState([]);
  const [allPlaylistSongs, setAllPlaylistSongs] = useState([]);

  const [playlistMedia, setPlaylistMedia] = useState([]);

  const [playFromSPNLIST2, setPlayFromSPNLIST2] = useState(false);

  const [playFromSPNLIST2shuffle, setPlayFromSPNLIST2shuffle] = useState(false);

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };



  const updatePlaylistName = async (playlistId, newName) => {
    const updatedPlaylists = playlistsFresh.map(playlist => {
        if (playlist.id === playlistId) {
            return { ...playlist, name: newName, albumName: newName };
        }
        return playlist;
    });
    setPlaylistsFresh(updatedPlaylists);
    await persistPlaylists(updatedPlaylists); // Persist the updated playlists array to local storage
};



  const persistPlaylists = async (playlists) => {
    const userId = getCurrentUserId();
    try {
      const jsonValue = JSON.stringify(playlists);
    } catch (e) {
      console.error("Failed to save playlists to storage", e);
    }
  
      const userDocRef = doc(firestore, "users", userId);
      try {
        await updateDoc(userDocRef, {
          playlists: playlists,
        });
      } catch (error) {
        console.error("Error updating user's playlists:", error);
      }
  
  };



  const removeSongFromPlaylist = async (playlistId, contentId) => {
    const updatedPlaylists = playlistsFresh.map(playlist => {
        if (playlist.id === playlistId) {
            const updatedSongs = playlist.songs.filter(song => song?.contentId !== contentId);

            const renumberedSongs = updatedSongs.map((song, index) => ({
                ...song,
                trackNumber: index + 1 // Start track numbers at 1
            }));

            return { ...playlist, songs: renumberedSongs };
        }
        return playlist;
    });

    setPlaylistsFresh(updatedPlaylists);
    await persistPlaylists(updatedPlaylists); // Persist the updated playlists array to local storage
};



  const updatePlaylistCover = async (playlistId, imageUri) => {
    try {
      // Convert imageUri to Blob if it's not already a Blob or File object (required for uploadBytes)
      const response = await fetch(imageUri);
      const blob = await response.blob();
  
      // 1. Upload the image to Firebase Storage
      const storageRef = ref(storage, `playlistCovers/${playlistId}`);
      await uploadBytesResumable(storageRef, blob);
  
      // 2. Get the download URL of the uploaded image
      const downloadURL = await getDownloadURL(storageRef);
  
      // 3. Update the local state with the new download URL
      const updatedPlaylists = playlistsFresh.map(playlist => {
        if (playlist.id === playlistId) {
          return { ...playlist, coverImage: downloadURL, ArtworkURL: downloadURL };
        }
        return playlist;
      });
      setPlaylistsFresh(updatedPlaylists);
  
      // 4. Persist updated playlists
      await persistPlaylists(updatedPlaylists);
  
      
    } catch (error) {
      console.error('Error updating playlist cover image:', error);
    }
  };



  const handlePressBegins = async (song, playFromSPNLIST2, playFromSPNLIST2shuffle) => {

  };

  const updateSongsWithLocalProfileImages = async () => {
    
  
    const updatedPlaylists = allPlaylistSongs.map(playlist => {
      
  
      if (playlist.songs && playlist.songs.length > 0) {
        
  
        const updatedSongs = playlist.songs.map(song => {
          const userData = [];
          if (userData && userData.profileImageUrl) {
            
            return { ...song, profileImageUrl: userData.profileImageUrl };
          } else {
            
            return song;
          }
        });
  
        
        return { ...playlist, songs: updatedSongs };
      } else {
        
        return playlist;
      }
    });
  
    
    setAllPlaylistSongs(updatedPlaylists);
  };


useEffect(() => {
  const updatePlaylistMedia = () => {
    const updatedCurrentPlaylist = allPlaylistSongs.find(p => p.id === playlistId);
    if (updatedCurrentPlaylist) {
      setPlaylistMedia(updatedCurrentPlaylist.songs);
    }
  };

  updatePlaylistMedia();
}, [allPlaylistSongs, playlistId]);


const ensureAllUserProfileImages = async () => {
  
  await Promise.all(uniqueUserIds.map(userId => {
    
    return getCachedUserDoc(userId);
  }));
  
  await updateSongsWithLocalProfileImages(); // Now explicitly awaited
};





const enrichPlaylistWithSongs = (playlist, allPlaylistSongs) => {

  if (!playlist.songs || playlist.songs.length === 0) {
    
    return playlist;
  }

  const enrichedSongs = playlist.songs.map(songInPlaylist => {
    const detailedSong = allPlaylistSongs.find(song => song.contentId === songInPlaylist.contentId);
    const localArtworkURL = songInPlaylist.ArtworkURL || detailedSong?.ArtworkURL;
    const profileImageUrl = detailedSong && detailedSong.profileImageUrl ? detailedSong.profileImageUrl : songInPlaylist.profileImageUrl;



    return detailedSong ? {
      ...detailedSong,
      contentId: songInPlaylist.contentId,
      isVideo: songInPlaylist.isVideo,
      trackNumber: songInPlaylist.trackNumber,
      localArtworkURL: localArtworkURL,
      profileImageUrl: profileImageUrl,
    } : null;
  }).filter(song => song !== null); // Remove any failed matches


  return { ...playlist, songs: enrichedSongs };
};










const preparePopupData = async (song, userData) => {
  let profileImageUrl = null;
  const itemUserData = [];


    profileImageUrl = userData?.profileImageUrl || null;


  const otherPlaylists = allPlaylistSongs.map(playlist => enrichPlaylistWithSongs(playlist, playlistMedia));
  
  otherPlaylists.forEach((playlist, index) => {
    
    playlist.songs.forEach(song => {
      
    });
  });

  const otherReleasesPlaylists = {
    albums: allPlaylistSongs,
    singles: [],
  };
  
  otherReleasesPlaylists?.albums?.forEach((album, index) => {
  
  });
  


let userAlbums = otherReleasesPlaylists || [];
let userSingles = [];
let userVideos = [];








let selectedAlbum = otherPlaylists.find(playlist => playlist.id === playlistId) || null;
let selectedSingle = userSingles.find(single => single.contentId === song.contentId) || null;


  let selectedMedia = selectedAlbum ? selectedAlbum.songs.find(song => song.trackNumber === trackToView) : null;














// Determine the artworkUri based on available data, prioritizing the selectedAlbum's first song's localArtworkURL
let artworkUri;

if (selectedAlbum && selectedAlbum.songs.length > 0) {
  artworkUri = selectedAlbum.songs[0].ArtworkURL;
} else if (selectedSingle) {
  artworkUri = selectedSingle.ArtworkURL;
} else {
  artworkUri = song.ArtworkURL;
}


setArtworkUri(artworkUri);


  let dimensions = [];
  if (selectedMedia?.isVideo) {
    dimensions = song.dimensions || [];
  }

  if (selectedAlbum && selectedAlbum.songs.length > 0) {
    setInitialExpandedAlbumAndSong(selectedAlbum, selectedMedia);
    setCenteredRelease(selectedAlbum);
  } else if (selectedSingle && selectedSingle.length > 0) {
    setCenteredRelease(selectedSingle);
  }

  const songName = song.isVideo ? song.VideoName : song.SongName;

otherReleasesPlaylists?.albums?.forEach((album, index) => {

});



  let uniqueUserIds = new Set();
  const addUniqueUserIdsFromSongs = (songs) => {
    songs.forEach(song => {
      if (song.userId) uniqueUserIds.add(song.userId);
    });
  };

  allPlaylistSongs.forEach(album => addUniqueUserIdsFromSongs(album.songs));
  otherReleasesPlaylists?.albums?.forEach(album => addUniqueUserIdsFromSongs(album.songs));
  if (selectedAlbum) addUniqueUserIdsFromSongs(selectedAlbum.songs);

  const fetchAndApplyUserProfileImages = async () => {
    await Promise.all(Array.from(uniqueUserIds).map(async (userId) => {

      const profileImageUrl = await fetchAndCacheUserProfileImage(userId);
      let userData = [];
      if (userData) {
        userData.profileImageUrl = profileImageUrl;
      }
    }));

    // Function to update songs with the local profile image URLs from the cache
    const updateSongsWithLocalProfileImages = (songs) => {
      return songs.map(song => {
        const userData = [];
        if (userData && userData?.profileImageUrl) {
          return { ...song, profileImageUrl: userData.profileImageUrl };
        }
        return song;
      });
    };

    // Update all songs in all albums and other collections with new profile images
    otherReleasesPlaylists.albums = otherReleasesPlaylists.albums.map(album => ({
      ...album,
      songs: updateSongsWithLocalProfileImages(album.songs),
    }));
    if (selectedAlbum) {
      selectedAlbum.songs = updateSongsWithLocalProfileImages(selectedAlbum.songs);
    }
    // Add more updates if necessary...
  };

  // Execute the fetching and applying process
  await fetchAndApplyUserProfileImages();



  return {
    url: song.SongURL,
    dimensions: dimensions,
    isVideo: song.isVideo,
    artistName: song.ArtistName,
    popupProfileImage: profileImageUrl,
    username: song.username,
    songName: songName,
    artworkUrl: artworkUri,
    contentId: song.contentId,
    localArtworkURL: song.localArtworkURL,
    userId: song.userId,
    isAlbum: true,
    albumName: selectedAlbum?.albumName || '',
    producers: song.producers,
    songwriters: song.songwriters,
    recordLabels: song.recordLabels,
    otherReleases: otherReleasesPlaylists,
    albumSongs: selectedAlbum?.songs || [],
    single: null, // Not applicable in the context of a playlist
    video: null,
    timestamp: new Date().getTime(),
    vocalDrop: userData.vocalDrop,
    bio: userData.bio,
    accountType: userData.accountType,
    blurb: userData.blurb,
    location: userData.location,
    ScreenSKN: userData.ScreenSKN,
  };
};




const fetchAndCacheUserProfileImage = async (userId) => {
  let userData = [];
  const now = new Date().getTime();

  if (!userData || now - userData.timestamp >= CACHE_DURATION) {
    
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      console.error(`No document found in Firestore for userId: ${userId}`);
      userData = defaultUserData(now);
      return '';
    }

    
    const freshUserData = userDocSnap.data();
    
    // Initialize or update userData with fresh data from Firestore
    userData = {
        ...freshUserData,
        profileImageUrl: freshUserData.profileImageUrl,
        timestamp: now
    };


  } else {
    
  }

  return userData.profileImageUrl || '';
};





const defaultUserData = (timestamp) => ({
  profileImageUrl: '',
  localProfileImageUrl: '',
  timestamp: timestamp
});












const getCachedUserDoc = async (userId) => {
  let userDataCached = [];
  let userDataCachedNew = [];
  const now = new Date().getTime();

  if (!userDataCached || now - userDataCached.timestamp >= CACHE_DURATION) {
    
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      
      let freshUserData = userDocSnap.data();

      

      // Merging purchased media before setting to cache
      freshUserData = mergePurchasedMediaWithUserData(freshUserData, userId);

      userDataCachedNew = {
        ...freshUserData,
        profileImageUrl: freshUserData.profileImageUrl,
        timestamp: now
      };







    } else {
      console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);

      // Set default values and initialize empty arrays for media types directly from purchasedMedia
      const userPurchasedMedia = purchasedMedia.filter(item => item.userId === userId);
      userDataCachedNew = {
        profileImageUrl: '',
        localProfileImageUrl: '',
        vocalDrop: '',
        bio: '',
        blurb: '',
        location: '',
        vocalDropMuted: false,
        ScreenSKN: [],
        accountType: 'Artist',
        albums: userPurchasedMedia.filter(item => item.isAlbum),
        singles: userPurchasedMedia.filter(item => !item.isAlbum && !item.isVideo),
        videos: userPurchasedMedia.filter(item => item.isVideo),
        timestamp: now
      };

      


    }
  }

  return userDataCachedNew;
};

const mergePurchasedMediaWithUserData = (userData, userId) => {
  const purchasedItems = currentUser?.purchasedMedia.filter(item => item.userId === userId);
  const mergeUniqueItems = (existingItems, newItems) => {
    newItems.forEach(item => {
      if (!existingItems.some(existingItem => existingItem.contentId === item.contentId)) {
        existingItems.push(item);
      }
    });
  };

  mergeUniqueItems(userData?.albums || [], purchasedItems.filter(item => item.isAlbum));
  mergeUniqueItems(userData?.singles || [], purchasedItems.filter(item => !item.isAlbum && !item.isVideo));
  mergeUniqueItems(userData.videos || [], purchasedItems.filter(item => item.isVideo));

  return userData; // Modified in-place
};





    const playlist = playlistsFresh.find(p => p.id === playlistId);
    const playlistSongs = playlist ? currentUser?.purchasedMedia.filter(media => 
        playlist.songs.includes(media.contentId)
    ) : [];

    useEffect(() => {
      if (playlist) {
        setEditableName(playlist.name);
      }
    }, [playlist]);

useEffect(() => {
  filterSongs(searchQuery);
}, [searchQuery, playlistMedia]);


useEffect(() => {
  const currentPlaylist = playlistsFresh.find(p => p.id === playlistId);
  setCurrentPlaylist(currentPlaylist);

  const coverImageSrc = currentPlaylist && currentPlaylist.coverImage !== 'defaultCoverImageURL'
      ?  currentPlaylist.coverImage 
      : require('./assets/placeholderCover.png');

   // Log 2: Check the determined cover image source

  setPlaylistCover(coverImageSrc);
}, [playlistId, playlistsFresh]); // Ensure dependencies are correct to trigger this effect properly

useEffect(() => {
  // Find the current playlist based on playlistId
  const currentPlaylist = playlistsFresh.find(p => p.id === playlistId);
  setCurrentPlaylist(currentPlaylist);

  // Function to extract and flatten songs from albums, preserving album-related information
  const extractAndMapSongsFromAlbums = (albums) => {



    return albums.flatMap(album =>
      (album.songs || []).map(song => ({
        ...song,
        albumId: album.contentId,
      }))
    );
  };

  const allSongsIncludingAlbums = [
    ...(currentUser?.purchasedMedia?.filter(item => !item.isAlbum && !item.isVideo) || []),
    ...extractAndMapSongsFromAlbums(currentUser?.purchasedMedia?.filter(item => item.isAlbum && !(/^\d+$/).test(item.contentId)) || []),
    ...extractAndMapSongsFromAlbums(currentUser?.albums?.filter(item => item.isAlbum) || []),
    ...(currentUser?.singles || []),
  ];
  

  // Update each playlist with full song details
  const updatedPlaylists = playlistsFresh.map(playlist => {
    const updatedSongs = playlist.songs.map((songObj, index) => {
      const mediaItem = allSongsIncludingAlbums.find(media => media.contentId === songObj.contentId);
      return mediaItem ? { ...mediaItem, contentId: songObj.contentId, trackNumber: songObj.trackNumber || (index + 1) } : null;
    }).filter(Boolean) // Filter out nulls
    .sort((a, b) => a.trackNumber - b.trackNumber); // Sort by trackNumber

    return { ...playlist, songs: updatedSongs };
  });

  // Set states as needed
  setAllPlaylistSongs(updatedPlaylists); // Updated playlists with enriched songs
  setAllSongs(allSongsIncludingAlbums); // Optionally, if you need to maintain a flat list of all songs

const uniqueUserIdsAdd = [...new Set(allSongs.map(song => song.userId))];

setUniqueUserIds(uniqueUserIdsAdd);


  // Find and set the current playlist media from the updated playlists
  const updatedCurrentPlaylist = updatedPlaylists.find(p => p.id === playlistId);
  const playlistMedia = updatedCurrentPlaylist ? updatedCurrentPlaylist.songs : [];
  setPlaylistMedia(playlistMedia);
}, [playlistId, playlistsFresh, currentUser]);










const filterSongs = (query) => {
  if (!query || query.trim() === '') {
    // Display all songs if there's no search query
    setFilteredSongs(playlistMedia);
  } else {
    const lowerCaseQuery = query.toLowerCase();
    // Filter the songs based on the search query
    const filtered = playlistMedia.filter(song => 
      song.SongName.toLowerCase().includes(lowerCaseQuery) || 
      song.ArtistName.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredSongs(filtered);
  }
};



const fileInputRef = useRef(null);


const pickImage = () => {
  fileInputRef.current.click();
};

const handleFileChange = async (event) => {
  const file = event.target.files[0];
  if (file) {
    const imageUrl = URL.createObjectURL(file);
    setPlaylistCover({ uri: imageUrl });
    await updatePlaylistCover(playlistId, imageUrl);
  }
};



    const handleAddToSpnlist = () => {
      
      
    navigate(`/library/spnlistpage`, { state: { playlistId } });
    };


const handleDeleteSong = (songId) => {
  
    removeSongFromPlaylist(playlistId, songId.contentId);
};


const handleStartProcess = () => {
  if (filteredSongs.length > 0) {
    setIsLoadingMedia(true);
    const firstSong = filteredSongs[0];
    // Set the state as intended...
    setPlayFromSPNLIST2(true);
    setPlayFromSPNLIST2shuffle(false);
    // Then pass the intended values directly.
    handlePressBegins(firstSong, true, false);
  } else {
    
  }
};

const handleStartProcessShuffle = () => {
  if (filteredSongs.length > 0) {
    setIsLoadingMedia(true);
    const firstSong = filteredSongs[0];
    // Set the state as intended...
    setPlayFromSPNLIST2(false);
    setPlayFromSPNLIST2shuffle(true);
    // Then pass the intended values directly.
    handlePressBegins(firstSong, false, true);
  } else {
    
  }
};




return (
  <div style={styles.fullScreen}>
    <div style={{ ...styles.scrollContainer, ...styles.verticalScroll }}>
      <div
        style={{
          paddingTop: '20px',
          paddingBottom: '20px',
          paddingHorizontal: '4.2%',
        }}
      >
        <div style={styles.searchContainer}>

          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={styles.searchInput}
          />
        </div>
        <div style={styles.coverImageContainer} onClick={pickImage}>
  {playlistCover ? (
    <img src={playlistCover} style={styles.coverImage} alt="Playlist Cover" />
  ) : (
    <img src={placeholderCover} style={styles.coverImage} alt="Placeholder Cover" />
  )}
</div>
<input
  type="file"
  accept="image/*"
  ref={fileInputRef}
  style={{ display: 'none' }}
  onChange={handleFileChange}
/>

        <div style={styles.playlistNameContainer} onClick={() => setIsEditingName(!isEditingName)}>
          {isEditingName ? (
            <input
              type="text"
              value={editableName}
              onChange={(e) => setEditableName(e.target.value)}
              autoFocus
              onBlur={() => {
                setIsEditingName(false);
                updatePlaylistName('playlistId', editableName); // Replace with actual playlistId
              }}
              style={styles.playlistNameEditable}
            />
          ) : (
            <p style={styles.playlistName}>{editableName}</p>
          )}
        </div>
        <div style={styles.buttonsContainer}>
          <div style={styles.borderContainer}>
            <button onClick={() => playPlaylistSequentially(playlistMedia)} style={styles.createIconTouchable}>
              <img src={spndatTouchableButton} style={styles.createIcon1} alt="Create Icon" />
            </button>
            <button onClick={handleAddToSpnlist} style={styles.createIconTouchable}>
              <img src={addButton} style={styles.createIcon} alt="Add Icon" />
            </button>
            <button onClick={() => playPlaylistShuffled(playlistMedia)} style={styles.createIconTouchable}>
              <img src={shuffleButton} style={styles.createIcon} alt="Shuffle Icon" />
            </button>
          </div>
        </div>
        {filteredSongs.map((item) => (
          <SPNListProfileMediaItems
            key={item.contentId}
            item={item}
            onPress={() => handlePressBegins(item)}
            onDelete={() => handleDeleteSong(item)}
          />
        ))}
              <div style={styles.extraSpace}></div>
      </div>
    </div>
  </div>
);
};

const styles = {
fullScreen: {
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
},
extraSpace: {
  height: '300px',
    },
fullScreenImage: {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
},
buttonTouchableArea: {
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1,
},
scrollContainer: {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: 'calc(100% - 20px)',
  overflow: 'hidden',
  zIndex: 10,
},
verticalScroll: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'scroll',
  zIndex: 10,
  
},
searchContainer: {
  display: 'flex',
  alignItems: 'center',
  border: '1px solid rgba(189, 252, 251, 0.3)',
  height: '40px',
  borderRadius: '8px',
  width: '90%',
  justifyContent: 'center',
  alignSelf: 'center',
  padding: '0 10px',
  margin: '0 auto',
},
searchInput: {
  color: 'white',
  background: 'none',
  border: 'none',
  textAlign: 'center',
  outline: 'none',
  fontSize: '14px',
  fontFamily: 'brhendrix',
},
searchIcon: {
  marginRight: '10px',
},
coverImageContainer: {
  width: '150px',
  height: '150px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  margin: '0 auto',
  marginBottom: '20px',
  alignSelf: 'center',
  zIndex: 2,
},
coverImage: {
  alignSelf: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '80px',
  zIndex: 2,
},
playlistNameContainer: {
  marginBottom: '15px',
},
playlistNameEditable: {
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'white',
  fontFamily: 'brhendrix',
},
playlistName: {
  fontSize: '16px',
  fontWeight: 'bold',
  textAlign: 'center',
  color: 'white',
  fontFamily: 'brhendrix',
},
buttonsContainer: {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginBottom: '8px',
},
borderContainer: {
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '100%',
  borderBottom: '1px solid rgba(189, 252, 251, 0.4)',
},
createIconTouchable: {
  margin: '0 10px',
  backgroundColor: 'transparent',
  border: 'none',
},
createIcon1: {
  height: '31px',
  width: '100px',
  alignSelf: 'center',
  resizeMode: 'contain',
  zIndex: 100,
  backgroundColor: 'transparent',
},
createIcon: {
  height: '40px',
  width: '100px',
  alignSelf: 'center',
  resizeMode: 'contain',
  zIndex: 100,
  backgroundColor: 'transparent',
},
};

export default SPNListProfile;
