import React, { useState, useContext } from 'react';
import { AppContext } from './AppContext';
import defaultProfilePic from './assets/defaultProfilePic.png';
import ConversationBG from './assets/ConversationBG.png';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';

const ProfileComponentMessages = ({ onPress, userProfile }) => {
  const { isLoadingProfilePicture, userId2, profileImageUrl } = useContext(AppContext);
  const [contentWidth, setContentWidth] = useState(0); // State to store dynamic content width

  const profileImageWidth = 100; // Set your desired width in px
  const padding = 20; // Set your desired padding in px

  // Measure the width of the text and icon container
  const onTextLayout = (event) => {
    const textWidth = event.target.offsetWidth;
    const totalWidth = profileImageWidth + textWidth + padding; // Calculate total width
    setContentWidth(totalWidth); // Update state with calculated width
  };

  if (isLoadingProfilePicture) {
    return (
      <div style={styles.loaderContainer}>
        <div style={styles.activityIndicator}>Loading...</div>
      </div>
    );
  }

  const profileImageSource = (userProfile.userId === userId2 && profileImageUrl)
    ? profileImageUrl // Use the profile image URL from context if IDs match
    : userProfile?.profileImageUrl
      ? userProfile.profileImageUrl 
      : defaultProfilePic;

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(userProfile?.username);

  const formatUsername = username => {
    if (!username) return '@'; // Default to '@' if username is undefined or null
    if (username[0] !== '@') return '@' + username;
    return username;
  };

  return (
    <>
      <div style={styles.fullScreenImageContainer}>
        <img src={ConversationBG} alt="Background" style={styles.fullScreenImage} />
      </div>
      <div onClick={onPress} style={{ ...styles.container, width: contentWidth || 'auto' }}>
        <div style={styles.profileViewStyle}>
          <div style={styles.imageStack}>
            <img src={profileImageSource} alt="Profile" style={styles.circularImage} />
          </div>
          <div onLayout={onTextLayout} style={styles.textContainer}>
            <div style={styles.artistName}>{userProfile.name}</div>
            <div style={styles.textWithIconContainer}>
              <div style={styles.username}>{formatUsername(userProfile?.username)}</div>
              {userProfile?.accountType !== 'Fan' && !isArtistIcon2Visible && (
                <img src={ArtistIcon1} alt="Artist Icon 1" style={styles.profilePicIcon} />
              )}
              {userProfile?.accountType !== 'Fan' && isArtistIcon2Visible && (
                <img src={ArtistIcon2} alt="Artist Icon 2" style={styles.profilePicIcon} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px', // Adjust padding as necessary
    height: '80px', // Adjust height as necessary
    cursor: 'pointer',
  },
  profileViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '80px', // Adjust margin to bring content closer to the left edge
  },
  imageStack: {
    width: '50px', // Adjust the width of the image
    height: '50px', // Adjust the height of the image
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  circularImage: {
    width: '100%', // Adjust to fit the container
    height: '100%', // Adjust to fit the container
    borderRadius: '50%',
    objectFit: 'cover',
  },
  textContainer: {
    marginLeft: '10px', // Adjust margin to bring text closer to the image
    justifyContent: 'center',
  },
  artistName: {
    color: 'white',
    fontSize: '16px', // Adjust font size
    fontWeight: '400',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
  textWithIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {
    color: '#62fbfa',
  },
  username: {
    color: '#62fbfa',
    fontSize: '14px', // Adjust font size
    opacity: 0.8,
    fontWeight: '400',
    fontFamily: 'brhendrix',
    marginRight: '2px',
  },
  profilePicIcon: {
    width: '20px', // Adjust icon size
    height: '20px', // Adjust icon size
    objectFit: 'contain',
  },
  fullScreenImageContainer: {
    width: '110%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: -18,
    zIndex: -1,
  },
  fullScreenImage: {
    width: '104%',
    height: '100%',
    position: 'absolute',
    alignSelf: 'center',
    objectFit: 'contain',
  },
};

export { ProfileComponentMessages };
