import React, { useContext } from 'react';
import { AppContext } from './AppContext';
import { useNavigate } from 'react-router-dom';
import Dot from './assets/dot.png';
import { getAuth, signOut } from 'firebase/auth';

const bottomAreaHeight = '18vh';
const horizontalAreaSpacing = '1.5vh';
const scrollAreaTopPadding = '18vh';
const imageSize = '2vh';







const CategoriesScreen2 = ({ onLogout }) => {
  const { userId2, showMediaPopup, following, setCurrentlyLoadingProfile, currentUser, setViewingProfile, setActivePopupMedia, activePopupMedia } = useContext(AppContext);
  const navigate = useNavigate();

  const specialUserIds = [
    "h0fEIStkM4XHTDErZM5i8XIo9rA3",
    "OHaY20b54nTGd5BBgbJOL2BHEbX2",
    "rdX289s3YBP31Hw5qn2eXVywtBq1",
    "Mkf4631LCEOYmHXWRzGirPdxBrt1"
  ];
  
  const categories = specialUserIds.includes(userId2) ? [
    { id: 'Profile', title: 'PROFILE' },
    { id: 'MyContent', title: 'MY CONTENT' },
    { id: 'Submissions', title: 'SUBMISSIONS' },
    { id: 'Followers', title: 'FOLLOWERS' },
    { id: 'Settings', title: 'SETTINGS' },
    { id: 'Earnings', title: 'EARNINGS' },
    { id: 'Analytics', title: 'ANALYTICS' },
    { id: 'SMP', title: 'SMP' },
    { id: 'Logout', title: 'LOGOUT' },
  ] : [
    { id: 'Profile', title: 'PROFILE' },
    { id: 'MyContent', title: 'MY CONTENT' },
    { id: 'Followers', title: 'FOLLOWERS' },
    { id: 'Settings', title: 'SETTINGS' },
    { id: 'Earnings', title: 'EARNINGS' },
    { id: 'Analytics', title: 'ANALYTICS' },
    { id: 'SMP', title: 'SMP' },
    { id: 'Logout', title: 'LOGOUT' },
  ];

  const filteredCategories = categories.filter(category => {
    // Always include these categories
    if (['Profile', 'Followers', 'Settings', 'Logout'].includes(category.id)) {
      return true;
    }
    // Include 'Submissions' if it's in the categories
    if (category.id === 'Submissions') {
      return true;
    }
    // Conditionally include the rest based on account type
    return currentUser?.accountType !== 'Fan' && ['MyContent', 'Earnings', 'Analytics', 'SMP'].includes(category.id);
  });

  const handlePress = (category) => {
     // Log the category being navigated to
    if (category === 'Logout') {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          
          // Optionally, you can navigate to a different path after signing out, e.g., a login screen
          // navigate('/login');
        })
        .catch((error) => {
          console.error("Error signing out:", error);
        });
    } else {
      const path = `/mydat/${category.toLowerCase()}`;
       // Log the navigation path
      navigate(path);
    }
  };
  
  
  
  

  return (
    <div style={styles.fullScreen}>
      <div style={styles.headerContainer}>
        <h2 style={styles.headerText}>MYDAT</h2>
      </div>
      <div style={styles.container}>
        <ul style={styles.scrollContainer}>
          {filteredCategories.map((item) => (
            <li key={item.id} style={styles.listItem}>
              <button onClick={() => handlePress(item.id)} style={styles.button}>
                <div style={styles.row}>
                  <img src={Dot} alt="Dot" style={{ width: imageSize, height: imageSize, marginRight: '1vw' }} />
                  <span style={styles.text}>{item.title}</span>
                </div>
              </button>
              <div style={styles.borderContainer}>
                <div style={styles.centeredBorder}></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
  
};

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 10,
    height: `calc(100vh - ${bottomAreaHeight})`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center', // Center the header horizontally
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '1vh',
    margin: '1vh 0',
    cursor: 'pointer',
    zIndex: 10,
    textAlign: 'left',
    width: '100%',
  },
  text: {
    color: 'white',
    fontSize: '2.4vh',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  scrollContainer: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    position: 'relative', // Changed from 'absolute' to 'relative'
    width: '100%',
    height: `135%`,
    zIndex: 2,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  borderContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: '0',
  },
  centeredBorder: {
    marginLeft: '18%',
    width: '110%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(189, 252, 251, 0.3)',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1vh 0', // Add padding to the header container
    width: '100%',
    backgroundColor: 'transparent', // Ensure the background is transparent
    zIndex: 10,
  },
  headerText: {
    color: 'white',
    fontSize: '2.5vh',
    fontWeight: 'bold',
    textAlign: 'center', // Center the text horizontally
    fontFamily: 'brhendrix',
  },
  listItem: {
    marginBottom: '1vh',
  },
};


export default CategoriesScreen2;
