import React, { useEffect, useRef, useContext } from 'react';
import { AppContext } from './AppContext';

const PlayVoiceDrop = () => {
  const { currentUser } = useContext(AppContext);
  const hasPlayedRef = useRef(false);
  const lastVocalDropUrl = useRef(null); // Ref to track the last played URL
  const lastMutedState = useRef(currentUser?.voiceDropMuted); // Ref to track the last muted state

  useEffect(() => {
    // Check if the currentUser and the vocalDrop URL are defined
    if (currentUser && currentUser.vocalDrop) {
      // Determine if muted has just been switched from true to false
      const justUnmuted = lastMutedState.current === true && !currentUser.voiceDropMuted;
      
      // Check if it's appropriate to reset the play state
      if (currentUser.voiceDropMuted) {
        // Reset hasPlayedRef to false when muted to allow replay when unmuted
        hasPlayedRef.current = false;
      } else if (justUnmuted || (currentUser.vocalDrop !== lastVocalDropUrl.current && !hasPlayedRef.current)) {
        // Play the audio only if it's just unmuted or if the vocalDrop URL changed and it hasn't played yet
        const audio = new Audio(currentUser.vocalDrop);
        lastVocalDropUrl.current = currentUser.vocalDrop; // Update the last played URL

        audio.play()
          .then(() => {
            // Set the ref to true after the audio has been played
            hasPlayedRef.current = true;
          })
          .catch(error => {
            console.error('Error playing the audio:', error);
          });
      }

      // Update the last muted state after handling the current state
      lastMutedState.current = currentUser.voiceDropMuted;
    }
  }, [currentUser]); // Add currentUser as a dependency to handle changes

  return null; // This component does not render anything
};

export default PlayVoiceDrop;
