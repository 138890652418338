import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import { getFirestore, runTransaction, addDoc, deleteDoc, setDoc, serverTimestamp, doc, getDoc, collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { firestore } from './firebase';

const CACHE_DURATION = 12;

const GenresScreen = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AppContext);

  const genresList = [
    { label: "Alternative", value: "alternative" },
    { label: "Blues", value: "blues" },
    { label: "Classical", value: "classical" },
    { label: "Country", value: "country" },
    { label: "Electronic/Dance", value: "electronic_dance" },
    { label: "Folk", value: "folk" },
    { label: "Rap", value: "hip_hop_rap" },
    { label: "Indie", value: "indie" },
    { label: "Jazz", value: "jazz" },
    { label: "Latin", value: "latin" },
    { label: "Metal", value: "metal" },
    { label: "Pop", value: "pop" },
    { label: "R&B/Soul", value: "rnb_soul" },
    { label: "Reggae", value: "reggae" },
    { label: "Rock", value: "rock" },
    { label: "World/International", value: "world_international" }
  ];

  const getCachedUserDoc = async (userId) => {
    let userDataCached = [];
    let userDataCachedNew = [];
    const now = new Date().getTime();
  
    if (!userDataCached || now - userDataCached.timestamp >= CACHE_DURATION) {
        
        const userDocRef = doc(firestore, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
            
            const freshUserData = userDocSnap.data();
  
            
            // Update userDataCached structure with fresh data and timestamp
            userDataCachedNew = {
                ...freshUserData, // overwrite with fresh data from Firestore
                profileImageUrl: freshUserData?.profileImageUrl,
                timestamp: now
            };
  
  
        } else {
            console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
        }
    } else {
        
        userDataCachedNew = userDataCached;
    }
  
    return userDataCachedNew;
  };

  const genreLabelMap = new Map(genresList.map(item => [item.value, item.label]));
  genreLabelMap.set("hip_hop", "Rap");

  const genres = useMemo(() => {
    const genreMap = new Map();

    currentUser?.purchasedMedia.forEach(item => {
      (item.genres || []).forEach(genre => {
        const baseGenrePart = genre.split(' -')[0];
        const normalizedBaseGenre = baseGenrePart.trim().toLowerCase();
        const effectiveGenre = normalizedBaseGenre === "hip_hop" ? "hip_hop_rap" : normalizedBaseGenre;
        const genreLabel = genreLabelMap.get(effectiveGenre) || effectiveGenre;

        if (!genreMap.has(effectiveGenre)) {
          genreMap.set(effectiveGenre, { items: [], label: genreLabel });
        }
        genreMap.get(effectiveGenre).items.push(item);
      });
    });

    return Array.from(genreMap, ([value, { label, items }]) => ({
      name: label,
      value,
      items
    })).sort((a, b) => a.name.localeCompare(b.name));
  }, [currentUser?.purchasedMedia]);

  const handleSelectGenre = (genreValue) => {
    navigate('/library/genres/genremedialist', { state: { genre: genreValue } });
  };
  

  return (
    <div style={styles.fullScreen}>
      <img src={require('./assets/BlankBG.png')} style={styles.fullScreenImage} alt="Background" />
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <h2 style={styles.headerText}>Genres</h2>
        </div>
        <ul style={styles.scrollContainer}>
          {genres.map((item, index) => (
            <li key={`genre-${index}`} style={styles.listItem}>
              <button onClick={() => handleSelectGenre(item.value)} style={styles.button}>
                <div style={styles.row}>
                  <img src={require('./assets/dot.png')} style={{ width: '2vh', height: '2vh', marginRight: '1vw' }} alt="dot" />
                  <span style={styles.text}>{item.name}</span>
                </div>
              </button>
              <div style={styles.borderContainer}>
                <div style={styles.centeredBorder}></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 10,
    height: `calc(100vh - 18vh)`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '130%',
    alignItems: 'center', // Center the header horizontally
    position: 'relative',
    overflow: 'hidden',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '1vh',
    margin: '1vh 0',
    cursor: 'pointer',
    zIndex: 10,
    textAlign: 'left',
    width: '100%',
  },
  text: {
    color: 'white',
    fontSize: '2.4vh',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  scrollContainer: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    position: 'relative', // Changed from 'absolute' to 'relative'
    width: '100%',
    height: `calc(100vh - 18vh)`,
    zIndex: 2,
    overflowY: 'auto',
  },
  borderContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: '0',
  },
  centeredBorder: {
    marginLeft: '18%',
    width: '110%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(189, 252, 251, 0.3)',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1vh 0', // Add padding to the header container
    width: '100%',
    backgroundColor: 'transparent', // Ensure the background is transparent
    zIndex: 10,
  },
  headerText: {
    color: 'white',
    fontSize: '2.5vh',
    fontWeight: 'bold',
    textAlign: 'center', // Center the text horizontally
    fontFamily: 'brhendrix',
  },
  listItem: {
    marginBottom: '1vh',
  },
};

export default GenresScreen;
