import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection, addDoc, Timestamp, updateDoc, setDoc, runTransaction, query, where, onSnapshot, getFirestore, orderBy, getDoc, doc, getDocs, serverTimestamp
} from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { firestore as db } from './firebase';
import { AppContext } from './AppContext';
import { RealTimeAgo } from './RealTimeAgo';
import { ProfileComponentPostsMessages } from './ProfileComponentPostsMessages';
import { DynamicHeightImage } from './DynamicHeightImage';
import { ProfileComponentShareMessages } from './ProfileComponentShareMessages';
import sendIcon from './assets/sendIcon.png';
import plusIcon from './assets/plusIcon.png';

const CACHE_DURATION = 120000;

const { width, height } = { width: window.innerWidth, height: window.innerHeight };

const ConversationComponent = ({  onBackPress, onMyProfile, profile, onMoveMP, onPostPress, onProfilePress }) => {

  const {
    isUnder480,
    likes, reposts,
    showMediaPopup,
    activePopupMedia, currentUser,
 setIsLoadingArtwork,
 setIsExpanded,
    setInitialExpandedAlbumAndSong,
setIsLoadingNewPopup,
setIsLoadingExpand,
setIsLoadingProfilePicture,
    setArtworkUri,  setCenteredRelease,
setHideExpandedAlbum,
setActiveTab,
    setInitiallySelectedItemId,
 setIsLoadingHideEA,
 setIsExpandedShare, setTrackToView,
 setCurrentlyLoadingProfile,
    setActivePopupMedia, selectedProfile, userId2, setShowMessageScreen, setCurrentView, followers, profileImageUrl
  } = useContext(AppContext);

  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [inputText, setInputText] = useState('');
  const [textInputHeight, setTextInputHeight] = useState(height * 0.05); // Initial height for single-line input
  const minHeight = height * 0.04;
  const auth = getAuth();
  const currentUserId = userId2;
  let unsubscribe = null;
  const flatListRef = useRef(null); // Ref for the FlatList component
  const [initialRender, setInitialRender] = useState(true); 

  const [modalVisible, setModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const unsubscribeRef = useRef([]);

  const messagesEndRef = useRef(null);

  const [mediaUri, setMediaUri] = useState(null);
  const [mediaType, setMediaType] = useState(null); // 'image' or 'video'

  const videoRefs = useRef({});

  const [locallyDeletedMessageIds, setLocallyDeletedMessageIds] = useState([]);

  const conversationId = [currentUserId, selectedProfile?.userId].sort().join('_');

  const [prevMessageCount, setPrevMessageCount] = useState(0);





  const firestore = getFirestore();

  const [deletionOptionsVisible, setDeletionOptionsVisible] = useState({});

  const handleImageTap = (imageUrl) => {
    setCurrentImage(imageUrl);
    setModalVisible(true);
  };

  const [isSelecting, setIsSelecting] = useState(false);

  const handlePressPost = (post) => {
    // Clone the post object to ensure it only contains cloneable data
    const clonedPost = JSON.parse(JSON.stringify(post));
    navigate('/viewpost', {
      state: {
        post: clonedPost,
        isLiked: !!likes[post.id],
        isReposted: !!reposts[post.id]
      }
    });
  };

  const [isAtBottom, setIsAtBottom] = useState(true);


    const fileInputRef = React.useRef();
  
    const handleClick = () => {
      fileInputRef.current.click();
    };

  async function pickImage(event) {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMediaUri(reader.result);
        setMediaType(file.type.startsWith('image') ? 'image' : 'video');
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  }

  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  async function uploadImage(uri, onProgress) {
    const storage = getStorage();
    const storageRef = ref(storage, `uploads/${currentUserId}/${Date.now()}`);

    const response = await fetch(uri);
    const blob = await response.blob();

    const uploadTask = uploadBytesResumable(storageRef, blob);

    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
          

          switch (snapshot.state) {
            case 'paused':
              
              break;
            case 'running':
              
              break;
          }
        },
        (error) => {
          console.error('Upload failed:', error);
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  }



  const handleLongPress = (id) => {
    setDeletionOptionsVisible(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const handleDeleteMessage = async (id, senderId) => {
    
    const currentUser = auth.currentUser.uid;
    const recipientId = selectedProfile.userId;
  
    // Construct the conversationId
    const conversationId = [currentUser, recipientId].sort().join('_');
    
  
    const deleteMessageFromUserDoc = async (userId) => {
      const messagesRef = doc(firestore, 'users', userId, 'messages', conversationId);
      
  
      try {
        const conversationDoc = await getDoc(messagesRef);
        if (conversationDoc.exists()) {
          const data = conversationDoc.data();
          let messages = data.messages || [];
  
          // Ensure messages is an array before attempting to delete
          if (!Array.isArray(messages)) {
            messages = [];
          }
  
          
  
          // Remove the message from the messages array
          messages = messages.filter(msg => msg.id !== id);
  
          
  
          // Update the messages document in Firestore
          await setDoc(messagesRef, { messages });
  
          
        } else {
          
        }
      } catch (error) {
        console.error(`Failed to delete message from ${userId}'s Firestore:`, error);
      }
    };
  
    // If current user is the sender, delete from both user's documents
    if (senderId === currentUser) {
      await deleteMessageFromUserDoc(currentUser);
      await deleteMessageFromUserDoc(recipientId);
    } else {
      // If current user is not the sender, delete only from their own document
      await deleteMessageFromUserDoc(currentUser);
    }
  
    // Always remove locally regardless of the owner
    setMessages(currentMessages => {
      const updatedMessages = currentMessages.filter(msg => msg.id !== id);
      
      return updatedMessages;
    });
  };

  const handleScroll = (event) => {
    const y = event.target.scrollTop;
    // Check if the user is close to the top of the list now, as it's inverted
    setIsAtBottom(y === 0);
  };
  

  const markConversationAsRead = async (conversationId) => {
    const conversationRef = doc(db, 'conversations', conversationId);

    await updateDoc(conversationRef, {
      [`unreadMessages.${currentUserId}`]: false
    });
  };

  useEffect(() => {
    const fetchMessagesWithListener = async (conversationId, setMessages) => {
      
      let unsubscribeFunctions = [];

      const fetchAndListenToMessages = (currentConversationId) => {
        const messagesRef = doc(firestore, 'users', currentUserId, 'messages', currentConversationId);
        

        const unsubscribe = onSnapshot(messagesRef, (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            const fetchedMessages = Array.isArray(data?.messages) ? data.messages : []; // Ensure fetchedMessages is always an array

            // Map messages to include an id and handle local messages correctly
            let allMessages = fetchedMessages.map((msg, index) => {
              let convertedTimestamp;
              if (msg.timestamp?.toDate) {
                convertedTimestamp = msg.timestamp;
              } else if (msg.timestamp?.seconds) {
                convertedTimestamp = new Timestamp(msg.timestamp.seconds, msg.timestamp.nanoseconds);
              } else {
                convertedTimestamp = Timestamp.fromDate(new Date(msg.timestamp)); // Fallback
              }

               // Debugging

              return {
                ...msg,
                id: msg.id || `msg_${Date.now()}_${Math.random().toString(36).substring(2, 10)}`, // Ensure each message has a unique id
                timestamp: convertedTimestamp,
              };
            });

            
            setMessages(allMessages.sort((a, b) => b.timestamp.toDate() - a.timestamp.toDate())); // Sort in descending order by timestamp

            // Mark conversation as read
            markConversationAsRead(currentConversationId);
          } else {
            
            setMessages([]); // If the document does not exist, clear the messages
          }
        });

        unsubscribeFunctions.push(unsubscribe);
      };

      // Fetch and listen to the main conversation document
      fetchAndListenToMessages(conversationId);

      return unsubscribeFunctions;
    };

    const markConversationAsRead = async (conversationId) => {
      const currentUserId = auth.currentUser.uid;
      const conversationSummaryRef = doc(firestore, 'users', currentUserId, 'conversationsSummary', 'summary');

      try {
        await updateDoc(conversationSummaryRef, {
          [`conversations.${conversationId}.unread`]: false
        });
        
      } catch (error) {
        console.error('Error marking conversation as read:', error);
      }
    };

    const currentUserId = auth.currentUser.uid;
    const recipientId = selectedProfile.userId;
    const conversationId = [currentUserId, recipientId].sort().join('_');

    if (conversationId) {
      fetchMessagesWithListener(conversationId, setMessages)
        .then(unsubscribeFunctions => {
          
          unsubscribeRef.current = unsubscribeFunctions;
        })
        .catch(error => {
          console.error('Error setting up message listeners:', error);
        });
    }

    return () => {
      if (unsubscribeRef.current.length > 0) {
        
        unsubscribeRef.current.forEach(unsub => unsub());
        unsubscribeRef.current = [];
      }
    };
  }, [selectedProfile]);

  useEffect(() => {
    // Clear messages state when conversationId changes or on component unmount
    return () => {
      setMessages([]);
      setFilteredMessages([]);
      unsubscribe && unsubscribe(); // Ensure we unsubscribe from the current conversation
    };
  }, [conversationId]);

  useEffect(() => {
    const lowercasedSearchText = searchText.toLowerCase();
    setFilteredMessages(messages.filter(message =>
      message.text.toLowerCase().includes(lowercasedSearchText)
    ));
  }, [searchText, messages]);

  async function checkOrCreateConversation(currentUserId, targetUserId) {
    if (!currentUserId || !targetUserId) {
      console.error("Invalid user IDs", { currentUserId, targetUserId });
      return null;
    }

    const conversationsRef = collection(db, 'conversations');
    const q = query(conversationsRef, 
                    where('participants', '==', [currentUserId, targetUserId].sort()));

    const querySnapshot = await getDocs(q);

    let conversationId = null;

    if (querySnapshot.empty) {
      // No existing conversation found, create a new one
      const docRef = await addDoc(conversationsRef, {
        participants: [currentUserId, targetUserId].sort(), // Store participants sorted to make future queries predictable
        createdAt: serverTimestamp(),
      });
      conversationId = docRef.id;
    } else {
      // Existing conversation found
      conversationId = querySnapshot.docs[0].id;
    }

    return conversationId;
  }

  const subscribeToMessages = (conversationId) => {
    const messagesRef = collection(db, 'conversations', conversationId, 'messages');
    const q = query(messagesRef, orderBy('timestamp', 'asc'));

    return onSnapshot(q, (querySnapshot) => {
      const fetchedMessages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);

      if (initialRender && flatListRef?.current) {
        setInitialRender(false); // Update initial render flag
      }
    });
  };

  const generateUniqueId = (length = 8) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const handleMessageSend = async () => {
    
    let mediaUrl = null;

    // Generate a consistent timestamp
    const currentTimestamp = Timestamp.now();
    const currentUserId = auth.currentUser.uid;
    const recipientId = selectedProfile.userId;

    // Log user IDs and construct the conversationId
    
    

    const conversationId = [currentUserId, recipientId].sort().join('_');
    

    // If there is text or a media URL to send
    if (inputText.trim() || mediaUri) {
        const db = getFirestore();

        // Check if recipient is in the sender's followers list
        if (!followers.includes(recipientId)) {
            alert("You can only send messages to users that follow you.");
            return;
        }

        // Generate a unique ID for the message
        const messageId = `msg_${Date.now()}_${Math.random().toString(36).substr(2, 8)}`;
        

        // Add the message locally for instant UI update
        const localMessage = {
            id: messageId,
            text: inputText,
            mediaUrl: mediaUri || '',
            mediaType: mediaType || '',
            userId: currentUserId,
            timestamp: currentTimestamp, // Use Firestore Timestamp for local display and Firestore storage
            conversationId: conversationId, // Include the conversationId in the message
            notificationNeeded: false // New field to indicate notification status
        };

        // Ensure prevMessages contains only valid timestamps
        setMessages(prevMessages => {
            const messages = [localMessage, ...prevMessages];
            return messages.sort((a, b) => {
                const aTimestamp = a.timestamp instanceof Timestamp ? a.timestamp.toDate() : new Date(a.timestamp.seconds * 1000);
                const bTimestamp = b.timestamp instanceof Timestamp ? b.timestamp.toDate() : new Date(b.timestamp.seconds * 1000);
                return bTimestamp - aTimestamp;
            });
        });

        if (mediaUri) {
            // If media is present, start the upload and track the progress
            setIsUploading(true);
            setUploadProgress(0);

            try {
                mediaUrl = await uploadImage(mediaUri, (progress) => {
                    setUploadProgress(progress);
                });
                setIsUploading(false);
            } catch (error) {
                console.error("Failed to upload image:", error);
                setIsUploading(false);
                return;
            }
        }

        const createOrGetConversationId = async () => {
            
            const senderSummaryRef = doc(firestore, 'users', currentUserId, 'conversationsSummary', 'summary');
            const recipientSummaryRef = doc(firestore, 'users', recipientId, 'conversationsSummary', 'summary');

            const senderSummarySnap = await getDoc(senderSummaryRef);
            const recipientSummarySnap = await getDoc(recipientSummaryRef);

            
            

            if (!senderSummarySnap.exists()) {
                await setDoc(senderSummaryRef, { conversations: {} });
            }
            if (!recipientSummarySnap.exists()) {
                await setDoc(recipientSummaryRef, { conversations: {} });
            }

            const summaryData = {
                unread: false,
                latestMessageTimestamp: currentTimestamp, // Use Timestamp.now() for Firestore storage
                userId: recipientId,
                accountType: selectedProfile.accountType || '',
                name: selectedProfile.name || '',
                username: selectedProfile.username || '',
                profileImageUrl: selectedProfile.profileImageUrl || '',
                otherUser: {
                    userId: recipientId,
                    accountType: selectedProfile.accountType || '',
                    name: selectedProfile.name || '',
                    username: selectedProfile.username || '',
                    profileImageUrl: selectedProfile.profileImageUrl || ''
                }
            };

            const recipientSummaryData = {
                unread: true,
                latestMessageTimestamp: currentTimestamp, // Use Timestamp.now() for Firestore storage
                userId: currentUserId,
                accountType: currentUser.accountType || '',
                name: currentUser.name || '',
                username: currentUser.username || '',
                profileImageUrl: currentUser.profileImageUrl || '',
                otherUser: {
                    userId: currentUserId,
                    accountType: currentUser.accountType || '',
                    name: currentUser.name || '',
                    username: currentUser.username || '',
                    profileImageUrl: currentUser.profileImageUrl || ''
                }
            };

            
            await updateDoc(senderSummaryRef, {
                [`conversations.${conversationId}`]: summaryData
            });

            
            await updateDoc(recipientSummaryRef, {
                [`conversations.${conversationId}`]: recipientSummaryData
            });

            return conversationId;
        };

        await createOrGetConversationId();

        const sendToConversation = async (convId, userId, notificationNeeded) => {
            
            const messagesRef = doc(db, 'users', userId, 'messages', convId);

            const conversationDoc = await getDoc(messagesRef);
            let messages = [];

            if (conversationDoc.exists()) {
                const data = conversationDoc.data();
                messages = data.messages || [];
            }

            // Ensure messages is an array before pushing
            if (!Array.isArray(messages)) {
                messages = [];
            }

            // Add message with generated timestamp for local update and Firestore storage
            const newMessage = {
                id: messageId,
                text: inputText,
                mediaUrl: mediaUrl,
                mediaType: mediaType,
                userId: currentUserId,
                timestamp: currentTimestamp, // Use Timestamp.now() for Firestore storage
                conversationId: convId, // Include the conversationId in the message
                notificationNeeded: notificationNeeded // Set notificationNeeded based on whether it's the recipient
            };

            messages.push(newMessage);

            
            await setDoc(messagesRef, { messages });
        };

        // Proceed with sending the message
        try {
            await sendToConversation(conversationId, currentUserId, false); // Set notificationNeeded to false for sender
            await sendToConversation(conversationId, recipientId, true); // Set notificationNeeded to true for recipient
        } catch (error) {
            // Specific known Firestore errors
            if (error.code === 'permission-denied') {
                alert('You do not have permission to send messages.');
            } else if (error.code === 'unavailable') {
                alert('Network unavailable. Please try again later.');
            } else if (error.code === 'resource-exhausted') {
                alert('Quota exceeded. Please try again later.');
            } else {
                alert(`Error sending message: ${error.message}`);
            }
        }

        // Reset the input state and media state after sending
        setInputText('');
        setMediaUri(null);
        setMediaType(null);
    }
};

useEffect(() => {
  if (selectedProfile?.isBeat) {
    setInputText(`I'm interested in this beat: "${selectedProfile?.BeatName}"`);
  }
}, [selectedProfile]);

  const markConversationAsUnread = async () => {
    const db = getFirestore();
    const conversationRef = doc(db, 'conversations', conversationId);

    // Assuming `profile.userId` holds the ID of the message recipient and is not the current user
    const otherUserId = selectedProfile.userId;

    // Asynchronously update the unread status and last message timestamp without waiting for it to complete
    updateDoc(conversationRef, {
      [`unreadMessages.${otherUserId}`]: true,
      'lastMessageTimestamp': serverTimestamp()  // Update the last message timestamp to the current server time
    }).then(() => {
      
    }).catch(error => {
      console.error('Error marking conversation as unread and updating timestamp:', error);
    });
  };



  const sortReleasesByTimestamp = (releases) => {
    const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);

    return sortedReleases;
  };

  const handlePressBegins = async (song) => {
    // Log the original song object for reference
    
  
    // Update the userId field to match the artistUserId field
    song.userId = song.artistUserId;
  
    // Clone the modified song object
    const clonedSong = JSON.parse(JSON.stringify(song));
  
    // Log the cloned song object for verification
    
  
    // Navigate to the view-song route with the modified song object
    navigate('/view-song', { state: { song: clonedSong } });
  };
  

  const preparePopupData = (song, userData) => {
    let profileImageUrl = null;
    const itemUserData = [];

    profileImageUrl = userData.profileImageUrl || null;

    let userAlbums = sortReleasesByTimestamp(userData.albums || []);
    let userSingles = sortReleasesByTimestamp(userData.singles || []);
    let userVideos = sortReleasesByTimestamp(userData.videos || []);

    let selectedAlbum = userAlbums.find(album => album.contentId === song.contentId) || null;
    let selectedSingle = userSingles.find(single => single.contentId === song.contentId) || null;
    let selectedVideo = userVideos.find(video => video.contentId === song.contentId) || null;

    const artworkUri = selectedVideo?.VideoThumbnailURL || (selectedAlbum || selectedSingle)?.ArtworkURL;
    setArtworkUri(artworkUri);

    let dimensions = [];
    if (song.isVideo) {
      dimensions = song.dimensions || [];
    }

    if (selectedAlbum && selectedAlbum.songs.length > 0) {
      setInitialExpandedAlbumAndSong(selectedAlbum, selectedAlbum.songs[0]);
      setCenteredRelease(selectedAlbum);
    } else if (selectedSingle && selectedSingle.length > 0) {
      setCenteredRelease(selectedSingle);
    } else if (selectedVideo) {
      setCenteredRelease(selectedVideo);
    }

    const songName = song.isVideo ? song.VideoName : song.SongName;

    return {
      url: song.SongURL,
      dimensions: dimensions,
      isVideo: song.isVideo,
      artistName: song.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: song.username,
      songName: songName,
      artworkUrl: artworkUri,
      contentId: song.contentId,
      localArtworkURL: song.localArtworkURL,
      userId: song.artistUserId,
      isAlbum: song.isAlbum,
      albumName: selectedAlbum?.albumName || '',
      producers: song.producers,
      songwriters: song.songwriters,
      recordLabels: song.recordLabels,
      otherReleases: { albums: userAlbums, singles: userSingles, videos: userVideos },
      albumSongs: selectedAlbum?.songs || [],
      single: selectedSingle,
      video: selectedVideo,
      timestamp: new Date().getTime(),
      vocalDrop: userData.vocalDrop,
      voiceDropMuted: userData?.voiceDropMuted,
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
      ScreenSKN: userData.ScreenSKN,
    };
  };



  const getCachedUserDoc = async (userId) => {
    let userDataCached = [];
    let userDataCachedNew = [];
    const now = new Date().getTime();

    if (!userDataCached || now - userDataCached.timestamp >= CACHE_DURATION) {
      
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        
        const freshUserData = userDocSnap.data();

        

        // Update userDataCached structure with fresh data and timestamp, keep old local URLs initially.
        userDataCachedNew = {
          ...freshUserData, // overwrite with fresh data from Firestore
          profileImageUrl: freshUserData.profileImageUrl,
          timestamp: now
        };

      } else {
        console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
      }
    }

    return userDataCachedNew;
  };




  const fetchUserProfileByRef = async (userRef) => {
    if (!userRef) {
      console.error("No user reference provided");
      return;
    }

    try {
      const userData = await getCachedUserDocShare(userRef);

      if (userData) {
        handlePressProfile(userData);
      } else {
        console.error(`No user found for the provided reference`);
      }
    } catch (error) {
      console.error('Error fetching user profile by reference:', error);
    } finally {
    }
  };



  const handleProfileSelectShare = async (profileData, userIdShare) => {

    try {
      setIsSelecting(true);
      const popupData = preparePopupDataShare(profileData, userIdShare);
      setActivePopupMedia(popupData); // This triggers the useEffect
    } catch (error) {
      console.error('Error in handleProfileSelect:', error);
    }
  };

  useEffect(() => {
    if (isSelecting && activePopupMedia) {
      onProfilePress(); // Call the function after activePopupMedia has been updated
      setCurrentlyLoadingProfile(false);
      setIsSelecting(false); // Reset isSelecting to false after calling onProfilePress
    }
  }, [activePopupMedia]); // Depend on activePopupMedia and isSelecting

  const sortReleasesByTimestampShare = (releases) => {
    const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);

    return sortedReleases;
  };

  const preparePopupDataShare = (userData, userIdShare) => {
    const albums = sortReleasesByTimestamp(userData.albums || []);
    const singles = sortReleasesByTimestamp(userData.singles || []);
    const videos = sortReleasesByTimestamp(userData.videos || []);

    

    return {
      artistName: userData.artistName || userData.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: userData.username,
      userId: userIdShare,
      otherReleases: { albums, singles, videos },
      vocalDrop: userData.vocalDrop,
      voiceDropMuted: userData?.voiceDropMuted,
      bio: userData.bio,
      accountType: userData.accountType,
      blurb: userData.blurb,
      location: userData.location,
      timestamp: new Date().getTime(),
      ScreenSKN: userData.ScreenSKN,
    };
  };





  const getCachedUserDocShare = async (userRef) => {
    if (!userRef) {
      console.error("Document reference is not provided");
      return null;
    }

    const userDocSnap = await getDoc(userRef);

    if (userDocSnap.exists()) {
      let userData = { ...userDocSnap.data(), userId: userDocSnap.id };

      return userData;
    } else {
      console.error(`No document found in Firestore for the provided reference`);
      return null;
    }
  };

  const handlePressProfile = (profile) => {
    const clonedProfile = JSON.parse(JSON.stringify(profile));
    const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
    navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
  };

  const fetchUserProfileByUsername = async (username) => {
    if (!username) return;

    try {
      const q = query(collection(firestore, 'users'), where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Since you're assuming username is unique, you directly use the first document
        const doc = querySnapshot.docs[0];
        const userData = await getCachedUserDocProfile(doc.id);

        if (userData) {
          // Now passing both userData and the document ID (which is the userId)
          handlePressProfile(userData);
        } else {
          console.error(`UserData could not be prepared for username: ${username}`);
        }
      } else {
        console.error(`No user found with username: ${username}`);
      }
    } catch (error) {
      console.error('Error fetching user by username:', error);
    } finally {
    }
  };

  const getCachedUserDocProfile = async (userId) => {
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      let userData = { ...userDocSnap.data(), userId: userId };

      return userData;
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  const handleProfileSelect = async (profileData) => {

    try {
      setIsSelecting(true);
      const popupData = preparePopupData2(profileData);
      setActivePopupMedia(popupData); // This triggers the useEffect
    } catch (error) {
      setCurrentlyLoadingProfile(false);
      console.error('Error in handleProfileSelect:', error);
    }
  };

  const preparePopupData2 = (userData) => {
    const albums = sortReleasesByTimestamp(userData.albums || []);
    const singles = sortReleasesByTimestamp(userData.singles || []);
    const videos = sortReleasesByTimestamp(userData.videos || []);

    return {
      artistName: userData.artistName || userData.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: userData.username,
      userId: userData.userId,
      otherReleases: { albums, singles, videos },
      vocalDrop: userData.vocalDrop,
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
      timestamp: new Date().getTime(),
      ScreenSKN: userData.ScreenSKN,
    };
  };





  const renderPostContent = (item) => (
    <div className="post-container" style={styles.postContainer} onClick={() => handlePressPost(item?.post)}>
      <div className="post-header">
        <ProfileComponentPostsMessages userProfile={item?.post} />
      </div>
      {item?.post.imageUri && (
        item?.post.mediaType === 'video' ? (
          <div className="media-container">
            <video src={item?.post.imageUri} className="media-thumbnail" controls />
            <img src={require('./assets/PlayButton.png')} className="play-icon" alt="Play" />
          </div>
        ) : (
          <DynamicHeightImage uri={item?.post.imageUri}  style={styles.postMedia} />
        )
      )}
      <p className="post-text">{item?.post?.text}</p>
      {item?.post.originalPost && (
        <div className="original-post-container" style={styles.originalPostContainer}>
          <div className="post-header">
            <ProfileComponentPostsMessages userProfile={item?.post.originalPost} />
          </div>
          {item?.post.originalPost?.imageUri && (
          <img src={item?.post.originalPost?.imageUri} className="post-media" style={styles.postMedia} alt="Original Post Media" />
          )}
          <p className="post-text">{item?.post.originalPost.text}</p>
        </div>
      )}
    </div>
  );

  const renderMessageItem = ({ item }) => {
    if (!item) {
      console.error('No item provided:', item);
      return null;
    }

    const isCurrentUser = item?.userId === userId2;
    const profileImageSource = isCurrentUser ? profileImageUrl : selectedProfile?.profileImageUrl;
    const imageSource = profileImageSource ? profileImageSource : require('./assets/defaultProfilePic.png');

    const handleProfilePress = () => {
      if (isCurrentUser) {
       
      } else {
        fetchUserProfileByUsername(selectedProfile.username);
      }
    };


    return (
      <div
        className={`message-container ${isCurrentUser ? 'current-user' : 'other-user'}`}
        onContextMenu={() => handleLongPress(item?.id)}
        style={isCurrentUser ? styles.messageContainerCurrentUser : styles.messageContainerOtherUser}
      >
        {!isCurrentUser && (
          <div className="image-stack" onClick={handleProfilePress} style={styles.imageStackLeft}>
            <img src={imageSource} alt="Profile" style={styles.circularImage} />
          </div>
        )}
        <div className={`message-bubble ${isCurrentUser ? 'right' : 'left'}`} style={isCurrentUser ? styles.messageBubbleRight : styles.messageBubbleLeft}>
          {item?.text && <p className="message-text" style={styles.messageText}>{item?.text}</p>}
          {item?.contentId && item?.ArtworkURL && (
            <div onClick={() => handlePressBegins(item)} style={styles.mediaInfoContainer}>
              <img src={item?.ArtworkURL} alt="Media" style={styles.artworkThumbnail} />
            </div>
          )}
          {item?.mediaUrl && (
            <div className="media-info-container" style={styles.mediaInfoContainer}>
              {item?.mediaType && item?.mediaType.startsWith('video') ? (
                <video src={item?.mediaUrl} className="media-thumbnail" controls style={styles.mediaThumbnail} />
              ) : (
                <img src={item?.mediaUrl} alt="Media" className="media-thumbnail" onClick={() => handleImageTap(item?.mediaUrl)} style={styles.mediaThumbnail} />
              )}
            </div>
          )}
          {item?.profileImageUrl && (
            <div className="media-info-container" style={styles.mediaInfoContainerProfile}>
              <ProfileComponentShareMessages onPress={() => fetchUserProfileByRef(item?.artistUserRef)} userProfile={item} style={styles.profileComponentStyle} />
            </div>
          )}
          {item?.postId && renderPostContent(item)}
          <p className="time-ago" style={styles.timeAgo}><RealTimeAgo timestamp={item?.timestamp} /></p>
        </div>
        {isCurrentUser && (
          <div className="image-stack" onClick={handleProfilePress} style={styles.imageStackRight}>

            <img src={imageSource} alt="Profile" style={styles.circularImage} />
          </div>
        )}
        {deletionOptionsVisible[item?.id] && (
          <div className="delete-overlay" style={styles.deleteOverlay}>
            <button className="delete-button" onClick={() => handleDeleteMessage(item?.id, item?.userId)} style={styles.deleteButton}>Delete</button>
            <button className="cancel-button" onClick={() => handleLongPress(item?.id)} style={styles.cancelButton}>Cancel</button>
          </div>
        )}
      </div>
    );
  };

  const handleContentSizeChange = (width, height) => {
    setTextInputHeight(Math.max(40, Math.min(80, height)));
  };

  return (
    <div style={styles.chatContainer}>
      <div style={styles.messagesList} onScroll={handleScroll}>
        {messages.map((item, index) => (
          <div key={item.id}>
            {renderMessageItem({ item })}
          </div>
        ))}

        <div ref={messagesEndRef} />
        
      </div>
      {isUnder480 && (
      <div style={styles.extraSpace}></div>
  )}
      <div style={styles.inputContainer}>
        {isUploading && (
          <div style={styles.progressBarContainer}>
            <div style={{ ...styles.progressBar, width: `${uploadProgress}%` }} />
          </div>
        )}
    <div>
      <input
        type="file"
        style={styles.iconButton}
        onChange={pickImage}
        ref={fileInputRef}
      />
      <img src={plusIcon} alt="Plus" style={styles.plusButton} onClick={handleClick} />
    </div>
        {mediaUri && (
          <div style={styles.mediaPreviewContainer}>
            <button style={styles.closeButton} onClick={() => { setMediaUri(null); setMediaType(null); }}>X</button>
            {mediaType === 'video' ? (
              <video src={mediaUri} style={styles.mediaPreview} controls />
            ) : (
              <img src={mediaUri} alt="Media Preview" style={styles.mediaPreview} />
            )}
          </div>
        )}
        <textarea
          placeholder="Type a message..."
          value={inputText}
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          onChange={(e) => setInputText(e.target.value)}
          style={{ ...styles.textInput, height: textInputHeight }}
        />
        <img style={styles.sendButton} src={sendIcon} alt="Send" onClick={handleMessageSend} />
      </div>
      {modalVisible && (
        <div style={styles.modal} onClick={() => setModalVisible(false)}>
          <img src={currentImage} alt="Full Screen" style={styles.fullScreenImage} />
        </div>
      )}
    </div>
  );
};

const styles = {
  chatContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden', // Prevents any unwanted scrolling
  },
  extraSpace: {
    height: '85px',
      },
  iconButton: {
    display: 'none', // Hide the default file input
  },
  originalPostContainer: {
    padding: '10px',
    backgroundColor: "rgba(0,0,0,0.5)",
    borderRadius: 8,
    borderWidth: 1,
    border: '1px solid rgba(23, 195, 250, 0.5)',
    borderColor: 'rgba(23, 195, 250, 0.5)',
    marginLeft: 10,
    marginRight: 10,
    marginTop: '10px',
  },
  postContainer: {
    // Add your existing styling for postContainer
    padding: '10px',
    border: '1px solid rgba(23, 195, 250, 0.5)',
    backgroundColor: "rgba(0,0,0,0.5)",
    borderRadius: 8,
    borderWidth: 1,
    borderColor: 'rgba(23, 195, 250, 0.5)',
    marginLeft: 10,
    marginRight: 10,
    marginTop: '10px',
    cursor: 'pointer',
  },
  originalPostText: {
    fontSize: 14,
    fontFamily: 'brhendrix',
    color: '#aaa',
  },
  customButton: {
    // Your custom button styles here
    padding: '10px 10px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '12px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  messagesList: {
    flex: 1,
    marginLeft: '40px',
    display: 'flex',
    marginRight: '40px',
    overflowY: 'auto',
    padding: '10px',
    flexDirection: 'column-reverse',
    marginBottom: '270px', // Ensure space for the input area
  },
  profileComponentStyle: {
width: '50px',
objectFit: 'contain',

  },
  inputContainer: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: '#0e061e',
    position: 'fixed',
    bottom: 70,
    width: '83%',
    maxWidth: '475px',
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  textInput: {
    alignSelf: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    padding: '10px',
    width: '60%',
    fontSize: '16px',
    color: 'white',
    borderRadius: '16px',
    border: '1px',
    fontFamily: 'brhendrix',
    backgroundColor: 'rgba(81, 33, 131, 0.5)',
    resize: 'none',

  },
  sendButton: {
    backgroundColor: 'rgba(81, 33, 131, 0.5)',
    color: 'white',
    width: '40px',
    objectFit: 'contain',
    height: '40px',
    border: 'none',
    padding: '5px 5px',
    borderRadius: '20px',
    cursor: 'pointer',
    fontFamily: 'brhendrix',
  },
  plusButton: {
    backgroundColor: 'none',
    color: 'white',
    width: '40px',
    objectFit: 'contain',
    height: '40px',
    border: 'none',
    padding: '5px 5px',
    borderRadius: '20px',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'brhendrix',
  },
  messageContainer: {
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'flex-end',
    width: '100%',
  },
  messageContainerCurrentUser: {
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
  },
  postMedia: {
    width: '150px', // Set according to your layout
    height: '150px',
    resizeMode: 'cover',
    borderRadius: 8,
  },
  messageContainerOtherUser: {
    justifyContent: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
  },
  imageStackLeft: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    flexShrink: 0,
    minWidth: '50px', // Ensures the profile image stack starts from the left
  },
  imageStackRight: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    flexShrink: 0,
    minWidth: '50px', // Ensures the profile image stack starts from the right
  },
  backgroundImage: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
  },
  circularImage: {
    width: '40px',
    height: '40px',
    borderRadius: '20px',
  },
  messageBubbleRight: {
    maxWidth: '70%', // Adjust the width to ensure it fits within the container
    padding: '10px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: '1px solid rgba(23, 195, 250, 0.5)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word', // Ensure long words break to fit within the bubble
    marginRight: '10px',
    marginBottom: '10px',
  },
  messageBubbleLeft: {
    maxWidth: '70%', // Adjust the width to ensure it fits within the container
    padding: '10px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    border: '1px solid rgba(23, 195, 250, 0.5)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    wordBreak: 'break-word', // Ensure long words break to fit within the bubble
    marginLeft: '10px',
    marginBottom: '10px',
  },
  messageText: {
    margin: 0,
    fontFamily: 'brhendrix',
  },
  mediaInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  mediaInfoContainerProfile: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    cursor: 'pointer',
  },
  mediaThumbnail: {
    maxWidth: '100%',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  artworkThumbnail: {
    maxWidth: '100%',
    maxHeight: '150px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  timeAgo: {
    fontSize: '12px',
    color: '#999',
    marginTop: '5px',
  },
  deleteOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteButton: {
    backgroundColor: '#ff4d4d',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '20px',
    cursor: 'pointer',
    marginRight: '10px',
  },
  cancelButton: {
    backgroundColor: '#ccc',
    color: 'black',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '20px',
    cursor: 'pointer',
  },
  progressBarContainer: {
    position: 'relative',
    width: '100%',
    height: '4px',
    backgroundColor: '#ccc',
    marginBottom: '10px',
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '4px',
    backgroundColor: '#007bff',
  },
  iconButton: {
    display: 'none',
  },
  mediaPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    width: '40px',
    alignItems: 'center',
    position: 'relative',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#007bff',
    alignSelf: 'center',
    justifyContent: 'center',
    display: 'flex',
    cursor: 'pointer',
    margin: '0 auto',
    fontSize: '12px',
    marginRight: '10px',
  },
  mediaPreview: {
    maxWidth: '100%',
    borderRadius: '10px',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  fullScreenImage: {
    maxWidth: '90%',
    maxHeight: '90%',
  },
};







export default ConversationComponent;

