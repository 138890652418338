import React from 'react';
import placeholderCover from './assets/placeholderCover.png';

const SPNLISTflatlist = ({ item, onPress, onDelete }) => {
  const { width, height } = { width: window.innerWidth, height: window.innerHeight };

  const imageSource = (item.coverImage && item.coverImage !== 'defaultCoverImageURL')
    ? item.coverImage
    : placeholderCover;

  const styles = {
    container: {
      flex: 1,
    },
    playlistItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: `${width * 0.01}px`,
      borderBottom: '1px solid rgba(189, 252, 251, 0.3)',
      position: 'relative',
    },
    playlistCover: {
      width: `70px`,
      height: `70px`,
      borderRadius: '8px',
      marginRight: `${width * 0.025}px`,
      cursor: 'pointer',
    },
    textContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
    },
    playlistName: {
      fontSize: `20px`,
      fontWeight: 'bold',
      color: 'white',
      fontFamily: 'brhendrix',
      marginBottom: `${height * 0.002}px`,
    },
    playlistCreator: {
      fontSize: `20px`,
      color: 'white',
      fontFamily: 'brhendrix',
    },
    deleteButton: {
      backgroundColor: '#852061',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      marginRight: '10px',
      width: '60px',
      height: '40%', // Match the height of the list item
      position: 'absolute',
      right: 0,
      cursor: 'pointer',
    },
    deleteButtonText: {
      color: 'white',
      fontWeight: 'bold',
    },
  };

  return (
    <div style={styles.playlistItem}>
      <img src={imageSource} alt="cover" style={styles.playlistCover} onClick={() => onPress(item.id)} />
      <div onClick={() => onPress(item.id)} style={styles.textContainer}>
        <span style={styles.playlistName}>{item.name}</span>
        <span style={styles.playlistCreator}>Created by: {item.createdBy}</span>
      </div>
      <button onClick={() => onDelete(item.id)} style={styles.deleteButton}>
        <span style={styles.deleteButtonText}>Delete</span>
      </button>
    </div>
  );
};

export default SPNLISTflatlist;
