import React from 'react';

const Report = ({ onPlayVideo, isVideoPlaying, onMoveMP }) => {
  const DISPLAY_TEXT = `To report any activity you feel may go against SPNDAT’s terms of services, please email us at support@spndat.com`;

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <p style={styles.fullScreenText}>{DISPLAY_TEXT}</p>
      </div>
      <div style={{ height: '18vh', backgroundColor: 'transparent' }} />
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollContainer: {
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '16vh',
    paddingBottom: '10vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  fullScreenText: {
    marginTop: '3vh',
    fontSize: '3vw',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'left',
    letterSpacing: '-0.4px',
    marginLeft: '3vw',
    marginRight: '3vw',
  },
};

export default Report;
