import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';
import { getAuth, browserSessionPersistence, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBh2UGuJGje-F0W68DbD9G3guI55sSupzI',
  authDomain: 'spndat-7d730.firebaseapp.com',
  databaseURL: 'https://spndat-7d730-default-rtdb.firebaseio.com/',
  projectId: 'spndat-7d730',
  storageBucket: 'spndat-7d730.appspot.com',
  messagingSenderId: '638251230937',
  appId: '1:638251230937:ios:38464f858338a6dca340c8',
};


const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });
const firestore = getFirestore(app);
const storage = getStorage(app);
const database = getDatabase(app);

export { auth, firestore, storage, database };