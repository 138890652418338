import React, { useState, useContext, useRef } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, setDoc, serverTimestamp, collection } from 'firebase/firestore';
import { AppContext } from './AppContext';
import { firestore } from './firebase';
import { ProfileComponentShareProfile } from './ProfileComponentShareProfile';
import { ProfileComponentMyProfile } from './ProfileComponentMyProfile';

const PostScreenShareDATprofile = ({ onNavigate, onPost, viewingProfile }) => {
  const [text, setText] = useState('');
  const [uploading, setUploading] = useState(false);

  const {
    currentUser,
    posts,
    setPosts,
    userId2,
  } = useContext(AppContext);

  const handlePost = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      alert('Please log in to post');
      return;
    }

    setUploading(true);

    try {
      const userRef = userId2 ? doc(firestore, 'users', userId2) : null;
      const artistUserRef = viewingProfile?.userId
        ? doc(firestore, 'users', viewingProfile.userId)
        : null;
      const docRef = doc(collection(getFirestore(), 'posts'));
      const profile = {
        userId: viewingProfile?.userId,
        name: viewingProfile?.artistName || viewingProfile?.ArtistName,
        profileImageUrl: viewingProfile.profileImageUrl,
        artistUserRef,
        accountType: viewingProfile.accountType,
        username: viewingProfile?.username,
      };
      const postData = {
        id: docRef.id,
        postId: docRef.id,
        text,
        imageUri: '',
        userRef,
        accountType: currentUser.accountType,
        userId: user.uid,
        username: currentUser.username,
        name: currentUser.name,
        profileImageUrl: currentUser.profileImageUrl,
        createdAt: serverTimestamp(),
        likeCount: 0,
        repostCount: 0,
        likes: {},
        comments: [],
        repostUserIds: [],
        profile: profile,
      };

      await setDoc(docRef, postData);

      setPosts((prevPosts) => [...prevPosts, postData]);

      setText('');
    } catch (error) {
      console.error('Error creating post:', error);
      alert('Error creating post. Please try again later.');
    } finally {
      setUploading(false);
    }
  };

  // Styles object
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '7vh',
      width: '100%',
    },
    commentSectionContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    postContainer: {
      padding: '0.7vh',
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderRadius: '8px',
      border: '1px solid rgba(23, 195, 250, 0.5)',
      marginLeft: '10px',
      marginRight: '10px',
      marginTop: '2vh',
    },
    addCommentContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    textInput: {
      padding: '1.5vh 4vw',
      width: '80%',
      fontSize: '20px',
      color: 'white',
      border: 'none',
      resize: 'none',
      zIndex: 100,
      marginBottom: '10px',
      textAlign: 'left',
      borderRadius: '20px',
      outline: 'none',
      backgroundColor: 'transparent',
    },
    characterCount: {
      color: 'white',
      textAlign: 'right',
      padding: '10px',
      fontSize: '13.5px',
      width: '80%',
      alignSelf: 'center',
    },
    buttonsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center', // Center the buttons horizontally
      alignItems: 'center',     // Align items vertically at the center
      width: '100%',

    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column', // Stack the button and text vertically
      alignItems: 'center',
      margin: '0 10px',
    },
    button: {
      padding: '10px 20px',
      color: '#62fbfa',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      border: 'none',
      fontSize: '20px',
      marginBottom: '5px',
      zIndex: 100,
      cursor: 'pointer',
    },
    limitText: {
      color: 'white',
      fontSize: '13.5px',
      textAlign: 'center',
    },
    fileInput: {
      display: 'none',
    },
    previewContainer: {
      cursor: 'pointer',
      marginBottom: '10px',
      textAlign: 'center',
    },
    mediaPreview: {
      width: '50vw',
      height: '50vw',
      marginBottom: '10px',
      borderRadius: '10px',
      objectFit: 'contain',
    },
    progressBarContainer: {
      width: '80%',
      backgroundColor: '#ccc',
      marginTop: '10px',
      height: '10px',
      alignSelf: 'center',
    },
    progressBar: {
      height: '100%',
      backgroundColor: '#6200EE',
    },
    fullScreenContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 1000,
    },
    fullScreenMedia: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
    },
    modal: {
      display: 'block',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.commentSectionContainer}>
        <div style={styles.postContainer}>
          <ProfileComponentMyProfile />

          <div style={styles.addCommentContainer}>
            <textarea
              placeholder="What's happening?"
              value={text}
              onChange={(e) => setText(e.target.value)}
              maxLength={1000}
              style={styles.textInput}
            />
            <div style={styles.characterCount}>{text.length}/1000</div>
          </div>

          <ProfileComponentShareProfile userProfile={viewingProfile} />
        </div>
      </div>

      {uploading ? (
        <div style={styles.buttonContainer}>
          <div className="loader">Loading...</div>
        </div>
      ) : (
        <div style={styles.buttonsRow}>
          <button
            style={styles.button}
            onClick={handlePost}
            disabled={uploading}
          >
            POST DAT
          </button>
        </div>
      )}
    </div>
  );
};

export { PostScreenShareDATprofile };
