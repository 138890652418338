import React, { useEffect, useState } from 'react';
import { collection, getDocs, getFirestore, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import Modal from 'react-modal';
import BlankBG from './assets/BlankBG.png';
import TopAreaFades from './assets/TopAreaFades.png';
import { getFunctions, httpsCallable } from 'firebase/functions';

const MySubmissions = () => {
  const [submissions, setSubmissions] = useState([]);
  const [paypalButton, setPaypalButton] = useState(false);
  const [paypalButtonsLoaded, setPaypalButtonsLoaded] = useState(false);
  const auth = getAuth();
  const firestore = getFirestore();
  const functions = getFunctions();
  const clientId = 'AUPVBkAu1c37lWNuP2jimRK5RnbpOs4pPv8ft6YYTBTS3RANAZ3bIoSaGUG4ymLDuLbTz6gh4QCxinmV'; 

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const userId = auth.currentUser.uid;
        const submissionsRef = collection(firestore, `users/${userId}/Movie Submissions`);
        const querySnapshot = await getDocs(submissionsRef);
        const fetchedSubmissions = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSubmissions(fetchedSubmissions);
      } catch (error) {
        console.error("Error fetching submissions: ", error);
      }
    };

    fetchSubmissions();
    loadPayPal();
  }, [auth, firestore]);

  const loadPayPalScript = (clientId) => {
    return new Promise((resolve, reject) => {
      if (window.paypal) {
        resolve(window.paypal);
        return;
      }

      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.onload = () => {
        if (window.paypal) {
          resolve(window.paypal);
        } else {
          reject(new Error('PayPal SDK could not be loaded.'));
        }
      };
      script.onerror = () => reject(new Error('PayPal SDK could not be loaded.'));
      document.body.appendChild(script);
    });
  };

  const loadPayPal = async () => {
    try {
      await loadPayPalScript(clientId);
    } catch (error) {
      console.error("Failed to load PayPal SDK", error);
    }
  };

  const handlePayPalPayment = async (submissionId, cost) => {
    setPaypalButton(true);
    setPaypalButtonsLoaded(false);
    const createPayPalTransaction = httpsCallable(functions, 'createPayPalTransaction');
    try {
      const payload = { amount: cost.toString() };
      const response = await createPayPalTransaction(payload);
      const orderId = response.data.id;

      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: cost.toString()
              }
            }]
          }).then(orderId => {
            return orderId;
          }).catch(error => {
            console.error("PayPal createOrder: Error creating order:", error);
            throw error;
          });
        },
        onApprove: async (data, actions) => {
          try {
            const details = await actions.order.capture();
            const { id } = details;

            // Update the status of the submission to "For Review"
            await updateDoc(doc(firestore, `users/${auth.currentUser.uid}/Movie Submissions`, submissionId), {
              status: 'ForReview'
            });

            await updateDoc(doc(firestore, 'MovieSubmissions', submissionId), {
              status: 'ForReview'
            });

            setPaypalButton(false);
            alert('Transaction completed by ' + details.payer.name.given_name);
          } catch (error) {
            console.error("PayPal onApprove: Error capturing payment or updating status:", error);
            if (error.message.includes('ORDER_ALREADY_CAPTURED')) {
              alert('Transaction already completed.');
            } else {
              alert('There was an error processing your transaction. Please try again.');
            }
            setPaypalButton(false);
          }
        },
        onCancel: (data) => {
          setPaypalButton(false);
        },
        onError: (err) => {
          console.error("PayPal Buttons onError: Error with PayPal buttons:", err);
          alert('There was an error loading PayPal. Please try again later.');
          setPaypalButton(false);
        },
        onRender: () => {
          setPaypalButtonsLoaded(true);
        }
      }).render('#paypal-button-container');

    } catch (error) {
      console.error("Error creating PayPal transaction:", error);
      setPaypalButton(false);
    }
  };

  const formatPremiereDate = (dateString) => {
    const date = new Date(dateString);
    return date.toDateString();
  };

  const renderSubmission = (submission, index) => {
    const {
      id,
      VideoThumbnailURL,
      MovieTitle,
      premiereDate,
      status,
      link,
      genres,
      directors,
      producers,
      cast,
      productionCompany
    } = submission;

    let statusText;
    let statusStyle = {
      fontSize: '14px',
      marginTop: '5px',
      color: '#d9534f', // Default red color for other statuses
    };
  
    if (status === 'ForReview') {
      statusText = 'Submission is in review';
      statusStyle.color = 'green';
    } else if (status === 'ComingSoon') {
      statusText = 'Approved';
      statusStyle.color = 'green';
    } else if (status === 'pending') {
      statusText = (
        <>
          Status: Pending Payment
          <br />
          <button onClick={() => handlePayPalPayment(id, "300.00")} style={styles.payButton}>
            Pay Submission Fee
          </button>
        </>
      );
    } else {
      statusText = `Status: ${status}`;
    }

    return (
      <div key={index} style={styles.submissionContainer}>
        <img src={VideoThumbnailURL} alt={`${MovieTitle} thumbnail`} style={styles.thumbnail} />
        <h2 style={styles.movieTitle}>{MovieTitle}</h2>
        <p style={styles.premiereDate}>{formatPremiereDate(premiereDate)}</p>
        <p style={statusStyle}>{statusText}</p>

        <p style={styles.sectionTitle}>Link:</p>
        <p style={styles.genre}>{link}</p>
        <p style={styles.sectionTitle}>Genres:</p>
        {genres.map((genre, idx) => (
          <p key={idx} style={styles.genre}>{genre}</p>
        ))}
        <p style={styles.sectionTitle}>Directors:</p>
        {directors.map((director, idx) => (
          <p key={idx} style={styles.personTag}>{director.tag}</p>
        ))}
        <p style={styles.sectionTitle}>Producers:</p>
        {producers.map((producer, idx) => (
          <p key={idx} style={styles.personTag}>{producer.tag}</p>
        ))}
        <p style={styles.sectionTitle}>Cast:</p>
        {cast.map((actor, idx) => (
          <p key={idx} style={styles.personTag}>{actor.tag}</p>
        ))}
        <p style={styles.sectionTitle}>Production Company:</p>
        {productionCompany.map((company, idx) => (
          <p key={idx} style={styles.personTag}>{company.tag}</p>
        ))}
      </div>
    );
  };

  return (
    <div style={styles.fullScreen}>
      <img src={BlankBG} alt="Background" style={styles.fullScreenImage} />
      <div style={styles.scrollContainer}>
        {submissions.map((submission, index) => renderSubmission(submission, index))}
      </div>
      <div style={{ height: styles.bottomAreaHeight, backgroundColor: 'transparent' }} />

      <Modal
        isOpen={paypalButton}
        onRequestClose={() => setPaypalButton(false)}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 10000,
          },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '400px',
            width: '80%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            padding: '20px',
            border: 'none',
            borderRadius: '8px',
          },
        }}
      >
        <div style={styles.sharingPost}>
          {!paypalButtonsLoaded && <div style={styles.loadingText}>Loading Checkout...</div>}
          <div id="paypal-button-container"></div>
        </div>
        {paypalButtonsLoaded && (
          <div style={styles.cancelContainer}>
            <button style={styles.cancelButton} onClick={() => setPaypalButton(false)}>Cancel</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

const styles = {
  fullScreen: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  popupImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 5,
    pointerEvents: 'none',
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: `calc(100vh - 18vh)`,
    zIndex: 2,
    overflowY: 'auto',
    paddingTop: '2vh',
    paddingBottom: '10vh',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
    objectFit: 'cover',
  },
  submissionContainer: {
    marginBottom: '20px',
    borderColor: '#ccc',
    borderWidth: '1px',
    padding: '10px',
    borderRadius: '8px',
    marginLeft: '10px',
    marginRight: '10px',
    color: 'white',
  },
  thumbnail: {
    width: '100%',
    aspectRatio: '16 / 9',
    borderRadius: '8px',
  },
  movieTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  premiereDate: {
    fontSize: '14px',
    marginTop: '5px',
  },
  status: {
    fontSize: '14px',
    color: '#d9534f',
    marginTop: '5px',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: '10px',
  },
  genre: {
    fontSize: '14px',
  },
  personTag: {
    fontSize: '14px',
  },
  payButton: {
    backgroundColor: '#0070ba',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
    display: 'block', // Ensures the button takes up the full width available
    marginLeft: 'auto',
    marginRight: 'auto', // Centers the button horizontally
  },
  
  sharingPost: {
    width: '100%',
    height: '200px',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  cancelContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  cancelButton: {
    padding: '10px 20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '0 auto',
    position: 'absolute',
    top: '180px',
    backgroundColor: 'transparent',
    color: '#62fbfa',
    border: 'none',
    fontSize: '20px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  loadingText: {
    fontSize: '20px',
    color: '#62fbfa',
    marginBottom: '10px',
  },
};

export default MySubmissions;
