import React, { useContext, useMemo, useEffect, useState } from 'react';
import { AppContext } from './AppContext';

const AnalyticsComponent = () => {
  const { 
    stateCountsSKN,
    viewingTotalSpnsAnalytics,
    viewingAlbumAnalytics,
    viewingSingleAnalytics,
    viewingVideosAnalytics,
    viewingVerchAnalytics
  } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  const activeCategories = useMemo(() => {
    const categories = [];
    if (viewingTotalSpnsAnalytics) categories.push('TotalSpns');
    if (viewingAlbumAnalytics) categories.push('Album');
    if (viewingSingleAnalytics) categories.push('Single');
    if (viewingVideosAnalytics) categories.push('Videos');
    if (viewingVerchAnalytics) {
      categories.push('Verch');
    }
    
    return categories;
  }, [viewingTotalSpnsAnalytics, viewingAlbumAnalytics, viewingSingleAnalytics, viewingVideosAnalytics, viewingVerchAnalytics]);

  const sortedData = useMemo(() => {
    const entries = Object.entries(stateCountsSKN || {});
    
    if (!entries.length || !activeCategories.length) return [];

    if (entries.length) {
      setIsLoading(false);
    }

    const filtered = entries.filter(([key]) =>
      activeCategories.some(category => key.startsWith(category))
    );

    const countsByState = filtered.reduce((acc, [key, count]) => {
      const state = key.split('_')[1];
      acc[state] = (acc[state] || 0) + count;
      return acc;
    }, {});

    const aggregatedAndSorted = Object.entries(countsByState)
      .map(([state, count]) => ({
        key: state,
        state,
        count,
      }))
      .sort((a, b) => b.count - a.count);

    
    return aggregatedAndSorted;
  }, [stateCountsSKN, activeCategories]);

  useEffect(() => {
    // Set a timer to stop loading after 2 seconds
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    // Cleanup function to clear the timer if the component unmounts
    // or if the loading state changes before the timer expires
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={styles.container}>
      {isLoading ? (
        <div style={styles.activityIndicatorContainer}>
          <div className="spinner-border text-info" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <ul style={styles.list}>
          {sortedData.map(item => (
            <li key={item.key} style={styles.itemContainer}>
              <span style={styles.itemState}>{item.state}</span>
              <span style={styles.itemCount}>{item.count}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const styles = {
  container: {
    minHeight: '330px',
    width: '50%',
    backgroundColor: 'transparent',
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 0',
  },
  itemState: {
    fontFamily: 'brhendrix',
    fontSize: '16px',
    color: '#62fbfa',
  },
  itemCount: {
    fontFamily: 'brhendrix',
    fontSize: '16px',
    color: '#62fbfa',
  },
  activityIndicatorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
  },
};

export { AnalyticsComponent };
