
import React, { useState, useRef, useEffect, useContext } from 'react';

import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, query, where, getDocs, getDoc, doc, getFirestore, limit } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { AppContext } from './AppContext';
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import Calendar from 'react-calendar';
const { width, height } = { width: window.innerWidth, height: window.innerHeight };
const bottomAreaHeight = height * 0.18;
const horizontalAreaSpacing = height * 0.015;
const scrollAreaTopPadding = height * 0.16;





const genresList = [
  { label: "Action", value: "action" },
  { label: "Adventure", value: "adventure" },
  { label: "Animation", value: "animation" },
  { label: "Comedy", value: "comedy" },
  { label: "Crime", value: "crime" },
  { label: "Documentary", value: "documentary" },
  { label: "Drama", value: "drama" },
  { label: "Fantasy", value: "fantasy" },
  { label: "Historical", value: "historical" },
  { label: "Horror", value: "horror" },
  { label: "Musical", value: "musical" },
  { label: "Mystery", value: "mystery" },
  { label: "Romance", value: "romance" },
  { label: "Sci-Fi", value: "sci_fi" },
  { label: "Thriller", value: "thriller" },
];





const getCurrentUserId = () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? user.uid : null;
};


const ReleaseFilm = () => {

const { 

      currentUser, setUser,
      currentlyReleasing, setCurrentlyReleasing,
      showSuccessAlertReleasedMusic, setShowSuccessAlertReleasedMusic,
      showSuccessAlertReleasedMusicVideo, setShowSuccessAlertReleasedMusicVideo
} = useContext(AppContext);

const artworkInputRef = useRef(null);


const [showPickerModal, setShowPickerModal] = useState(false);
const [selectedReleaseId, setSelectedReleaseId] = useState(null);


  const [albumMode, setAlbumMode] = useState(false);
  const [albumDetails, setAlbumDetails] = useState({
    artistName: '',
    albumName: '',
    artworkURL: '',
    genres: [],
    tags: [],
    artistFeatures: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
  });
  const [editingAlbumDetails, setEditingAlbumDetails] = useState(false);
  const [selectedGenre, setSelectedGenre] = useState('action');
  const [selectedSubgenre, setSelectedSubgenre] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [producerName, setProducerName] = useState('');
  const [producerTrackName, setProducerTrackName] = useState('');
  const [songwriterName, setSongwriterName] = useState('');
  const [songDetails, setSongDetails] = useState([]);
  const [songTitle, setSongTitle] = useState('');
  const [songArtworkURL, setSongArtworkURL] = useState('');
  const [songUri, setSongUri] = useState('');
  const [titleInput, setTitleInput] = useState('');
  const [linkInput, setLinkInput] = useState('');
  const [lyricsInput, setLyricsInput] = useState('');

  const [showCollaborators, setShowCollaborators] = useState(false);
  const [collaborators, setCollaborators] = useState([
    { name: '', userId: '', percentage: '', searchQuery: '', searchResults: [], isSearching: false }
  ]);
  
  const handleToggleCollaborators = () => {
    setShowCollaborators(!showCollaborators);
  };
  

  
  const handleAddCollaborator = () => {
    setCollaborators([...collaborators, { name: '', email: '', percentage: '' }]);
  };
  
  const handleRemoveCollaborator = (index) => {
    const updatedCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(updatedCollaborators);
  };

const [searchQueryArtistName, setSearchQueryArtistName] = useState('');
const [searchResultsArtistName, setSearchResultsArtistName] = useState([]);
const [isSearchingArtistName, setIsSearchingArtistName] = useState(false);

const [searchQueryProducers, setSearchQueryProducers] = useState('');
const [searchResultsProducers, setSearchResultsProducers] = useState([]);
const [isSearchingProducers, setIsSearchingProducers] = useState(false);

const [searchQuerySongwriters, setSearchQuerySongwriters] = useState('');
const [searchResultsSongwriters, setSearchResultsSongwriters] = useState([]);
const [isSearchingSongwriters, setIsSearchingSongwriters] = useState(false);

const [searchQueryCollaborator, setSearchQueryCollaborator] = useState('');
const [searchResultsCollaborator, setSearchResultsCollaborator] = useState([]);
const [isSearchingCollaborator, setIsSearchingCollaborator] = useState(false);

const [searchQueryArtistFeatures, setSearchQueryArtistFeatures] = useState('');
const [searchResultsArtistFeatures, setSearchResultsArtistFeatures] = useState([]);
const [isSearchingArtistFeatures, setIsSearchingArtistFeatures] = useState(false);

const [searchQueryRecordLabels, setSearchQueryRecordLabels] = useState('');
const [searchResultsRecordLabels, setSearchResultsRecordLabels] = useState([]);
const [isSearchingRecordLabels, setIsSearchingRecordLabels] = useState(false);


const [editingLinkedTrack, setEditingLinkedTrack] = useState(false);
const [selectedTrackForEdit, setSelectedTrackForEdit] = useState(null);


const [isDatePickerVisible, setDatePickerVisibility] = useState(false);
const [selectedDate, setSelectedDate] = useState(null);
const [selectedRating, setSelectedRating] = useState(null);
const [isCalendarVisible, setCalendarVisibility] = useState(false);

const showCalendar = () => {
  setCalendarVisibility(true);
};

const hideCalendar = () => {
  if (!selectedDate) {
    setSelectedDate(oneMonthLater);  // Set default date to one month later if nothing is selected
  }
  setCalendarVisibility(false);
};

const handleDateChange = (date) => {
  setSelectedDate(date);
  hideCalendar();
};

const formatDate = (date) => {
  return date ? date.toDateString() : '';
};

const currentDate = new Date();
const oneMonthLater = new Date();
oneMonthLater.setMonth(currentDate.getMonth() + 1);



const [formMode, setFormMode] = useState('add');

  const [displayedArtwork, setDisplayedArtwork] = useState('');
  const [isSearching, setIsSearching] = useState(false);
const firestore = getFirestore();
const usersRef = collection(firestore, 'users');
const [videoDisplayName, setvideoDisplayName] = useState('');

const [addingSongs, setAddingSongs] = useState(false);
const [selectedTrack, setSelectedTrack] = useState(1);
const [currentTrackNumber, setCurrentTrackNumber] = useState(1);
const [currentForm, setCurrentForm] = useState('Album Details');


const [currentTrack, setCurrentTrack] = useState(1);
const [currentTrackDetails, setCurrentTrackDetails] = useState({
  title: '',
  artistName: '',
  directors: [],
  artworkURL: '',
  songURL: '',
  genres: [],
  tags: [],
  producers: [],
  songwriters: [],
  recordLabels: [],
  lyrics: '',
  videoDisplayName: '',
  trackNumber: null,
});
    

const [tracks, setTracks] = useState([]);
const [trackDetails, setTrackDetails] = useState({ title: '', artworkURL: '', songURL: '' });
const [currentPickerSelection, setCurrentPickerSelection] = useState('Album Details');

const [albumDetailsVisible, setAlbumDetailsVisible] = useState(false);
const [trackDetailsVisible, setTrackDetailsVisible] = useState(new Array(10).fill(false));

const [userReleases, setUserReleases] = useState({ albums: [], singles: [] });

useEffect(() => {
  const fetchUserReleases = async () => {
    const userId = getCurrentUserId();
    const userRef = doc(firestore, 'users', userId);

    try {
      const userSnapshot = await getDoc(userRef);
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        const albums = userData.albums || [];
        const singles = userData.singles || [];

        const processedAlbums = albums.map(album => ({
          ...album,
          tracks: album.songs.map(song => ({ ...song })).sort((a, b) => a.trackNumber - b.trackNumber),
        }));

        setUserReleases({
          albums: processedAlbums,
          singles: singles.map(single => ({ ...single })), 
        });
      } else {
        
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  fetchUserReleases();
}, []);



const renderPickerModal = () => (
  <Modal isOpen={showPickerModal} onRequestClose={() => setShowPickerModal(false)}>
    <div style={styles.modalView}>
      {albumMode ? (
        userReleases.albums.map((album, index) => {
          const key = album.contentId || `album-${index}`;
          return (
            <div key={key} onClick={() => handleReleaseSelection(album.contentId, true)}>
              <p style={styles.modalText}>{album.albumName}</p>
            </div>
          );
        })
      ) : (
        userReleases.singles.map((single, index) => {
          const key = single.contentId || `single-${index}`;
          return (
            <div key={key} onClick={() => handleReleaseSelection(single.contentId, false)}>
              <p style={styles.modalText}>{single.SongName}</p>
            </div>
          );
        })
      )}
      <button onClick={() => setShowPickerModal(false)}>Close</button>
    </div>
  </Modal>
);



const pickTrackThumbnail = (event) => {
  const file = event.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      const uri = reader.result;
      setTracks((currentTracks) =>
        currentTracks.map((track) =>
          track.trackNumber === selectedTrackForEdit.trackNumber ? { ...track, artworkURL: uri } : track
        )
      );
      setEditingLinkedTrack(false);
    };
    reader.readAsDataURL(file);
  }
};


const handleTrackSelection = (selectedTrack) => {
  setSelectedTrackForEdit(selectedTrack);
  setEditingLinkedTrack(true);
};



const handleReleaseSelection = (contentId, isAlbum) => {
  setSelectedReleaseId(contentId);
  setShowPickerModal(false);

  const selectedRelease = isAlbum ? userReleases.albums.find(album => album.contentId === contentId) : userReleases.singles.find(single => single.contentId === contentId);

  if (isAlbum) {
    const adjustedTracks = selectedRelease.tracks.map((song, index) => ({
      trackNumber: song.trackNumber || index + 1,
      title: song.SongName,
      artistName: { id: song.artistId, tag: song.ArtistName },
      artworkURL: song.ArtworkURL,
      songURL: song.SongURL,
      genres: song.genres,
      tags: song.tags,
      artistFeatures: song.artistFeatures,
      producers: song.producers,
      songwriters: song.songwriters,
      recordLabels: song.recordLabels,
      lyrics: song.lyrics,
    })).sort((a, b) => a.trackNumber - b.trackNumber);

    setAlbumDetails({
      ...selectedRelease,
      isLinked: true,
    });

    setTracks(adjustedTracks);
  } else {

  }
};







const pickerSelectStyles = {
  input: {
    color: 'black',
    fontFamily: 'brhendrix',
    fontSize: `22px`,
  },
  placeholder: {
    color: 'black',
    fontFamily: 'brhendrix',
  },
};


  const storage = getStorage();
  const functions = getFunctions();
  const submitMovie = httpsCallable(functions, 'submitMovie');

  const pickVideo = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*';
    input.onchange = async (event) => {
      const file = event.target.files[0];
      const uri = URL.createObjectURL(file);
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        songURL: uri,
        videoDisplayName: file.name
      }));
    };
    input.click();
  };




  const pickVideoLinked = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = file.name;
      const videoURL = URL.createObjectURL(file);
  
      setTracks((prevTracks) =>
        prevTracks.map((track) =>
          track.trackNumber === selectedTrackForEdit.trackNumber
            ? { ...track, videoDisplayName: fileName, songURL: videoURL }
            : track
        )
      );
  
      setEditingLinkedTrack(false);
      setSelectedTrackForEdit(null);
    }
  };

  const inputVideoRef = useRef(null);


  const toggleMode = (mode) => {
    setAlbumMode(mode === 'album');
  };

  const pickArtwork = async (event) => {
    const file = event.target.files[0];
    if (file) {
      

      const fileUrl = URL.createObjectURL(file);
      setAlbumDetails({ ...albumDetails, artworkFile: file, artworkURL: fileUrl });
      
    }
  };




  const uploadVideoThumbnail = async (file, folderName = 'video_thumbnails/') => {
    if (!file) {
      console.error('No file provided for upload');
      return;
    }
  
    
    
  
    const fileRef = ref(storage, `${folderName}${new Date().toISOString()}`);
    const metadata = {
      contentType: file.type || 'image/png', // Use the correct MIME type
    };
  
    try {
      
      
  
      const uploadTask = uploadBytesResumable(fileRef, file, metadata);
  
      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            
          },
          (error) => {
            console.error('Error during photo upload:', error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            
            resolve(downloadURL);
          }
        );
      });
    } catch (error) {
      console.error('Error during photo upload:', error);
      throw error;
    }
  };
  
  







const addGenre = () => {
  if (selectedGenre) {
    if (!albumDetails.genres.includes(selectedGenre) && albumDetails.genres.length < 3) {
      setAlbumDetails(prevState => ({ ...prevState, genres: [...prevState.genres, selectedGenre] }));
    }
  }
};

  const addTag = () => {
    if (selectedTag && !albumDetails.tags.includes(selectedTag) && albumDetails.tags.length < 50) {
      setAlbumDetails(prevState => ({ ...prevState, tags: [...prevState.tags, selectedTag] }));
    }
  };

  const removeGenre = (genre) => {
    setAlbumDetails(prevState => ({ ...prevState, genres: prevState.genres.filter(g => g !== genre) }));
  };

  const removeTag = (tag) => {
    setAlbumDetails(prevState => ({ ...prevState, tags: prevState.tags.filter(t => t !== tag) }));
  };

const addProducer = () => {
  const input = searchQueryProducers.trim();

  if (albumDetails.producers.length >= 5) {
    return;
  }

  if (input) {
    const newProducer = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      producers: [...prevState.producers, newProducer]
    }));
  } else {
  }

  setSearchQueryProducers('');
};


const removeProducer = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    producers: prevState.producers.filter(producer => producer.id !== id)
  }));
};


const addSongwriter = () => {
  const input = searchQuerySongwriters.trim();

  if (albumDetails.songwriters.length >= 5) {
    return;
  }

  if (input) {
    const newSongwriter = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      songwriters: [...prevState.songwriters, newSongwriter]
    }));
  } else {
  }

  setSearchQuerySongwriters('');
};

const removeSongwriter = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    songwriters: prevState.songwriters.filter(songwriter => songwriter.id !== id)
  }));
};




const addArtistFeature = () => {
  const input = searchQueryArtistFeatures.trim();

  if (albumDetails.artistFeatures.length >= 5) {
    return;
  }

  if (input) {
    const newArtistFeature = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      artistFeatures: [...prevState.artistFeatures, newArtistFeature]
    }));
  } else {
  }

  setSearchQueryArtistFeatures('');
};

    const removeArtistFeature = (id) => {
      setAlbumDetails(prevState => ({
        ...prevState,
        artistFeatures: prevState.artistFeatures.filter(artistFeature => artistFeature.id !== id)
      }));
    };

const removeArtistName = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    artistName: null
  }));
};



const addRecordLabel = () => {
  const input = searchQueryRecordLabels.trim();

  if (albumDetails.recordLabels.length >= 3) {
    return;
  }

  if (input) {
    const newRecordLabel = {
      tag: input,
      tagLower: input.toLowerCase(),
      id: Date.now().toString()
    };

    setAlbumDetails(prevState => ({
      ...prevState,
      recordLabels: [...prevState.recordLabels, newRecordLabel]
    }));
  } else {
  }

  setSearchQueryRecordLabels('');
};

const removeRecordLabel = (id) => {
  setAlbumDetails(prevState => ({
    ...prevState,
    recordLabels: prevState.recordLabels.filter(recordLabel => recordLabel.id !== id)
  }));
};



const newTrackTemplate = {
  title: '',
  artworkURL: '',
  songURL: '',
  producers: [],
  songwriters: [],
  artistFeatures: [],
  recordLabels: [],
  lyrics: ''

};



const handleAddTrack = () => {
  if (currentForm === 'Add Track') {
    const newTrackNumber = tracks.length + 1;
    const newTrack = { ...currentTrackDetails, trackNumber: newTrackNumber };
    setTracks([...tracks, newTrack]);

    setCurrentTrackDetails({
      title: '',
      artworkURL: '',
      songURL: '',
      producers: [],
      songwriters: [],
      artistFeatures: [],
      recordLabels: [],
      lyrics: '',
      trackNumber: newTrackNumber + 1,
    });
  } else {
    const updatedTracks = tracks.map(track => {
      if (track.trackNumber === currentTrackDetails.trackNumber) {
        return currentTrackDetails;
      }
      return track;
    });

    setTracks(updatedTracks);
    setCurrentForm('Add Track');
    setCurrentTrackDetails({
      title: '',
      artworkURL: '',
      songURL: '',
      producers: [],
      songwriters: [],
      artistFeatures: [],
      recordLabels: [],
      lyrics: '',
      trackNumber: tracks.length + 1,
    });
  }
};





useEffect(() => {
  
}, [currentTrackDetails]);

useEffect(() => {
  setTitleInput(currentTrackDetails.title);
}, [currentTrackDetails.title]);

const handleTitleChange = (event) => {
  const text = event.target.value; // Get the value from the event
  setTitleInput(text);
  setCurrentTrackDetails(prevDetails => ({ ...prevDetails, title: text }));
};

const handleLinkChange = (event) => {
  const text = event.target.value; // Get the value from the event
  setLinkInput(text);
  setCurrentTrackDetails(prevDetails => ({ ...prevDetails, link: text }));
};

useEffect(() => {
  setLyricsInput(currentTrackDetails.lyrics);
}, [currentTrackDetails.lyrics]);

const handleLyricsChange = (text) => {
  setLyricsInput(text);
  setCurrentTrackDetails(prevDetails => ({ ...prevDetails, lyrics: text }));
};



const formatArtistWithFeatures = (artistName, artistFeatures) => {
  if (artistFeatures && artistFeatures.length > 0) {
    const featureNames = artistFeatures.map(feature => feature.tag);
    return `${artistName} ft. ${featureNames.join(', ')}`;
  } else {
    return artistName;
  }
};



const renderTrackModules = () => {
  if (tracks.length === 0) return null;

  return tracks.map((track, index) => (
    <div
      key={track.trackNumber}
      onClick={() => {
        setCurrentForm(`Edit Track ${track.trackNumber}`);
        setEditingAlbumDetails(false);
        setCurrentTrackDetails(JSON.parse(JSON.stringify(track)));
        setFormMode('edit');
      }}
      style={styles.albumInfoContainer}
    >
      <img
        src={track.artworkURL || albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Track Artwork"
      />
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
        {`Track ${track.trackNumber}:\n${track.title}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
      {`Filmmaker:\n${track?.artistName.tag}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
        {`Movie File Link:\n${track.videoDisplayName || 'No file selected'}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
      {`Directed By:\n${track?.directors.map(p => p.tag).join(', ')}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
        {`Produced By:\n${track.producers.map(p => p.tag).join(', ')}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
      {`Cast:\n${track?.songwriters.map(s => s.tag).join(', ')}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
      {`Production Company:\n${track?.recordLabels.map(s => s.tag).join(', ')}`}
      </p>
      <p style={{ ...styles.albumInfoText, color: 'white' }}>
        {`Genre:\n${track.genres?.join(', ')}`}
      </p>
      {/* Add other details as needed */}
    </div>
  ));
};




const [isReviewing, setIsReviewing] = useState(false);



const renderTrackModulesLinked = () => {
  if (tracks.length === 0) return null;

  return tracks.map((track) => (
    <div
      key={track.trackNumber}
      onClick={() => handleTrackSelection(track)}
      style={styles.albumInfoContainer}
    >
      <img
        src={track.artworkURL || albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Track Artwork"
      />
      <p style={styles.albumInfoText}>
        {`Track ${track.trackNumber}:\n${track.title}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Artist:\n${track.artistName.tag}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Video File:\n${track.videoDisplayName || 'No file selected'}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Produced By:\n${track.producers.map(p => p.tag).join(', ')}`} 
      </p>
      <p style={styles.albumInfoText}>
        {`Written By:\n${track.songwriters.map(s => s.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Record Label:\n${track.recordLabels.map(r => r.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Genre:\n${track.genres?.join(', ')}`}
      </p>
    </div>
  ));
};






const handleAlbumDetailsSubmit = () => {
  setFormMode('add');
  setAlbumDetailsVisible(true);
  setAddingSongs(true);

  setCurrentForm('Add Track');

};



const handleSaveTrackChanges = () => {
  const updatedTracks = tracks.map(track => {
    if (track.trackNumber === currentTrackDetails.trackNumber) {
      return { ...currentTrackDetails };
    }
    return track;
  });

  setTracks(updatedTracks);


  setFormMode('add');
  setCurrentForm('Add Track');
};




const handleSaveSingleChanges = () => {
  const updatedTracks = tracks.map(track => {
    if (track.trackNumber === currentTrackDetails.trackNumber) {
      return { ...currentTrackDetails };
    }
    return track;
  });

  setTracks(updatedTracks);


};



const inputFileRef = useRef(null);

const removeTrack = (trackNumber) => {
  const updatedTracks = [...tracks];

  const indexToRemove = updatedTracks.findIndex(t => t.trackNumber === trackNumber);

  if (indexToRemove !== -1) {
    updatedTracks.splice(indexToRemove, 1);

    updatedTracks.forEach((track, index) => {
      if (track.trackNumber > trackNumber) {
        updatedTracks[index].trackNumber -= 1;
      }
    });

    setTracks(updatedTracks);

    if (currentTrackNumber === trackNumber) {
      setCurrentTrackNumber(updatedTracks.length > 0 ? Math.min(trackNumber, updatedTracks.length) : 1);
    }
  }
};

async function testUploadSong() {
  try {
    const firebasePath = `single_songs/${currentTrackDetails.title.replace(/[^a-zA-Z0-9]/g, '_')}`;
    const uploadResult = await uploadSongFileToFirebase(currentTrackDetails.songURL, firebasePath);
    
  } catch (error) {
    console.error('Test upload failed:', error);
  }
}



async function uploadSongFileToFirebase(songFile, firebasePath) {
  try {
    

    
    const reader = new FileReader();

    reader.onloadend = async (event) => {
      const arrayBuffer = event.target.result;

      
      const blob = new Blob([arrayBuffer], { type: songFile.type });

      
      const storageRef = ref(storage, firebasePath);
      

      
      const uploadTask = uploadBytesResumable(storageRef, blob);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // You can add progress handling here if needed
        },
        (error) => {
          console.error('Error during upload:', error);
          throw error;
        },
        async () => {
          

          
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          

          return downloadURL;
        }
      );
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
      throw error;
    };

    reader.readAsArrayBuffer(songFile);
  } catch (error) {
    console.error('Error uploading song file:', error);
    throw error;
  }
}

const [uploadProgress, setUploadProgress] = useState(0);

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const uploadVideoFile = async (uri, fileName, retries = 5, delayTime = 3000) => {
  console.log(`Starting upload for file: ${fileName}`);
  const storage = getStorage();
  const storageRef = ref(storage, `videos/${fileName}`);
  
  const attemptUpload = async (retryCount) => {
    console.log(`Attempting upload for file: ${fileName}, Retry count: ${retries - retryCount + 1}`);
    try {
      console.log(`Fetching video file from URI: ${uri}`);
      const response = await fetch(uri);
      const blob = await response.blob();
      console.log(`Fetched video file: ${fileName}, Blob size: ${blob.size} bytes`);
  
      const uploadTask = uploadBytesResumable(storageRef, blob);
      console.log(`Upload task created for file: ${fileName}`);
  
      await new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log(`Upload progress for ${fileName}: ${progress.toFixed(2)}%`);
            setUploadProgress(progress); // Update the progress state
          },
          (error) => {
            console.error(`Upload error for file: ${fileName}`, error);

            // Check for additional details in the error payload
            if (error.serverResponse) {
              try {
                const errorPayload = JSON.parse(error.serverResponse);
                console.error(`Server response for file ${fileName}:`, errorPayload);
              } catch (e) {
                console.error(`Error parsing server response for file ${fileName}:`, e);
              }
            }

            reject(error);
          },
          () => {
            console.log(`Upload successful for file: ${fileName}`);
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log(`Download URL for file ${fileName}: ${downloadURL}`);
              resolve(downloadURL);
            });
          }
        );
      });
  
      if (blob.close) {
        console.log(`Closing blob for file: ${fileName}`);
        blob.close();
      }

      return await getDownloadURL(uploadTask.snapshot.ref);
    } catch (error) {
      console.error(`Error during upload for file: ${fileName}, Retry count: ${retries - retryCount + 1}`);
      if (retryCount > 0) {
        console.log(`Retrying upload for file: ${fileName} (${retries - retryCount + 1}/3) after delay of ${delayTime}ms...`);
        await delay(delayTime);
        return attemptUpload(retryCount - 1);
      } else {
        console.error(`Upload failed after 3 retries for file: ${fileName}`);
        alert("Low internet connection, please try again");
        throw error; // Re-throw to be caught by caller
      }
    }
  };
  
  const result = await attemptUpload(retries);
  console.log(`Final download URL for file ${fileName}: ${result}`);
  return result;
};




const prepareThumbnail = async (uri) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = uri;
    
    img.onload = () => {
      const { width, height } = img;

      // Calculate target dimensions for 16:9 aspect ratio
      let targetWidth, targetHeight;
      if (width / height > 16 / 9) {
        // Image is wider than 16:9
        targetHeight = height;
        targetWidth = targetHeight * (16 / 9);
      } else {
        // Image is narrower than 16:9 or equal
        targetWidth = width;
        targetHeight = targetWidth / (16 / 9);
      }

      // Calculate position to start cropping
      const cropX = (width - targetWidth) / 2;
      const cropY = (height - targetHeight) / 2;

      // Create a canvas to crop the image
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      ctx.drawImage(img, cropX, cropY, targetWidth, targetHeight, 0, 0, targetWidth, targetHeight);

      // Convert canvas to a data URL
      canvas.toDataURL('image/jpeg', (dataUrl) => {
        resolve(dataUrl);
      });
    };

    img.onerror = reject;
  });
};

const [alertMessage, setAlertMessage] = useState('');


const handleSubmit = async () => {
  try {
    setCurrentlyReleasing(true);
    
    const userId = getCurrentUserId();
    

    const extractInfoFromTag = (tag) => {
      const parts = tag.split(' - ');
      return {
        username: parts.length > 1 ? parts[0] : null,
        artistName: parts.length > 1 ? parts[1] : tag
      };
    };

    if (albumMode) {
      
      const sanitizedAlbumName = albumDetails.albumName.replace(/[^a-zA-Z0-9]/g, '_');
      const albumThumbnailURL = await uploadVideoThumbnail(albumDetails.artworkFile);


      const albumData = {
        username: extractInfoFromTag(albumDetails.artistName.tag).username,
        isAlbum: true,
        isVideo: true,
        userId,
        ArtistName: extractInfoFromTag(albumDetails.artistName.tag).artistName,
        ArtistNameLower: extractInfoFromTag(albumDetails.artistName.tag).artistName.toLowerCase(),
        artistId: albumDetails.artistName.id,
        albumName: albumDetails.albumName,
        VideoThumbnailURL: albumThumbnailURL,
        genres: albumDetails.genres,
        artistFeatures: albumDetails.artistFeatures,
        producers: albumDetails.producers,
        songwriters: albumDetails.songwriters,
        recordLabels: albumDetails.recordLabels,
        videos: tracks.map(track => ({
          title: track.title,
          VideoURL: track.songURL,
          VideoThumbnailURL: track.artworkURL
        }))
      };

      
      await submitMovie({ album: albumData });
      
    } else {
      

      if (!currentTrackDetails.title || !currentTrackDetails.artworkURL || !currentTrackDetails.genres.length || !currentTrackDetails.producers.length || !currentTrackDetails.songwriters.length || !currentTrackDetails.recordLabels.length) {
        
        setAlertMessage('Please fill in all required fields.');
        setCurrentlyReleasing(false);
        return;
      }

      const videoThumbnailURL = await uploadVideoThumbnail(currentTrackDetails.artworkFile);

      const singleVideoData = {
        username: extractInfoFromTag(currentTrackDetails.artistName.tag).username,
        isAlbum: false,
        isVideo: true,
        userId,
        ArtistName: extractInfoFromTag(currentTrackDetails.artistName.tag).artistName,
        ArtistNameLower: extractInfoFromTag(currentTrackDetails.artistName.tag).artistName.toLowerCase(),
        MovieTitle: currentTrackDetails.title,
        songNameLower: currentTrackDetails.title.toLowerCase(), 
        VideoThumbnailURL: videoThumbnailURL,
        ArtworkURL: videoThumbnailURL,
        genres: currentTrackDetails.genres,
        directors: currentTrackDetails.directors,
        producers: currentTrackDetails.producers,
        cast: currentTrackDetails.songwriters,
        link: currentTrackDetails.link,
        premiereDate: selectedDate,
        movieRating: selectedRating,
        productionCompany: currentTrackDetails.recordLabels,
        collaborators: collaborators,
      };

      
      await submitMovie({ videos: [singleVideoData] });
      

      alert('Your film has been saved under My Submissions in MYDAT. Pay the submission fee on spndat.com for it to be reviewed.');
    setCurrentlyReleasing(false);
  setIsReviewing(false);
  resetForm();
}
  } catch (error) {
    console.error('Error with the music release process:', error);
    setCurrentlyReleasing(false);
  }
};


function resetForm() {

  setAlbumDetails((prevState) => ({
    ...prevState, // Spread the previous state
    albumName: '',
    artworkURL: '',
    genres: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
    artistFeatures: [],
    tags: []
    // artistName is not reset here, preserving its current value
  }));

  setCurrentTrackDetails((prevState) => ({
    ...prevState, // Spread the previous state
    title: '',
    artworkURL: '',
    genres: [],
    producers: [],
    songwriters: [],
    recordLabels: [],
    artistFeatures: [],
    tags: [],
    lyrics: ''
    // artistName is not reset here, preserving its current value
  }));

  // Assuming setTracks is a setter for track details, resetting it as before
  setTracks([]);
}







useEffect(() => {
  if (currentUser) {
    setAlbumDetails(prev => ({
      ...prev,
      artistName: {
        tag: `@${currentUser.username} - ${currentUser.name}`, 
        id: currentUser.uid, 
      },
    }));

    setCurrentTrackDetails(prev => ({
      ...prev,
      artistName: {
        tag: `@${currentUser.username} - ${currentUser.name}`, 
        id: currentUser.uid, 
      },
    }));
  }
}, [currentUser]);



    
const selectProducer = (user) => {
  if (albumDetails.producers.length >= 5) {
    return;
  }

  const newProducer = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    producers: [...prevState.producers, newProducer]
  }));

  setSearchQueryProducers('');
  setIsSearchingProducers(false);
};


const selectSongwriter = (user) => {
  if (albumDetails.producers.length >= 5) {
    return;
  }

  const newSongwriter = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    songwriters: [...prevState.songwriters, newSongwriter]
  }));

  setSearchQuerySongwriters('');
  setIsSearchingSongwriters(false);
};




const selectArtistFeature = (user) => {
  if (albumDetails.artistFeatures.length >= 5) {
    return;
  }

  const newArtistFeature = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    artistFeatures: [...prevState.artistFeatures, newArtistFeature]
  }));

  setSearchQueryArtistFeatures('');
  setIsSearchingArtistFeatures(false);
};

const selectArtistName = (user) => {
  if (albumDetails.artistName) {
    return;
  }

  const newArtistName = {
    tag: `@${currentUser.username} - ${currentUser.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    artistName: newArtistName
  }));

  setSearchQueryArtistName('');
  setIsSearchingArtistName(false);
};



const selectRecordLabel = (user) => {
  if (albumDetails.recordLabels.length >= 3) {
    return;
  }

  const newRecordLabel = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setAlbumDetails(prevState => ({
    ...prevState,
    recordLabels: [...prevState.recordLabels, newRecordLabel]
  }));

  setSearchQueryRecordLabels('');
  setIsSearchingRecordLabels(false);
};



const getPickerItems = () => {
  const items = [{ label: 'Album Details', value: 'Album Details' }];
  tracks.forEach((track, index) => {
    const label = track.name || `Track ${index + 1}`;
    items.push({ label, value: label });
  });
  return items;
};



useEffect(() => {
  if (currentForm === 'Add Track') {
    setCurrentTrackDetails({
      title: '',
      artworkURL: albumDetails.artworkURL,
      artworkFile: albumDetails.artworkFile,
      songURL: '',
      genres: albumDetails.genres || [],
      tags: albumDetails.tags || [],
      producers: albumDetails.producers,
      artistFeatures: albumDetails.artistFeatures,
      songwriters: albumDetails.songwriters,
      recordLabels: albumDetails.recordLabels,
      lyrics: '',
      trackNumber: tracks.length + 1,
      artistName: albumDetails.artistName ? { 
        id: albumDetails.artistName.id, 
        tag: albumDetails.artistName.tag 
      } : null,
    });
  }
}, [currentForm, tracks.length, albumDetails]);






    
useEffect(() => {
  const search = async () => {
    if (searchQueryProducers.startsWith("@") && searchQueryProducers.length > 1) {
      setIsSearchingProducers(true);
      const searchValue = searchQueryProducers.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');

      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsProducers(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsProducers([]);
      }
    } else {
      setIsSearchingProducers(false);
      setSearchResultsProducers([]);
    }
  };

  if (searchQueryProducers) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQueryProducers]);



    
useEffect(() => {
  const search = async () => {
    if (searchQuerySongwriters.startsWith("@") && searchQuerySongwriters.length > 1) {
      setIsSearchingSongwriters(true);
      const searchValue = searchQuerySongwriters.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to target 'username_lower' for case-insensitive search
      // and add a limit to the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsSongwriters(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsSongwriters([]);
      }
    } else {
      setIsSearchingSongwriters(false);
      setSearchResultsSongwriters([]);
    }
  };

  if (searchQuerySongwriters) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQuerySongwriters]);



const selectCollaborator = (user, index) => {
  setCollaborators(prevCollaborators => {
    return prevCollaborators.map((collaborator, idx) => {
      if (idx === index) {
        // Update the selected collaborator with the userId
        return {
          ...collaborator,
          name: `@${user.username} (${user.name})`, // Format the selected collaborator's name
          userId: user.userId, // Add the userId of the selected collaborator
          searchQuery: '', // Clear the search query
          isSearching: false, // Stop the search
          searchResults: [], // Clear the search results
        };
      } else {
        // Ensure all other collaborators' search results are cleared
        return {
          ...collaborator,
          searchQuery: '',
          isSearching: false,
          searchResults: []
        };
      }
    });
  });

  // Optionally, clear any global search input and search results state
  setSearchQueryCollaborator('');
  setSearchResultsCollaborator([]);
  setIsSearchingCollaborator(false);
};


const [searchQueries, setSearchQueries] = useState([]);

const handleCollaboratorChange = (index, field, value) => {
  setCollaborators(prevCollaborators => {
    const updatedCollaborators = prevCollaborators.map((collaborator, idx) => {
      if (idx === index) {
        // Update the collaborator being edited
        return {
          ...collaborator,
          [field]: value,
          isSearching: field === 'searchQuery' ? true : collaborator.isSearching,
        };
      } else if (field === 'searchQuery') {
        // Clear search results for other collaborators
        return {
          ...collaborator,
          searchQuery: '',
          searchResults: [],
          isSearching: false,
        };
      }
      return collaborator;
    });

    // Log the updated collaborators object to see its structure
    console.log('Updated collaborators:', updatedCollaborators);
    return updatedCollaborators;
  });

  if (field === 'searchQuery') {
    setSearchQueries(prevQueries => {
      const updatedQueries = [...prevQueries];
      updatedQueries[index] = value;
      return updatedQueries;
    });
  }
};




useEffect(() => {
  const searchCollaborators = async () => {
    const currentSearchQueryIndex = searchQueries.findIndex(searchQuery => searchQuery.startsWith("@") && searchQuery.length > 1);

    // Clear search results for all other collaborators before starting a new search
    setCollaborators(prevCollaborators => {
      return prevCollaborators.map((collaborator, idx) => {
        if (idx !== currentSearchQueryIndex) {
          return {
            ...collaborator,
            searchResults: [],
            isSearching: false,
          };
        }
        return collaborator;
      });
    });

    const searchPromises = searchQueries.map(async (searchQuery, index) => {
      if (searchQuery.startsWith("@") && searchQuery.length > 1) {
        const searchValue = searchQuery.substring(1).toLowerCase();
        const usersRef = collection(firestore, 'users');
        const q = query(
          usersRef,
          where('username_lower', '>=', searchValue),
          where('username_lower', '<=', searchValue + '\uf8ff'),
          limit(3)
        );

        try {
          const querySnapshot = await getDocs(q);
          const users = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));

          setCollaborators(prevCollaborators => {
            return prevCollaborators.map((collaborator, idx) => {
              if (idx === index) {
                return {
                  ...collaborator,
                  searchResults: users,
                  isSearching: false,
                };
              }
              return collaborator;
            });
          });

        } catch (error) {
          console.error("Error searching users:", error);
          setCollaborators(prevCollaborators => {
            return prevCollaborators.map((collaborator, idx) => {
              if (idx === index) {
                return {
                  ...collaborator,
                  searchResults: [],
                  isSearching: false,
                };
              }
              return collaborator;
            });
          });
        }
      }
    });

    await Promise.all(searchPromises);
  };

  if (searchQueries.some(searchQuery => searchQuery.startsWith("@") && searchQuery.length > 1)) {
    searchCollaborators();
  }
}, [searchQueries]);




useEffect(() => {
  const search = async () => {
    if (searchQueryArtistFeatures.startsWith("@") && searchQueryArtistFeatures.length > 1) {
      setIsSearchingArtistFeatures(true);
      const searchValue = searchQueryArtistFeatures.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to use 'username_lower' field for case-insensitive search
      // and add a limit to the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsArtistFeatures(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsArtistFeatures([]);
      }
    } else {
      setIsSearchingArtistFeatures(false);
      setSearchResultsArtistFeatures([]);
    }
  };

  if (searchQueryArtistFeatures) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Debounce the search to run 300ms after the user stops typing

    return () => clearTimeout(delayDebounce); // Cleanup function to cancel the timeout
  }
}, [searchQueryArtistFeatures]);

useEffect(() => {
  const search = async () => {
    // Check if the search query starts with "@" and has more than one character
    if (searchQueryArtistName.startsWith("@") && searchQueryArtistName.length > 1) {
      setIsSearchingArtistName(true);
      // Extract the search value, omitting the "@" symbol and converting to lowercase
      const searchValue = searchQueryArtistName.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Update the query to target the 'username_lower' field for a case-insensitive search
      // and limit the number of results
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the number of results to 3
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsArtistName(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsArtistName([]);
      }
    } else {
      // Reset the search if the conditions aren't met
      setIsSearchingArtistName(false);
      setSearchResultsArtistName([]);
    }
  };

  // Debounce the search to reduce the frequency of Firestore queries
  if (searchQueryArtistName) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // Wait 300ms after the user stops typing

    // Cleanup function to cancel the timeout when the component unmounts or the effect reruns
    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryArtistName]);




useEffect(() => {
  const search = async () => {
    // Check if the query starts with "@" and has more than one character
    if (searchQueryRecordLabels.startsWith("@") && searchQueryRecordLabels.length > 1) {
      setIsSearchingRecordLabels(true);
      const searchValue = searchQueryRecordLabels.substring(1).toLowerCase();
      const usersRef = collection(firestore, 'users');
      // Adjust the query to target a lowercase field for case-insensitive search,
      // here I'm assuming 'username_lower' is the field you're interested in
      const q = query(
        usersRef, 
        where('username_lower', '>=', searchValue), 
        where('username_lower', '<=', searchValue + '\uf8ff'),
        limit(3) // Limit the results to improve efficiency
      );

      try {
        const querySnapshot = await getDocs(q);
        const users = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setSearchResultsRecordLabels(users);
      } catch (error) {
        console.error("Error searching users:", error);
        setSearchResultsRecordLabels([]);
      }
    } else {
      setIsSearchingRecordLabels(false);
      setSearchResultsRecordLabels([]);
    }
  };

  // Debounce the search to prevent excessive Firestore queries
  if (searchQueryRecordLabels) {
    const delayDebounce = setTimeout(() => {
      search();
    }, 300); // 300ms delay

    // Cleanup to cancel the timeout when the component unmounts or the input changes
    return () => clearTimeout(delayDebounce);
  }
}, [searchQueryRecordLabels]);


const renderSingleSongForm = () => {
  return renderTrackFormContent(currentTrackDetails);
};




const renderAlbumDetailsModule = () => {
  if (!albumDetailsVisible) return null;

  return (
    <div style={styles.albumInfoContainer}>
      <img
        src={albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Album Artwork"
      />
      <p style={styles.albumInfoText}>
        {`Album Name:\n${albumDetails.albumName}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Artist:\n${albumDetails.artistName ? albumDetails.artistName.tag : 'Not selected'}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Features:\n${albumDetails.artistFeatures.map(s => s.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Produced By:\n${albumDetails.producers.map(producer => producer.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Written By:\n${albumDetails.songwriters.map(songwriter => songwriter.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Record Label:\n${albumDetails.recordLabels.map(recordLabel => recordLabel.tag).join(', ')}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Genre:\n${albumDetails.genres.join(', ')}`}
      </p>
    </div>
  );
};



const renderAlbumDetailsModuleLinked = () => {
  if (!albumDetailsVisible) return null;

  const features = albumDetails.artistFeatures?.map(f => f.tag).join(', ') || 'None';
  const producers = albumDetails.producers?.map(p => p.tag).join(', ') || 'None';
  const songwriters = albumDetails.songwriters?.map(s => s.tag).join(', ') || 'None';
  const recordLabels = albumDetails.recordLabels?.map(r => r.tag).join(', ') || 'None';
  const genres = albumDetails.genres?.join(', ') || 'None';
  const tags = albumDetails.tags?.join(', ') || 'None';

  return (
    <div style={styles.albumInfoContainer}>
      <img
        src={albumDetails.artworkURL}
        style={styles.imagePreview}
        alt="Album Artwork"
      />
      <p style={styles.albumInfoText}>
        {`Album Name:\n${albumDetails.albumName}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Artist:\n${albumDetails.artistName?.tag || 'Not selected'}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Features:\n${features}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Produced By:\n${producers}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Written By:\n${songwriters}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Record Label:\n${recordLabels}`}
      </p>
      <p style={styles.albumInfoText}>
        {`Genre:\n${genres}`}
      </p>
    </div>
  );
};












const renderTrackForm = () => {
  if (currentForm === 'Add Track') {

    return renderTrackFormContent(currentTrackDetails);
  } else if (currentForm === 'Album Details') {
    return renderAlbumDetailsForm();
  } else {
    const trackNumber = parseInt(currentForm.split(' ')[2], 10);
    const trackToEdit = tracks.find(track => track.trackNumber === trackNumber);
    return renderTrackFormContent(trackToEdit);
  }
};


const renderAlbumDetailsForm = () => {
  return (
    <div style={styles.scrollViewInnerContainer}>
      <h3 style={styles.subtitle3}>Artist</h3>

      {/* Display Selected Artist Name */}
      {albumDetails.artistName && (
        <div style={styles.listItem}>
          <p style={styles.tagText}>{albumDetails.artistName.tag}</p>
        </div>
      )}

      <h4 style={styles.subtitle4}>Album Name</h4>
      <input
        type="text"
        style={styles.inputWider}
        value={albumDetails.albumName}
        onChange={(e) =>
          albumMode
            ? setAlbumDetails({ ...albumDetails, albumName: e.target.value })
            : setSongTitle(e.target.value)
        }
        placeholder="Album Name"
        maxLength={30}
      />
      <div style={styles.buttonContainer}>
        <input type="file" accept="image/*" onChange={pickArtwork} style={{ display: 'none' }} ref={artworkInputRef} />
        <button onClick={() => artworkInputRef.current.click()} style={styles.artworkButton}>Album Artwork</button>
        {albumDetails.artworkURL && (
          <img
            src={albumDetails.artworkURL}
            style={styles.imagePreview}
            alt="Artwork Preview"
          />
        )}
      </div>

      <h2 style={styles.subtitle2}>Genres</h2>

      {/* Display added genres */}
      <div style={styles.tagList}>
        {albumDetails.genres.map((genre, index) => (
          <div key={index} style={styles.tagItem}>
            <p style={styles.tagText}>{genre}</p>
            <button onClick={() => removeGenre(genre)} style={{ ...styles.removeButton, ...styles.whiteText }}>X</button>
          </div>
        ))}
      </div>

      <div style={styles.inputContainer}>
        <div style={styles.pickerContainer}>
          <select onChange={(e) => setSelectedGenre(e.target.value)} style={pickerSelectStyles}>
            <option value={null}>Select Genre...</option>
            {genresList.map((genre, index) => (
              <option key={index} value={genre.value}>{genre.label}</option>
            ))}
          </select>
        </div>
        <button onClick={addGenre} style={styles.customButtonStyle2}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      <h2 style={styles.subtitle2}>Directors</h2>
      {isSearchingArtistFeatures &&
        searchResultsArtistFeatures.map((user, index) => (
          <button key={index} onClick={() => selectArtistFeature(user)}>
            <p style={{ ...styles.userItem, color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </button>
        ))
      }
      {albumDetails.artistFeatures.map((artistFeature) => (
        <div key={artistFeature.id} style={styles.listItem}>
          <p style={styles.tagText}>{artistFeature.tag}</p>
          <button onClick={() => removeArtistFeature(artistFeature.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          value={searchQueryArtistFeatures}
          onChange={(e) => setSearchQueryArtistFeatures(e.target.value)}
          placeholder="Feature's Name or @username"
          
          maxLength={50}
        />
        <button onClick={addArtistFeature} style={styles.customButtonStyle}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      <h2 style={styles.subtitle2}>Producers</h2>
      {isSearchingProducers &&
        searchResultsProducers.map((user, index) => (
          <button key={index} onClick={() => selectProducer(user)}>
            <p style={{ ...styles.userItem, color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </button>
        ))
      }
      {albumDetails.producers.map((producer) => (
        <div key={producer.id} style={styles.listItem}>
          <p style={styles.tagText}>{producer.tag}</p>
          <button onClick={() => removeProducer(producer.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          value={searchQueryProducers}
          onChange={(e) => setSearchQueryProducers(e.target.value)}
          placeholder="Producer's Name or @username"
          
          maxLength={50}
        />
        <button onClick={addProducer} style={styles.customButtonStyle}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      <h2 style={styles.subtitle2}>Songwriters</h2>
      {isSearchingSongwriters &&
        searchResultsSongwriters.map((user, index) => (
          <button key={index} onClick={() => selectSongwriter(user)}>
            <p style={{ ...styles.userItem, color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </button>
        ))
      }
      {albumDetails.songwriters.map((songwriter) => (
        <div key={songwriter.id} style={styles.listItem}>
          <p style={styles.tagText}>{songwriter.tag}</p>
          <button onClick={() => removeSongwriter(songwriter.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          value={searchQuerySongwriters}
          onChange={(e) => setSearchQuerySongwriters(e.target.value)}
          placeholder="Songwriter's Name or @username"
          maxLength={50}
          
        />
        <button onClick={addSongwriter} style={styles.customButtonStyle}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      <h2 style={styles.subtitle2}>Record Labels</h2>
      {isSearchingRecordLabels &&
        searchResultsRecordLabels.map((user, index) => (
          <button key={index} onClick={() => selectRecordLabel(user)}>
            <p style={{ ...styles.userItem, color: 'white' }}>{`@${user.username} (${user.name})`}</p>
          </button>
        ))
      }
      {albumDetails.recordLabels.map((recordLabel) => (
        <div key={recordLabel.id} style={styles.listItem}>
          <p style={styles.tagText}>{recordLabel.tag}</p>
          <button onClick={() => removeRecordLabel(recordLabel.id)} style={styles.removeButton}>Remove</button>
        </div>
      ))}

      <div style={styles.inputContainer}>
        <input
          type="text"
          style={styles.input}
          value={searchQueryRecordLabels}
          onChange={(e) => setSearchQueryRecordLabels(e.target.value)}
          placeholder="Record Label's Name or @username"
          maxLength={50}
          
        />
        <button onClick={addRecordLabel} style={styles.customButtonStyle}>
          <span style={styles.customButtonText2}>Add</span>
        </button>
      </div>

      {albumMode && !editingAlbumDetails && (
        <div>
          {!addingSongs && (
            <button onClick={handleAlbumDetailsSubmit} style={{ color: '#62fbfa' }}>
              Start Adding Songs
            </button>
          )}
        </div>
      )}
      {editingAlbumDetails && (
        <button onClick={handleAlbumDetailsSubmit} style={{ color: '#62fbfa' }}>
          Save Changes
        </button>
      )}
    </div>
  );
};




const handleAddNewTrack = () => {
  const newTrack = {
    ...currentTrackDetails,
    artistName: albumDetails.artistName ? { ...albumDetails.artistName } : null,
    trackNumber: tracks.length + 1
  };

  setTracks([...tracks, newTrack]);

  setCurrentForm('Add Track');
};




const renderTrackFormContent = (editingTrack = {}) => {
  const isEditingExistingTrack = editingTrack && editingTrack.trackNumber;
  const trackDetails = isEditingExistingTrack ? editingTrack : currentTrackDetails;

  const handleGenreSelect = (event) => {
    setSelectedGenre(event.target.value);
  };


const addTrackGenre = () => {
  if (selectedGenre) {
    if (!currentTrackDetails.genres.includes(selectedGenre) && currentTrackDetails.genres.length < 3) {
      setCurrentTrackDetails(prevState => ({
        ...prevState,
        genres: [...prevState.genres, selectedGenre]
      }));
    }
    setSelectedGenre(null);
  }
};

const handleRemoveTrackGenre = (index) => {
  setCurrentTrackDetails(prevState => ({
    ...prevState,
    genres: prevState.genres.filter((_, i) => i !== index)
  }));
};






const selectTrackProducer = (user) => {
  if (currentTrackDetails.producers.length >= 5) {
    return;
  }

  const newProducer = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    producers: [...prevState.producers, newProducer]
  }));

  setSearchQueryProducers('');
  setIsSearchingProducers(false);
};


const handleAddProducer = () => {
  const input = searchQueryProducers.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.producers.some(p => p.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        producers: [...prevDetails.producers, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryProducers('');
};





const handleRemoveProducer = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    producers: prevDetails.producers.filter((_, i) => i !== index)
  }));
};


const selectTrackSongwriter = (user) => {
  if (currentTrackDetails.songwriters.length >= 5) {
    return;
  }

  const newSongwriter = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    songwriters: [...prevState.songwriters, newSongwriter]
  }));

  setSearchQuerySongwriters('');
  setIsSearchingSongwriters(false);
};
    
    
const selectTrackArtistFeature = (user) => {
  if (currentTrackDetails.directors.length >= 5) {
    alert("Limit reached", "You can only add up to 5 Features for a track.");
    return;
  }

  const newDirector = {
    tag: `@${user.username} - ${user.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    directors: [...prevState.directors, newDirector]
  }));

  setSearchQueryArtistFeatures('');
  setIsSearchingArtistFeatures(false);
};


const selectTrackRecordLabel = (user) => {
  if (currentTrackDetails.recordLabels.length >= 3) {
    return;
  }

  const newRecordLabel = {
        tag: `@${user.username} - ${user.name}`,
        id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    recordLabels: [...prevState.recordLabels, newRecordLabel]
  }));

  setSearchQueryRecordLabels('');
  setIsSearchingRecordLabels(false);
};

const selectTrackArtistName = (user) => {
  if (currentTrackDetails.artistName) {
    return;
  }

  const newArtistName = {
    tag: `@${currentUser.username} - ${currentUser.name}`,
    id: user.id
  };

  setCurrentTrackDetails(prevState => ({
    ...prevState,
    artistName: newArtistName
  }));

  setSearchQueryArtistName('');
  setIsSearchingArtistName(false);
};




const handleAddSongwriter = () => {
  const input = searchQuerySongwriters.trim();

  if (input && !input.startsWith("@")) {
    if (!currentTrackDetails.songwriters.some(s => s.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        songwriters: [...prevDetails.songwriters, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQuerySongwriters('');
};

const handleAddArtistFeature = () => {
  const input = searchQueryArtistFeatures.trim();

  if (input) {
    if (!currentTrackDetails.directors.some(a => a.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        directors: [...prevDetails.directors, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryArtistFeatures('');
};


const handleAddRecordLabel = () => {
  const input = searchQueryRecordLabels.trim();

  if (input) {
    if (!currentTrackDetails.recordLabels.some(r => r.tag === input)) {
      setCurrentTrackDetails(prevDetails => ({
        ...prevDetails,
        recordLabels: [...prevDetails.recordLabels, { tag: input, id: Date.now().toString() }]
      }));
    } else {
    }
  }

  setSearchQueryRecordLabels('');
};

const handleRemoveRecordLabel = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    recordLabels: prevDetails.recordLabels.filter((_, i) => i !== index)
  }));
};



const handleRemoveArtistName = () => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    artistName: null
  }));
};




const handleRemoveSongwriter = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    songwriters: prevDetails.songwriters.filter((_, i) => i !== index)
  }));
};

const handleRemoveArtistFeature = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    artistFeatures: prevDetails.artistFeatures.filter((_, i) => i !== index)
  }));
};



const handleAddTag = () => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    tags: [...prevDetails.tags, selectedTag]
  }));
};



const handleRemoveTag = (index) => {
  setCurrentTrackDetails(prevDetails => ({
    ...prevDetails,
    tags: prevDetails.tags.filter((_, tagIndex) => tagIndex !== index)
  }));
};

const pickTrackArtwork = async (event) => {
  const file = event.target.files[0];
  if (file) {
    

    const fileUrl = URL.createObjectURL(file);
    setCurrentTrackDetails(prevDetails => ({
      ...prevDetails,
      artworkFile: file,
      artworkURL: fileUrl,
    }));
    
    
  }
};

// In your component







const handleReview = () => {
  

  // Add genres to the track
  addTrackGenre();
  handleAddProducer();
  handleAddSongwriter();
  handleAddRecordLabel();
  handleAddArtistFeature();

  // Log current track details after attempting to add genres
  

  // Add a delay
  setTimeout(() => {
    setCurrentTrackDetails(prevDetails => {
      

      const newTrack = {
        ...prevDetails,
        trackNumber: tracks.length + 1,
        artworkFile: prevDetails.artworkFile // Ensure artworkFile is preserved
      };

      

      const updatedTracks = [...tracks, newTrack];

      // Update the tracks state
      setTracks(updatedTracks);

      // Update the form state
      setCurrentForm(`Edit Track ${newTrack.trackNumber}`);
      setEditingAlbumDetails(false);
      setFormMode('edit');

      setIsReviewing(true);

      
      return newTrack; // Ensure to return the newTrack object
    });
  }, 100); // 100 milliseconds delay
};




return (
  
  <div key={`track-form-${currentTrackDetails.trackNumber}`} style={styles.scrollViewInnerContainer2}>
    
          <style>
        {`
          .input-placeholder::placeholder {
            color: black; /* Change this to your desired placeholder text color */
          }
        `}
      </style>

    <h3 style={styles.subtitle3}>Filmmaker</h3>
    {/* Display Selected Artist */}
    <div style={styles.list}>
      {currentTrackDetails.artistName && (
        <div key={currentTrackDetails.artistName.id} style={styles.listItem}>
          <span style={styles.tagText}>{currentTrackDetails.artistName.tag}</span>
        </div>
      )}
    </div>

    <input
      type="text"
      style={styles.inputWider}
      value={titleInput}
      onChange={handleTitleChange}
      placeholder="Movie Title"
      placeholderTextColor="white"
      maxLength={30}
    />

<input
      type="text"
      style={styles.inputWider}
      value={linkInput}
      onChange={handleLinkChange}
      placeholder="Dropbox/Google Drive Link"
      placeholderTextColor="white"
      maxLength={3000}
    />

<div style={{ textAlign: 'center', marginTop: '1%' }}>
      {selectedDate && (
        <p style={{ color: 'white', fontSize: '16px' }}>
          Selected Premiere Date: {formatDate(selectedDate)}
        </p>
      )}
      <button onClick={showCalendar} style={styles.artworkButton}>
        Choose Premiere Date
      </button>
      {isCalendarVisible && (
        <div style={styles.modalOverlay} onClick={hideCalendar}>
          <div style={styles.calendarWrapper} onClick={(e) => e.stopPropagation()}>
            <Calendar
              onChange={handleDateChange}
              value={selectedDate}
              minDate={oneMonthLater}
              defaultActiveStartDate={oneMonthLater}
            />
            <button onClick={hideCalendar} style={styles.buttonCalendar}>
              Confirm Date
            </button>
          </div>
        </div>
      )}
    </div>

    {!albumMode && (
  <div style={{ marginTop: 20 }}>
    <button onClick={handleToggleCollaborators} style={styles.customButtonStyle2}>
      + Collaborators
    </button>
    {showCollaborators && (
      <div style={{ marginTop: 10 }}>
        {collaborators.map((collaborator, index) => (
          <div key={index} style={{ marginBottom: 10 }}>
            <p style={{ color: 'white', textAlign: 'center' }}>Collaborator {index + 1}</p>
            
            {/* Search Input */}
            <input
              style={styles.inputWider}
              placeholder="Search by @username"
              value={collaborator.searchQuery}
              onChange={(e) => handleCollaboratorChange(index, 'searchQuery', e.target.value)}
            />
            
            {collaborator.searchResults && collaborator.searchResults.length > 0 && collaborator.searchQuery && 
              collaborator.searchResults.map((user, idx) => (
                <div key={idx} onClick={() => selectCollaborator(user, index)} style={{ cursor: 'pointer', marginBottom: 5 }}>
                  <p style={{ color: 'white' }}>{`@${user.username} (${user.name})`}</p>
                </div>
              ))
            }

            {/* Display Selected Collaborator */}
            {collaborator.name && (
              <div style={styles.listItem}>
                <p style={styles.tagText}>{collaborator.name}</p>
                <button onClick={() => handleRemoveCollaborator(index)} style={styles.removeButtonC}>
                  Remove Collaborator
                </button>
              </div>
            )}

            {/* Percentage Input */}
            <div style={styles.inputContainer2}>
              <div style={styles.inputWrapper}>
                <input
                  style={styles.inputWider}
                  placeholder="Percentage"
                  value={collaborator.percentage}
                  onChange={(e) => {
                    let formattedText = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                    if (formattedText.startsWith('0')) {
                      formattedText = formattedText.slice(1); // Remove leading zero
                    }
                    formattedText = formattedText.slice(0, 2); // Limit to 2 digits
                    handleCollaboratorChange(index, 'percentage', formattedText);
                  }}
                  type="number"
                  maxLength={2}
                  disabled={!collaborator.name} // Disable if no collaborator is selected
                />
                <span style={styles.percentageSymbol}>%</span>
              </div>
            </div>

            <button onClick={() => handleRemoveCollaborator(index)} style={styles.removeButtonC}>
              Remove Collaborator
            </button>
          </div>
        ))}
        <button onClick={handleAddCollaborator} style={styles.customButtonStyle2}>
          {collaborators.length === 0 ? '+ Add Collaborator' : '+ Add Another Collaborator'}
        </button>
      </div>
    )}
  </div>
)}


    <input
      type="file"
      accept="image/*"
      ref={inputFileRef}
      style={{ display: 'none' }}
      onChange={pickTrackArtwork}
    />
    <div style={styles.buttonContainer}>
      <button onClick={() => artworkInputRef.current.click()} style={styles.artworkButton}>Choose Thumbnail</button>
    </div>
    <input type="file" accept="image/*" onChange={pickTrackArtwork} style={{ display: 'none' }} ref={artworkInputRef} />
    {currentTrackDetails.artworkURL && (
      <img
        src={currentTrackDetails.artworkURL}
        style={styles.imagePreview}
        alt="Artwork Preview"
      />
    )}

<h3 style={styles.subtitle2}>Movie Rating</h3>

<div style={styles.inputContainer}>
  {/* Rating Selection */}
  <div style={styles.pickerContainer2}>
    <select
      onChange={(event) => setSelectedRating(event.target.value)}
      value={selectedRating}
      style={pickerSelectStyles.input} // Use a general input style for the select element
    >
      <option value="" disabled>Select Rating...</option>
      <option value="G">G</option>
      <option value="PG">PG</option>
      <option value="PG-13">PG-13</option>
      <option value="R">R</option>
      <option value="NC-17">NC-17</option>
    </select>
  </div>
</div>


    <h3 style={styles.subtitle2}>Genres</h3>
    {/* Display Added Genres */}
    <div style={styles.tagList}>
      {currentTrackDetails.genres.map((genre, index) => (
        <div key={index} style={styles.tagItem}>
          <span style={styles.tagText}>{genre}</span>
          <button onClick={() => handleRemoveTrackGenre(index)} style={styles.removeButton}>X</button>
        </div>
      ))}
    </div>

    <div style={styles.inputContainer}>
      {/* Genre Selection */}
      <div style={styles.pickerContainer}>
        <select
          onChange={handleGenreSelect}
          value={selectedGenre}
          style={pickerSelectStyles.input}
        >
          <option value="" disabled>Select Genre...</option>
          {genresList.map((genre, index) => (
            <option key={index} value={genre.value}>{genre.label}</option>
          ))}
        </select>
      </div>
      <button onClick={addTrackGenre} style={styles.customButtonStyle2}>
        <span style={styles.customButtonText2}>Add</span>
      </button>
    </div>

    <h3 style={styles.subtitle2}>Directors</h3>
    {/* Display Added Artist Features */}
    <div style={styles.list}>
      {currentTrackDetails.directors.map((director, index) => (
        <div key={director.id} style={styles.listItem}>
          <span style={styles.tagText}>{director.tag}</span>
          <button onClick={() => handleRemoveArtistFeature(index)} style={styles.removeButton}>Remove</button>
        </div>
      ))}
    </div>

    {isSearchingArtistFeatures &&
      searchResultsArtistFeatures.map((user, index) => (
        <button key={index} onClick={() => selectTrackArtistFeature(user)} style={styles.userItem}>
          @{user.username} ({user.name})
        </button>
      ))
    }

    <div style={styles.inputContainer}>
      <input
        type="text"
        style={styles.input}
        value={searchQueryArtistFeatures}
        onChange={(e) => setSearchQueryArtistFeatures(e.target.value)}
        placeholder="Director's Name or @username"
        maxLength={50}
        
      />
      <button onClick={handleAddArtistFeature} style={styles.customButtonStyle}>
        <span style={styles.customButtonText2}>Add</span>
      </button>
    </div>

    <h3 style={styles.subtitle2}>Producers</h3>
    {/* Display Added Producers */}
    <div style={styles.list}>
      {currentTrackDetails.producers.map((producer, index) => (
        <div key={producer.id} style={styles.listItem}>
          <span style={styles.tagText}>{producer.tag}</span>
          <button onClick={() => handleRemoveProducer(index)} style={styles.removeButton}>Remove</button>
        </div>
      ))}
    </div>

    {isSearchingProducers &&
      searchResultsProducers.map((user, index) => (
        <button key={index} onClick={() => selectTrackProducer(user)} style={styles.userItem}>
          @{user.username} ({user.name})
        </button>
      ))
    }

    <div style={styles.inputContainer}>
      <input
        type="text"
        style={styles.input}
        value={searchQueryProducers}
        onChange={(e) => setSearchQueryProducers(e.target.value)}
        placeholder="Producer's Name or @username"
        maxLength={50}
        
      />
      <button onClick={handleAddProducer} style={styles.customButtonStyle}>
        <span style={styles.customButtonText2}>Add</span>
      </button>
    </div>

    <h3 style={styles.subtitle2}>Cast</h3>
    {/* Display Added Songwriters */}
    <div style={styles.list}>
      {currentTrackDetails.songwriters.map((songwriter, index) => (
        <div key={songwriter.id} style={styles.listItem}>
          <span style={styles.tagText}>{songwriter.tag}</span>
          <button onClick={() => handleRemoveSongwriter(index)} style={styles.removeButton}>Remove</button>
        </div>
      ))}
    </div>

    {isSearchingSongwriters &&
      searchResultsSongwriters.map((user, index) => (
        <button key={index} onClick={() => selectTrackSongwriter(user)} style={styles.userItem}>
          @{user.username} ({user.name})
        </button>
      ))
    }

    <div style={styles.inputContainer}>
      <input
        type="text"
        style={styles.input}
        value={searchQuerySongwriters}
        onChange={(e) => setSearchQuerySongwriters(e.target.value)}
        placeholder="Cast Member's Name or @username"
        maxLength={50}
        
      />
      <button onClick={handleAddSongwriter} style={styles.customButtonStyle}>
        <span style={styles.customButtonText2}>Add</span>
      </button>
    </div>

    <h3 style={styles.subtitle2}>Production Company</h3>
    <div style={styles.list}>
      {currentTrackDetails.recordLabels.map((recordLabel, index) => (
        <div key={recordLabel.id} style={styles.listItem}>
          <span style={styles.tagText}>{recordLabel.tag}</span>
          <button onClick={() => handleRemoveRecordLabel(index)} style={styles.removeButton}>Remove</button>
        </div>
      ))}
    </div>

    {isSearchingRecordLabels &&
      searchResultsRecordLabels.map((user, index) => (
        <button key={index} onClick={() => selectTrackRecordLabel(user)} style={styles.userItem}>
          @{user.username} ({user.name})
        </button>
      ))
    }

    <div style={styles.inputContainer}>
      <input
        type="text"
        style={styles.input}
        value={searchQueryRecordLabels}
        onChange={(e) => setSearchQueryRecordLabels(e.target.value)}
        placeholder="Production Company's Name or @username"
        maxLength={50}
        
      />
      <button onClick={handleAddRecordLabel} style={styles.customButtonStyle}>
        <span style={styles.customButtonText2}>Add</span>
      </button>
    </div>



    {currentTrackDetails.songURL && (
      <span style={styles.selectedSong}>
        {currentTrackDetails.videoDisplayName || 'Song file selected'}
      </span>
    )}

    {albumMode ? (
      <button onClick={formMode === 'edit' ? handleSaveTrackChanges : handleAddNewTrack} style={{ backgroundColor: 'transparent', color: "#62fbfa" }}>
        {formMode === 'edit' ? "Save Changes" : "Add Song"}
      </button>
    ) : (
      !isReviewing ? (
        <button onClick={handleReview} style={styles.reviewButton}>
          Review Submission
        </button>
      ) : (
        <button onClick={handleSaveSingleChanges} style={{ marginTop: '20px', backgroundColor: 'transparent', color: "#62fbfa" }}>
          Save Changes
        </button>
      )
    )}

  </div>
);

        };




        const [termsModalVisible, setTermsModalVisible] = useState(false);
        const [termsAccepted, setTermsAccepted] = useState(false);
        
        
        const DISPLAY_TEXT = `By selecting "Agree," you understand that your movie submission will be reviewed by SPNDAT to determine if it is appropriate to premiere on the platform. To proceed with the review, a non-refundable fee of $300 is required. This fee must be paid on our website, spndat.com, in the "My Submissions" section of My Content in MYDAT.`;



        return (
          
          <div style={styles.fullScreen}>
            
                  <style>
        {`
          .input-placeholder::placeholder {
            color: white; /* Change this to your desired placeholder text color */
          }
        `}
      </style>
      {alertMessage && (
      <div style={styles.alertOverlay} onClick={() => setAlertMessage('')}>
        <div style={styles.alertBox}>
          <p style={styles.alertText}>{alertMessage}</p>
        </div>
      </div>
    )}
            <div
              style={{
                ...styles.scrollContainer,
                paddingTop: scrollAreaTopPadding,
                paddingBottom: height * 0.1,
                overflowY: 'scroll',
              }}
            >
              
              <div style={styles.scrollViewInnerContainer}>
                <h1 style={styles.title}>Submit Film</h1>

                
        

        
                {renderPickerModal()}
        
                {albumDetails.isLinked ? (
                  <>
                    {renderAlbumDetailsModuleLinked()}
                    {renderTrackModulesLinked()}
                  </>
                ) : (
                  <>
                    {albumMode && renderTrackForm()}
        
                    {albumMode &&
                      tracks.map((track, index) => (
                        <div key={index} style={styles.trackModule}>
                          <p style={{ ...styles.trackInfo, color: 'white' }}>
                            Track {track.trackNumber}: {track.title}
                          </p>
                          <button onClick={() => removeTrack(track.trackNumber)} style={{ color: "#f63bb0" }}>
                            Remove
                          </button>
                        </div>
                      ))}
        
                    {!albumMode && renderSingleSongForm()}
        
                    {!albumMode && isReviewing && renderTrackModules()}
        
                    {!albumDetails.isLinked && renderAlbumDetailsModule()}
        
                    {albumMode && addingSongs && renderTrackModules()}
        
                    {!albumMode && isReviewing && tracks.length > 0 && (
                      <button onClick={handleSubmit} style={styles.button}>
                        Submit Film
                      </button>
                    )}
        
                    {(tracks.length >= 5 || albumDetails.isLinked) && (
                      <button onClick={handleSubmit} style={{ color: "#62fbfa" }}>
                        Submit Film
                      </button>
                    )}
                  </>
                )}
        
                <div
                  style={{
                    display: termsModalVisible ? 'block' : 'none',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }}
                  onClick={() => setTermsModalVisible(!termsModalVisible)}
                >
                  <div style={styles.centeredView} onClick={(e) => e.stopPropagation()}>
                    <div style={styles.modalView}>
                      <div style={{ marginBottom: 20, overflowY: 'scroll' }}>
                        <p style={styles.modalText}>{DISPLAY_TEXT}</p>
                      </div>
                      <div style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <button
                          style={styles.buttonAgree}
                          onClick={() => {
                            setTermsAccepted(true);
                            setTermsModalVisible(false);
                            handleSubmit();
                          }}
                        >
                          I Agree
                        </button>
                        <button
                          style={styles.buttonDisagree}
                          onClick={() => {
                            setTermsAccepted(false);
                            setTermsModalVisible(false);
                          }}
                        >
                          I Disagree
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div style={{ height: bottomAreaHeight, backgroundColor: 'transparent' }} />

            {editingLinkedTrack && (
              <>
                <img
                  src="./assets/DATBG.png"
                  style={styles.fullScreenImage2}
                  alt="DAT Background"
                />
    <input
      type="file"
      accept="video/*"
      ref={inputVideoRef}
      style={{ display: 'none' }}
      onChange={pickVideoLinked}
    />
    <button onClick={() => inputVideoRef.current.click()}>
      Upload Video File
    </button>
    <input
      type="file"
      accept="image/*"
      ref={inputFileRef}
      style={{ display: 'none' }}
      onChange={pickTrackThumbnail}
    />
    <button style={styles.artworkButton} onClick={() => inputFileRef.current.click()}>
      Choose Thumbnail
    </button>
              </>
            )}
                    <Modal
                    
  isOpen={currentlyReleasing}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      color: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center',
      zIndex: 1000000,
    }
  }}
>
  <h2>Submitting Movie...</h2>
  <div style={{ width: '100%', height: '20px', backgroundColor: '#f3f3f3', borderRadius: '5px', marginTop: '20px' }}>

        </div>
</Modal>
                          <div style={styles.extraSpace}></div>

          </div>
        );
        
};

const styles = {
  inputPlaceholder: {
    '::placeholder': {
      color: 'black', // Change this to your desired placeholder text color
    },
  },
  extraSpace: {
    height: '300px',
      },
      modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      },
      calendarWrapper: {
        backgroundColor: 'white',
        padding: '20px',
        width: '300px',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1001,
      },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    bottom: 130,
    position: 'relative',
    zIndex: 10,
  },
  removeButton: {
    backgroundColor: '#852061',
    color: 'white',
    border: 'none',
    padding: '5px 10px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    marginTop: '10px',
    fontFamily: 'brhendrix',
  },
  reviewButton: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    border: 'none',
    padding: 10,
    fontSize: '16px',
    borderRadius: 5,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100vh',
    position: 'relative',
    zIndex: 10,
  },
  fullScreenImageOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  },
  fullScreenImage: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 0,
  },
  fullScreenImage2: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 100,
  },

  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',

    marginTop: '10px',
    zIndex: 3,
    color: 'white',
    marginBottom: 20,
  },
  genresTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
    marginTop: '5px',
    marginBottom: '-10px',
    zIndex: 3,
    color: 'white',
  },
  subtitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginTop: 20,
    zIndex: 3,
    fontFamily: 'brhendrix',
  },
  subtitle2: {
    fontSize: '16px',
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    marginBottom: 8,
    marginTop: 15,
    zIndex: 3,
    color: 'white',
  },
  alertOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black background with 0.7 opacity
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 1000, // Ensure it is on top of other content
  },
  alertBox: {
    backgroundColor: 'black', // Black background
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center',
  },
  alertText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  subtitle3: {
    fontSize: '20px',
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8,
    alignSelf: 'center',
    zIndex: 3,
    color: 'white',
  },
  subtitle4: {
    fontSize: '16px',
    fontWeight: 'regular',
    fontFamily: 'brhendrix',
    marginBottom: 8,
    marginTop: 8,
    zIndex: 3,
    color: 'white',
  },
  artworkButton: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    fontSize: '16px',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: '15px',
    display: 'flex',
    padding: '10px',
    borderRadius: '5px',
    marginBottom: '10px',
    borderWidth: '1px',
    borderColor: 'gray',
    borderStyle: 'solid',
    boxSizing: 'border-box',
  },
  inputWider: {
    width: '87%',
    fontFamily: 'brhendrix',
    fontSize: '16px',
    textAlign: 'left',
    padding: '8px',
    backgroundColor: 'transparent',
    marginBottom: '4px',
    marginTop: '20px',
    borderWidth: '1px',
    borderColor: 'gray',
    color: 'white',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '8px',
    zIndex: 3,
    boxSizing: 'border-box',
    borderStyle: 'solid', // Ensure a consistent border style
  },
  
  inputWider2: {
    width: '87%',
    alignSelf: 'center',
    display: 'flex',
    textAlign: 'left',
    color: 'white',
    justifyContent: 'center',
    fontSize: '16px',
    fontFamily: 'brhendrix',
    padding: 8,
    marginBottom: 4,
    marginTop: 4,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 8,
    zIndex: 3,
  },
  
  input: {
    width: '87%',
    color: 'white',
    padding: '5px', // Increase padding for a larger input area
    fontSize: '16px', // Increase font size for better readability
    textAlign: 'left', // Centered text
    marginBottom: 20, // Increase margin bottom for spacing
    borderRadius: 5, // Rounded corners
    border: '1px solid #ccc', // Border styling
    backgroundColor: 'transparent', // Transparent background
  },
  inputContainer: {
    width: '87%',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  imagePreview: {
    width: '60%',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',

    height: 'auto',
    aspectRatio: '16 / 9',
    borderRadius: 8,
    marginBottom: 10,
    zIndex: 3,
  },
  tagList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 10,
    zIndex: 3,
    fontFamily: 'brhendrix',
  },
  tagText: {
    zIndex: 3,
    fontFamily: 'brhendrix',
    color: 'white',
    fontSize: '16px',
  },
  tagItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#240f48',
    padding: '5px 10px',
    borderRadius: 20,
    marginVertical: 5,
    marginRight: 5,
    fontFamily: 'brhendrix',
    zIndex: 3,
  },
  removeButton: {
    marginLeft: '2%',
    color: '#f63bb0',
    zIndex: 3,
    fontFamily: 'brhendrix',
    fontSize: '16px',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#240f48',
    padding: '5px 10px',
    borderRadius: 5,
    marginVertical: 5,
    zIndex: 3,
  },
  customButtonStyle: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    fontSize: '16px',
    justifyContent: 'center',
    alignSelf: 'center',
    
    display: 'flex',
    padding: '10px',
    border: 'none',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  customButtonStyle2: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    fontSize: '16px',
    justifyContent: 'center',
    alignSelf: 'center',
    
    display: 'flex',
    padding: '10px',
    border: 'none',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  customButtonText: {
    color: "#62fbfa",
    fontSize: '16px',
  },
  customButtonText2: {
    color: "#62fbfa",
    fontSize: '16px',
  },
  selectedSong: {
    marginTop: 10,
    fontSize: '2.4vw',
    zIndex: 3,
    fontFamily: 'brhendrix',
    color: 'white',
  },
  songItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 5,
    zIndex: 3,
  },
  songTitle: {
    fontSize: '2.4vw',
    fontFamily: 'brhendrix',
  },
  scrollViewContainer: {
    padding: 20,
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 20,
    overflowY: 'auto', // Ensure vertical scrolling for the entire container
  },
  scrollViewInnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '23px',
    justifyContent: 'flex-start',
    minHeight: '100%',
    width: '100%',
    paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
    zIndex: 10,
  },
  scrollViewInnerContainer2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '100%',
    paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
    zIndex: 10,
  },
  scrollViewInnerContainerCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingBottom: bottomAreaHeight, // Prevent overlap with the bottom area
    zIndex: 10,
  },
  modeToggleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  activeModeText: {
    color: '#62fbfa',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '20px',
    marginHorizontal: 10,
    fontFamily: 'brhendrix',
  },
  inactiveModeText: {
    color: '#b3b3b3',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '20px',
    marginHorizontal: 10,
    fontFamily: 'brhendrix',
  },
  wheelSelector: {
    width: 150,
    height: 150,
  },
  albumInfoContainer: {
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
    alignItems: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  albumInfoText: {
    color: 'white',
    textAlign: 'center',
    fontSize: '2.4vw',
    marginHorizontal: 10,
    fontFamily: 'brhendrix',
    padding: 3,
  },
  trackInfo: {
    color: 'white',
    textAlign: 'center',
    fontSize: '2.4vw',
    marginHorizontal: 10,
    fontFamily: 'brhendrix',
    padding: 3,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  overlayContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionButton: {
    backgroundColor: '#ffffff',
    padding: 10,
    marginBottom: 10,
    borderRadius: 5,
    zIndex: 101,
  },
  popupImage: {
    position: 'absolute',
    resizeMode: 'cover',
  },
  button: {
    backgroundColor: 'transparent',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    color: '#62fbfa',
    marginBottom: '40px',
    zIndex: 1000,
  },
  buttonText: {
    color: '#62fbfa',
    fontSize: 16,
  },
  buttonCalendar: {
    backgroundColor: 'transparent',
    padding: 10,
    borderRadius: 5,
    alignItems: 'center',
    color: 'black',
    marginBottom: '40px',
    zIndex: 1000,
  },
  centeredView: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  buttonAgree: {
    backgroundColor: "#2196F3",
    borderRadius: 10,
    padding: 10,
    elevation: 2,
    marginVertical: 10,
    marginLeft: '26%',
  },
  modalView: {
    marginTop: '29%',
    marginBottom: '42%',
    backgroundColor: "black",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
  },
  modalText: {
    color: "white",
    marginBottom: '2vh',
    fontSize: '3.5vw',
    lineHeight: 30,
    marginTop: '2vh',
    fontFamily: 'brhendrix',
    textAlign: "center",
  },
  textStyle: {
    color: "white",
    fontWeight: "bold",
    textAlign: "center",
  },
  buttonDisagree: {
    backgroundColor: "#f44336",
    borderRadius: 10,
    padding: 10,
    elevation: 2,
    marginVertical: 10,
  },
};


    export default ReleaseFilm;


