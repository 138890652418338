import React, { useState, useEffect } from 'react';

const RealTimeAgo = ({ timestamp, style }) => {
  const [timeAgo, setTimeAgo] = useState('');

  const formatTimeAgo = (timestamp) => {
    const postDate = timestamp?.toDate ? timestamp?.toDate() : new Date(timestamp?.seconds * 1000);
    const now = new Date();
    const differenceInSeconds = Math.floor((now - postDate) / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);

    if (differenceInDays > 0) {
      return `${differenceInDays}d`;
    } else if (differenceInHours > 0) {
      return `${differenceInHours}h`;
    } else if (differenceInMinutes > 0) {
      return `${differenceInMinutes}m`;
    } else {
      return `${differenceInSeconds}s`;
    }
  };

  useEffect(() => {
    const updateTimeAgo = () => {
      setTimeAgo(formatTimeAgo(timestamp));
    };

    updateTimeAgo(); // Immediate update on mount

    const intervalId = setInterval(updateTimeAgo, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [timestamp]);

  return <span style={style}>{timeAgo}</span>;
};

export { RealTimeAgo };
