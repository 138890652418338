import React, { useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import defaultProfilePic from './assets/defaultProfilePic.png';
import ProfilePicBG from './assets/ProfilePicBG.png';
import ProfilePic from './assets/defaultProfilePic2.png';

const ScreenSKNPicSelector = ({ imageUri, onImagePicked, style }) => {
  const { currentUser, setUser, isViewingScreenSKN } = useContext(AppContext);

  useEffect(() => {
    if (isViewingScreenSKN === false) {
      setUser({ ...currentUser, screenSKNbuttonTemp: null, screenSKNbarTemp: null });
    }
  }, [isViewingScreenSKN, setUser, currentUser]);

  const profileImageSource = imageUri ? imageUri : ProfilePic;

  return (
    <div
      style={{ width: '27%', height: '14%', zIndex: 200, cursor: 'pointer', ...style }}
    >
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        accept="image/*"
        onChange={onImagePicked}
      />
      <div
        onClick={() => document.getElementById('fileInput').click()}
        style={{
          backgroundImage: `url(${ProfilePicBG})`,
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{
          width: '87%',
          height: '78%',
          borderRadius: '50%',
          overflow: 'hidden',
        }}>
          <img src={profileImageSource} style={{ width: '100%', height: '100%' }} alt="Profile" />
        </div>
      </div>
    </div>
  );
};

const fullScreenStyle = {
  flex: 1,
  position: 'relative',
};

const confirmationViewStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 3,
  pointerEvents: 'none',
};

const circularImagePreviewContainerStyle = {
  position: 'absolute',
  alignSelf: 'center',
  bottom: '47vh',
  width: '25vh',
  height: '25vh',
  borderRadius: '15vh',
  overflow: 'hidden',
  zIndex: 400,
  justifyContent: 'center',
  alignItems: 'center',
};

const circularImagePreviewStyle = {
  width: '25vh',
  height: '25vh',
  borderRadius: '15vh',
  zIndex: 400,
};

const reCropButtonStyle = {
  zIndex: 400,
  position: 'absolute',
  top: '58vh',
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'pointer',
};

const reCropTextStyle = {
  zIndex: 400,
  position: 'absolute',
  fontFamily: 'brhendrix',
  color: 'white',
  fontSize: '1.6vw',
  fontWeight: 'light',
  alignSelf: 'center',
};

const confirmButtonStyle = {
  zIndex: 400,
  top: '65vh',
  left: '50%',
  transform: 'translateX(-50%)',
  position: 'absolute',
  cursor: 'pointer',
};

const confirmTextStyle = {
  zIndex: 400,
  position: 'absolute',
  fontFamily: 'brhendrix',
  color: 'white',
  fontSize: '1.6vw',
  fontWeight: 'light',
  alignSelf: 'center',
};

const progressBarContainerStyle = {
  height: '2vh',
  width: '100%',
  bottom: '3vh',
  backgroundColor: '#e0e0e0',
  borderRadius: 10,
  zIndex: 200,
};

const progressBarStyle = {
  height: '100%',
  backgroundColor: '#62fbfa',
  borderRadius: 10,
  zIndex: 200,
};

const CustomButton = ({ onClick, title }) => (
  <button onClick={onClick} style={customButtonStyle}>
    {title}
  </button>
);

const customButtonStyle = {
  padding: 10,
  borderRadius: 5,
  backgroundColor: 'rgba(0,0,0,0.5)',
  marginBottom: 16,
  width: '80%',
  color: 'white',
  fontSize: '2.4vw',
  fontFamily: 'brhendrix',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 10,
};

export default ScreenSKNPicSelector;
