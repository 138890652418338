import React, { useMemo } from 'react';

const SongItemMusicVideo = ({ item, onPress }) => {
  const artworkUrl = item?.ArtworkURL || item?.artworkURL;

  const formatArtistFeatures = (artistFeatures) => {
    return artistFeatures.map(feature => {
      if (/^\d+$/.test(feature.id)) {
        return feature.tag;
      } else {
        const parts = feature.tag.split(' - ');
        return parts.length > 1 ? parts[1] : parts[0];
      }
    }).join(', ');
  };

  const songDisplayName = useMemo(() => {
    return item.isAlbum
      ? item.albumName || 'Unknown Album'
      : `${item.SongName || 'Unknown Song'}${item.artistFeatures && item.artistFeatures.length > 0 ? ` ft. ${formatArtistFeatures(item.artistFeatures)}` : ''}`;
  }, [item]);

  if (!item) {
    return <div style={styles.songArtworkPlaceholder}>Loading...</div>;
  }

  return (
    <div style={styles.videoItemContainer} onClick={() => onPress(item)}>
      <div style={styles.horizontalTouchable}>
        {artworkUrl ? (
          <img src={artworkUrl} alt="Artwork" style={styles.videoThumbnail} />
        ) : (
          <div style={styles.videoThumbnailPlaceholder}>Loading...</div>
        )}
      </div>
      <div style={styles.artistName}>{item.ArtistName}</div>
      <div style={styles.songName}>{songDisplayName}</div>
    </div>
  );
};

const styles = {
  videoItemContainer: {
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: '10px',
    cursor: 'pointer',
  },
  horizontalTouchable: {
    width: '200px',
    height: '112.5px', // Maintains 16:9 aspect ratio
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  videoThumbnail: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  videoThumbnailPlaceholder: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    backgroundColor: '#cccccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  artistName: {
    marginTop: '10px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
    fontFamily: 'brhendrix',
  },
  songName: {
    marginTop: '2px',
    color: '#b8b8b8',
    fontFamily: 'brhendrix',
    fontSize: '14px',
    whiteSpace: 'normal',
    textAlign: 'left',
  },
};

export default React.memo(SongItemMusicVideo);
