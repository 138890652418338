import React, { useMemo } from 'react';

const VideoItemSeeAll = ({ item, onPress }) => {
  const artworkUrl = item?.ArtworkURL || item?.artworkURL;

  const formatArtistFeatures = (artistFeatures) => {
    return artistFeatures.map(feature => {
      if (/^\d+$/.test(feature.id)) {
        return feature.tag;
      } else {
        const parts = feature.tag.split(' - ');
        return parts.length > 1 ? parts[1] : parts[0];
      }
    }).join(', ');
  };

  const songDisplayName = useMemo(() => {
    return item.isAlbum
      ? item.albumName || 'Unknown Album'
      : `${item.SongName || 'Unknown Song'}${item.artistFeatures && item.artistFeatures.length > 0 ? ` ft. ${formatArtistFeatures(item.artistFeatures)}` : ''}`;
  }, [item]);

  if (!item) {
    return <div style={styles.videoThumbnailPlaceholder} />;
  }

  return (
    <div style={styles.videoItemContainer} onClick={() => onPress(item)}>
      <div style={styles.horizontalTouchable}>
        {artworkUrl ? (
          <img src={artworkUrl} alt="Artwork" style={styles.videoThumbnail} />
        ) : (
          <div style={styles.videoThumbnailPlaceholder}>
            <div style={styles.spinner}></div>
          </div>
        )}
      </div>
      <p style={styles.artistName}>{item.ArtistName}</p>
      <p style={styles.songName}>{songDisplayName}</p>
    </div>
  );
};

const imageSize = '96px'; // You can adjust the size based on your design

const styles = {
  videoItemContainer: {
    width: imageSize,
    alignItems: 'flex-start',
    cursor: 'pointer',
  },
  horizontalTouchable: {
    width: imageSize,
    height: imageSize,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  videoThumbnail: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    objectFit: 'contain',
  },
  videoThumbnailPlaceholder: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#cccccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    width: '40px',
    height: '40px',
    border: '4px solid #62fbfa',
    borderTop: '4px solid transparent',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  },
  artistName: {
    margin: 0,
    marginTop: '10px',
    padding: 0,
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    textAlign: 'left',
    wordWrap: 'break-word',      // Ensures text wraps inside the container
    overflow: 'hidden',          // Hides any overflow text
    textOverflow: 'ellipsis',    // Adds ellipsis (...) for overflowing text
  },
  songName: {
    margin: 0,
    marginBottom: '10px',
    padding: 0,
    color: '#b8b8b8',
    fontSize: '12px',
    textAlign: 'left',
    fontWeight: 'regular',
    wordWrap: 'break-word',      // Ensures text wraps inside the container
    overflow: 'hidden',          // Hides any overflow text
    textOverflow: 'ellipsis',    // Adds ellipsis (...) for overflowing text
  },
};

export default React.memo(VideoItemSeeAll);
