import React, { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from './AppContext';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import { useNavigate } from 'react-router-dom';

const AnalyticsComponentRecent = ({ onProfilePress }) => {
  const {
    recentData, userId2,
    viewingTotalSpnsAnalytics,
    viewingAlbumAnalytics,
    viewingSingleAnalytics,
    viewingVideosAnalytics,
    viewingVerchAnalytics,
    showMediaPopup, currentUser, setViewingProfile, setActivePopupMedia, activePopupMedia,
    setCurrentlyLoadingProfile
  } = useContext(AppContext);

  const navigate = useNavigate();

  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  

  const sortedRecentData = useMemo(() => {
    const categories = [];
    if (viewingTotalSpnsAnalytics) categories.push('RecentSpns');
    if (viewingAlbumAnalytics) categories.push('RecentAlbum');
    if (viewingSingleAnalytics) categories.push('RecentSingle');
    if (viewingVideosAnalytics) categories.push('RecentVideos');
    if (viewingVerchAnalytics) {
      categories.push('RecentVerch');
    }

    const filteredRecentEntries = categories.flatMap(category => recentData[category] || [])
      .sort((a, b) => b.timestamp - a.timestamp);

    
    return filteredRecentEntries;
  }, [recentData, viewingTotalSpnsAnalytics, viewingAlbumAnalytics, viewingSingleAnalytics, viewingVideosAnalytics, viewingVerchAnalytics]);

  const handleProfileSelect = async (profileData) => {
    try {
      setIsSelecting(true);
      const popupData = preparePopupData(profileData);
      setActivePopupMedia(popupData);
    } catch (error) {
      setCurrentlyLoadingProfile(false);
      console.error('Error in handleProfileSelect:', error);
    }
  };

  useEffect(() => {
    if (isSelecting && activePopupMedia) {
      onProfilePress();
      setCurrentlyLoadingProfile(false);
      setIsSelecting(false);
    }
  }, [activePopupMedia]);

  const sortReleasesByTimestamp = (releases) => {
    return [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
  };

  const preparePopupData = (userData) => {
    const albums = sortReleasesByTimestamp(userData.albums || []);
    const singles = sortReleasesByTimestamp(userData.singles || []);
    const videos = sortReleasesByTimestamp(userData.videos || []);

    return {
      artistName: userData.artistName || userData.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: userData.username,
      userId: userData.userId,
      accountType: userData?.accountType,
      voiceDropMuted: userData?.voiceDropMuted,
      otherReleases: { albums, singles, videos },
      vocalDrop: userData.vocalDrop,
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
      timestamp: new Date().getTime(),
      ScreenSKN: userData.ScreenSKN,
    };
  };

  const handlePressProfile = (profile) => {
    const clonedProfile = JSON.parse(JSON.stringify(profile));
    const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
    navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
  };

  const fetchUserProfileByUsername = async (username) => {
    if (!username) return;

    try {
      const q = query(collection(firestore, 'users'), where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Since you're assuming username is unique, you directly use the first document
        const doc = querySnapshot.docs[0];
        const userData = await getCachedUserDocProfile(doc.id);

        if (userData) {
          // Now passing both userData and the document ID (which is the userId)
          handlePressProfile(userData);
        } else {
          console.error(`UserData could not be prepared for username: ${username}`);
        }
      } else {
        console.error(`No user found with username: ${username}`);
      }
    } catch (error) {
      console.error('Error fetching user by username:', error);
    } finally {
    }
  };

  const getCachedUserDocProfile = async (userId) => {
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      let userData = { ...userDocSnap.data(), userId: userId };

      return userData;
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const extractUsernameFromDisplayText = (displayText) => {
    const regex = /(?:Purchased by:|SPN by:) (.*?),/;
    const match = displayText.match(regex);
    return match ? match[1] : null;
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div style={styles.container}>
      {isLoading ? (
        <div style={styles.activityIndicatorContainer}>
          <div className="spinner-border text-info" role="status">
            <span style={{ color: 'white' }} className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <ul style={styles.list}>
          {sortedRecentData.map((item, index) => (
            <li key={index} style={styles.itemContainer}>
              <button onClick={() => {
                const username = extractUsernameFromDisplayText(item.displayText);
                if (username) fetchUserProfileByUsername(username);
              }} style={styles.button}>
                <span style={styles.item}>{`${item.displayText} (···)`}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const styles = {
  container: {
    minHeight: '330px',
    width: '80%',
    backgroundColor: 'transparent',
    overflowY: 'auto',
  },
  itemContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '7px',
    backgroundColor: 'transparent',
  },
  item: {
    fontFamily: 'brhendrix',
    fontSize: '16px',
    color: '#62fbfa',
  },
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
    textAlign: 'left',
    width: '100%',
  },
  activityIndicatorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  list: {
    listStyleType: 'none',
    padding: 0,
    marginTop: '0px',
  },
};

export { AnalyticsComponentRecent };
