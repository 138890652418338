import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from './AppContext';
import defaultProfilePic from './assets/defaultProfilePic.png';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';

const scalingFactor = 0.7;

const { width, height } = { width: window.innerWidth * scalingFactor, height: window.innerHeight * scalingFactor };

const ProfileComponentShareProfile = ({ onPress, userProfile }) => {
  const { isLoadingProfilePicture } = useContext(AppContext);
  const [contentWidth, setContentWidth] = useState(0);
  const [profileImageUrl, setProfileImageUrl] = useState(defaultProfilePic);
  const [isLoading, setIsLoading] = useState(true);

  const profileImageWidth = width * 0.164;
  const padding = 20 * scalingFactor;

  const onTextLayout = (event) => {
    const { width: textWidth } = event.target.getBoundingClientRect();
    const totalWidth = profileImageWidth + textWidth + padding;
    setContentWidth(totalWidth);
  };

  if (isLoadingProfilePicture) {
    return (
      <div style={styles.loaderContainer}>
        <div style={styles.activityIndicator}></div>
      </div>
    );
  }

  const formatUsername = (username) => {
    if (!username) return '@';
    if (username[0] !== '@') return '@' + username;
    return username;
  };

  const profileImageSource = userProfile?.profileImageUrl
    ? userProfile.profileImageUrl
    : defaultProfilePic;

  const isArtistIcon2Visible = ['Cole', 'Sonny', 'SPNDAT', 'Anuva', 'CharlieP'].includes(userProfile?.username);

  return (
    <div onClick={onPress} style={{ ...styles.container, width: contentWidth || 'auto' }}>
      <div style={styles.imageStack}>
        {userProfile.profileImageUrl ? (
          <img src={profileImageSource} style={styles.circularImage} alt="Profile" />
        ) : (
          <img src={defaultProfilePic} style={styles.circularImage} alt="Profile" />
        )}
      </div>
      <div onLayout={onTextLayout} style={styles.textContainer}>
        <span style={styles.artistName}>{userProfile.name}</span>
        <div style={styles.textWithIconContainer}>
          <span style={styles.username}>{formatUsername(userProfile?.username)}</span>
          {userProfile?.accountType !== 'Fan' && !isArtistIcon2Visible && (
            <img src={ArtistIcon1} style={styles.profilePicIcon} alt="Artist Icon 1" />
          )}
          {userProfile?.accountType !== 'Fan' && isArtistIcon2Visible && (
            <img src={ArtistIcon2} style={styles.profilePicIcon} alt="Artist Icon 2" />
          )}
        </div>
      </div>
    </div>
  );
};

const imageSize = width * 0.22;
const circularImageSize = width * 0.164;

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px', // Adjust padding as necessary
    height: '80px', // Adjust height as necessary
    cursor: 'pointer',
  },
  profileViewStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: '80px', // Adjust margin to bring content closer to the left edge
    cursor: 'pointer',
  },
  imageStack: {
    width: '50px', // Adjust the width of the image
    height: '50px', // Adjust the height of the image
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    marginRight: '7px',
  },
  circularImage: {
    width: '100%', // Adjust to fit the container
    height: '100%', // Adjust to fit the container
    borderRadius: '50%',
    objectFit: 'cover',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  artistName: {
    color: 'white',
    fontSize: '16px', // Adjust font size
    fontWeight: '400',
    textAlign: 'left',
    width: '155px',
    fontFamily: 'brhendrix',
  },
  textWithIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loaderContainer: {
    left: `${width * 0.118}px`,
    bottom: `${height * 0.057}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activityIndicator: {},
  username: {
    color: '#62fbfa',
    fontSize: '14px', // Adjust font size
    opacity: 0.8,
    fontWeight: '400',
    fontFamily: 'brhendrix',
    marginRight: '2px',
  },
  profilePicIcon: {
    width: '20px', // Adjust icon size
    height: '20px', // Adjust icon size
    objectFit: 'contain',
  },
};

export { ProfileComponentShareProfile };
