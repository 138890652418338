
import React, { useState, useEffect, useContext } from 'react';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { AppContext } from './AppContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useCache } from './CacheContext';
import downloadOnIphone from './assets/downloadOnIphone.png';
import downloadOnGoogle from './assets/downloadOnGoogle.png';


const LoginScreen = ({ deviceToken, onSwitchToSignup, onPostLoginSetupCompleted }) => {

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const source = searchParams.get('source');
    const path = location.pathname;

    if (source === 'app' && path.includes('/mydat/profile/')) {
      const username = path.split('/mydat/profile/')[1];
      if (username) {
        window.location.href = `myapp://profile/${username}`;
      }
    }
  }, [location]);

  const auth = getAuth();
  const firestore = getFirestore();
  const navigate = useNavigate();
  const { setUser, setProfileImage, setCredits, setIsLoggedIn } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async () => {
    if (!email) {
      setError('Please enter your email to reset your password');
      return;
    }
    
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Please check your email to reset your password');
    } catch (firebaseError) {
      console.error("Password reset error:", firebaseError.message);
      setError(firebaseError.message);
    }
  };



  const CustomButton = ({ onClick, title, textStyle, buttonStyle }) => (
    <button onClick={onClick} style={{ ...styles.button, ...buttonStyle }}>
      <span style={{ ...styles.buttonText, ...textStyle }}>
        {title}
      </span>
    </button>
  );

  const postLoginSetup = async (user) => {
    

    if (!user) {
      console.error("No user is logged in");
      setError("No user is logged in");
      return;
    }

    try {
      const userDocRef = doc(firestore, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      if (userDoc.exists()) {
        

        if (deviceToken) {
          await updateDoc(userDocRef, { deviceToken });
          
        }

        setUser(userData);
        setProfileImage(userData.profileImage || '');
        setCredits(userData.credits || 0);
        setIsLoggedIn(true);

        onPostLoginSetupCompleted();

      } else {
        console.error("No user data found");
        setError("No user data found");
        return;
      }
    } catch (firebaseError) {
      setError(firebaseError.message);
    }
  };

  const handleLogin = async () => {
    setError('');
    if (!email || !password) {
      setError('Please enter both email and password');
      return;
    }

    try {
      

      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      await postLoginSetup(userCredential.user);

    } catch (firebaseError) {
      console.error("Login error:", firebaseError.message);
      setError(firebaseError.message);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.form}>
        <h1 style={styles.header}>Login</h1>
        {error ? <p style={styles.error}>{error}</p> : null}
        <input
          style={styles.input}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          style={styles.input}
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <CustomButton
          title="Login"
          onClick={handleLogin}
          textStyle={{ color: '#FFF' }}
          buttonStyle={{ borderColor: '#62fbfa', borderWidth: 2, marginBottom: 16 }}
        />
        <CustomButton
          title="Don't have an account? Sign Up"
          onClick={() => navigate('/signup')}
          textStyle={{ color: '#FFF', fontSize: '1rem' }}
          buttonStyle={{ borderColor: '#62fbfa', borderWidth: 2, marginBottom: 16 }}
        />
        <CustomButton
          title="Forgot Password?"
          onClick={handlePasswordReset}
          textStyle={{ color: '#FFF' }}
          buttonStyle={{ borderColor: '#62fbfa', borderWidth: 2 }}
        />
                <CustomButton
          title="Privacy Policy" // New button to navigate to Privacy Policy
          onClick={() => navigate('/privacy-policy')}
          textStyle={{ color: '#FFF' }}
          buttonStyle={{ borderColor: '#62fbfa', borderWidth: 2, marginTop: 16 }}
        />
        <div onClick={() => window.open('https://apps.apple.com/us/app/spndat/id6503178936', '_blank')} style={{position: 'relative', display: 'flex', alignSelf: 'center', margin: '0 auto', justifyContent: 'center', top: '50px', }}>
  <img src={downloadOnIphone} alt="Download on iOS" style={{ position: 'absolute', objectFit: 'cover', display: 'flex', justifyContent: 'center', margin: '0 auto', width: '150px', height: '50px', cursor: 'pointer', alignSelf: 'center', backgroundColor: 'transparent', marginTop: '20px' }} />
</div>
<div onClick={() => window.open('https://play.google.com/store/apps/details?id=com.spndat.spndat', '_blank')} style={{position: 'relative', display: 'flex', alignSelf: 'center', margin: '0 auto', justifyContent: 'center', top: '50px', }}>
  <img src={downloadOnGoogle} alt="Download on Google" style={{ position: 'absolute', objectFit: 'cover', display: 'flex', justifyContent: 'center', margin: '0 auto', width: '150px', height: '50px', cursor: 'pointer', alignSelf: 'center', backgroundColor: 'transparent', marginTop: '135px' }} />
</div>

      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#0e061e',
  },
  form: {
    width: '80%',
    maxWidth: '400px',
    backgroundColor: '#0e061e',
    padding: '20px',
    borderRadius: '10px',
    border: 'none',
  },
  header: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '16px',
    textAlign: 'center',
    color: '#fff',
  },
  input: {
    width: '100%',
    height: '40px',
    borderColor: '#ddd',
    borderWidth: '1px',
    marginBottom: '16px',
    padding: '8px',
    fontSize: '1rem',
    color: '#fff',
    backgroundColor: 'rgba(255,255,255,0.5)',
    borderRadius: '5px',
  },
  error: {
    color: 'red',
    marginBottom: '16px',
    fontSize: '1rem',
  },
  button: {
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    width: '100%',
    cursor: 'pointer',
  },
  buttonText: {
    fontSize: '1rem',
    color: '#fff',
  },
};

export default LoginScreen;
