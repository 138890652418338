import React, { useState, useEffect, useRef } from 'react';
import { getDocs, getDoc, collection, getFirestore, doc, updateDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const ReleaseTrailer = () => {
  const [moviesComingSoon, setMoviesComingSoon] = useState([]);
  const [itemSelected, setItemSelected] = useState(null);
  const [trailerTitle, setTrailerTitle] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [thumbnailUploadProgress, setThumbnailUploadProgress] = useState(0);
  const [videoURL, setVideoURL] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);

  const videoFileInputRef = useRef(null);
  const thumbnailFileInputRef = useRef(null);

  useEffect(() => {
    const fetchMovies = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const firestore = getFirestore();
        const comingSoonSnapshot = await getDocs(collection(firestore, `users/${user.uid}/Movie Submissions`));

        const comingSoonMovies = comingSoonSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(movie => movie.status === 'ComingSoon');

        setMoviesComingSoon(comingSoonMovies);
      } catch (error) {
        console.error('Error fetching movies:', error);
      }
    };

    fetchMovies();
  }, []);

  const handleVideoFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileName = `${itemSelected.id}-${new Date().getTime()}`;
      const videoURL = await uploadVideoFile(file, fileName);
      setVideoURL(videoURL);

      await updateFirestoreWithTrailerData(videoURL, thumbnailURL);
    }
  };

  const handleThumbnailFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const thumbnailURL = await uploadVideoThumbnail(file);
      setThumbnailURL(thumbnailURL);

      await updateFirestoreWithTrailerData(videoURL, thumbnailURL);
    }
  };

  const uploadVideoFile = async (file, fileName, retries = 5, delayTime = 3000) => {
    const storage = getStorage();
    const storageRef = ref(storage, `videos/${fileName}`);

    const attemptUpload = async (retryCount) => {
      try {
        const blob = file;
        const uploadTask = uploadBytesResumable(storageRef, blob);

        await new Promise((resolve, reject) => {
          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
            },
            (error) => {
              console.error(`Upload error for file: ${fileName}`, error);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
              });
            }
          );
        });

        return await getDownloadURL(uploadTask.snapshot.ref);
      } catch (error) {
        if (retryCount > 0) {
          await new Promise((resolve) => setTimeout(resolve, delayTime));
          return attemptUpload(retryCount - 1);
        } else {
          alert("Low internet connection, please try again");
          throw error;
        }
      }
    };

    return await attemptUpload(retries);
  };

  const uploadVideoThumbnail = async (file, folderName = 'video_thumbnails/') => {
    if (!file) return;

    const storage = getStorage();
    const fileRef = ref(storage, `${folderName}${new Date().toISOString()}`);
    const metadata = {
      contentType: file.type || 'image/png',
    };

    try {
      const uploadTask = uploadBytesResumable(fileRef, file, metadata);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setThumbnailUploadProgress(progress);
          },
          (error) => {
            console.error('Error during thumbnail upload:', error);
            reject(error);
          },
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          }
        );
      });
    } catch (error) {
      console.error('Error during thumbnail upload:', error);
      throw error;
    }
  };

  const updateFirestoreWithTrailerData = async (videoURL, thumbnailURL) => {
    const auth = getAuth();
    const firestore = getFirestore();
    const userId = auth.currentUser.uid;
    const movieTrailerDocRef = doc(firestore, `users/${auth.currentUser.uid}/MovieTrailers`, itemSelected.id);

    const docSnapshot = await getDoc(movieTrailerDocRef);

    const newTrailer = {
      title: trailerTitle,
      link: videoURL || '',
      VideoThumbnailURL: thumbnailURL || '',
      isTrailer: true,
      userId: userId,
    };

    if (docSnapshot.exists()) {
      const trailers = docSnapshot.data().trailers || [];
      const existingTrailerIndex = trailers.findIndex(trailer => trailer.link === videoURL || trailer.VideoThumbnailURL === thumbnailURL);

      if (existingTrailerIndex !== -1) {
        trailers[existingTrailerIndex] = newTrailer;
      } else {
        trailers.push(newTrailer);
      }

      await updateDoc(movieTrailerDocRef, { trailers });
    } else {
      await setDoc(movieTrailerDocRef, { trailers: [newTrailer] });
    }
  };

  const handleReleaseTrailer = async () => {
    if (!trailerTitle.trim()) {
        alert('Please enter a valid trailer title.');
        return;
    }

    if (!videoURL || !videoURL.startsWith('http') || !videoURL.includes('firebase')) {
        alert('Invalid video URL. Please upload the video to Firebase.');
        return;
    }

    if (!thumbnailURL || !thumbnailURL.startsWith('http') || !thumbnailURL.includes('firebase')) {
        alert('Invalid thumbnail URL. Please upload the thumbnail to Firebase.');
        return;
    }

    const auth = getAuth();

    const userId = auth.currentUser.uid;
    const firestore = getFirestore();
    const comingSoonDocRef = doc(firestore, `ComingSoonMovies`, itemSelected.id);

    try {
        const docSnapshot = await getDoc(comingSoonDocRef);

        const newTrailer = {
          title: trailerTitle,
          link: videoURL,
          VideoThumbnailURL: thumbnailURL,
          isTrailer: true,
          userId: userId,
      };

        if (docSnapshot.exists()) {
            await updateDoc(comingSoonDocRef, {
                trailers: [...(docSnapshot.data().trailers || []), newTrailer]
            });
        } else {
            await setDoc(comingSoonDocRef, {
                trailers: [newTrailer]
            });
        }

        alert('Movie trailer released successfully!');
    } catch (error) {
        console.error("Error releasing trailer:", error);
        alert('Failed to release movie trailer. Please try again.');
    }
};

  const getPlaceholderTitle = () => {
    if (!itemSelected) return '';
    const trailersCount = itemSelected?.trailers?.length || 0;
    return `${itemSelected.MovieTitle} Trailer ${trailersCount + 1}`;
  };

  return (
    <div style={styles.fullScreen}>
      <div style={styles.outerContainer}>
        {itemSelected ? (
          <>
            <div style={styles.backArrow} onClick={() => setItemSelected(null)}>
              &#8592; Back
            </div>
            <p style={styles.releaseText}>Release Trailer for {itemSelected.MovieTitle}</p>
            
            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
  <label style={styles.label}>Trailer Title</label>
  <input
    type="text"
    value={trailerTitle}
    onChange={(e) => setTrailerTitle(e.target.value)}
    placeholder={getPlaceholderTitle()}
    style={styles.inputField}
  />
</div>

            
            {!videoURL && (
              <>
                <input
                  type="file"
                  ref={videoFileInputRef}
                  onChange={handleVideoFileChange}
                  style={styles.hiddenFileInput}
                />
                <button onClick={() => videoFileInputRef.current.click()} style={styles.customFileInputButton}>
                  Choose Video File
                </button>
                {uploadProgress > 0 && (
                  <div style={styles.progressBarContainer}>
                    <div style={{ ...styles.progressBar, width: `${uploadProgress}%` }}></div>
                  </div>
                )}
              </>
            )}
  
            {videoURL && (
              <>
                <video src={videoURL} controls style={styles.videoPreview}></video>
              </>
            )}
  
            {!thumbnailURL && (
              <>
                <input
                  type="file"
                  ref={thumbnailFileInputRef}
                  onChange={handleThumbnailFileChange}
                  style={styles.hiddenFileInput}
                />
                <button onClick={() => thumbnailFileInputRef.current.click()} style={styles.customFileInputButton}>
                  Choose Thumbnail File
                </button>
                {thumbnailUploadProgress > 0 && (
                  <div style={styles.progressBarContainer}>
                    <div style={{ ...styles.progressBar, width: `${thumbnailUploadProgress}%` }}></div>
                  </div>
                )}
              </>
            )}
  
            {thumbnailURL && (
              <>
                <img src={thumbnailURL} alt="Thumbnail" style={styles.thumbnailPreview} />
                <button onClick={handleReleaseTrailer} style={styles.releaseButton}>Release Movie Trailer</button>
              </>
            )}
          </>
        ) : (
          <>
            <p style={styles.sectionTitle}>Select a Movie to Release Trailer</p>
            {moviesComingSoon.length > 0 && (
              <>
                {moviesComingSoon.map((item) => {
                  const premiereDate = new Date(item.premiereDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  });
                  return (
                    <div
                      key={item.id}
                      style={styles.movieItemContainer}
                      onClick={() => setItemSelected(item)}
                    >
                      <img src={item.VideoThumbnailURL} alt={item.MovieTitle} style={styles.movieThumbnail} />
                      <p style={styles.movieTitle}>{item.MovieTitle}</p>
                      <p style={styles.movieReleaseDate}>Premiering {premiereDate}</p>
                    </div>
                  );
                })}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
  
};

// Styles
const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '20px',
    paddingBottom: '50px',
    backgroundColor: '#000', 
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },
  outerContainer: {

    
    height: 'calc(100vh - 20px)', // Adjust based on header/footer height if needed
    overflowY: 'auto',
    paddingBottom: '50px', // Adjust bottom padding to ensure all content is visible
    paddingLeft: '10px', // Optional: Add padding for horizontal spacing
    paddingRight: '10px', // Optional: Add padding for horizontal spacing
  },
  backArrow: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: '#62fbfa',
    fontSize: '16px',
    cursor: 'pointer',
  },
  sectionTitle: {
    fontSize: '18px',
    color: '#62fbfa',
    fontWeight: 'bold',
    marginVertical: '10px',
    textAlign: 'center',
  },
  movieItemContainer: {
    marginBottom: '20px',
    alignItems: 'center',
    zIndex: 100,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
  },
  movieThumbnail: {
    width: '90%',
    height: 'auto',
    aspectRatio: '16 / 9',
    borderRadius: '8px',
    marginBottom: '10px',
    marginLeft: '10px',
  },
  movieTitle: {
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '5px',
  },
  movieReleaseDate: {
    color: '#b8b8b8',
    fontSize: '14px',
    textAlign: 'center',
  },
  releaseText: {
    fontSize: '20px',
    color: '#62fbfa',
    fontWeight: 'bold',
    textAlign: 'center',
    marginVertical: '20px',
  },
  label: {
    color: 'white',
    marginTop: '20px',
    fontSize: '16px',
  },
  inputField: {
    width: '80%',
    padding: '10px',
    margin: '10px 0',
    fontSize: '16px',
  },
  hiddenFileInput: {
    display: 'none', 
  },
  customFileInputButton: {
    padding: '10px 20px',
    fontSize: '16px',
    color: 'black',
    backgroundColor: '#62fbfa',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    marginBottom: '10px',
    marginTop: '10px', 
  },
  progressBarContainer: {
    width: '80%',
    height: '10px',
    backgroundColor: '#333',
    borderRadius: '5px',
    overflow: 'hidden',
    marginTop: '10px',
  },
  progressBar: {
    height: '100%',
    backgroundColor: '#62fbfa',
    borderRadius: '5px',
  },
  videoPreview: {
    width: '80%',
    marginTop: '20px',
  },
  thumbnailPreview: {
    width: '60%', 
    marginTop: '20px',
    borderRadius: '8px',
  },
  releaseButton: {
    marginTop: '20px',
    padding: '10px 20px',
    fontSize: '16px',
    color: 'black',
    backgroundColor: '#62fbfa',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
  },
};

export default ReleaseTrailer;
