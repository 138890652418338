import React, { useState, useRef, useEffect, useContext } from 'react';
import { ProfileComponentFollowing } from './ProfileComponentFollowing';
import { AppContext } from './AppContext';
import { doc, getDoc, query, collection, where, getDocs } from 'firebase/firestore';
import { firestore } from './firebase';
import { useLocation, useNavigate } from 'react-router-dom';

const CACHE_DURATION = 120000;

const ProfilesDisplayFollowers = ({ onProfilePress }) => {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { followers, setCurrentlyLoadingProfile, currentUser, setActivePopupMedia, activePopupMedia, userId2 } = useContext(AppContext);

  const navigate = useNavigate();

  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [lastIndex, setLastIndex] = useState(0);
  const [isSearching, setIsSearching] = useState(false);

  const profileListRef = useRef(null);

  const handlePressProfile = (profile) => {
    const clonedProfile = JSON.parse(JSON.stringify(profile));
    const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
    navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
  };

  useEffect(() => {
    if (isSelecting) {
      onProfilePress();
      setIsSelecting(false);
    }
  }, [activePopupMedia]);

  useEffect(() => {
    loadInitialProfiles();
  }, [followers]);

  useEffect(() => {
    const handleScroll = () => {
      if (profileListRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = profileListRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
          loadMoreProfiles();
        }
      }
    };

    const listRef = profileListRef.current;
    if (listRef) {
      listRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (listRef) {
        listRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isLoading]);

  const loadInitialProfiles = async () => {
    setIsLoading(true);
    if (Array.isArray(followers) && followers.length > 0) {
      let sliceEnd = 0;
      const newProfiles = [];
  
      while (sliceEnd < followers.length && newProfiles.length < 9) {
        const userIdsSlice = followers.slice(sliceEnd, sliceEnd + 9 - newProfiles.length);
        const loadedProfiles = await loadProfiles(userIdsSlice);
  
        newProfiles.push(...loadedProfiles.filter(profile => profile !== null));
        sliceEnd += userIdsSlice.length;
      }
  
      setProfiles(newProfiles);
      setFilteredProfiles(newProfiles);
      setLastIndex(sliceEnd);
    } else {
      console.warn("following is undefined or not an array");
    }
    setIsLoading(false);
  };

  const getCachedUserDocMinimal = async (userId) => {
    let userDataCached = [];

    
    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      
      const freshUserData = userDocSnap.data();
      userDataCached = { ...freshUserData, timestamp: new Date().getTime() };

      return {
        userId,
        voiceDropMuted: userDataCached.voiceDropMuted,
        accountType: userDataCached.accountType,
        name: userDataCached.name,
        username: userDataCached.username,
        profileImageUrl: userDataCached.profileImageUrl,
      };
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const loadMoreProfiles = async () => {
    if (lastIndex >= followers.length || isLoading) return;
  
    setIsLoading(true);
  
    let nextEndIndex = lastIndex;
    const newProfiles = [];
  
    while (nextEndIndex < followers.length && newProfiles.length < 9) {
      const userIdsSlice = followers.slice(nextEndIndex, nextEndIndex + 9 - newProfiles.length);
      const loadedProfiles = await loadProfiles(userIdsSlice);
  
      newProfiles.push(...loadedProfiles.filter(profile => profile !== null));
      nextEndIndex += userIdsSlice.length;
    }
  
    if (newProfiles.length > 0) {
      setProfiles(prevProfiles => [...prevProfiles, ...newProfiles]);
      setFilteredProfiles(prevFiltered => [...prevFiltered, ...newProfiles]);
      setLastIndex(nextEndIndex);
    }
  
    setIsLoading(false);
  };
  
  

  const loadProfiles = async (userIds) => {
    const loadedProfiles = [];
    for (const userId of userIds) {
      if (userId !== userId2) {
        const userData = await getCachedUserDocMinimal(userId);
        if (userData) {
          loadedProfiles.push(userData);
        }
      }
    }
    return loadedProfiles;
  };
  

  const handleProfileSelect = async (profileData, userId) => {
    try {
      setIsSelecting(true);
      const popupData = preparePopupData(profileData, userId);
      handlePressProfile(popupData);
    } catch (error) {
      console.error('Error in handleProfileSelect:', error);
    }
  };

  const sortReleasesByTimestamp = (releases) => {
    return [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
  };

  const preparePopupData = (userData, userId) => {
    
    const albums = sortReleasesByTimestamp(userData.albums || []);
    const singles = sortReleasesByTimestamp(userData.singles || []);
    const videos = sortReleasesByTimestamp(userData.videos || []);

    const popupData = {
      artistName: userData.artistName || userData.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: userData.username,
      accountType: userData.accountType,
      userId,
      otherReleases: { albums, singles, videos },
      vocalDrop: userData.vocalDrop,
      voiceDropMuted: userData?.voiceDropMuted,
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
      timestamp: new Date().getTime(),
      ScreenSKN: userData.ScreenSKN,
    };

    
    return popupData;
  };



  const fetchUserProfile = async (userId) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        handlePressProfile(userDoc.data());
      } else {
        
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const fetchUserProfileByUserId = async (userId) => {
    if (!userId) {
      
      return;
    }

    


    try {
      fetchUserProfile(userId);

    } catch (error) {
      console.error('fetchUserProfileByUserId: Error fetching user by userId:', error);
    } finally {

      setIsLoading(false);
    }
  };

  const renderProfile = (item) => {
    if (!item) return null;
    
    return (
      <ProfileComponentFollowing
        key={item.userId}
        userProfile={item}
        onPress={() => fetchUserProfileByUserId(item.userId)}
      />
    );
  };

  const handleSearch = async (text) => {
    setSearchText(text);
    if (!text.trim()) {
      setFilteredProfiles(profiles);
      setIsSearching(false);
      return;
    }
  
    setIsSearching(true);
    const lowercasedText = text.toLowerCase();
    const usersRef = collection(firestore, 'users');
    const searchQuery = query(
      usersRef, 
      where('username_lower', '>=', lowercasedText), 
      where('username_lower', '<=', lowercasedText + '\uf8ff')
    );
    const artistNameQuery = query(
      usersRef, 
      where('ArtistNameLower', '>=', lowercasedText), 
      where('ArtistNameLower', '<=', lowercasedText + '\uf8ff')
    );
    
    setIsLoading(true);
    try {
      const [usernameSnapshot, artistNameSnapshot] = await Promise.all([
        getDocs(searchQuery),
        getDocs(artistNameQuery),
      ]);
  
      const searchResults = [];
      usernameSnapshot.forEach(doc => {
        const userData = doc.data();
        if (userData && followers.includes(doc.id)) {
          searchResults.push({
            userId: doc.id,
            voiceDropMuted: userData.voiceDropMuted,
            accountType: userData.accountType,
            name: userData.name,
            username: userData.username,
            profileImageUrl: userData.profileImageUrl
          });
        }
      });
  
      artistNameSnapshot.forEach(doc => {
        const userData = doc.data();
        if (userData && followers.includes(doc.id) && !searchResults.some(profile => profile.userId === doc.id)) {
          searchResults.push({
            userId: doc.id,
            voiceDropMuted: userData.voiceDropMuted,
            accountType: userData.accountType,
            name: userData.name,
            username: userData.username,
            profileImageUrl: userData.profileImageUrl
          });
        }
      });
  
      setFilteredProfiles(searchResults);
    } catch (error) {
      console.error('Error searching profiles:', error);
      alert('Search Error', 'There was an error while searching. Please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div style={styles.outerContainer}>
      <div style={styles.container}>
        <input
          style={styles.searchInput}
          placeholder="Search..."
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div style={styles.profileListContainer} ref={profileListRef}>
          <div style={styles.profileList}>
            {filteredProfiles.map(renderProfile)}
            {isLoading && <div style={styles.loader}><div style={styles.activityIndicator}></div></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  outerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    zIndex: 100,
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    color: 'white',
    overflow: 'hidden',
    zIndex: 100,
  },
  searchInput: {
    width: '90%',
    padding: '10px',
    marginBottom: '20px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #6b92c2',
    color: 'white',
    backgroundColor: 'transparent',
    zIndex: 10,
  },
  profileListContainer: {
    flex: 1,
    width: '100%',
    overflow: 'auto',
    zIndex: 100,
  },
  profileList: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    alignItems: 'flex-start',
    zIndex: 100,
  },
  profileItem: {
    width: '90%',
    marginBottom: '20px',
    pointer: 'cursor',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '20px',
  },
  activityIndicator: {
    width: '40px',
    height: '40px',
    border: '4px solid #62fbfa',
    borderRadius: '50%',
    borderTop: '4px solid transparent',
    animation: 'spin 1s linear infinite',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
};

export { ProfilesDisplayFollowers };

