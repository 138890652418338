import React from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

const { width, height } = { width: window.innerWidth, height: window.innerHeight };
const bottomAreaHeight = height * 0.18;
const scrollAreaTopPadding = height * 0.16;

const Terms = ({ onPlayVideo, isVideoPlaying, onMoveMP }) => {
  
  const navigate = useNavigate();
  const DISPLAY_TEXT = `TERMS OF SERVICE - SOCIAL MEDIA COMPONENT

Terms of Service
These Terms of Service ("Terms") govern your access to use the SPNDAT social media platform, including all features, functionalities, and services offered by us (collectively, the "Service"). By accessing or using the Service, you agree to be bound by these Terms and our Privacy Policy.

1. Account Registration
1.1. You must be at least 13 years old to use the Service. By creating an account, you represent that you are at least 13 years old and have the legal capacity to enter into these Terms.
1.2. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.

2. Use of the Service
2.1. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.
2.2. You agree not to use the Service for any illegal or unauthorized purpose.

3. Messaging, Posting, and Following
3.1. The Service allows you to send messages, post content, and follow other users. You are solely responsible for the content of your messages and posts.
3.2. You agree not to post or send any content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.
3.3. You agree not to engage in any activity that interferes with or disrupts the Service, including the servers and networks connected to the Service.

4. Intellectual Property
4.1. The Service and its original content, features, and functionality are owned by SPNDAT INC. and are protected by copyright, trademark, and other intellectual property laws.
4.2. You agree not to reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Service, except as necessary for your own personal, non-commercial use.

5. Termination
5.1. We reserve the right to terminate or suspend your account and access to the Service at any time and for any reason, without notice.

6. Governing Law
6.1. These Terms shall be governed by and construed in accordance with the laws of the state of Tennessee, without regard to its conflict of law principles.

7. Changes to Terms
7.1. We reserve the right to modify or revise these Terms at any time. We will notify you of any changes by posting the new Terms on the Service.

8. Additional Disclaimer
8.1. By choosing to follow users of the SPNDAT platform, you are allowing those you follow to send you direct marketing info which include but are not limited to, direct messages, images, special offers and promotions. You can opt out of receiving information from any user on the SPNDAT platform by simply unfollowing that user and/or content provider. In addition, if at any point you have received content that you deem inappropriate or a violation of these Terms and Services, SPNDAT has provided a report tool to help assist in these matters.

9. Doxing Protocol
9.1. Anyone who attempts to display another user's personally identifiable information without their consent will be terminated immediately without notice.
9.2. The definition of doxing would include the following: Doxing or doxxing is the act of publicly providing personally identifiable information about an individual or organization, via the Internet and without their consent.

10. Contact Us
10.1. If you have any questions about these Terms, please contact us at support@spndat.com.
Write to us at: SPNDAT Inc., 2025 S McCall Road, Englewood, Florida 34223

SPNDAT Inc. is the data controller of personal data processed under these terms of service.

© SPNDAT Inc. 2024


TERMS OF SERVICE - MUSIC & VIDEO COMPONENT

Terms of Service
These Terms of Service ("Terms") govern your use of the music and video jukebox component of the ("App") provided by SPNDAT INC. ("we" or "us"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, do not use the App.

1. Use of the App
1.1. The App allows you to play and purchase music and video, as well as listen to free music provided by the App's library.
1.2. You may use the App for personal or commercial purposes. You may not use the App for any illegal or unauthorized purpose.

2. Account Registration
2.1. You may be required to create an account to use certain features of the App. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
2.2. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.

3. Content
3.1. The music available on the App is provided by Artist account holders and is subject to copyright protection. Any account that is not an artist account, may only use the music for personal, non-commercial purposes.
3.2. You agree not to modify, reproduce, distribute, transmit, display, perform, or create derivative works from the music without the express permission of the copyright owner, and hold SPNDAT INC. harmless against any content or copyright infringements.
3.3. You agree that any content provided to the app is content that you have the sole right to provide. SPNDAT is not responsible for any royalties, licenses, agreements, or claims associated with the provided content.
3.4. SPNDAT has the right to remove any content provided to the app unlawfully for any reason, without notice.
3.5. All SPNDAT vendor accounts are charged an 8% fee on all financial transactions throughout the app. This would include: Artist, SPNcaster, Producer, DJ, and record label accounts.
3.6. SPNDAT is not responsible for any reimbursements to you; all sales and transactions are final.

4. Prohibited Conduct
4.1. You agree not to use the App in any way that violates any applicable laws or regulations.
4.2. You agree not to use the App to transmit any content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.

5. Termination
5.1. We reserve the right to terminate or suspend your account and access to the App at any time and for any reason, without notice.

6. Disclaimer of Warranty
6.1. The App is provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement.

7. Limitation of Liability
7.1. To the fullest extent permitted by applicable law, we shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the App; (b) any content accessed through the App; or (c) any unauthorized access to or use of our servers and/or any personal information stored therein.

8. Governing Law
8.1. These Terms shall be governed by and construed in accordance with the laws of the state of Tennessee, without regard to its conflict of law principles.

9. Changes to Terms
9.1. We reserve the right to modify or revise these Terms at any time. We will notify you of any changes by posting the new Terms on the App.

10. Additional Disclaimer
10.1. By choosing to follow users of the SPNDAT platform, you are allowing those you follow to send you direct marketing info which include but are not limited to, direct messages, images, special offers, and promotions. You can opt out of receiving information from any user on the SPNDAT platform by simply unfollowing that user and/or content provider. In addition, if at any point you have received content that you deem inappropriate or a violation of these Terms and Services, SPNDAT has provided a report tool to help assist in these matters.
10.2. By deactivating an account, all data associated with that account will be lost. This would include but is not limited to personal information and unclaimed money.

10. Contact Us
10.1. If you have any questions about these Terms, please contact us at support@spndat.com.
Write to us at: SPNDAT Inc., 2025 S McCall Road, Englewood, Florida 34223

SPNDAT Inc. is the data controller of personal data processed under these terms of service.

© SPNDAT Inc. 2024


`;

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <p style={styles.fullScreenText}>{DISPLAY_TEXT}</p>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  scrollContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    zIndex: 2,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullScreenImage: {
    width: width,
    height: height,
    position: 'absolute',
    zIndex: 0,
  },
  fullScreenText: {
    marginRight: '10px',
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'white',
    whiteSpace: 'pre-wrap',
    textAlign: 'left',
    fontFamily: 'brhendrix',
    letterSpacing: -0.4,
    marginBottom: '300px',
  },
};

export default Terms;
