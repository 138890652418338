import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './AppContext';
import SPNListPageMediaItems from './SPNListPageMediaItems';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, runTransaction, addDoc, deleteDoc, setDoc, serverTimestamp, doc, getDoc, collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { firestore } from './firebase';

const CACHE_DURATION = 5000;

const GenreMediaListScreen = ({ onMoveMP }) => {
  const {
    playMedia,
    isPopupVisible, setIsPopupVisible,
    showMediaPopup,
    activePopupMedia, 
    closePopup,
    httpArtworkUrl, setHttpArtworkUrl,
    isLoadingArtwork, setIsLoadingArtwork,
    popupSongArtworkUrl, setPopupSongArtworkUrl,
    popupVideoArtworkUrl, setPopupVideoArtworkUrl,
    showPopup, setShowPopup,
    handlePlayAndClose,
    isExpanded, setIsExpanded,
    userId, setUserId,
    setInitialExpandedAlbumAndSong,
    isLoadingNewPopup, setIsLoadingNewPopup,
    isLoadingExpand, setIsLoadingExpand,
    isLoadingSameUser, setIsLoadingSameUser,
    isLoadingProfilePicture, setIsLoadingProfilePicture,
    lastViewedMedia, setLastViewedMedia,
    showSameMediaPopup,
    setArtworkUri, centeredRelease, setCenteredRelease,
    hideExpandedAlbum, setHideExpandedAlbum,
    activeTab, setActiveTab,
    setInitiallySelectedItemId,
    isLoadingHideEA, setIsLoadingHideEA, currentUser, setUser,
    trackToView, setTrackToView, purchasedMedia
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const { genre } = location.state || {}; // Correctly access genre from state
  const [mediaItems, setMediaItems] = useState([]);


  const sortReleasesByTimestamp = (releases) => {
    return releases.sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
  };

  const handlePressBegins = async (song) => {
    const clonedSong = JSON.parse(JSON.stringify(song));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  const preparePopupData = (song, userData) => {
    let profileImageUrl = null;
    const itemUserData = [];

    if (itemUserData) {
      profileImageUrl = itemUserData?.profileImageUrl || null;
    }

    let userAlbums = sortReleasesByTimestamp(userData.albums || []);
    let userSingles = sortReleasesByTimestamp(userData.singles || []);
    let userVideos = sortReleasesByTimestamp(userData.videos || []);

    let selectedAlbum = userAlbums.find(album => album.contentId === song.contentId) || null;
    let selectedSingle = userSingles.find(single => single.contentId === song.contentId) || null;
    let selectedVideo = userVideos.find(video => video.contentId === song.contentId) || null;

    const artworkUri = selectedVideo?.VideoThumbnailURL || (selectedAlbum || selectedSingle)?.ArtworkURL || song.ArtworkURL;
    setArtworkUri(artworkUri);

    let dimensions = [];
    if (song.isVideo) {
      dimensions = song.dimensions || [];
    }

    if (selectedAlbum && selectedAlbum.songs.length > 0) {
      setInitialExpandedAlbumAndSong(selectedAlbum, selectedAlbum.songs[0]);
      setCenteredRelease(selectedAlbum);
    } else if (selectedSingle && selectedSingle.length > 0) {
      setCenteredRelease(selectedSingle);
    } else if (selectedVideo) {
      setCenteredRelease(selectedVideo);
    }

    const songName = song.isVideo ? song.VideoName : song.SongName;

    return {
      url: song.SongURL,
      dimensions: dimensions,
      isVideo: song.isVideo,
      artistName: song.ArtistName,
      popupProfileImage: profileImageUrl,
      username: song.username,
      songName: songName,
      accountType: userData.accountType,
      artworkUrl: artworkUri,
      contentId: song.contentId,
      localArtworkURL: song.localArtworkURL,
      userId: song.userId,
      isAlbum: song.isAlbum,
      albumName: selectedAlbum?.albumName || '',
      producers: song.producers,
      songwriters: song.songwriters,
      recordLabels: song.recordLabels,
      otherReleases: { albums: userAlbums, singles: userSingles, videos: userVideos },
      albumSongs: selectedAlbum?.songs || [],
      single: selectedSingle,
      video: selectedVideo,
      timestamp: new Date().getTime(),
      vocalDrop: userData.vocalDrop,
      voiceDropMuted: userData?.voiceDropMuted,
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
      ScreenSKN: userData.ScreenSKN,
    };
  };

  const fetchMediaByGenre = async () => {
    const genreToMatch = genre.replace(/_/g, ' ').toLowerCase().trim(); 
    let matchedGenres = [];

    if (genreToMatch === 'hip hop' || genreToMatch === 'hip hop rap') {
      matchedGenres = ['hip hop', 'hip hop rap'];
    } else {
      matchedGenres = [genreToMatch];
    }

    const filteredMedia = purchasedMedia.filter(item =>
      item.genres && item.genres.some(g =>
        matchedGenres.includes(g.replace(/_/g, ' ').toLowerCase().trim().split(' -')[0])
      )
    );

    return filteredMedia;
  };

  useEffect(() => {
    const fetchMedia = async () => {
      const items = await fetchMediaByGenre();
      setMediaItems(items);
    };

    fetchMedia();
  }, [genre, currentUser]);


  const getCachedUserDoc = async (userId) => {
    let userDataCached = [];
    let userDataCachedNew = [];
    const now = new Date().getTime();
  
    if (!userDataCached || now - userDataCached.timestamp >= CACHE_DURATION) {
        
        const userDocRef = doc(firestore, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
            
            const freshUserData = userDocSnap.data();
  
            
            // Update userDataCached structure with fresh data and timestamp
            userDataCachedNew = {
                ...freshUserData, // overwrite with fresh data from Firestore
                profileImageUrl: freshUserData?.profileImageUrl,
                timestamp: now
            };
  
  
        } else {
            console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
        }
    } else {
        
        userDataCachedNew = userDataCached;
    }
  
    return userDataCachedNew;
  };


  const capitalizedGenre = genre.charAt(0).toUpperCase() + genre.slice(1).toLowerCase();


  return (
    <div style={styles.fullScreen}>
      <div style={styles.container}>
        <div style={styles.headerContainer}>
        <h2 style={styles.headerText}>{capitalizedGenre}</h2>
        </div>
        <div style={styles.mediaList}>
          {mediaItems.map(item => (
            <SPNListPageMediaItems key={item.contentId} item={item} onPress={() => handlePressBegins(item)} isSelected={false} onDelete={() => {}} />
          ))}
        </div>
      </div>
    </div>
  );
  
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 10,
    height: '82vh', // calculated from height - bottomAreaHeight
    overflowY: 'scroll',
    paddingTop: '2vh', // Adjusted top padding
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginBottom: '2vh', // Space below the header
  },
  headerText: {
    color: 'white',
    fontSize: '2rem', // Adjusted font size
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'brhendrix',
  },
  mediaList: {
    width: '100%',
    boxSizing: 'border-box',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  buttonTouchableArea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
};

export default GenreMediaListScreen;
