// SpncastMoreCategories.jsx
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext'; // Adjust the path based on your project structure

const additionalCategories = [
  { label: "Podcasts", value: "podcasts" },
  { label: "Behind-the-Scenes", value: "behind_the_scenes" },
  { label: "Interviews/Q&A", value: "interviews" },
  { label: "Music Gear", value: "musicgear" },
  { label: "Comedy/Sketch", value: "comedy_sketch" },
  { label: "Gaming", value: "gameplays" },
  { label: "General Entertainment", value: "general_entertainment" },
  { label: "Courses/Tutorials", value: "tutorials" },
  { label: "News", value: "news" },
  { label: "Vlogs", value: "vlogs" },
  { label: "Product Reviews", value: "product_reviews" },
  { label: "Unboxings", value: "unboxings" },
  { label: "Short Films", value: "short_films" },
  { label: "Sports Highlights", value: "sports_highlights" },
  { label: "Cooking/Food", value: "cooking_food" },
  { label: "Travel", value: "travel" },
  { label: "Fitness/Workout", value: "fitness_workout" },
  { label: "Animation", value: "animation" },
  { label: "DIY/Crafts", value: "diy_crafts" },
  { label: "Fashion & Beauty", value: "beauty_fashion" },
  { label: "Technology/Gadgets", value: "technology_gadgets" },
  { label: "ASMR", value: "asmr" },
  { label: "Motivational/Inspirational", value: "motivational_inspirational" },
  { label: "Pranks", value: "pranks" },
  { label: "Nature/Wildlife", value: "nature_wildlife" },
  { label: "Storytelling", value: "storytelling" },
  { label: "Pets/Animals", value: "pets_animals" }
];

const SpncastMoreCategories = () => {
  const navigate = useNavigate();
  const { setActiveTab } = useContext(AppContext); // Use your context to manage state if needed

  const handleCategoryPress = (category) => {
    // Optionally set active tab or any other context state
    if (setActiveTab) {
      setActiveTab(category.value);
    }
    // Navigate to the selected category's screen
    navigate('/spncastcategoryvideos', { state: { category: category.value } });
  };

  return (
    <div style={styles.fullScreen}>

      {/* Scrollable Container */}
      <div style={styles.scrollContainer}>
        {/* Header */}
        <h1 style={styles.headerText}>More SPNCast Categories</h1>

        {/* List of Additional Categories */}
        {additionalCategories.map((category, index) => (
          <button
            key={index}
            style={styles.categoryButton}
            onClick={() => handleCategoryPress(category)}
          >
            {category.label}
          </button>
        ))}

        {/* Spacer at the bottom */}
        <div style={styles.bottomSpacer}></div>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    zIndex: 0,
  },
  scrollContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    paddingTop: '20px', // Equivalent to height * 0.16
    paddingBottom: '350px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headerText: {
    color: '#62fbfa',
    fontSize: '24px', // Adjust as needed
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif', // Replace with your desired font
  },
  categoryButton: {
    backgroundColor: '#220f44', 
    padding: '15px',
    border: 'none',
    borderRadius: 10,
    width: '350px',
    marginBottom: '10px',
    marginVertical: '8px',
    textAlign: 'center',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  categoryButtonHover: {
    backgroundColor: '#3a2c6c', // Example hover color
  },
  categoryText: {
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  bottomSpacer: {
    height: '18vh', // Equivalent to height * 0.18
    backgroundColor: 'transparent',
  },
};

export default SpncastMoreCategories;
