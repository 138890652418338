import React, { useCallback, useState, useContext } from 'react';
import ScreenSKNpicSelector from './ScreenSKNpicSelector';
import { getFirestore, doc, updateDoc, arrayUnion, addDoc, collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth } from "firebase/auth";
import { AppContext } from './AppContext';
import Modal from 'react-modal';

const { width, height } = { width: window.innerWidth, height: window.innerHeight };
const bottomAreaHeight = window.innerHeight * 0.3;

const ScreenSKNPage = () => {
  const [tempImageUri, setTempImageUri] = useState(null);
  const [screenSKNName, setScreenSKNName] = useState('');
  const [tempButtonImageUri, setTempButtonImageUri] = useState(null);
  const [tempBarImageUri, setTempBarImageUri] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const { currentUser, setUser, setHasBar, currentlyReleasing, setCurrentlyReleasing, setIsViewingScreenSKN } = useContext(AppContext);

  const pickImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const imageUri = URL.createObjectURL(file);
      setTempButtonImageUri(imageUri);
    }
  };

  const pickImageBar = (event) => {
    if (event.target.files && event.target.files[0]) {
      setHasBar(true);
      const file = event.target.files[0];
      const imageUri = URL.createObjectURL(file);
      setTempBarImageUri(imageUri);
    }
  };

  const confirmImage = useCallback((forBar = false) => {
    if (forBar) {
      setTempBarImageUri(null);
    } else {
      setIsViewingScreenSKN(true);
      setSelectedImage(tempButtonImageUri);
      setTempButtonImageUri(null);
    }
  }, [tempButtonImageUri, tempBarImageUri, setIsViewingScreenSKN]);
  

  const releaseScreenSKN = useCallback(async () => {
    setCurrentlyReleasing(true);
    setIsUploading(true);
    
  
    if (!selectedImage || !screenSKNName) {
      console.error('Missing button image URI or ScreenSKN name.');
      alert("Error: Missing button image URI or ScreenSKN name.");
      setIsUploading(false);
      setCurrentlyReleasing(false);
      return;
    }
  
    try {
      
      const buttonImageUrl = await uploadImage(selectedImage, (progress) => setUploadProgress(progress));
      
  
      let barImageUrl = null;
      let hasBar = false;
      if (currentUser.screenSKNbar) {
        
        barImageUrl = await uploadImage(currentUser.screenSKNbar, (progress) => setUploadProgress(progress));
        
        hasBar = true;
      }
  
      const firestore = getFirestore();
      const userId = getCurrentUserId();
  
      const screenSKNData = {
        userId,
        artistName: currentUser.name,
        username: currentUser.username,
        screenSKNName,
        buttonImage: buttonImageUrl,
        ...(barImageUrl && { barImage: barImageUrl }),
        hasBar,
      };
  
      const docRef = await addDoc(collection(firestore, "ScreenSKN"), screenSKNData);
      const screenSKNId = docRef.id;
  
      screenSKNData.screenSKNId = screenSKNId;
  
      
      await updateDoc(doc(firestore, "users", userId), {
        ScreenSKN: arrayUnion(screenSKNData)
      });
  
      const updatedScreenSKNArray = [...(currentUser?.ScreenSKN || []), screenSKNData];
      const updatedUserData = { ...currentUser, ScreenSKN: updatedScreenSKNArray };
  
      setUser(updatedUserData);
  
      alert("Success: Your ScreenSKN has been released successfully.");
      
  
    } catch (error) {
      console.error("Failed to complete the ScreenSKN release process:", error);
      alert("Error: An error occurred while releasing your ScreenSKN.");
  
    } finally {
      setIsUploading(false);
      setCurrentlyReleasing(false);
    }
  }, [selectedImage, screenSKNName, currentUser.screenSKNbar, currentUser.name, currentUser.username, setUser]);
  

  const uploadImage = async (uri, onProgress) => {
    try {
      const userId = getCurrentUserId();
      const response = await fetch(uri);

      if (!response.ok) throw new Error(`Network response was not ok, status: ${response.status}`);

      const blob = await response.blob();
      const storageRef = ref(getStorage(), `screenSKN/${userId}/${new Date().toISOString()}`);

      const uploadTask = uploadBytesResumable(storageRef, blob);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            
            onProgress(progress);

            switch (snapshot.state) {
              case 'paused':
                
                break;
              case 'running':
                
                break;
              default:
                
                break;
            }
          },
          (error) => {
            console.error('Upload task error:', error);
            console.error(`Error code: ${error.code}`);
            console.error(`Error message: ${error.message}`);
            if (error.serverResponse) {
              console.error(`Server response: ${error.serverResponse}`);
            }
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              
              resolve(downloadURL);
            }).catch((error) => {
              console.error('Error getting download URL:', error);
              reject(error);
            });
          }
        );
      });
    } catch (error) {
      console.error('An unexpected error occurred during the upload process:', error);
      throw error;
    }
  };

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  return (
    <div style={styles.fullScreenStyle}>
      <div style={styles.scrollContainerStyle}>
        <div style={styles.scrollViewInnerContainerStyle}>
          <h1 style={styles.titleStyle}>Create ScreenSKN</h1>
          <ScreenSKNpicSelector 
            onImagePicked={pickImage}
            style={styles.positionStyle}
            imageUri={selectedImage}
          />
          <input
            style={styles.inputStyle}
            placeholder="Enter ScreenSKN Name"
            value={screenSKNName}
            onChange={(e) => setScreenSKNName(e.target.value.slice(0, 16))}
            maxLength={19}
          />
          <button
            onClick={releaseScreenSKN}
            style={styles.customButtonStyle}
          >
            Release ScreenSKN
          </button>
        </div>
      </div>
      <div style={{ height: bottomAreaHeight, backgroundColor: 'transparent' }} />

      {tempButtonImageUri && (
        <>
          <Modal
            isOpen={tempButtonImageUri}
            onRequestClose={() => tempButtonImageUri(null)}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                zIndex: 10000,
              },
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                height: '450px',
                width: '80%',
                zIndex: 12,
                flexDirection: 'column',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'transparent',
                padding: '20px',
                border: 'none',
                borderRadius: '8px',
              },
            }}
          >
            <div style={styles.circularImagePreviewContainerStyle}>
              <img src={tempButtonImageUri} style={styles.circularImagePreviewStyle} alt="Circular Preview" />
            </div>
            <button onClick={() => document.getElementById('fileInput').click()} style={styles.reCropButtonStyle}>
              <span style={styles.reCropTextStyle}>Change Image</span>
            </button>
            <button onClick={() => confirmImage(false)} style={styles.confirmButtonStyle}>
              <span style={styles.confirmTextStyle}>Confirm</span>
            </button>
            {isUploading && (
              <div style={styles.progressBarContainerStyle}>
                <div style={{ ...styles.progressBarStyle, width: `${uploadProgress}%` }} />
              </div>
            )}
            <div style={styles.cancelContainer}>
              <button style={styles.cancelButton} onClick={() => setTempButtonImageUri(null)}>Cancel</button>
            </div>
          </Modal>
        </>
      )}

      {tempBarImageUri && (
        <>
          <Modal
            isOpen={tempImageUri}
            onRequestClose={() => tempImageUri(null)}
            style={{
              overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.85)',
                zIndex: 10000,
              },
              content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                height: '200px',
                width: '80%',
                zIndex: 12,
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
                backgroundColor: 'transparent',
                padding: '20px',
                border: 'none',
                borderRadius: '8px',
              },
            }}
          >
            <div style={styles.barImagePreviewContainerStyle}>
              <img src={tempBarImageUri} style={styles.barImagePreviewStyle} alt="Bar Preview" />
            </div>
            <button onClick={() => document.getElementById('fileInputBar').click()} style={styles.reCropButtonStyle}>
              <span style={styles.reCropTextStyle}>Re-crop</span>
            </button>
            <button onClick={() => confirmImage(true)} style={styles.confirmButtonStyle}>
              <span style={styles.confirmTextStyle}>Confirm</span>
            </button>
            <div style={styles.cancelContainer}>
              <button style={styles.cancelButton} onClick={() => setTempImageUri(null)}>Cancel</button>
            </div>
          </Modal>
        </>
      )}
              <Modal
  isOpen={currentlyReleasing}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
      zIndex: 1000000
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      background: 'transparent',
      color: 'white',
      borderRadius: '10px',
      padding: '20px',
      textAlign: 'center',
      zIndex: 1000000,
    }
  }}
>
  <h2>Releasing Music...</h2>
</Modal>

      <input type="file" id="fileInputBar" style={{ display: 'none' }} accept="image/*" onChange={pickImageBar} />
    </div>
  );
};

const styles = {
  fullScreenStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '130%',
    position: 'relative',
    zIndex: 10,
  },
  scrollContainerStyle: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%', // Adjust the height calculation as needed
    zIndex: 2,
    overflowY: 'auto',
  },
  scrollViewInnerContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    paddingTop: '11.9vh', // Adjust the padding calculation as needed
    zIndex: 5,
  },
  titleStyle: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginBottom: 20,
    color: 'white',
    fontFamily: 'brhendrix', // Ensure you have this font available, or adjust as necessary
  },
  inputStyle: {
    height: '5vh',
    borderWidth: 1,
    borderColor: '#62fbfa',
    width: '80%',
    marginTop: '20px',
    fontFamily: 'brhendrix',
    fontSize: '16px',
    backgroundColor: 'transparent',
    color: 'white',
    padding: 10,
    marginBottom: 20,
    borderRadius: 5,
  },
  confirmationViewStyle: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 5,
  },
  circularImagePreviewContainerStyle: {
    position: 'relative',
    alignSelf: 'center',
    width: '100%',
    height: '80%', 
    display: 'flex',
    margin: '0 auto',
    borderRadius: '15px',
    overflow: 'hidden',
    zIndex: 400,
    justifyContent: 'center',
    alignItems: 'center', 
  },
  circularImagePreviewStyle: {
    width: '25vh',
    height: '25vh',
    borderRadius: '15vh',
    zIndex: 400,
  },
  barImagePreviewContainerStyle: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: '47vh',
    width: '88%',
    height: '7vh', 
    zIndex: 400,
    justifyContent: 'center',
    alignItems: 'center', 
  },
  barImagePreviewStyle: {
    width: '100%',
    height: '100%',
    zIndex: 400,
    resizeMode: 'contain',
  },
  progressBarContainerStyle: {
    height: '2vh',
    width: '100%',
    bottom: '3vh',
    backgroundColor: '#62fbfa',
    borderRadius: 10,
    zIndex: 200,
  },
  progressBarStyle: {
    height: '100%',
    backgroundColor: '#62fbfa',
    borderRadius: 10,
    zIndex: 200,
  },
  reCropButtonStyle: {
    zIndex: 400,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '0 auto',
    bottom: '20px',
    backgroundColor: 'transparent',
    border: 'none',
  },
  reCropTextStyle: {
    zIndex: 400,
    position: 'relative',
    fontFamily: 'brhendrix',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'light',
    alignSelf: 'center',
  },
  confirmButtonStyle: {
    zIndex: 400,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '0 auto',
    bottom: '10px',
    backgroundColor: 'transparent',
    border: 'none',
  },
  confirmTextStyle: {
    zIndex: 400,
    position: 'relative',
    fontFamily: 'brhendrix',
    color: 'white',
    fontSize: '16px', 
    marginLeft: '10px',
    fontWeight: 'light',
    alignSelf: 'center',
  },
  customButtonStyle: {
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'rgba(0,0,0,0.5)',
    marginBottom: 16,
    width: '80%',
    color: 'white',
    fontSize: '2.4vw',
    fontFamily: 'brhendrix',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  fullScreenImageOverlayStyle: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  },
  cancelContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  cancelButton: {
    padding: '10px 20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '0 auto',
    position: 'absolute',
    bottom: '10px',
    backgroundColor: 'transparent',
    color: '#62fbfa',
    border: 'none',
    fontSize: '20px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  positionStyle: {
    height: '200px',
    width: '200px',
    zIndex: 200,
    objectFit: 'contain',
    marginBottom: '20px',
  }
};

export default ScreenSKNPage;
