import React, { useState, useEffect, useContext } from 'react';
import SPNSKNscreenSKN from './SPNSKNscreenSKN';
import { AppContext } from './AppContext';

const scrollAreaTopPadding = '9vh';
const bottomAreaHeight = '19vh';

const ScreenSKNowned = ({ handlePressBeginsScreenSKN }) => {
  const { currentUser, screenSKNs } = useContext(AppContext);

  return (
    <div style={styles.profilesContainer3}>
      {screenSKNs.length > 0 && (
        <div style={styles.scrollContainer}>
          {screenSKNs.map((item, index) => (
            <SPNSKNscreenSKN key={item.buttonImage} item={item} onPress={handlePressBeginsScreenSKN} />
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  profilesContainer3: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '50px',
    overflow: 'auto', // Updated to allow scrolling
    height: '100vh',  // Ensure the container takes full height of the viewport
  },
  scrollContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignSelf: 'center',
    justifyContent: 'center',
    paddingTop: scrollAreaTopPadding,
    paddingBottom: bottomAreaHeight,
    overflowY: 'scroll',
    marginBottom: '200px',
    maxHeight: '100%',  // Ensure the container can scroll within its parent
  },
};

export default ScreenSKNowned;
