import React, { useState, useEffect } from 'react';

const DynamicHeightImage = ({ uri }) => {
  const [aspectRatio, setAspectRatio] = useState(1);

  useEffect(() => {
    if (uri) {
      const img = new Image();
      img.onload = () => {
        setAspectRatio(img.width / img.height);
      };
      img.onerror = (error) => {
        console.error(error);
      };
      img.src = uri;
    }
  }, [uri]);

  return (
    <div style={{ width: '100%', aspectRatio }}>
      <img
        src={uri}
        alt=""
        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
    </div>
  );
};

export { DynamicHeightImage };
