import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { getFirestore, updateDoc, runTransaction, addDoc, deleteDoc, setDoc, serverTimestamp, doc, getDoc, collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { firestore, storage } from './firebase';
import { AppContext } from './AppContext';
import MYDATProfilePicSelector from './MYDATProfilePicSelector';
import MYDATsongs from './MYDATsongs';
import MYDATscreenSKN from './MYDATscreenSKN';
import MYDATTwoRowScrollView from './MYDATTwoRowScrollView';
import { useNavigate } from 'react-router-dom';
import ProfileFeed from './ProfileFeed';
import blurb from './assets/blurb.png';
import iconFeed from './assets/iconFeed.png';
import iconFeedActive from './assets/iconFeedActive.png';
import iconMusic from './assets/iconMusic.png';
import iconMusicActive from './assets/iconMusicActive.png';
import iconVideo from './assets/iconVideo.png';
import iconVideoActive from './assets/iconVideoActive.png';
import iconStore from './assets/iconStore.png';
import iconBeats from './assets/iconBeats.png';
import iconBeatsActive from './assets/iconBeatsActive.png';
import iconStoreActive from './assets/iconStoreActive.png';
import BlankBG from './assets/BlankBG.png';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import VoiceDropMuteButton from './assets/VoiceDropMuteButton.png';
import VoiceDropMuteButtonMuted from './assets/VoiceDropMuteButtonMuted.png';
import Modal from 'react-modal';
import PlayVoiceDrop from './PlayVoiceDrop';

const width = window.innerWidth;
const height = window.innerHeight;

const bottomAreaHeight = height * 0.2;
const horizontalAreaSpacing = height * 0;
const offsetFromCenter = 236;
const leftPosition = width / 2 - (width / 1.6) / 2 - offsetFromCenter;

const UserProfileInfo = ({ currentUser }) => {
  const [contentWidth, setContentWidth] = useState(0);
  const [iconWidth, setIconWidth] = useState(0);
  

  const onTextLayout = (event) => {
    const { width } = event.target.getBoundingClientRect();
    setContentWidth(width);
  };

  const onIconLayout = (event) => {
    const { width } = event.target.getBoundingClientRect();
    setIconWidth(currentUser?.accountType !== 'Fan' ? width : 0);
  };

  const totalWidth = contentWidth + iconWidth;

  const centeredViewStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    transform: `translateX(${-totalWidth +41 / 2}px)`,
    top: '215px',
    position: 'absolute',
  };

  const isArtistIcon2Visible = ['Cole', '@Cole', '@Sonny', '@Anuva', '@CharlieP', 'Sonny', 'Anuva', 'CharlieP'].includes(currentUser?.username);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '580px' }}>
      <div style={centeredViewStyle}>
        <div
          onLoad={onTextLayout}
          style={styles.profilePicUsername}
        >
          @{currentUser?.username}
        </div>
        {currentUser?.accountType !== 'Fan' && !isArtistIcon2Visible && (
          <img onLoad={onIconLayout} src={require('./assets/ArtistIcon1.png')} style={styles.profilePicIcon} />
        )}
        {currentUser?.accountType !== 'Fan' && isArtistIcon2Visible && (
          <img onLoad={onIconLayout} src={require('./assets/ArtistIcon2.png')} style={styles.profilePicIcon} />
        )}
      </div>
    </div>
  );
};

const BioUpdateComponent = ({ onSave }) => {
  const { currentUser, bio, setBio } = useContext(AppContext);
  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const userId = getCurrentUserId();

  const [lineLimitExceeded, setLineLimitExceeded] = useState(false);
  const maxLength = 400;
  const maxLines = 50;
  const [isEditing, setIsEditing] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const inputRef = useRef(null);
  const prefix = 'Bio: ';

  const updateUserBio = async () => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, { bio }); // Save bio directly
      
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user's bio:", error);
    }
  };

  const handlePressOut2 = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      if (isEditing) {
        updateUserBio();
      }
    }
  };

  useEffect(() => {
    
  }, [isEditing]);

  const handleBioChange = (text) => {
    setBio(text);
  };

  const handleOnFocus = () => {
    setIsEditing(true);
    const cursorPosition = prefix.length + bio.length;
    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  };

  const combinedCharCounterStyle = bio.length === maxLength 
    ? { ...styles.charCounter, ...styles.charLimitReached } 
    : styles.charCounter;

    return (
      <>
      <div style={styles.BioContainer}>
        <div style={styles.accountTypeLabel}>{currentUser?.accountType}</div>
        <textarea
          ref={inputRef}
          style={{ ...styles.textInput, resize: 'none' }}
          onChange={(e) => handleBioChange(e.target.value)}
          value={bio}
          maxLength={maxLength}
          onFocus={handleOnFocus}
          placeholder="Type your bio here..."
        />
        {isEditing && (
          <div style={styles.editingContainer}>
            <div style={combinedCharCounterStyle}>
              {`${bio.length} / ${maxLength}`}
            </div>
            {lineLimitExceeded && <div style={styles.warningText}>Too long</div>}
          </div>
        )}
      </div>
      {isEditing && (
        <button onClick={updateUserBio} style={styles.button}>
        <div style={styles.buttonTitle}>Save</div>
      </button>
      )}
      </>
    );
  };


const BlurbUpdateComponent = ({ onSave }) => {
  const { currentUser, blurb, setBlurb } = useContext(AppContext);

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const userId = getCurrentUserId();

  const [lineLimitExceeded, setLineLimitExceeded] = useState(false);
  const maxLength = 80;
  const maxLines = 10;
  const [isEditing, setIsEditing] = useState(false);

  const updateUserBlurb = async () => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, {
        blurb: blurb,
      });
      
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user's blurb:", error);
    }
  };

  const handlePressOut = () => {
    if (isEditing) {
      updateUserBlurb();
    }
  };


  const combinedCharCounterStyle = blurb.length === maxLength 
    ? { ...styles.charCounterBlurb, ...styles.charLimitReached } 
    : styles.charCounterBlurb;

  return (
    <>
    <div onClick={handlePressOut} style={styles.BlurbContainer}>
      <textarea
        style={{ ...styles.textInputBlurb, resize: 'none' }}
        onChange={(e) => {
          setBlurb(e.target.value);
          setIsEditing(true);
        }}
        value={blurb}
        maxLength={maxLength}
        placeholder="Type your blurb here..."
      />
      {isEditing && (
        <>
          <div style={combinedCharCounterStyle}>
            {`${blurb.length} / ${maxLength}`}
          </div>
          {lineLimitExceeded && <div style={styles.warningTextBlurb}>Too long</div>}
        </>
      )}
    </div>
    {isEditing && (
        <button onClick={updateUserBlurb} style={styles.button2}>
        <div style={styles.buttonTitle2}>Save</div>
      </button>
      )}
    </>
  );
};


const BlurbUpdateComponent2 = ({ onSave }) => {
  const { currentUser, blurb, setBlurb } = useContext(AppContext);

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  const userId = getCurrentUserId();

  const [lineLimitExceeded, setLineLimitExceeded] = useState(false);
  const maxLength = 80;
  const maxLines = 10;
  const [isEditing, setIsEditing] = useState(false);

  const updateUserBlurb = async () => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, {
        blurb: blurb,
      });
      
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user's blurb:", error);
    }
  };



  const handlePressOut = () => {
    if (isEditing) {
      updateUserBlurb();
    }
  };

  return (
    <div onClick={handlePressOut} style={styles.BlurbContainer3}>
      <textarea
        style={styles.textInputBlurb}
        onChange={(e) => {
          setBlurb(e.target.value);
          setIsEditing(true);
        }}
        value={blurb}
        resize="none"
        maxLength={maxLength}
        placeholder="Type your blurb here..."
      />
      {isEditing && (
        <>
          <div style={[styles.charCounterBlurb, blurb.length === maxLength && styles.charLimitReached]}>
            {`${blurb.length} / ${maxLength}`}
          </div>
          {lineLimitExceeded && <div style={styles.warningTextBlurb}>Too long</div>}
        </>
      )}
    </div>
  );
};

const MYDATprofile = ({ activeProfileSection, onSKN, onSectionChange, onPlayVideo, onProfilePress, onMoveMP, onMoveMPProfile }) => {
  const isMounted = useRef(false);
  const handleSKN = (media) => {
    onSKN(media);
  };

  const navigate = useNavigate();

  const [isUploading, setIsUploading] = useState(false);


  const [isEditing, setIsEditing] = useState(false);

  const [visibleItem, setVisibleItem] = useState(null);

  const [isAvatarSelectionVisible, setIsAvatarSelectionVisible] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [screenSKNs, setScreenSKNs] = useState([]);

  const { bio, currentUser, mediaData, musicVideos, videoAlbums, updateProfileImage, setInitialExpandedAlbumAndSong, setArtworkUri, setCenteredRelease, hideExpandedAlbum, activeTab, setInitiallySelectedItemId, setTrackToView, userId2, notificationsVisible, voiceDropMuted, setVoiceDropMuted, imageUri, setImageUri, tempImageUri, setTempImageUri, myPosts, setPostScreenVisible, setMyPosts } = useContext(AppContext);





  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  useEffect(() => {
    
    
  
    if (!currentUser) {
      
      return;
    }
    try {
      setScreenSKNs(currentUser?.ScreenSKN || []);
    } catch (error) {
      console.error("Error setting music data:", error);
    } finally {

    }
  }, [currentUser]);

  const updateUserBio = async () => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, { bio }); // Save bio directly
      
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user's bio:", error);
    }
  };

  const handlePressBegins = async (song) => {
    const clonedSong = JSON.parse(JSON.stringify(song));
    navigate('/view-song', { state: { song: clonedSong } });
  };

  const userId = getCurrentUserId();

  const handleToggleProfile = async (image, section) => {
};

  const updateUserProfileImage = async (imageUrl) => {
    const userDocRef = doc(firestore, "users", userId);
    try {
      await updateDoc(userDocRef, { profileImageUrl: imageUrl });
      updateProfileImage(imageUrl);
      
    } catch (error) {
      console.error("Error updating user's profile image URL in Firestore user document:", error);
    }
  };

  const onImagePicked = async (url) => {
    try {
      await updateUserProfileImage(url);
    } catch (error) {
      console.error('Error updating profile image:', error);
    }
  };

  const uploadImage = async (uri, onProgress) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    const storageRef = ref(storage, 'profile_pictures/' + userId);
    const uploadTask = uploadBytesResumable(storageRef, blob);
    return new Promise((resolve, reject) => {
      uploadTask.on('state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(progress);
        },
        (error) => {
          reject(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const pickNewImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setTempImageUri(imageUrl);
    }
  };

  const pickImage = (imageUri) => {
    setTempImageUri(imageUri);
  };

  const cancelProfilePic = () => {
    setTempImageUri('');
    setImageUri('');
    };
  

  const handleConfirmImage = useCallback(async () => {
    if (!tempImageUri) {
      console.error('No image URI available to upload.');
      return;
    }
    try {
      const uploadedImageUrl = await uploadImage(tempImageUri, (progress) => setUploadProgress(progress));
      await updateUserProfileImage(uploadedImageUrl);
      onImagePicked(uploadedImageUrl);
      setTempImageUri(null);
    } catch (error) {
      console.error("Failed to complete the image confirmation process:", error);
    }
  }, [tempImageUri, onImagePicked]);




  

  const muteVoiceDrop = async () => {
    
    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', userId2);
    try {
      setVoiceDropMuted(prevState => {
        const newState = !prevState;
        updateDoc(userDocRef, {
          voiceDropMuted: newState
        }).then(() => {
          
        }).catch((error) => {
          console.error("Error updating voiceDropMuted:", error);
        });
        return newState;
      });
    } catch (error) {
      console.error("Error toggling voiceDropMuted:", error);
    }
  };

  

  const positionStyle = {
    position: 'absolute',
    left: width * 0.355,
    bottom: height * 0.72,
    zIndex: 200,
  };

  const hasSingles = mediaData.singles.length > 0;
const hasAlbums = mediaData.albums.length > 0;

const albumSectionStyle = hasSingles ? 
    styles.horizontalSection2 : 
    {
        ...styles.horizontalSection2, 
    top: height * 0.558,
    };

const singleSectionStyle = hasAlbums ? 
    styles.horizontalSection : 
    {
        ...styles.horizontalSection, 
    bottom: height * 0.3,
    };


const hasMusicVideos = mediaData.videos.length > 0;
const singleMusicVideo = mediaData.videos.length === 1;


const musicVideosSectionStyle = singleMusicVideo ? 
    {...styles.horizontalSectionMusicVideos,     bottom: height * 0.29, } : 
    styles.horizontalSectionMusicVideos;


    const [activeIcon, setActiveIcon] = useState('feed');

    const handleIconClick = (icon) => {
      setActiveIcon(icon);
    };

    const iconStyles = (icon) => ({
      width: '100px',
      height: '100px',
      cursor: 'pointer',
      zIndex: 10,
      filter: activeIcon === icon ? 'brightness(1.5)' : 'brightness(0.8)',
    });

    const postsLimit = 10;

    const lastVisiblePostRef = useRef(null);

    const [loadingMore, setLoadingMore] = useState(false); // Manage loading state for loading more posts
const [allPostsLoaded, setAllPostsLoaded] = useState(false);

const [isLoading, setIsLoading] = useState(false);


    const fetchPosts = async (loadMore = false) => {
      if (!userId2) {
        setMyPosts([]);
        setAllPostsLoaded(true);
        return;
      }
  
      if (loadMore && allPostsLoaded) return;
      if (isLoading) return; // Prevent new fetch if already loading
  
      setIsLoading(true);
  
      const db = getFirestore();
      let postsQuery = query(
        collection(db, 'posts'),
        where('userId', '==', userId2),
        orderBy('createdAt', 'desc'),
        limit(postsLimit)
      );
  
      if (loadMore && lastVisiblePostRef.current) {
        postsQuery = query(postsQuery, startAfter(lastVisiblePostRef.current));
      }
  
      try {
        const querySnapshot = await getDocs(postsQuery);
        const fetchedPosts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        if (querySnapshot.docs.length > 0) {
          lastVisiblePostRef.current = querySnapshot.docs[querySnapshot.docs.length - 1];
        }
  
        if (!loadMore) {
          setMyPosts(fetchedPosts);
        } else {
          setMyPosts(prevPosts => {
            const newPosts = fetchedPosts.filter(newPost => !prevPosts.some(post => post.id === newPost.id));
            return [...prevPosts, ...newPosts];
          });
        }
  
        if (fetchedPosts.length < postsLimit) {
          setAllPostsLoaded(true);
        } else {
          setAllPostsLoaded(false);
        }
      } catch (error) {
        console.error("Error fetching posts: ", error);
      } finally {
        setIsLoading(false);
      }
    };



    return (
      <div style={styles.fullScreen}>
        <div style={styles.scrollContainerMain}>
          <div style={styles.container}>
          <img src={blurb} style={styles.BlurbContainer2}img/>
            <div style={styles.topSection}>
              <div style={styles.BlurbContainer}>
                <BlurbUpdateComponent onSave={updateUserBio} />
              </div>
              <div style={styles.BlurbContainer3}>
                <BlurbUpdateComponent2 onSave={updateUserBio} />
              </div>
              <div style={styles.avatarContainer}>
                <div style={styles.profilePicWrapper}>
                  <MYDATProfilePicSelector onImagePicked={pickImage} />
                </div>
                <UserProfileInfo currentUser={currentUser} />
                <div style={styles.profileInfoWrapper}>
                <div style={styles.profilePicName}>
                  {currentUser?.name}
                </div>
                <div style={styles.profileLocation}>
                  {currentUser?.location}
                </div>
                </div>
              </div>
              <div style={styles.BioContainer}>
                <BioUpdateComponent onSave={updateUserBio} />
              </div>
            </div>
    

    
            <div style={styles.iconRow}>
              <img
                src={activeIcon === 'feed' ? iconFeedActive : iconFeed}
                style={iconStyles('feed')}
                alt="Feed"
                onClick={() => handleIconClick('feed')}
              />
              {currentUser?.accountType !== 'Fan' && (
                <>
                {currentUser?.accountType !== 
                'SPNCaster' && (
                  <img
                    src={activeIcon === 'music' ? iconMusicActive : iconMusic}
                    style={iconStyles('music')}
                    alt="Music"
                    onClick={() => handleIconClick('music')}
                  />
                )}
<img
  src={currentUser?.accountType === 'Producer' 
    ? (activeIcon === 'video' ? iconBeatsActive : iconBeats) 
    : (activeIcon === 'video' ? iconVideoActive : iconVideo)}
  style={iconStyles('video')}
  alt={currentUser?.accountType === 'Producer' ? "Beats" : "Video"}
  onClick={() => handleIconClick('video')}
/>

                  <img
                    src={activeIcon === 'store' ? iconStoreActive : iconStore}
                    style={iconStyles('store')}
                    alt="Store"
                    onClick={() => handleIconClick('store')}
                  />
                </>
              )}
            </div>

            {currentUser?.accountType !== 'Fan' && (
              <button onClick={(e) => { e.stopPropagation(); muteVoiceDrop(); }} style={styles.muteButton}>
                <img
                  src={voiceDropMuted ? VoiceDropMuteButtonMuted : VoiceDropMuteButton}
                  alt="Mute"
                  style={styles.muteButtonimg}
                />
              </button>
            )}
    
      <div style={{ flexGrow: 1 }}>
        {activeIcon === 'feed' && (
          <ProfileFeed userId={currentUser?.uid} />
        )}
      </div>

      
    
      {activeIcon === 'music' && (
  <>
    {mediaData.singles.length > 0 ? (
      <div style={singleSectionStyle}>
        <div style={styles.horizontalText}>Singles</div>
        <div style={styles.horizontalScroll}>
          {mediaData.singles.map(item => (
            <MYDATsongs key={item.contentId} item={item} onPress={handlePressBegins} />
          ))}
        </div>
      </div>
    ) : mediaData.albums.length === 0 && (
      <button style={styles.tapToReleaseTouchable} onClick={() => navigate('/mydat/mycontent/releasemusic')}>
        <div style={styles.tapToReleaseText}>
          Tap to release your first Single
        </div>
      </button>
    )}

    {mediaData.albums.length > 0 ? (
      <div style={albumSectionStyle}>
        <div style={styles.horizontalText}>Albums</div>
        <div style={styles.horizontalScroll}>
          {mediaData.albums.map(item => (
            <MYDATsongs key={item.contentId} item={item} onPress={handlePressBegins} />
          ))}
        </div>
      </div>
    ) : mediaData.singles.length === 0 && (
      <button style={styles.tapToReleaseTouchable2} onClick={() => navigate('/mydat/mycontent/releasemusic')}>
        <div style={styles.tapToReleaseText}>
          Tap to release your first Album
        </div>
      </button>
    )}

    {/* Add Music Videos section if accountType is 'Producer' */}
    {currentUser.accountType === 'Producer' && mediaData.videos.length > 0 && (
      <div style={musicVideosSectionStyle}>
        <div style={styles.horizontalText}>
        {currentUser.accountType === 'SPNCaster' ? 'SPNCasts' : 'Music Videos'}
        </div>
        <MYDATTwoRowScrollView 
          musicVideos={mediaData.videos} 
          onPress={handlePressBegins} 
        />
      </div>
    )}

    <div style={styles.extraSpace}></div>
  </>
)}

    
    {activeIcon === 'video' && (
  <>
    {currentUser.accountType === 'Producer' ? (
      mediaData?.beats?.length > 0 ? (
        <div style={singleSectionStyle}>
          <div style={styles.horizontalText}>Beats</div>
          <div style={styles.horizontalScroll}>
            {mediaData?.beats?.map(item => (
              <MYDATsongs key={item.contentId} item={item} onPress={handlePressBegins} />
            ))}
          </div>
        </div>
      ) : (
        <button 
          style={styles.tapToReleaseTouchable} 
          onClick={() => navigate('/mydat/mycontent/releasebeats')}
        >
          <div style={styles.tapToReleaseText}>
            Tap to release your first Beat
          </div>
        </button>
      )
    ) : (
      mediaData.videos.length > 0 ? (
        <div style={musicVideosSectionStyle}>
          <div style={styles.horizontalText}>
          {currentUser.accountType === 'SPNCaster' ? 'SPNCasts' : 'Music Videos'}
          </div>
          <MYDATTwoRowScrollView 
            musicVideos={mediaData.videos} 
            onPress={handlePressBegins} 
          />
        </div>
      ) : (
        <button 
          style={styles.tapToReleaseTouchable} 
          onClick={() => navigate(currentUser.accountType === 'SPNCaster' ? '/mydat/mycontent/releasespncast' : '/mydat/mycontent/releasemusicvideos')}
        >
          <div style={styles.tapToReleaseText}>
          {currentUser.accountType === 'SPNCaster' ? 'Tap to release your first SPNCast' : 'Tap to release your first Music Video'}
          </div>
        </button>
      )
    )}
    <div style={styles.extraSpace}></div>
  </>
)}


    
            {activeIcon === 'store' && (
              <>
                {screenSKNs.length > 0 && (
                  <div style={styles.horizontalSectionScreenSKN}>
                    <div style={styles.horizontalText}>ScreenSKN</div>
                    <div style={styles.horizontalScroll}>
                      {screenSKNs.map(item => (
                        <MYDATscreenSKN key={item.buttonImage} item={item} onPress={() => handleSKN(item)} />
                      ))}
                    </div>
                  </div>
                )}
                             <div style={styles.extraSpace}></div>
                             
              </>
            )}
          </div>


    
          <div style={{ height: bottomAreaHeight }} />
        </div>
        <Modal
  isOpen={!!tempImageUri}
  onRequestClose={() => setTempImageUri(null)}
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      zIndex: 10000,
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '400px',
      width: '80%',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'transparent',
      padding: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      border: 'none',
      borderRadius: '8px',
    },
  }}
>
{tempImageUri && (
      <>

        <div style={styles.circularImagePreviewContainer}>
          <img src={tempImageUri} alt="Preview" style={styles.circularImagePreview} />
        </div>
        

        <button style={styles.confirmButton} onClick={handleButtonClick}>
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={pickNewImage}
      />
      <span style={styles.confirmText}>New Image</span>
    </button>

        <button onClick={handleConfirmImage} style={styles.confirmButton}>
          <span style={styles.confirmText}>Confirm</span>
        </button>
        
        <button onClick={cancelProfilePic} style={styles.cancelButton}>
          <span style={styles.confirmText}>Cancel</span>
        </button>

        {isUploading && (
          <div style={styles.progressBarContainer}>
            <div style={{...styles.progressBar, width: `${uploadProgress}%`}} />
          </div>
        )}
      </>
    )}
  </Modal>
  <PlayVoiceDrop />

      </div>
    );
  };
  
  const imageSize = width * 0.3;
  const imageSizeSKN = width * 0.3;
  const circularImageSize = width * 0.3;
  
  const styles = {
    fullScreen: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%', 
overflow: 'hidden',
overflowX: 'hidden',
    },
    confirmationView: {
      position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignSelf: 'center',
  alignItems: 'center',
  flexDirection: 'column',
width: '100%',
height: '100%',
      zIndex: 2000,
      pointerEvents: "none"
    },
    circularImageContainer: {
      position: 'relative',
      left: '7.4%', // Converted to percentage
      bottom: '73.05%', // Converted to percentage
      width: '100px', // Fixed pixel value
      height: '100px', // Fixed pixel value
      borderRadius: '50px', // Fixed pixel value
      overflow: 'hidden',
      zIndex: 400,
      justifyContent: 'center',
      alignItems: 'center',
    },
    circularImage: {
      width: '77.6%', // Already in percentage
      height: '77.6%', // Already in percentage
      borderRadius: '40px', // Fixed pixel value
      zIndex: 400,
    },
    circularImagePreviewContainer: {
      position: 'relative',
      alignSelf: 'center',
      margin: '0 auto',
      display: 'flex',
      width: '250px', // Fixed pixel value
      height: '250px', // Fixed pixel value
      borderRadius: '150px', // Fixed pixel value
      overflow: 'hidden',
      zIndex: 4000,
      justifyContent: 'center',
      alignItems: 'center',
    },
    circularImagePreview: {
      width: '250px', // Fixed pixel value
      height: '250px', // Fixed pixel value
      borderRadius: '150px', // Fixed pixel value
      zIndex: 400,
    },
    reCropButton: {
      zIndex: 4000,
      position: 'relative',
display: 'flex',
justifyContent: 'center',
alignSelf: 'center',
margin: '0 auto',
backgroundColor: 'transparent',
    },
    reCropText: {
      zIndex: 400,
      position: 'absolute',
      fontFamily: 'brhendrix',
      color: 'white',
      fontSize: '16px',
      fontWeight: 'light',
      alignSelf: 'center',
    },
    confirmButton: {
      zIndex: 4000,
      position: 'relative',
      display: 'flex',
      backgroundColor: 'transparent',
      justifyContent: 'center',
alignSelf: 'center',
margin: '0 auto',
marginTop: '10px',
    },
    cancelButton: {
      zIndex: 4000,
      position: 'relative',
      display: 'flex',
      backgroundColor: 'transparent',
      justifyContent: 'center',
alignSelf: 'center',
margin: '0 auto',
marginTop: '10px',
    },
    confirmText: {
      zIndex: 400,
      position: 'relative',
      fontFamily: 'brhendrix',
      color: 'white',
      fontSize: '16px', // Converted to percentage
      fontWeight: 'light',
      alignSelf: 'center',
    },
    extraSpace: {
      height: '500px',
        },
    charCounterBlurb: {
      marginLeft: '8px',
      marginTop: '120px',
      display: 'flex',
      fontFamily: 'brhendrix',
      color: 'white',
  fontSize: '16px',
    },
    charLimitReachedBlurb: {
      right: width * 0.077,
      fontFamily: 'brhendrix',
      color: 'red',
    },
    button: {
      backgroundColor: 'transparent',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      cursor: 'pointer',
      textAlign: 'center',
      textDecoration: 'none',
      display: 'inline-block',
      fontSize: '16px',
      margin: '10px 0',
      marginTop: '110px',
      marginLeft: '85px',
      position: 'absolute',
      borderRadius: '4px',
      zIndex: '100000',
    },
    buttonTitle: {
      fontSize: '16px',
      zIndex: '100000',
    },
    button2: {
      backgroundColor: 'transparent',
      color: 'white',
      padding: '10px 20px',
      border: 'none',
      cursor: 'pointer',
      textAlign: 'center',
      textDecoration: 'none',
      display: 'inline-block',
      fontSize: '16px',
      margin: '10px 0',
      marginTop: '50px',
      position: 'absolute',
      borderRadius: '4px',
      zIndex: '100000',
    },
    buttonTitle2: {
      fontSize: '16px',
      zIndex: '100000',
    },
    textInput: {
      color: 'white',
      fontFamily: 'brhendrix',
      width: '140px',
      height: '160px',
      padding: 10, 
      marginBottom: '60px',
      marginRight: 40,
      marginLeft: '55px',
      textAlignVertical: 'top',
      fontSize: '13px',
      fontWeight: 'bold',
      position: 'absolute',
      border: 'none',
      backgroundColor: 'transparent',
      zIndex: 10,
      resize: 'none',
      overflow: 'hidden',
    },
    textInputBlurb: {
      color: 'white',
      fontFamily: 'brhendrix',
      width: '124px',
      height: '80px',
      
      padding: 7,
      textAlignVertical: 'top',
      fontSize: '12px',
      fontWeight: 'bold',
      lineHeight: '19px',
      border: 'none',
      backgroundColor: 'transparent',
      position: 'absolute',
      zIndex: 10,
      resize: 'none',
    },
    accountTypeLabel: {
      fontSize: '24px',
      fontWeight: 'bold',
      color: '#62fbfa',
      marginBottom: 260,
      textAlign: 'center',
      position: 'absolute',
      zIndex: 10,
    },
    profilePicWrapper: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '-500px', // Adjust margin as needed
    },
    profileInfoWrapper: {
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      flexDirection: 'column',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '-200px', // Adjust margin as needed
    },
    container: {

      alignItems: 'center',
      justifyContent: 'center',

      position: 'relative',
      flexGrow: 1,
    },
    scrollContainerMain: {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      overflowY: 'auto', 
      overflowX: 'hidden',
      zIndex: 10,
    },
    topSection: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: '20px',
    },
    avatarContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    profilePicName: {
      display: 'flex',
      color: 'white',
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center',

    },
    profileLocation: {
      color: '#62fbfa',
      fontSize: '18px',
      textAlign: 'center',
    },
    profilePicUsername: {
      color: 'white',
      fontSize: '20px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '5px',
    },
    profilePicIcon: {
      width: '20px',
      height: '20px',
      marginLeft: '5px',
    },
    BioContainer: {
      marginBottom: '45px',
      display: 'flex',
      width: '150px',
      height: '150px',
      marginRight: '15px',
      marginLeft: '17px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      marginTop: '20px',
      backgroundColor: 'transparent',
      overflow: 'hidden',
    },
    BlurbContainer: {
      marginBottom: '145px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      left: '2px',
      position: 'absolute',
      width: '100%',
      height: '100%',
      marginTop: '20px',
    },
    BlurbContainer3: {
      marginBottom: '145px',
      display: 'flex',
      marginLeft: '15px',
      opacity: 0,
      marginRight: '15px',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      marginTop: '20px',
    },
    BlurbContainer2: {
      left: '-20px',
      top: '-50px',
      marginBottom: '125px',
      alignItems: 'center',
      position: 'absolute',
marginRight: '25px',
      width: '170px',
      height: '200px',
      
      marginTop: '20px',
    },
    muteButton: {
      width: '60px',
      height: '40px',
      position: 'absolute',
      cursor: 'pointer',
      right: '20px',
      marginTop: '-130px',
      border: 'none',
      zIndex: 5000000,
      backgroundColor: 'transparent',
    },
    muteButtonimg: {
      width: '100%',
      height: '100%',
      display: 'flex',
      right: '20px',
      border: 'none',
      zIndex: 500000,
      backgroundColor: 'transparent',
    },
    iconRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: '100%',
      marginTop: '-50px',
    },
    horizontalSectionScreenSKN: {
      marginTop: '-10px',
      marginLeft: '20px',
    },
    horizontalSection: {
      marginTop: '20px',
    },
    horizontalSection2: {
      marginTop: '20px',
    },
    horizontalSectionMusicVideos: {
      marginTop: '20px',
    },
    horizontalText: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: 'white',
      marginBottom: '10px',
      textAlign: 'left',
    },
    horizontalScroll: {
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'scroll',
      zIndex: 10,
    },
    tapToReleaseTouchable: {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      marginLeft: '10px',
      padding: '10px',
      marginTop: '50px',
      backgroundColor: '#62fbfa',
      borderRadius: '8px',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 10,
    },
    tapToReleaseTouchable2: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '10px',
      marginTop: '150px',
      marginLeft: '10px',
      backgroundColor: '#62fbfa',
      borderRadius: '8px',
      cursor: 'pointer',
      position: 'absolute',
      zIndex: 10,
      alignSelf: 'center',
    },
    tapToReleaseText: {
      color: 'black',
      fontSize: '16px',
      textAlign: 'center',
      alignSelf: 'center',
    },
    fullScreenImage: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 0,
    },
  };
  
  export default MYDATprofile;
