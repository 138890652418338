import React, { useState, useContext } from 'react';
import { AppContext } from './AppContext';
import {
  collection, addDoc, query, where, setDoc, getFirestore, Timestamp, getDocs, updateDoc, getDoc, doc, serverTimestamp
} from 'firebase/firestore';

const SharePostPopup = ({ onShare, onCancel, userId }) => {
  const [amount, setAmount] = useState('');
  const { 
    showShareDATPopup, setShowShareDATPopup, currentUser, selectedProfile, centeredRelease, setMessages, credits, activePopupMedia, selectedUserIdShare, followers, userId2, postToShare, setShowSharePostPopup
  } = useContext(AppContext);

  const [inputText, setInputText] = useState('');

  const handleShare = async () => {
    try {
        const currentUserId = userId2;
        const recipientId = selectedUserIdShare;

        if (!followers.includes(recipientId)) {
            alert('You can only send messages to users that follow you.');
            return;
        }

        if (!currentUserId || !recipientId || !postToShare) {
            console.error('Invalid parameters', { currentUserId, recipientId, postToShare });
            return;
        }

        const db = getFirestore();
        const conversationId = [currentUserId, recipientId].sort().join('_');
        const messageId = `msg_${Date.now()}_${Math.random().toString(36).substr(2, 8)}`;
        const currentTimestamp = Timestamp.now();

        const createOrGetConversationId = async () => {
            const senderSummaryRef = doc(db, 'users', currentUserId, 'conversationsSummary', 'summary');
            const recipientSummaryRef = doc(db, 'users', recipientId, 'conversationsSummary', 'summary');

            const senderSummarySnap = await getDoc(senderSummaryRef);
            const recipientSummarySnap = await getDoc(recipientSummaryRef);

            if (!senderSummarySnap.exists()) {
                await setDoc(senderSummaryRef, { conversations: {} });
            }
            if (!recipientSummarySnap.exists()) {
                await setDoc(recipientSummaryRef, { conversations: {} });
            }

            const summaryData = {
                unread: false,
                latestMessageTimestamp: currentTimestamp,
                userId: recipientId,
                accountType: selectedProfile?.accountType || '',
                name: selectedProfile?.name || '',
                username: selectedProfile?.username || '',
                profileImageUrl: selectedProfile?.profileImageUrl || '',
                otherUser: {
                    userId: recipientId,
                    accountType: selectedProfile?.accountType || '',
                    name: selectedProfile?.name || '',
                    username: selectedProfile?.username || '',
                    profileImageUrl: selectedProfile?.profileImageUrl || ''
                }
            };

            const recipientSummaryData = {
                unread: true,
                latestMessageTimestamp: currentTimestamp,
                userId: currentUserId,
                accountType: currentUser?.accountType || '',
                name: currentUser?.name || '',
                username: currentUser?.username || '',
                profileImageUrl: currentUser?.profileImageUrl || '',
                otherUser: {
                    userId: currentUserId,
                    accountType: currentUser?.accountType || '',
                    name: currentUser?.name || '',
                    username: currentUser?.username || '',
                    profileImageUrl: currentUser?.profileImageUrl || ''
                }
            };

            await updateDoc(senderSummaryRef, {
                [`conversations.${conversationId}`]: summaryData
            });

            await updateDoc(recipientSummaryRef, {
                [`conversations.${conversationId}`]: recipientSummaryData
            });

            return conversationId;
        };

        await createOrGetConversationId();

        const sendToConversation = async (convId, userId, notificationNeeded) => {
            const messagesRef = doc(db, 'users', userId, 'messages', convId);
            const conversationDoc = await getDoc(messagesRef);
            let messages = [];

            if (conversationDoc.exists()) {
                const data = conversationDoc.data();
                messages = data.messages || [];
            }

            if (!Array.isArray(messages)) {
                messages = [];
            }

            const newMessage = {
                id: messageId,
                text: inputText,
                mediaUrl: '',
                mediaType: '',
                userId: currentUserId,
                timestamp: currentTimestamp,
                conversationId: convId,
                notificationNeeded: notificationNeeded,
                postId: postToShare.postId,
                post: postToShare,
                username: postToShare.username,
                ...(postToShare?.accountType ? { accountType: postToShare.accountType } : {}),
                ...(postToShare.media ? { media: postToShare.media } : {}),
                ...(postToShare.originalPost ? { originalPost: postToShare.originalPost } : {})
            };

            messages.push(newMessage);

            await setDoc(messagesRef, { messages });
        };

        await sendToConversation(conversationId, currentUserId, false);
        await sendToConversation(conversationId, recipientId, true);

        setInputText('');
        setShowSharePostPopup(false);
    } catch (error) {
        console.error('Error in handleShare:', error);
    }
  };

  return (
    <div style={styles.popupContainer}>
      <div style={styles.touchableWithoutFeedback}>
        <div style={styles.container}>
          <p style={styles.text}>Message Text (optional)</p>
          <textarea
            style={styles.input}
            placeholder="Enter Message"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            rows="4" // Adjust the number of rows to control the height of the textarea
          />

          <div style={styles.shareButtonContainer}>
            <button style={styles.buttonContainer} onClick={handleShare}>
              <span style={styles.buttonText}>SHAREDAT</span>
            </button>
          </div>
          <div style={styles.cancelButtonContainer}>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  popupContainer: {
    width: '100%',
    height: '50%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 800,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 5,
  },
  text: {
    marginBottom: '10px',
    fontSize: '2rem',
    color: 'white',
  },
  input: {
    borderWidth: '1px',
    borderColor: 'white',
    display: 'flex',
    position: 'absolute',
    width: '80%',
    height: '50px',  // Adjusted height for a single line input
    fontSize: '18px',
    padding: '10px',
    marginBottom: '20px',
    color: 'white',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    textAlign: 'left',
    lineHeight: '1.5', // Adjusted line-height for better text spacing
    boxSizing: 'border-box',// Changed to left for multiline input
  },
  shareButtonContainer: {
    marginBottom: '20px',
  },
  cancelButtonContainer: {
    marginBottom: '20px',
  },
  buttonContainer: {
    padding: '10px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '80px',
    backgroundColor: '#6200ea',
    color: 'white',
    cursor: 'pointer',
  },
  buttonText: {
    color: 'white',
    fontSize: '1rem',
  },
  touchableWithoutFeedback: {
    width: '100%',
    height: '100%',
  },
};

export { SharePostPopup };
