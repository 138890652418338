import React, { useEffect, useContext, useState } from 'react';
import { getFirestore, doc, getDoc, query, collection, where, getDocs, updateDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { useLocation, useNavigate } from 'react-router-dom';
import { firestore } from './firebase';
import { RealTimeAgo } from './RealTimeAgo';
import { AppContext } from './AppContext';

const db = getFirestore();
const auth = getAuth();

const NotificationsScreen = ({ onProfilePress, onNavigate, userId }) => {
  const {
    userId2,
    activePopupMedia, likes, notifications,
    setCurrentlyLoadingProfile, setActivePopupMedia, reposts
  } = useContext(AppContext); 

  const location = useLocation();
  const navigate = useNavigate();

  const [userProfile, setUserProfile] = useState(null);

  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  

  const getCurrentUserId = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    return user ? user.uid : null;
  };

  useEffect(() => {
    const userId2 = getCurrentUserId();
    

    if (!userId2) {
      console.error("User ID not found.");
      return;
    }

    const firestore = getFirestore();
    const userDocRef = doc(firestore, 'users', userId2);

    const resetUnreadNotifications = async () => {
      try {
        await updateDoc(userDocRef, { hasUnreadNotifications: false });
        
      } catch (error) {
        console.error("Failed to reset unread notifications status:", error);
      }
    };

    resetUnreadNotifications();
  }, []);

  const handlePressProfile = (profile) => {
    const clonedProfile = JSON.parse(JSON.stringify(profile));
    const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
    navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
  };





  

  useEffect(() => {
    if (isSelecting && activePopupMedia) {
      try {
        onProfilePress(); // Call the function after activePopupMedia has been updated

        setIsSelecting(false);
      } catch (error) {
        console.error("Error in useEffect for activePopupMedia:", error);
      }
    }
  }, [activePopupMedia]);

  const handleProfileSelect = async (profileData) => {
    try {
      setIsSelecting(true);
      const popupData = preparePopupData(profileData);
      handlePressProfile(popupData); // This triggers the useEffect
    } catch (error) {
      console.error('Error in handleProfileSelect:', error);
    }
  };

  const sortReleasesByTimestamp = (releases) => {
    try {
      const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
      return sortedReleases;
    } catch (error) {
      console.error('Error in sortReleasesByTimestamp:', error);
      return [];
    }
  };

  const preparePopupData = (userData) => {
    try {
      
      const albums = Array.isArray(userData?.albums) ? sortReleasesByTimestamp(userData.albums) : [];
      const singles = Array.isArray(userData?.singles) ? sortReleasesByTimestamp(userData.singles) : [];
      const videos = Array.isArray(userData?.videos) ? sortReleasesByTimestamp(userData.videos) : [];

      return {
        artistName: userData?.artistName || userData?.ArtistName || '',
        popupProfileImage: typeof userData?.profileImageUrl === 'string' ? userData.profileImageUrl : '',
        username: typeof userData?.username === 'string' ? userData.username : '',
        userId: typeof userData?.userId === 'string' ? userData.userId : '',
        accountType: typeof userData?.accountType === 'string' ? userData.accountType : '',
        otherReleases: { albums, singles, videos },
        vocalDrop: typeof userData?.vocalDrop === 'string' ? userData?.vocalDrop : false,
        voiceDropMuted: typeof userData?.voiceDropMuted === 'boolean' ? userData?.voiceDropMuted : false,
        bio: typeof userData?.bio === 'string' ? userData.bio : '',
        blurb: typeof userData?.blurb === 'string' ? userData.blurb : '',
        location: typeof userData?.location === 'string' ? userData.location : '',
        timestamp: new Date().getTime(),
        ScreenSKN: Array.isArray(userData?.ScreenSKN) ? userData.ScreenSKN : [],
      };
    } catch (error) {
      console.error('Error in preparePopupData:', error);
      return null;
    }
  };

  const getCachedUserDoc = async (userId) => {
    try {
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        let userData = { ...userDocSnap.data(), userId: userId };
        
        return userData;
      } else {
        console.error(`No document found in Firestore for userId: ${userId}`);
        return null;
      }
    } catch (error) {
      console.error("Error fetching user document:", error);
      return null;
    }
  };

  const fetchUserProfileByUsername = async (username) => {
    if (!username) return;



    try {
      const q = query(collection(firestore, 'users'), where('username', '==', username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const doc = querySnapshot.docs[0];
        const userData = await getCachedUserDoc(doc.id);

        if (userData) {
          handlePressProfile(userData);
        } else {
          console.error(`UserData could not be prepared for username: ${username}`);

        }
      } else {
        console.error(`No user found with username: ${username}`);

      }
    } catch (error) {
      console.error('Error fetching user by username:', error);

    } finally {
      setIsLoading(false);
    }
  };

  const handlePressPost = (post) => {
    // Clone the post object to ensure it only contains cloneable data
    const clonedPost = JSON.parse(JSON.stringify(post));
    navigate('/viewpost', {
      state: {
        post: clonedPost,
        isLiked: !!likes[post.id],
        isReposted: !!reposts[post.id]
      }
    });
  };

  const handlePressNotification = (notification) => {
    try {
      if (
        notification.type === 'newFollower' || 
        notification.type === 'isLive' || 
        notification.type === 'newSingle' || 
        notification.type === 'newAlbum' || 
        notification.type === 'newMusicVideo'
      ) {
        fetchUserProfileByUsername(notification.username); // Assuming the username is part of the notification
      } else if (notification.postId) { // For notifications related to posts
        const postRef = doc(db, 'posts', notification.postId);
        getDoc(postRef).then(documentSnapshot => {
          if (documentSnapshot.exists()) {
            const postData = { id: documentSnapshot.id, ...documentSnapshot.data() };
            const clonedPost = JSON.parse(JSON.stringify(postData));
            navigate('/viewpost', {
              state: {
                post: clonedPost,
                isLiked: notification.isReposted ? !!likes[notification.originalPost] : !!likes[notification.postId],
                isReposted: notification.isReposted
              }
            });
          }
        }).catch(error => {
          console.error('Error fetching post document:', error);
        });
      }
    } catch (error) {
      console.error('Error in handlePressNotification:', error);
    }
  };

  const getNotificationText = (notification) => {
    try {
      switch (notification.type) {
        case 'like':
          return `${notification.name} liked your post!`;
        case 'comment':
          return `${notification.name} commented on your post: "${notification.text}"`;
        case 'taggedComment':
          return `${notification.name} tagged you in a comment: "${notification.text}"`;
        case 'reply':
          return `${notification.name} replied to your comment: "${notification.text}"`;
        case 'taggedReply':
          return `${notification.name} tagged you in a reply: "${notification.text}"`;
        case 'newFollower':
          return `${notification.name} has started following you!`;
        case 'repost':
          return `${notification.name} has reposted your post!`;
        case 'credit_received':
          return `${notification.fromUserName} sent you "${notification.amount}" credits!`;
        case 'consolidatedReplyTag':
          return `${notification.name} replied and tagged you in a comment: "${notification.text}"`;
        case 'isLive':
          return `${notification.name} is now live!`;
        case 'newSingle':
          return `${notification.name} released a new single!`;
        case 'newAlbum':
          return `${notification.name} released a new album!`;
        case 'newMusicVideo':
          return `${notification.name} released a new music video!`;
        default:
          return `${notification.name} tagged you in a post!`;
      }
    } catch (error) {
      console.error('Error in getNotificationText:', error);
      return 'You have a new notification!';
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.scrollContainer}>
        {notifications.map(notification => (
          <div key={notification.id} style={styles.notificationItem} onClick={() => handlePressNotification(notification)}>
            <p style={styles.notificationText}>{getNotificationText(notification)}</p>
            <RealTimeAgo timestamp={notification.timestamp} style={styles.timeAgoStyle} />
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0e061e',
  },
  scrollContainer: {
    marginBottom: '250px',
    marginTop: '80px',
    height: '80%', // Adjust this to change the scrollable area height
    width: '90%',
    overflowY: 'scroll',
    padding: '10px',
    borderRadius: '10px',
  },
  notificationItem: {
    padding: '10px',
    borderBottom: '1px solid #ccc',
    cursor: 'pointer',
  },
  notificationText: {
    fontSize: '16px',
    color: 'white'
  },
  postText: {
    fontSize: '14px',
    color: 'grey'
  },
  timeAgoStyle: {
    fontSize: '12px',
    display: 'flex',
    marginTop: '-10px',
    color: 'grey'
  }
};

export default NotificationsScreen;
