
import React, { createContext, useState } from 'react';

export const CacheContext = createContext();

export const CacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  const persistCache = (key, value) => {
    setCache((prevCache) => ({ ...prevCache, [key]: value }));
  };

  return (
    <CacheContext.Provider value={{ cache, persistCache }}>
      {children}
    </CacheContext.Provider>
  );
};
