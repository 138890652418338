import React, { useState, useRef, useEffect, useContext } from 'react';
import { getAuth } from 'firebase/auth';
import { AppContext } from './AppContext';
import {
  getFirestore,
  doc,
  query,
  where,
  setDoc,
  serverTimestamp,
  getDocs,
  collection,
  limit
} from 'firebase/firestore';
import { firestore } from './firebase';
import { ProfileComponentMyProfile } from './ProfileComponentMyProfile';

const PostScreenShareDAT = ({ centeredRelease, onNavigate, onPost }) => {
  const [text, setText] = useState('');
  const [uploading, setUploading] = useState(false);
  const [tempImageUri, setTempImageUri] = useState(null);

  const {
    showMediaPopup,
    userId2,
    currentUser,
    posts,
    setPosts,
    setCurrentlyLoadingProfile,
    setViewingProfile,
    setActivePopupMedia,
    activePopupMedia
  } = useContext(AppContext);

  const [searchResultsComments, setSearchResultsComments] = useState([]);
  const [isSearchingComments, setIsSearchingComments] = useState(false);
  const [tags, setTags] = useState([]);
  const commentInputRef = useRef(null);
  const [selection, setSelection] = useState({ start: 0, end: 0 });

  useEffect(() => {
    const keyboardDidHide = () => {
      setIsSearchingComments(false);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        keyboardDidHide();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const searchUsers = async (queryText) => {
      const lastAtPos = queryText.lastIndexOf('@');
      if (lastAtPos === -1) {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
        return;
      }

      const spaceAfterAt = queryText.indexOf(' ', lastAtPos);
      if (spaceAfterAt !== -1 && spaceAfterAt < queryText.length) {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
        return;
      }

      const searchQuery = queryText.substring(lastAtPos + 1);
      if (searchQuery.length > 0) {
        setIsSearchingComments(true);
        const searchValue = searchQuery.toLowerCase();
        const usersRef = collection(firestore, 'users');
        const q = query(
          usersRef,
          where('username_lower', '>=', searchValue),
          where('username_lower', '<=', searchValue + '\uf8ff'),
          limit(3)
        );

        try {
          const querySnapshot = await getDocs(q);
          const users = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setSearchResultsComments(users);
        } catch (error) {
          console.error('Error searching users:', error);
          setSearchResultsComments([]);
        }
      } else {
        setIsSearchingComments(false);
        setSearchResultsComments([]);
      }
    };

    if (text.includes('@')) {
      const delayDebounce = setTimeout(() => {
        searchUsers(text);
      }, 300);

      return () => clearTimeout(delayDebounce);
    }
  }, [text]);

  const handleSelectUser = (user) => {
    const cursorPosition = selection.start;
    const lastAtPos = text.lastIndexOf('@', cursorPosition);

    const beforeAt = text.substring(0, lastAtPos);
    const afterAt = text.substring(cursorPosition);

    const newComment = `${beforeAt}@${user.username} ${afterAt}`;
    setText(newComment);

    const newTag = {
      username: user.username,
      userId: user.id,
      start: beforeAt.length + 1,
      end: beforeAt.length + 1 + user.username.length,
    };

    const updatedTags = [...tags.filter((tag) => tag.end <= lastAtPos), newTag].sort((a, b) => a.start - b.start);
    setTags(updatedTags);

    setIsSearchingComments(false);
    setSearchResultsComments([]);

    const newCursorPos = beforeAt.length + user.username.length + 2;
    setSelection({ start: newCursorPos, end: newCursorPos });
  };

  const handleTextChange = (text) => {
    setText(text);

    const lastAtPos = text.lastIndexOf('@');
    const cursorPosition = selection.start;
    if (lastAtPos > (tags.length > 0 ? Math.max(...tags.map((tag) => tag.end)) : -1)) {
      setIsSearchingComments(true);

      const searchQuery = text.substring(lastAtPos + 1, cursorPosition);
      if (text.endsWith(' ') && searchQuery.length > 0) {
        const matchedUser = searchResultsComments.find((user) => user.username.toLowerCase() === searchQuery.trim().toLowerCase());
        if (matchedUser) {
          handleSelectUser(matchedUser);
          return;
        }
      }
    } else {
      setIsSearchingComments(false);
    }

    const adjustedTags = tags
      .map((tag) => {
        if (text.substring(tag.start, tag.end) === `@${tag.username}`) {
          return tag;
        } else {
          const startPos = text.indexOf(`@${tag.username}`);
          if (startPos !== -1) {
            return { ...tag, start: startPos, end: startPos + tag.username.length + 1 };
          }
        }
        return null;
      })
      .filter((tag) => tag !== null);

    setTags(adjustedTags);
  };

  const handlePost = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      alert('Please log in to post');
      return;
    }

    setUploading(true);

    try {
      const userRef = userId2 ? doc(firestore, 'users', userId2) : null;
      const docRef = doc(collection(getFirestore(), 'posts'));
      const media = {
        contentId: centeredRelease.contentId,
        SongName: centeredRelease?.songName || centeredRelease?.albumName || centeredRelease?.SongName,
        ArtistName: centeredRelease?.artistName || centeredRelease?.ArtistName,
        ArtworkURL: centeredRelease?.artworkUrl || centeredRelease?.ArtworkURL || centeredRelease?.VideoThumbnailURL,
        artistUserId: centeredRelease?.userId,
        isAlbum: centeredRelease?.isAlbum || false,
        isVideo: centeredRelease?.isVideo || false,
        username: centeredRelease?.username,
      };
      const postData = {
        id: docRef.id,
        postId: docRef.id,
        text,
        userRef,
        accountType: currentUser.accountType,
        imageUri: centeredRelease.ArtworkURL || '',
        userId: user.uid,
        username: currentUser.username,
        name: currentUser.name,
        profileImageUrl: currentUser.profileImageUrl,
        createdAt: serverTimestamp(),
        likeCount: 0,
        repostCount: 0,
        likes: {},
        comments: [],
        repostUserIds: [],
        media: media,
        tags,
      };

      await setDoc(docRef, postData);

      setPosts((prevPosts) => [...prevPosts, postData]);

      const navigationPostData = {
        ...postData,
        createdAt: {
          seconds: Math.floor(Date.now() / 1000),
          nanoseconds: 0,
        },
      };

      setText('');
      setTempImageUri(null);
    } catch (error) {
      console.error('Error creating post:', error);
      alert('Error creating post. Please try again later.');
    } finally {
      setUploading(false);
    }
  };

  // Updated Styles object to center artwork and reduce its size
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '5vh',
      width: '100%',
      boxSizing: 'border-box',
      maxWidth: '500px', // Reduced maxWidth for smaller component
      margin: '0 auto', // Center the container horizontally
      padding: '0 20px', // Add horizontal padding for better spacing on small screens
    },
    commentSectionContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start', // Align items to the left
    },
    postContainer: {
      width: '90%',
      alignSelf: 'center',
      padding: '15px',
      backgroundColor: 'rgba(0,0,0,0.5)',
      borderRadius: '8px',
      border: '1px solid rgba(23, 195, 250, 0.5)',
      marginTop: '2vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start', // Align contents to the left
      position: 'relative', // For overlay positioning
      boxSizing: 'border-box',
    },
    addCommentContainer: {
      width: '100%',
      marginBottom: '10px',
    },
    textInput: {
      width: '100%',
      fontSize: '16px',
      color: 'white',
      border: 'none',
      resize: 'none',
      marginBottom: '5px',
      textAlign: 'left',
      borderRadius: '10px',
      outline: 'none',
      backgroundColor: 'transparent',
      padding: '10px',
      boxSizing: 'border-box',
      height: '60px',
    },
    characterCount: {
      color: 'white',
      textAlign: 'right',
      fontSize: '12px',
      width: '100%',
      marginBottom: '10px',
    },
    buttonsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center', // Center the button horizontally
      width: '100%',
      marginTop: '10px',
    },
    button: {
      width: '120px',
      height: '50px',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'transparent',
      color: 'white',
      fontSize: '16px',
      border: 'none',
      cursor: 'pointer',
      position: 'relative',
    },
    buttonImage: {
      width: '110%',
      height: '100%',
      borderRadius: '8px',
      objectFit: 'cover',
    },
    imagePreview: {
      width: '120px', // Reduced width to prevent it from taking the whole container
      maxWidth: '200px', // Further reduce maxWidth for smaller size
      height: 'auto', // Let the height adjust automatically
      alignSelf: 'center', // Center the image within the post container
      borderRadius: '10px',
      marginTop: '10px',
      objectFit: 'cover',
    },
    loaderContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      width: '100%',
    },
    overlayContainer: {
      position: 'absolute',
      top: '100%',
      left: '0',
      right: '0',
      zIndex: 5,
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      borderRadius: '5px',
      marginTop: '5px',
      width: '100%',
      boxSizing: 'border-box',
    },
    userSuggestionContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      borderRadius: '5px',
      maxHeight: '150px',
      overflowY: 'auto',
    },
    userSuggestion: {
      color: 'white',
      padding: '8px 10px',
      fontSize: '14px',
      borderRadius: '5px',
      marginVertical: '2px',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      cursor: 'pointer',
      textAlign: 'left',
      border: 'none',
    },
    profileContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
      marginBottom: '10px',
    },
    profileImage: {
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      marginRight: '10px',
    },
    profileInfo: {
      display: 'flex',
      flexDirection: 'column',
      color: 'white',
    },
    profileName: {
      fontSize: '16px',
      fontWeight: 'bold',
    },
    profileUsername: {
      fontSize: '14px',
      color: '#cccccc',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.commentSectionContainer}>
        <div style={styles.postContainer}>
          <ProfileComponentMyProfile />

          <div style={styles.addCommentContainer}>
            <textarea
              ref={commentInputRef}
              placeholder="Share to Feed..."
              value={text}
              onChange={(e) => handleTextChange(e.target.value)}
              style={styles.textInput}
              onSelect={(e) =>
                setSelection({ start: e.target.selectionStart, end: e.target.selectionEnd })
              }
              maxLength={1000}
            />
            <div style={styles.characterCount}>{text.length}/1000</div>
          </div>

          {centeredRelease?.ArtworkURL && (
            <img src={centeredRelease.ArtworkURL} alt="Preview" style={styles.imagePreview} />
          )}



          {isSearchingComments && (
            <div style={styles.overlayContainer}>
              <div style={styles.userSuggestionContainer}>
                {searchResultsComments.map((user) => (
                  <button
                    key={user.id}
                    onClick={() => handleSelectUser(user)}
                    style={styles.userSuggestion}
                  >
                    {user.username}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {uploading ? (
            <div style={styles.loaderContainer}>
              <div className="loader">Loading...</div>
            </div>
          ) : (
            <div style={styles.buttonsRow}>
              <button
                onClick={handlePost}
                style={styles.button}
                disabled={uploading}
                aria-label="Post Data"
              >
                <img
                  src={require('./assets/postDAT.png')}
                  alt="Post"
                  style={styles.buttonImage}
                />
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export { PostScreenShareDAT };
