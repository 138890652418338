import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { AppProvider } from './AppContext';
import { CacheProvider } from './CacheContext';
import LoginScreen from './LoginScreen';
import SignupScreen from './SignupScreen';
import AppWrapped from './AppWrapped';
import PrivacyPolicy from './PrivacyPolicy';
import MYDATprofileUser from './MYDATprofileUser';
import { auth } from './firebase';
import RedirectHandler from './RedirectHandler';
import './globalStyles.css';
import './index.css'; 

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
      setUser(user);
      setLoading(false); // Set loading to false once the auth state is determined
    });

    // Set a timeout to avoid long loading times
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 10000); // Adjust the timeout duration as needed (e.g., 5000ms = 5 seconds)

    return () => {
      unsubscribe();
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts
    };
  }, []);

  const loadingStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#0e061e',
    color: 'white',
    fontSize: '24px',
  };

  if (loading) {
    return (
      <div style={loadingStyles}>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <AppProvider>
      <CacheProvider>
        <Router>
        <RedirectHandler />
          <Routes>
            <Route path="/login" element={!isLoggedIn ? <LoginScreen /> : <Navigate to="/" />} />
            <Route path="/signup" element={!isLoggedIn ? <SignupScreen /> : <Navigate to="/" />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/mydat/profile/:username" element={isLoggedIn ? <MYDATprofileUser /> : <Navigate to="/login" />} />
            <Route path="*" element={isLoggedIn ? <AppWrapped user={user} /> : <Navigate to="/login" />} />
          </Routes>
        </Router>
      </CacheProvider>
    </AppProvider>
  );
};

export default App;
